import React, { useState } from 'react'
import '../styles/InputField.scss'
import { TextField } from '@material-ui/core';

const TextAreaExpandable = ({ type = 'text', name, value, className, label, multiline, onChange, onBlur, autocomplete, disabled, onKeyPress }) => {
    const [togglePassword, setTogglePassword] = useState(false)

    return (

        <div id='xx' className={className}>
            <TextField
                name={name}
                // className={'textarea'}
                className={'xxx'}
                value={value}
                multiline rows={1}
                // multiline rows={4}
                label={label}
                // inputProps={{
                //     style: {
                //         padding: 0
                //     }
                // }}
                variant="outlined"
                disabled={disabled}
                onChange={onChange}
              
            />

         </div>
    )
}

export default TextAreaExpandable