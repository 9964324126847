import React, { useEffect } from 'react';
import FormColumns from "../../Common/FormColumns";
import InputAndActionContainer from "../../Common/InputAndActionContainer";
import InputContainer from "../../Common/InputContainer";
import InputField from "../../../App/components/InputField";
import FormBoxSection from "../../Common/FormBoxSection";
import FormBoxSectionDualScreen from "../../Common/FormBoxSectionDualScreen";
import Dropdown from "../../../App/components/Dropdown";
import useForm from "../../../helpers/customHooks/useForm";
import { updateEducation } from "../../../App/store/user";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";

const EducationEditing = props => {

    const { data } = useSelector(state => state.user.introduction)
    const { educations } = data
    const initialState = [{
        institution: '',
        subject: '',
        typeOfDegree: ''
    }]
    const { handleChange, handleSubmit, values, setValues, isEdited, setIsEdited } = useForm([], submit, validate)
    const dispatch = useDispatch()

    function submit() {
        dispatch(updateEducation(values))
        setIsEdited(false)
    }

    function validate(v) {
        return {}
    }

    useEffect(() => {
        if (!educations.length)
            return setValues(initialState)
        return setValues(educations)
    }, [])

    const removeItem = (index) => {
        const filteredArr = [...values]
        filteredArr.splice(index, 1);
        setValues(filteredArr)
        setIsEdited(true)
    }

    return (
        <form className='mt-3r-1r-res' id={'education'} action="" onSubmit={handleSubmit}>
            <FormBoxSectionDualScreen title={"Education"}>
                {!educations ? <Loader /> : < >
                    <div className={'d-flex flex-column py-2'}>
                        {values.map((item, index) => {
                            return <div key={index}>
                                {
                                    <a href={'#experience'} className={'link-small'}
                                        onClick={() => removeItem(index)}>Remove</a>}
                                <InputContainer>
                                    <InputField label={"Institution"} name={'institution'}
                                        value={item.institution}
                                        onChange={(e) => handleChange(e, index)}
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <InputField label={"Subject"} name={'subject'} value={item.subject || ''}
                                        onChange={(e) => handleChange(e, index)} />
                                </InputContainer>
                                <InputContainer>
                                    <Dropdown options={[{ name: 'Associate\'s degree', value: 'Associate\'s degree' }, {
                                        name: 'Bachelor\'s degree',
                                        value: 'Bachelor\'s degree'
                                    }, {
                                        name: 'Master\'s degree',
                                        value: 'Master\'s degree'
                                    }, { name: 'Doctoral degree', value: 'Doctoral degree' }]}
                                        label={'Select type of Degree'} onChange={(e) => handleChange(e, index)}
                                        name={'typeOfDegree'}
                                        value={item.typeOfDegree || ''}
                                    />
                                </InputContainer></div>
                        })}
                        <div>
                            {/*{calculateWordFromNum(values.length)}*/}
                            <p><a href={'#education'} className={'link-small'}
                                onClick={() => setValues([...values, initialState])}>Add new education </a>
                            </p>
                            <div>
                                {isEdited && <button className={'btn btn-primary'} type='submit'>Save</button>}

                            </div>
                        </div>
                    </div>
                </>}
            </FormBoxSectionDualScreen>
        </form>
    );
};

EducationEditing.propTypes = {};

export default EducationEditing;
