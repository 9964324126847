import React from 'react'
import MessageScss from './style/Message.module.scss'


const AddToNetworkMessage = (props) => {
    // console.log(props)
    const { name, type } = props

    // console.log(name)
    // console.log(type)

    if (type == 'add_to_network') {
        return (
            <div className={MessageScss.message_div}>
                <div className={MessageScss.message_text}>Friend request has been sent to</div>
                <div className={MessageScss.message_text}>{name}</div>
                {/* <div className={MessageScss.message_text}>{type}</div> */}
            </div>
        )
    } else if (type == 'simple_message') {
        return (
            <div className={MessageScss.message_div}>
                <div className={MessageScss.message_text}>{name}</div>
                {/* <div className={MessageScss.message_text}>invite has already been sent</div> */}
            </div>
        )
    }else{
        return (
            <div className={MessageScss.message_div}>
                <div className={MessageScss.message_text}>Just a message</div>
            </div>
        )
    }
}

export default AddToNetworkMessage



// return (

//     // <div className={MessageScss.message_div + ' bor-o bac '}>
//     <div className={MessageScss.message_div}>
//         <div className={MessageScss.message_text}>Friend request has been sent to</div>
//         <div className={MessageScss.message_text}>{name.name}</div>
//     </div>
// )

//----------------
{/* <div className={MessageScss.ww  + ' bor-o bac'}>
<div className={MessageScss.yy + ' bor-g bac'}>
    <div className={MessageScss.zz  + ' bor '}>asd</div>
    <div className={MessageScss.zz  + ' bor '}>zzz</div>
    <div>asdasd</div>
</div>
</div> */}


{/* <div onClick={() => { console.log(name.name) }} className={MessageScss.ww}>{`Friend request has been sent to ${name.name || `someone`}`}  </div> */ }