import React from 'react';
import '../../styles/Projects.scss'
import Image from "../../../App/components/Image";

const CommentInfoBoard = ({children}) => {
    return (
        <div className={'comment pr-5 '}>
            <div></div>
            <Image className={'simple-border bg-white rounded-circle mr-4 comment-img'}
                   w={40} h={40} src="/assets/images/logo.svg"
            />
            <div className={'bg-gray p-3 comment-content'}>{children}</div>

        </div>

    );
};

CommentInfoBoard.propTypes = {};

export default CommentInfoBoard;