import {createSlice} from '@reduxjs/toolkit'
import api from "../config/api";

const initialState = {
    data: {},
    isLoading: true,
    error: false,
}

const slice = createSlice({
    name: 'advancedSearch',
    initialState: initialState,
    reducers: {
        advancedSearchSuccess: (state, action) => {
            state.data = action.payload.body
            state.isLoading = false
        },
    },
});
export default slice.reducer
// Actions
const {
    advancedSearchSuccess
} = slice.actions


export const getAdvancedSearchResults = (data) => async dispatch => {
    try {
        const res = await api.post('/api/advanced-search', data)
        dispatch(advancedSearchSuccess(res.data))
    } catch (e) {
        return console.error(e.message);
    }
}








