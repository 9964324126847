import React from 'react';
import '../styles/InputAndActionContainer.scss'

const InputAndActionContainer = ({ children, className, dualScreen, PlacesAutocomplete }) => {

    if (dualScreen === true && PlacesAutocomplete == true) {
        return (
            <div className={'row flex-wrap input-action'}>
                <div
                    className={"col-md-9 input-action-text-left text-left " + className}>{children}</div>
                {/* <div
                    className="col-md-6 input-action-text text-left d-flex align-items-center pl-5">{children[1] && children[1]}</div> */}
            </div>
        );

    }

    else if (dualScreen === true) {
        return (
            <div className={'row flex-wrap input-action'}>
                <div
                    className={"col-md-9 input-action-text-left text-left " + className}>{children[0] ? children[0] : children}</div>
                {/* <div
                    className="col-md-6 input-action-text text-left d-flex align-items-center pl-5">{children[1] && children[1]}</div> */}
            </div>
        );

    } else {
        return (
            <div className={'row flex-wrap input-action'}>
                <div
                    className={"col-md-6 input-action-text-left text-left " + className}>{children[0] ? children[0] : children}</div>
                <div
                    className="col-md-6 input-action-text text-left d-flex align-items-center pl-5">{children[1] && children[1]}</div>
            </div>
        );
    }
};

InputAndActionContainer.propTypes = {};

export default InputAndActionContainer;