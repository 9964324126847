import React, {useState} from 'react';
import InputField from "../../../App/components/InputField";
import Button from "../../../App/components/Button";
import {useHistory} from "react-router";
import Checkbox from "../../../App/components/Checkbox";
import Modal from "../../../App/components/Modal";

const CreateProjectModalContent = props => {
    const [name, setName] = useState('')
    const [openModal1, setOpenModal1] = useState(false)
    const history = useHistory()

    const handleChange = (e) => {
        [e.target.name] = e.target.value
        setName(e.target.value)
    }

    return (
        <>
            <form>
                <div className=" corner-radius10 bg-white p-3">
                    <div className={'d-flex justify-content-between'}>
                        <h4 className={'font-weight-bold'}>Create New Project</h4>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu neque pulvinar,
                        tristique
                        orci ut, feugiat justo.</p>
                    <div>
                        <InputField label={"Project Name"}
                                    className={'mb-4'} name={'name'} value={name} onChange={handleChange}/>
                        <InputField multiline label={"Short Description"}
                                    className={'mb-4'}/>
                        <div className={'row justify-content-between  align-items-center'}>
                            <div className="col-md-6">
                                <InputField type={'date'} label={"Date"} className={'mb-4'}/>
                            </div>
                            <div className="col-md-6">
                                <InputField type={'date'} label={"End Date"} className={'mb-4'}/>
                            </div>
                        </div>
                        <Checkbox>Enable end date</Checkbox>
                    </div>
                    <div className="d-flex justify-content-end mt-4">
                        <Button className={'btn-blue'}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setOpenModal1(true)
                                }}>Next</Button>
                    </div>
                </div>
            </form>
            {openModal1 && <Modal onClick={() => setOpenModal1(false)}>
                <form>
                    <div className=" corner-radius10 bg-white p-3">
                        <div className={'d-flex justify-content-between'}>
                            <h4 className={'font-weight-bold'}>Create New Project</h4>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu neque pulvinar,
                            tristique
                            orci ut, feugiat justo.</p>
                        <div>
                            {/*<div className="mb-4">*/}
                            {/*    <DropZone*/}
                            {/*        title={<div>Drag your image or <a href={''} className={'link-small'}>browse</a>*/}
                            {/*        </div>}/></div>*/}
                            {/*<div className="mb-4">*/}
                            {/*    <DropZone*/}
                            {/*        title={<div>Drag your image or <a href={''} className={'link-small'}>browse</a>*/}
                            {/*        </div>}/></div>*/}
                            <InputField name={'projectName'} label={"Project Website"}
                                        className={'mb-4'}/>

                        </div>
                        <div className="d-flex justify-content-between mt-4">
                            <Button className={'btn-white'} onClick={() => setOpenModal1(false)}
                            >Back</Button>
                            <Button className={'btn-blue'}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        history.push(`/create-project?name=${name || "AECF"}`)
                                        setOpenModal1(false)
                                    }}
                            >Create</Button>
                        </div>
                    </div>
                </form>
            </Modal>
            }
        </>
    );
};

CreateProjectModalContent.propTypes = {};

export default CreateProjectModalContent;