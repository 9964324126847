import React from 'react';
import ProfileInfoBoxSection from "../../Common/ProfileInfoBoxSection";

const CompanyType = ({data}) => {
    const {aliasIdentity} = data
    return (
        <ProfileInfoBoxSection className={''} title={"Company Type"}>
            <div className="profile-info">
                <div>
                    <span>Founded</span>
                    <p>{aliasIdentity.openSince}</p>
                    <span>Ownership</span>
                    <p>{aliasIdentity.ownership}</p>
                    <span>Influence</span>
                    <p>{aliasIdentity.influence}</p>
                    <span>Sector</span>
                    <p>{aliasIdentity.sector}</p>
                    <span>Sub Sector</span>
                    <p>{aliasIdentity.subSector}</p>
                    <span>Company Size</span>
                    <p>{aliasIdentity.size.toLowerCase()}</p>
                </div>
            </div>
        </ProfileInfoBoxSection>
    );
};

CompanyType.propTypes = {};

export default CompanyType;