import React, {useRef, useState} from 'react';
import MenuDots from "../../../App/components/MenuDots";
import Image from "../../../App/components/Image";
import Checkbox from "../../../App/components/Checkbox";
import {useClickAway} from "react-use";

const ActivityItem = ({children, src, name, status, statusColor, hours, dateFrom, dateTo, className, checkbox}) => {
    const [openDotsDropdown, setOpenDotsDropdown] = useState(false)
    const ref = useRef(null);
    useClickAway(ref, (event) => {
        setOpenDotsDropdown(false);
    });
    return (
        <div className={'d-flex corner-radius5  align-items-center p-2 mb-3 bg-white ' + className}>
            {name && <div className="col-md-3 d-flex align-items-center">
                {checkbox && <div style={{marginTop: -20, marginRight: 5}}><Checkbox/></div>}
                <Image className={'mr-3'}
                       src={src}
                       w={40} h={40}/>
                <b>{name}</b>
            </div>}
            <div className={`col-md-${name ? '2' : '4'} d-flex align-items-center`}>
                <div style={{
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    background: statusColor,
                    marginRight: 10,
                    flexShrink: 0
                }}></div>
                <div> {status}</div>
            </div>
            <div className="col-md-1"><b>{hours}</b></div>
            <div className="col-md-3">
                <div>From: <b>
                    {dateFrom || 'Mon, 29 Nov 2020'}
                </b></div>
                <div>To: <b>
                    {dateTo || 'Tue, 30 Nov 2020'}
                </b></div>

            </div>
            <div className={`col-md-${name ? '3' : '4'} d-flex justify-content-end align-items-center`}
                 style={{position: "relative"}}>
                <div className={'d-flex'}>
                    {children}
                </div>
                <MenuDots onClick={() => setOpenDotsDropdown(true)} className={'dots-vertical'}/>
                {openDotsDropdown &&
                <div style={{position: "absolute", top: 10, right: 23}} className={'dots-dropdown'} ref={ref}>
                    <div className={'mb-1'}><a href="" className={'link-small'}>Open Profile</a></div>
                    <div className={'mb-1'}><a href="" className={'link-small'}>View Contract</a></div>
                    <div className={'mb-1'}><a href="" className={'link-small'}>View Project</a></div>
                    <div className={'mb-1'}><a href="" className={'link-small'}>Report a problem</a></div>
                    <div className={'mb-1'}><a href="" className={'link-small'}>Delete encounter</a></div>
                    <div><a href="" className={'link-small'}>Report</a></div>
                </div>}
            </div>
        </div>
    );
};

ActivityItem.propTypes = {};

export default ActivityItem;