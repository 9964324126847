import React, {useEffect, useState} from 'react';
import Button from "../../../App/components/Button";
import AdvancedSearchFields from "./AdvancedSearchFields";
import IndividualUserFields from "./IndividualUserFields";
import CompanyFields from "./CompanyFields";
import InvestmentFundFields from "./InvestmentFundFields";
import BusinessAssociationFields from "./BusinessAssociationFields";
import FreelanceProfileFields from "./FreelanceProfileFields";
import ServiceProviderFields from "./ServiceProviderFields";
import '../styles/AdvancedSearchBar.scss'
import {useSearchParam} from "react-use";
import useForm from "../../../helpers/customHooks/useForm";
import {useDispatch} from "react-redux";
import {getAdvancedSearchResults} from "../../../App/store/advancedSearch";

const AdvancedSearchModal = ({setOpenModal, advancedSearch, setAdvancedSearch, handleChangeSearch}) => {
    const searchParams = useSearchParam('search')
    const [individualUserFields, setIndividualUserFields] = useState([])
    const [companyFields, setCompanyFields] = useState([])
    const [investmentFundFields, setInvestmentFundFields] = useState([])
    const [businessAssociationFields, setBusinessAssociationFields] = useState([])
    const [freelanceProfileFields, setFreelanceProfileFields] = useState([])
    const [serviceProviderFields, setServiceProviderFields] = useState([])
    const [filter, setFilter] = useState({})
    const dispatch = useDispatch()
    const [allFilters, setAllFilters] = useState(
        [
            {type: "post", fields: [], selected: false},
            {type: "individualUser", fields: [], selected: false},
            {type: "company", fields: [], selected: false},
            {type: "investmentFund", fields: [], selected: false},
            {type: "businessAssociation", fields: [], selected: false},
            {type: "freelanceProfile", fields: [], selected: false},
            {type: "serviceProvider", fields: [], selected: false},
            {type: "contract", fields: [], selected: false},
            {type: "opportunity", fields: [], selected: false},
            {type: "projects", fields: [], selected: false}
        ]
    )
    const filtersArr = [
        {
            name: 'Post',
            child: null
        }, {
            name: 'Individual User',
            child: <IndividualUserFields filter={filter}
                                         setFilter={setFilter}
                                         searchFields={individualUserFields}
                                         setSearchFields={setIndividualUserFields}/>
        }, {
            name: 'Company',
            child: <CompanyFields filter={filter}
                                  setFilter={setFilter}
                                  searchFields={companyFields}
                                  setSearchFields={setCompanyFields}/>
        }, {
            name: 'Investment Fund',
            child: <InvestmentFundFields filter={filter}
                                         setFilter={setFilter}
                                         searchFields={investmentFundFields}
                                         setSearchFields={setInvestmentFundFields}/>
        }, {
            name: 'Business Association',
            child: <BusinessAssociationFields filter={filter}
                                              setFilter={setFilter}
                                              searchFields={businessAssociationFields}
                                              setSearchFields={setBusinessAssociationFields}/>
        }, {
            name: 'Freelance Profile',
            child: <FreelanceProfileFields filter={filter}
                                           setFilter={setFilter}
                                           searchFields={freelanceProfileFields}
                                           setSearchFields={setFreelanceProfileFields}/>
        }, {
            name: 'ServiceProvider',
            child: <ServiceProviderFields filter={filter}
                                          setFilter={setFilter}
                                          searchFields={serviceProviderFields}
                                          setSearchFields={setServiceProviderFields}/>
        }, {
            name: 'Contract',
            child: null
        }, {
            name: 'Opportunity',
            child: null
        }, {
            name: 'Projects',
            child: null
        }
    ]

    const {handleSubmit} = useForm(advancedSearch, submit, validate)

    useEffect(() => {
        searchParams && setAdvancedSearch(searchParams)
    }, [])


    function submit() {
        const selectedFilters = []
        const allSelected = []

        allFilters.map((item) => {
            if (item.selected === true) {
                return selectedFilters.push({
                    type: item.type,
                    fields: [...item.fields, {name: "name", value: advancedSearch}],
                });
            } else {
                return allSelected.push({type: item.type, fields: [{name: "name", value: advancedSearch}]});
            }
        });

        const data = selectedFilters.length ? selectedFilters : allSelected
        dispatch(getAdvancedSearchResults(data))
        setOpenModal(false)
        //
        // api.post('/api/advanced-search', data)
        //     .then((res) => {
        //         console.log(res.data)
        //     }).catch(e => console.log(e))
    }

    function validate(v) {
        return {}
    }

    useEffect(() => {
        if (Object.keys(filter).length) {
            const fieldIndex = allFilters.findIndex(item => item.type === filter.type);
            setAllFilters([...allFilters.slice(0, fieldIndex), filter, ...allFilters.slice(fieldIndex + 1)])
        }
    }, [filter])

    return (
        <form action="" onSubmit={handleSubmit}>
            <div className={'advanced-search-modal'}>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <h5 className={'m-0'}><b>Advanced Search</b></h5>
                    <div><Button className={'btn-blue'}>Search</Button></div>
                </div>
                <div className={'advanced-search-field border-bottom mb-3'}>
                    <input name={'search'} value={advancedSearch} onChange={handleChangeSearch}
                           placeholder={'Search'}
                           className={'advanced-input'} type="text"/>
                </div>
                <div className={'mb-4'}>The quick, brown fox jumps over a lazy dog <br/>
                    Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs.
                </div>

                <div className={'advanced-search-modal-fields'}>
                    {filtersArr.map((item, i) => {
                        return <AdvancedSearchFields key={i} allFilters={allFilters}
                                                     setAllFilters={setAllFilters}
                                                     name={item.name}
                                                     index={i}>
                            {item.child}
                        </AdvancedSearchFields>
                    })}
                </div>
                <div className={'d-flex justify-content-end mt-3'}>
                    {/*<a href="#"><b>Clear</b></a>*/}
                </div>
            </div>
        </form>
    );
};

AdvancedSearchModal.propTypes = {};

export default AdvancedSearchModal;