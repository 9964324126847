import React, {useEffect, useState} from 'react';
import Header from "../../Common/Header";
import Tabs from "../../../App/components/Tabs";
import ContractingOverview from "../components/ContractingOverview";
import Editor from "../../Common/Editor";
import MenuContracting from '../components/MenuContracting'
import {useSearchParam} from "react-use";
import DocumentUploader from "../components/DocumentUploader";
import Modal from "../../../App/components/Modal";
import {Prompt, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getContractById} from "../../../App/store/contracting";
import ContractingParties from "../components/ContractingParties";

const Contract = props => {
    const [modalOpen, setModalOpen] = useState(false)
    const [isSaved, setIsSaved] = useState(true)
    const modalQuery = useSearchParam('modal')
    const [fileContent, setFileContent] = useState(null)
    const [documentTitle, setDocumentTitle] = useState('')
    const params = useParams()
    const dispatch = useDispatch()
    const {data, isLoading} = useSelector(state => state.contracting.contract)
    const {individualUserProfile} = useSelector(state => state.user.account.data)


    useEffect(() => {
        setModalOpen(!!modalQuery)
        console.log(!!modalQuery)
        // !Object.keys(data).length &&
        if (params.id)
            dispatch(getContractById(params.id))
    }, [])

    useEffect(() => {
        if (!isLoading && params.id) {
            setDocumentTitle(data.name)
            setFileContent(data.data)
            console.log(data.data)
        }
    }, [data])


    useEffect(() => {
        if (fileContent)
            setModalOpen(false)
    }, [fileContent])


    return (
        <div>
            <div>
                <Header/>
                {modalOpen &&
                <Modal title={'Upload Document'} className={'text-left'}
                       onClick={() => setModalOpen(false)}>
                    <p className={'my-4'}>Please upload document in the following supported file types:<b> docx,
                        txt.</b></p>
                    <DocumentUploader setIsSaved={setIsSaved}
                                      setFileContent={setFileContent} setDocumentTitle={setDocumentTitle}/></Modal>}
                <div className=""><MenuContracting setModalOpen={setModalOpen}/></div>
                <Prompt
                    when={!isSaved}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                <div className="col-md-11 offset-md-1 ">
                    <div className={'dashboard-inner-container'}>
                        <div className="row">
                            <div className="col-md-7 mt-2">
                                <Editor setIsSaved={setIsSaved} isSaved={isSaved} fileContent={fileContent}
                                        setFileContent={setFileContent}
                                        documentTitle={documentTitle}/>
                            </div>
                            <div className="col-md-5">
                                <Tabs
                                    className={'tabs-dashboard'}
                                    tabs={['Overview', 'Chat', 'Parties', 'History',]}>
                                    <ContractingOverview setIsSaved={setIsSaved} setDocumentTitle={setDocumentTitle}
                                                         documentTitle={documentTitle} fileContent={fileContent}/>
                                    <div>
                                        {individualUserProfile && <iframe style={{height: '85vh', width: "100%"}}
                                                                          src={`http://directventures.ekonet.mk:8080/?user1=${individualUserProfile.firstName}&user2=Darko`}
                                                                          frameBorder="0"/>}

                                    </div>
                                    <div>
                                        {/*<img src="/assets/images/contract_parties.png" className={'w-100'} alt=""/>*/}
                                        <ContractingParties/>
                                    </div>
                                    <div>
                                        <img src="/assets/images/contract_history.png" className={'w-100'} alt=""/>

                                    </div>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Contract.propTypes = {};

export default Contract;