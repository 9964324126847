import React from 'react'

const SocialLinks = () => {
    return (
        <>
            <a><img className='mr-4' src={'/assets/images/LinkedIn.svg'}/></a>
            <a><img className='mr-4' src={'/assets/images/Facebook.svg'}/></a>
            <a><img className='mr-4' src={'/assets/images/Twitter.svg'}/></a>
            <a><img className='mr-4' src={'/assets/images/YouTube.svg'}/></a>
        </>
    )
}

export default SocialLinks