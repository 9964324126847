import React from 'react';
import MenuBar from "../../Common/MenuBar";
import Tabs from "../../../App/components/Tabs";
import Introduction from "../Creation/Introduction";
import Profession from "../Creation/Profession";
import Qualification from "../Creation/Qualification";
import Availability from "../Creation/Availability";
import Group from "../Creation/Group";
import Verification from "../Creation/Verification";
import Header from "../../Common/Header";

const FreelanceProfileCreation = props => {
    return (
        <div>
            <Header/>
            <div className=""><MenuBar page={'Company'}/></div>
            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container'}>
                    <Tabs
                        className={'tabs-dashboard'}
                        tabs={['Introduction', 'Profession', 'Qualification', 'Availability', 'Group', 'Media', 'Verification']}>
                        <Introduction/>
                        <Profession/>
                        <Qualification/>
                        <Availability/>
                        <Group/>
                        <div>media</div>
                        <Verification/>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

FreelanceProfileCreation.propTypes = {};

export default FreelanceProfileCreation;