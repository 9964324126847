import React from 'react'
import Map from './Map';
import OVCss from './styles/OpportunitiesView.module.scss'

const LocationOpportunitiesView = ({ props }) => {

    const { location, latitude, longitude } = props

    return (

        <section className={` mt-3 margin-b-1 d-flex flex-wrap  ${OVCss.border_gray} ${OVCss.form_box} `}>
            <div className="col-md-12 d-flex flex-column">

                <div>
                    <div className={OVCss.view_profile_form_title + ' mt-2 '}>Location</div>
                </div>


                <div>
                    <div className={OVCss.view_profile_form_normal_text}>{location || 'no chosen location'}</div>
                </div>

                <div className={"row flex-wrap justify-content-center align-items-center pb-4"}>
                    <Map latitude={latitude} longitude={longitude} />
                </div>

            </div>
        </section>
    )
}

export default LocationOpportunitiesView