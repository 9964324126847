import { createSlice } from '@reduxjs/toolkit'
import api from "../config/api";
import { checkAliasType } from "../../helpers/helpers";

const initialState = {
    data: {},
    isLoading: true,
    error: false,
}

const slice = createSlice({
    name: 'aliases',
    initialState: initialState,

    reducers: {
        createAliasIdentitySuccess: (state, action) => {
            const aliasType = checkAliasType(action.payload.aliasType).variable
            const id = action.payload.id
            state.data = { ...state.data, activeId: id }
            state.data[aliasType] = {
                ...state.data[id]
            }
            // state.data[aliasType] = {
            //     ...state.data
            // }
            state.data[aliasType][id] = { aliasIdentity: {} }
            state.data[aliasType][id].aliasIdentity = action.payload
            state.isLoading = false
        },
        updateAliasIdentitySuccess: (state, action) => {
            const aliasType = checkAliasType(action.payload.aliasIdentity.aliasType).variable
            const id = action.payload.aliasIdentity.id
            console.log(action.payload)
            state.data[aliasType][id] = action.payload
            state.isLoading = false
        },
        getAliasIntroductionSuccess: (state, action) => {
            const aliasType = checkAliasType(action.payload.aliasIdentity.aliasType).variable
            const id = action.payload.id
            // state.data[aliasType] ={...state.data}

            state.data[aliasType] = {}
            state.data[aliasType][id] = action.payload
            state.isLoading = false

        },
        setActiveAlias: (state, action) => {
            const id = action.payload.id
            if (id) {
                const routeEdit = checkAliasType(action.payload.aliasIdentity.aliasType).route
                const routeView = checkAliasType(action.payload.aliasIdentity.aliasType).routeProfile

                state.data.activeAlias = {
                    name: action.payload.aliasIdentity.name,
                    publicUrl: action.payload.aliasIdentity.publicUrl,
                    routeEdit,
                    routeView,
                    id
                }
            } else {
                state.data.activeAlias = null
            }

        },
        requestBegin: (state, action) => {
            const { part, aliasType, id } = action.payload
            const type = checkAliasType(aliasType).variable
            state.data[type][id][part].updating = true

        },
        requestFinish: (state, action) => {
            const { part, aliasType, id } = action.payload
            const type = checkAliasType(aliasType).variable
            state.data[type][id][part].updating = false
            // if (typeof state[action.payload.tab].data[action.payload.part] === "boolean") {
            //     console.log('bool')
            // } else
            //     state[tab].data[part].updating = false

        }

    },
});
export default slice.reducer
// Actions
const {
    createAliasIdentitySuccess, getAliasIntroductionSuccess, updateAliasIdentitySuccess, setActiveAlias, requestBegin, requestFinish
} = slice.actions


export const createAlias = (data) => async dispatch => {
    console.log(data)
    try {
        const res = await api.post('/api/alias-identity', data)
        return dispatch(createAliasIdentitySuccess(res.data))
    } catch (e) {
        return console.error(e.message);
    }
}
// export const createAlias = (data) => async dispatch => {
//     try {
//         const res = await api.post('/api/alias-identity', data)
//         return dispatch(createAliasIdentitySuccess(res.data))
//     } catch (e) {
//         return console.error(e.message);
//     }
// }

export const getAliasIntroductionById = (id) => async dispatch => {
    try {
        const res = await api.get('/api/alias-introduction/' + id)
        return dispatch(getAliasIntroductionSuccess({ ...res.data, id }))
    } catch (e) {
        return console.error(e.message);
    }
}


export const setActiveAliasById = (id) => async dispatch => {
    try {
        const res = await api.get('/api/alias-introduction/' + id)
        return dispatch(setActiveAlias({ ...res.data, id }))
    } catch (e) {
        if (id === null)
            dispatch(setActiveAlias({ id }))
        return console.error(e.message);
    }
}

export const updateAlias = (data) => async dispatch => {
    dispatch(requestBegin({ ...data, part: 'aliasIdentity' }))
    try {
        const res = await api.put('/api/alias-identity', data)
        dispatch(requestFinish({ ...data, part: 'aliasIdentity' }))
        return dispatch(updateAliasIdentitySuccess(res.data))
    } catch (e) {
        return console.error(e.message);
    }
}





