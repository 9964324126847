import React from 'react';
import Dropdown from "../../../App/components/Dropdown";
import Button from "../../../App/components/Button";

const PitchEditing = props => {
    return (
        <form>
            <div className=" corner-radius10 bg-white p-3">
                <div className={'d-flex justify-content-between'}>
                    <h4 className={'font-weight-bold'}>Pitch</h4>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu neque pulvinar, tristique
                    orci ut, feugiat justo.</p>
                <div>
                    <Dropdown options={[{name: 'Full project description', value: 'Full project description'}]}
                              label={"Full project description"}
                              className={'mb-4'}/>
                    <Dropdown options={[{name: 'Opportunity', value: 'Opportunity'}]}
                              label={"Opportunity"}
                              className={'mb-4'}/>
                    <Dropdown options={[{name: 'Market', value: 'Market'}]}
                              label={"Market"}
                              className={'mb-4'}/>
                    <Dropdown options={[{name: 'Expenditures', value: 'Expenditures'}]}
                              label={"Expenditures"}
                              className={'mb-4'}/>
                    <div><h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                        <a href="" className={'link-small'}>Add more fields</a></div>

                    <div className="d-flex justify-content-between mt-3">
                        <Button className={'btn-white'}>Delete</Button>
                        <Button className={'btn-blue'}>Update</Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

PitchEditing.propTypes = {};

export default PitchEditing;