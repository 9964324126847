import React from 'react';
import SubscriptionCard from "./SubscriptionCard";
import Button from "../../App/components/Button";

const Subscription = props => {
    return (
        <div className={'row px-5 mt-5 text-center d-flex flex-wrap justify-content-around'}>
            <div className="col-md-4 d-flex justify-content-center subscription  card-free">
                <SubscriptionCard src={"https://via.placeholder.com/200x150"}
                                  title={'Free'}
                                  price={'0.00'}
                                  pricePer={'Per Month'}>
                    <Button className={'btn-block btn-gray'}>Free</Button>
                    <div>
                        <h6>On-Demand Learning</h6>
                        <p>Reach out to hiring managers and recruiters via InMail to indicate interest in an open
                            role. InMail is 2.6x more effective than emails alone.
                        </p>
                    </div>
                    <div>
                        <h6>Monthly InMail</h6>
                        <p>Access over 15,000 expert-led LinkedIn Learning courses to hone your skills or learn
                            something new.
                        </p>
                    </div>
                    <div>
                        <h6>Who’s Viewed Your Profile</h6>
                        <p>Find out whether a hiring manager or recruiter has viewed your profile.
                        </p>
                    </div>
                    <div>
                        <h6>Applicant Insights</h6>
                        <p>Get directed to open roles where you’d be a fit based on your skills, past experience,
                            salary requirements, and education.
                        </p>
                    </div>

                </SubscriptionCard>
            </div>
            <div className="col-md-4 d-flex justify-content-center subscription card-basic">
                <SubscriptionCard src={"https://via.placeholder.com/200x150"}
                                  title={'Basic'}
                                  price={'9.99'}
                                  pricePer={'Per Month'}>
                    <Button className={'btn-block btn-white'}>Subscribe</Button>
                    <div>
                        <h6>On-Demand Learning</h6>
                        <p>Reach out to hiring managers and recruiters via InMail to indicate interest in an open
                            role. InMail is 2.6x more effective than emails alone.
                        </p>
                    </div>
                    <div>
                        <h6>Monthly InMail</h6>
                        <p>Access over 15,000 expert-led LinkedIn Learning courses to hone your skills or learn
                            something new.
                        </p>
                    </div>
                    <div>
                        <h6>Who’s Viewed Your Profile</h6>
                        <p>Find out whether a hiring manager or recruiter has viewed your profile.
                        </p>
                    </div>
                    <div>
                        <h6>Applicant Insights</h6>
                        <p>Get directed to open roles where you’d be a fit based on your skills, past experience,
                            salary requirements, and education.
                        </p>
                    </div>

                </SubscriptionCard>
            </div>
            <div className="col-md-4 d-flex justify-content-center subscription">
                <SubscriptionCard src={"https://via.placeholder.com/200x150"}
                                  title={'Premium'}
                                  price={'19.99'}
                                  pricePer={'Per Month'}>
                    <Button className={'btn-block btn-blue'}>Go Premium</Button>
                    <div>
                        <h6>On-Demand Learning</h6>
                        <p>Reach out to hiring managers and recruiters via InMail to indicate interest in an open
                            role. InMail is 2.6x more effective than emails alone.
                        </p>
                    </div>
                    <div>
                        <h6>Monthly InMail</h6>
                        <p>Access over 15,000 expert-led LinkedIn Learning courses to hone your skills or learn
                            something new.
                        </p>
                    </div>
                    <div>
                        <h6>Who’s Viewed Your Profile</h6>
                        <p>Find out whether a hiring manager or recruiter has viewed your profile.
                        </p>
                    </div>
                    <div>
                        <h6>Applicant Insights</h6>
                        <p>Get directed to open roles where you’d be a fit based on your skills, past experience,
                            salary requirements, and education.
                        </p>
                    </div>
                </SubscriptionCard>
            </div>
        </div>
    );
};

Subscription.propTypes = {};

export default Subscription;