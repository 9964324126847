import React from 'react'
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import OVCss from './styles/OpportunitiesView.module.scss';

const OverviewBriefView = () => {
    const params = useParams()
    const { individualUserProfile, locations } = useSelector(state => state.user.account.data)
    const { bio, dateOfBirth } = individualUserProfile || {}
    const { educations, experiences } = useSelector(state => state.user.introduction.data)
    const { userProfileData } = useSelector(state => state.users)
    const { individualUserAccountResponse, individualUserIntroductionResponse } = userProfileData.data || {}

    //-------------------------------
    // const introduction = useSelector(state => state.user.introduction.data)
    const { introduction } = useSelector(state => state.user)


    return (
        <section className={` mt-3 margin-b-1 d-flex flex-wrap  ${OVCss.border_gray} ${OVCss.form_box} `}>
            <div className="col-md-12 d-flex flex-column">

                <div>
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Overview</div>
                    {/* moe za proba */}
                    <div className={OVCss.view_profile_form_normal_text}>{!params.id ? bio : individualUserAccountResponse.body.individualUserProfile.bio}</div>
                    {/* moe za proba */}
                </div>

                <div>
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Date of birth</div>
                    {/* moe za proba */}
                    <div className={OVCss.view_profile_form_normal_text}>{!params.id ? dateOfBirth : individualUserAccountResponse.body.individualUserProfile.dateOfBirth}</div>
                    {/* moe za proba */}
                </div>

                <div>
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}>From</div>
                    {/* moe za proba */}
                    {((!params.id ? locations : individualUserIntroductionResponse.body.locations) || []).map((item, i) => {
                        return <div className={OVCss.view_profile_form_normal_text} key={i}>{item.stateOrCity} • {item.country}</div>
                    })}
                    {/* moe za proba */}
                </div>
                <div>
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Education</div>
                    {/* moe za proba */}
                    {((!params.id ? educations : individualUserIntroductionResponse.body.educations) || []).map((item, i) => {
                        return <div className={OVCss.view_profile_form_normal_text} key={i}>{item.institution} • {item.subject} • {item.typeOfDegree}</div>
                    })}
                    {/* moe za proba */}
                </div>
                <div>
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Experience</div>
                    {/* moe za proba */}
                    {(!params.id ? experiences : individualUserIntroductionResponse.body.experiences).map((item, i) => {
                        return <div className={OVCss.view_profile_form_normal_text} key={i}>{item.company} • {item.position} • {item.startDate} - {item.endDate}</div>
                    })}
                    {/* moe za proba */}
                </div>






            </div>
        </section>
    );
}

export default OverviewBriefView