/**
  * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
  * 
  * @param {String} text The text to be rendered.
  * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
  * 
  * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
  */

function getTextWidth(text, font) {
    // re-use canvas object for better performance
    const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
}

function getCssStyle(element, prop) {
    return window.getComputedStyle(element, null).getPropertyValue(prop);
}

function getCanvasFontSize(el = document.body) {
    const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
    const fontSize = getCssStyle(el, 'font-size') || '16px';
    const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';

    return `${fontWeight} ${fontSize} ${fontFamily}`;
}

//   const cutString = (string, num) => {

//     let str = string.length <= num ? string : `${string.substring(0, num - 4)}...`
//     return str
//   }


// function adjustStringWidth(params, sayWhen) {
//     let str = params

//     while (getTextWidth(str, "bold 12pt arial") > sayWhen) {
//         str = str.substring(0, str.length - 2)
//     }

//     return `${str} ...`
//     // return str
// }

export const adjustStringWidth = (params, sayWhen) => {
    let str = params
    if (getTextWidth(str, "bold 15px Montserrat") < sayWhen) { return str }

    while (getTextWidth(str, "bold 15px Montserrat") > sayWhen) {
        str = str.substring(0, str.length - 2)
    }
    return `${str} ...`

}