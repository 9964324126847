import React from 'react';
import MenuDots from "../../../App/components/MenuDots";
import Image from "../../../App/components/Image";
import {randomMonth, randomWeekday} from "../../../helpers/helpers";

const ProjectUpdatesItem = ({name, src}) => {
    return (
        <div
            className={'simple-border corner-radius5 bg-white d-flex justify-content-between py-3 px-4 align-items-center mb-3'}>
            <div className={'d-flex align-items-center'}>
                <Image w={40} h={40} className={'simple-border rounded-circle mr-4'} src={src}
                />
                <div className={'d-flex'}>
                    <div className={'mr-2'}><b>{name}</b></div>
                    - <div className={'ml-2'}> Updated to {Math.floor((Math.random() * 20) + 1)} dos</div>
                </div>
            </div>
            <div>
                inCharge 6
            </div>
            <div className={'d-flex align-items-center'}>
                <div><b>{randomWeekday()}, {Math.floor((Math.random() * 31) + 1)} {randomMonth()} 2020</b></div>
                <MenuDots className={'dots-vertical'}/>
            </div>
        </div>
    );
};

ProjectUpdatesItem.propTypes = {};

export default ProjectUpdatesItem;