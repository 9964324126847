import React, { useState, useEffect } from 'react';
import Experience_2 from './OpportunityComponents_2/Experience_2';
import BusinessAndTrade from './BusinessAndTrade';
import InvestInStartUps from './InvestInStartUps';
import Location from './OpportunityComponents_2/Location';
import Loader from "../../Common/Loader";
import Button from '../../../App/components/Button';
import InfoView_2 from '../shared_components/InfoView_2';
import Pitch from '../shared_components/Pitch';
import Places from './OpportunityComponents_2/Places';
import api from "../../../App/config/api";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import NoDataMessageDiv from './NoDataMessageDiv';
import InfoOpportunitiesView from './InfoOpportunitiesView';
import PitchOpportunitiesView from './PitchOpportunitiesView';
import ExperienceOpportunitiesView from './ExperienceOpportunitiesView';
import LocationOpportunitiesView from './LocationOpportunitiesView';




const PurposeInfo = props => {
    const params = useParams()

    const { account } = useSelector((state) => state.user);
    const [userId, setUserId] = useState(account.data.individualUserProfile.id);

    const [data, setData] = useState()
    const [length, setLength] = useState()
    const [opp, setOpp] = useState()
    const [isDataLoaded, setIsDataLoaded] = useState(false)


    const getPurpose = async () => {
        try {
            const res = await api.get(`/api/purposes?userId=${params.id ? params.id : userId}&userType=0`)
            setLength(res.data.body.length - 1)

            setOpp({
                opportunity: res.data.body[0],
                index: 0
            })
            // setData(res.data.body.length > 0 ? res.data.body : null)
            setData(res.data.body)
            setIsDataLoaded(true)

        } catch (e) {
            return console.error(e.message);
        }
    }

    useEffect(() => {
        getPurpose()
    }, [])

    return (
        <>

            {!isDataLoaded ? <Loader /> : data.length < 1 ?

                <NoDataMessageDiv message={'user doesnt have any purpose yet'} />
                : <div>

                    {/* <InfoView_2 props={opp.opportunity} /> */}
                    <InfoOpportunitiesView props={opp.opportunity} />
                    {/* {opp.opportunity.experience && <Experience_2 experience={opp.opportunity.experience} />} */}
                    {opp.opportunity.experience && <ExperienceOpportunitiesView experience={opp.opportunity.experience} />}
                    {/* <Pitch props={opp.opportunity} /> */}

                    {/* <Places /> */}
                    {/* <Location props={opp.opportunity} /> */}
                    <LocationOpportunitiesView props={opp.opportunity} />

                    <div className='d-flex justify-content-end g-opportunity-view-naviation-buttons-div'>

                        {/* Back */}
                        <div className='g-opportunity-view-naviation-buttons'
                            onClick={() => {
                                opp.index - 1 >= 0 && setOpp({ opportunity: data[opp.index - 1], index: opp.index - 1 });
                                window.scrollTo(0, 0);
                            }}>
                            <img className={'g-opportunity-view-naviation-arrow-img'} src="\assets\images\left_arrow_gray.svg" alt="" />
                        </div>

                        {/* First List Item */}
                        {data && <div className={'g-opportunity-view-naviation-buttons ' + (opp.index == 0 ? ' active-navigation-button ' : null)} onClick={() => {
                            setOpp({ opportunity: data[0], index: 0 })
                            window.scrollTo(0, 0);
                        }} >1</div>}

                        {/*listing rest of the items */}
                        {data && data.map((x, i) => {
                            if (i != data.length - 1 && i !== 0) {
                                if (i == opp.index - 1 || i == opp.index || i == opp.index + 1 || i == opp.index + 2) {
                                    return (
                                        <div key={i} className={' g-opportunity-view-naviation-buttons ' + (opp.index == i ? ' active-navigation-button ' : null)} onClick={(e) => {

                                            setOpp({ opportunity: data[i], index: i })
                                            window.scrollTo(0, 0);
                                        }} >{i + 1}</div>
                                    )
                                }
                            }
                        })}

                        {/* Three dots ... */}
                        {data && (data.length - opp.index) > 4 && <div className='g-opportunity-view-naviation-buttons'  >...</div>}

                        {/* Last List Item */}
                        {data && data.length > 1 && <div className={'g-opportunity-view-naviation-buttons ' + (opp.index == data.length - 1 ? ' active-navigation-button ' : null)} onClick={() => {
                            setOpp({ opportunity: data[data.length - 1], index: data.length - 1 })
                            window.scrollTo(0, 0);
                        }} >{data.length}</div>}

                        <div className='g-opportunity-view-naviation-input-page-wrap'>
                            <div className='g-opportunity-view-naviation-input-page-text'>
                                go to page:
                            </div>
                        </div>

                        {/* input 'page' that you wanna visit */}
                        {data && <input type='number' className='g-opportunity-view-naviation-buttons g-opportunity-view-select-opp-input '
                            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    console.log('enter press here! ')
                                    if (e.currentTarget.value > 0 && e.currentTarget.value <= data.length) {
                                        setOpp({ opportunity: data[e.currentTarget.value - 1], index: e.currentTarget.value - 1 })
                                        window.scrollTo(0, 0);
                                    }
                                }
                            }} />}

                        {/* Next */}
                        <div className='g-opportunity-view-naviation-buttons'
                            onClick={() => {
                                opp.index + 1 <= length && setOpp({ opportunity: data[opp.index + 1], index: opp.index + 1 });
                                window.scrollTo(0, 0);
                            }}>
                            <img className={'g-opportunity-view-naviation-arrow-img'} src="\assets\images\right_arrow_gray.svg" alt="" />
                        </div>
                    </div>
                </div>}
        </>
    );
};

PurposeInfo.propTypes = {};

export default PurposeInfo;

