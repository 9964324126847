import React from 'react';
import '../styles/Button.scss'

const Button = ({children, className, onClick, type, disabled, onKeyPress}) => {
    return (
        <button disabled={disabled} className={'button ' + className} onClick={onClick} type={type}
                onKeyUp={onKeyPress}>
            {children}
        </button>
    );
};

Button.propTypes = {};

export default Button;
