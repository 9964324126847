import React, { useEffect } from 'react';
import FormBoxSection from "../../Common/FormBoxSection";
import FormBoxSectionDualScreen from "../../Common/FormBoxSectionDualScreen";
import FormColumns from "../../Common/FormColumns";
import InputAndActionContainer from "../../Common/InputAndActionContainer";
import InputContainer from "../../Common/InputContainer";
import InputField from "../../../App/components/InputField";
import useForm from "../../../helpers/customHooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { updateExperience } from "../../../App/store/user";
import Loader from "../../Common/Loader";

const ExperienceEditing = props => {

    const { data } = useSelector(state => state.user.introduction)
    const { experiences } = data
    const initialState = [{
        company: '',
        position: '',
        startDate: '',
        endDate: ''
    }]
    const { handleChange, handleSubmit, values, setValues, isEdited, setIsEdited } = useForm(initialState, submit, validate)
    const dispatch = useDispatch()

    function submit() {
        dispatch(updateExperience(values))
        setIsEdited(false)
    }

    function validate(v) {
        return {}
    }

    useEffect(() => {
        if (!experiences.length)
            return setValues(initialState)
        return setValues(experiences)
    }, [])

    const removeItem = (index) => {
        const filteredArr = [...values]
        filteredArr.splice(index, 1);
        setValues(filteredArr)
        setIsEdited(true)
    }

    return (
        <form id={'experience'} action="" onSubmit={handleSubmit}>
            <FormBoxSectionDualScreen title={"Experience"}>
                {!experiences ? <Loader /> :
                    < >
                        <div className={'d-flex flex-column py-2 '}>
                            {values.map((item, index) => {
                                return <div key={index}>
                                    <a href={'#experience'} className={'link-small'}
                                        onClick={() => removeItem(index)}>Remove</a>
                                    <InputContainer >
                                        <InputField label={"Company"} name={'company'} value={item.company}
                                            onChange={(e) => handleChange(e, index)} />

                                    </InputContainer>
                                    <InputContainer >
                                        <InputField label={"Position"} name={'position'} value={item.position}
                                            onChange={(e) => handleChange(e, index)} />

                                    </InputContainer>
                                    <InputContainer >
                                        <InputField type={'date'} label={"Start Date"} name={'startDate'}
                                            value={item.startDate}
                                            onChange={(e) => handleChange(e, index)} />

                                    </InputContainer>
                                    <InputContainer >
                                        <InputField type={'date'} label={"End Date"} name={'endDate'} value={item.endDate}
                                            onChange={(e) => handleChange(e, index)} />

                                    </InputContainer></div>
                            })}
                            <>
                                <div>
                                    <p><a href={'#experience'} className={'link-small'}
                                        onClick={() => setValues([...values, {
                                            company: '',
                                            position: '',
                                            startDate: '',
                                            endDate: ''
                                        }])}>Add new experience</a></p>
                                </div>
                                <div>
                                    {isEdited && <button className={'btn btn-primary'} type='submit'>Save</button>}

                                </div>
                            </>
                        </div>

                    </>}
            </FormBoxSectionDualScreen>
        </form>
    );
};

ExperienceEditing.propTypes = {};

export default ExperienceEditing;