import React from 'react';
import InputField from "../../../App/components/InputField";
import Dropdown from "../../../App/components/Dropdown";
import Button from "../../../App/components/Button";

const InfoEditing = props => {
    return (
        <form>
            <div className=" corner-radius10 bg-white p-3">
                <div className={'d-flex justify-content-between'}>
                    <h4 className={'font-weight-bold'}>Info</h4>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu neque pulvinar, tristique
                    orci ut, feugiat justo.</p>
                <div>
                    <InputField multiline label={"Description"} className={'mb-4'}/> 
                    <InputField label={"Start Date"} className={'mb-4'}/>
                    <InputField label={"Project Website"} className={'mb-4'}/>
                    <Dropdown options={[{name: 'Privatecorp inc.', value: 'Privatecorp inc.'}]} label={"Ownership"}
                              className={'mb-4'}/>
                    <Dropdown options={[{name: 'Tangible', value: 'Tangible'}]} label={"Contribution"}
                              className={'mb-4'}/>
                    <InputField label={"Project Budget"} className={'mb-4'}/>

                    
                    <div className="row mb-4">
                        <div className="col-md-6"><InputField label={"Sector"}/></div>
                        <div className="col-md-6"><InputField label={"Subsector"}/></div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <Button className={'btn-blue'}>Update</Button>
                    </div>
                </div>

            </div>

        </form>
    );
};

InfoEditing.propTypes = {};

export default InfoEditing;