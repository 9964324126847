import React, {useEffect, useState} from 'react';
import InputWithAutocomplete from "../../App/components/InputWithAutocomplete";
import Tabs from "../../App/components/Tabs";
import Image from "../../App/components/Image";
import api from "../../App/config/api";
import {useSelector} from "react-redux";
import chatapi from "../../App/config/chatapi";

const ChatHeaderMenu = props => {
    const [showChat, setShowChat] = useState(false)
    const [receiverUser, setReceiverUser] = useState(0)
    const [rocketChatId, setrocketChatId] = useState(0)
    const [allUsers, setAllUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])
    const [firstName, setFirstName] = useState('')
    const {data, isLoading} = useSelector(state => state.user.account)
    const {individualUserProfile} = data
    const message_map = useSelector(state => state.user.message_map)

    useEffect(() => {
        const getAllUsers = async () => {
            const res = await api.get(`/api/friends-chat?userType=INDIVIDUAL_USER`)
            setAllUsers(res.data)
            setFilteredUsers(res.data)
        }
        getAllUsers()
    }, [])



    const searchUsers = (e) => {
        const searchField = e.target.value
        if (searchField) {
            const filtered = allUsers.filter((item, i) => {
                return item.firstName.toLowerCase().includes(searchField)
                    || item.lastName.toLowerCase().includes(searchField)
            })
            return setFilteredUsers(filtered)
        }
        return setFilteredUsers(allUsers)
    }

    const chat = () => {
        setShowChat(false);
        document.querySelector('iframe').contentWindow.postMessage({
            externalCommand: 'login-with-token',
            token: localStorage.getItem('chat-token')
        }, '*');
    }

    const openUserChat =(id)=>{
        document.querySelector('iframe').contentWindow.postMessage({
            externalCommand: 'go',
            path: '/direct/9EWNuMBRGQjXfbzFADeENecRDpNFeGSwsA'
        });
    }
    const call = ()=>{
        window.open('https://meet.jit.si/dventures-' + rocketChatId, '_blank')
    }

    return (
        <div>
            <div className={'d-flex px-3 justify-content-between align-items-center'}>
                <InputWithAutocomplete label={"Search"} name={'search'} onChange={searchUsers}/>
                <div><i className={'fas fa-comments'}> </i></div>
                <div className={'mr-3'}><i className={'fas fa-cog '}> </i></div>
            </div>
            <div className="tabs-border-bottom">

                <Tabs onClick={() => chat()} className={'tabs-header-menu'}
                      tabs={['All', 'Requests']}>
                    {showChat ?
                        <div className={'chat-img'}>

                            {individualUserProfile && firstName &&
                            <div>
                                <div className={'tabs-head'}>
                                    <div style={{paddingTop: '10px'}} onClick={call} >
                                        <i className={'fas fa-phone'}></i>
                                    </div>
                                </div>

                                <iframe style={{height: 500, width: "100%"}}
                                        src={`http://devchat.devdv.ekonet.mk/direct/` + rocketChatId + '?layout=embedded' }
                                        frameBorder="0"/>
                            </div> }
                        </div> :
                        <div>
                            <div>
                                <h6>Connections</h6>
                                {filteredUsers.map((item, i) => {
                                    console.log(item);
                                    return <div key={i}
                                                className="d-flex align-items-center justify-content-between mb-3">
                                        <div className={'d-flex align-items-center pointer'}
                                             onClick={() => {

                                                 setShowChat(true);
                                                 setReceiverUser(item.friendShipId)
                                                 setFirstName(item.firstName)
                                                 setrocketChatId(item.chatRoomId)
                                             }}>
                                            <Image w={50} h={50}
                                                   src={`http://localhost:3000/home`}
                                                   alt=""/>
                                            <div className={'ml-3'}>
                                                <p className={'mb-2'}>{item.firstName + " " + item.lastName}</p>
                                                <div style={{fontSize: '0.6rem', color:'red'}}> { message_map[item.chatRoomId] } new messages </div>
                                                <div style={{fontSize: '0.8rem'}}>Available</div>
                                            </div>
                                        </div>
                                    </div>
                                })}

                                {/*    <div className="d-flex align-items-center justify-content-between mb-3">*/}
                                {/*        <div className={'d-flex align-items-center pointer'}*/}
                                {/*             onClick={() => setShowChat(true)}>*/}
                                {/*            <Image w={50} h={50}*/}
                                {/*                   src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnTeOroNP_UHQzoQ0wVepOD5mPaUbBm8V_tNlM4AYhseRLXCY&s"*/}
                                {/*                   alt=""/>*/}

                                {/*            <div className={'ml-3'}>*/}
                                {/*                <p className={'mb-2'}>Sam Jones</p>*/}
                                {/*                <div style={{fontSize: '0.8rem'}}>Offline</div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="d-flex align-items-center justify-content-between mb-3">*/}
                                {/*        <div className={'d-flex align-items-center pointer'}*/}
                                {/*             onClick={() => setShowChat(true)}>*/}
                                {/*            <Image w={50} h={50}*/}
                                {/*                   src="https://www.venmond.com/demo/vendroid/img/avatar/big.jpg"*/}
                                {/*                   alt=""/>*/}

                                {/*            <div className={'ml-3'}>*/}
                                {/*                <p className={'mb-2'}>Sarah Cox</p>*/}
                                {/*                <div style={{fontSize: '0.8rem'}}>Away 1 min</div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="d-flex align-items-center justify-content-between mb-3">*/}
                                {/*        <div className={'d-flex align-items-center pointer'}*/}
                                {/*             onClick={() => setShowChat(true)}>*/}
                                {/*            <Image w={50} h={50}*/}
                                {/*                   src="https://preview.keenthemes.com/metronic-v4/theme/assets/pages/img/avatars/team4.jpg"*/}
                                {/*                   alt=""/>*/}

                                {/*            <div className={'ml-3'}>*/}
                                {/*                <p className={'mb-2'}>Nadine Pigeon</p>*/}
                                {/*                <div style={{fontSize: '0.8rem'}}>Away 4 min</div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div>*/}
                                {/*    <h6>Yesterday</h6>*/}
                                {/*    <div className="d-flex align-items-center justify-content-between mb-3">*/}
                                {/*        <div className={'d-flex align-items-center pointer'}*/}
                                {/*             onClick={() => setShowChat(true)}>*/}
                                {/*            <Image w={50} h={50}*/}
                                {/*                   src="/assets/images/Person3.png"*/}
                                {/*                   alt=""/>*/}

                                {/*            <div className={'ml-3'}>*/}
                                {/*                <p className={'mb-2'}>Paul Rudd</p>*/}
                                {/*                <div style={{fontSize: '0.8rem'}}>Away 4 min</div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="d-flex align-items-center justify-content-between mb-3">*/}
                                {/*        <div className={'d-flex align-items-center pointer'}*/}
                                {/*             onClick={() => setShowChat(true)}>*/}
                                {/*            <Image w={50} h={50}*/}
                                {/*                   src="https://preview.keenthemes.com/metronic-v4/theme/assets/pages/img/avatars/team1.jpg"*/}
                                {/*                   alt=""/>*/}

                                {/*            <div className={'ml-3'}>*/}
                                {/*                <p className={'mb-2'}>Chris Evans</p>*/}
                                {/*                <div style={{fontSize: '0.8rem'}}>Available</div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="d-flex align-items-center justify-content-between mb-3">*/}
                                {/*        <div className={'d-flex align-items-center pointer'}*/}
                                {/*             onClick={() => setShowChat(true)}>*/}
                                {/*            <Image w={50} h={50}*/}
                                {/*                   src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT33o0dGZM6sHmhPdDTB0PuArZ8ciUxav7nbQk52u7_kvSiPGgI&s"*/}
                                {/*                   alt=""/>*/}

                                {/*            <div className={'ml-3'}>*/}
                                {/*                <p className={'mb-2'}>Will Wader</p>*/}
                                {/*                <div style={{fontSize: '0.8rem'}}>Available</div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="d-flex align-items-center justify-content-between mb-3">*/}
                                {/*        <div className={'d-flex align-items-center pointer'}*/}
                                {/*             onClick={() => setShowChat(true)}>*/}
                                {/*            <Image w={50} h={50}*/}
                                {/*                   src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpIFXpNdTEawMOEYmqIsB7P86N1Z4BtPh9N6z7rW8s-O3SieKk&s"*/}
                                {/*                   alt=""/>*/}

                                {/*            <div className={'ml-3'}>*/}
                                {/*                <p className={'mb-2'}>Matt Patson</p>*/}
                                {/*                <div style={{fontSize: '0.8rem'}}>Available</div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div>*/}
                                {/*    <h6>Last week</h6>*/}
                                {/*    <div className="d-flex align-items-center justify-content-between mb-3">*/}
                                {/*        <div className={'d-flex align-items-center pointer'}*/}
                                {/*             onClick={() => setShowChat(true)}>*/}
                                {/*            <Image w={50} h={50}*/}
                                {/*                   src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThl1GFEUcxc7BYdi_itDxnHd25BatW2osw6eVKL44bnQwH7bvi&s"*/}
                                {/*                   alt=""/>*/}

                                {/*            <div className={'ml-3'}>*/}
                                {/*                <p className={'mb-2'}>Matthew Bryant</p>*/}
                                {/*                <div style={{fontSize: '0.8rem'}}>Away 4 min</div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="d-flex align-items-center justify-content-between mb-3">*/}
                                {/*        <div className={'d-flex align-items-center pointer'}*/}
                                {/*             onClick={() => setShowChat(true)}>*/}
                                {/*            <Image w={50} h={50}*/}
                                {/*                   src="https://mk0ziglar4ifu5ixq7cx.kinstacdn.com/wp-content/uploads/2016/09/michelle-prince-profile-img.png"*/}
                                {/*                   alt=""/>*/}
                                {/*            <div className={'ml-3'}>*/}
                                {/*                <p className={'mb-2'}>Jenna Pitt</p>*/}
                                {/*                <div style={{fontSize: '0.8rem'}}>Available</div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="d-flex align-items-center justify-content-between mb-3">*/}
                                {/*        <div className={'d-flex align-items-center pointer'}*/}
                                {/*             onClick={() => setShowChat(true)}>*/}

                                {/*            <Image w={50} h={50}*/}
                                {/*                   src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkFZo6mrJhcTDN7ZAb0hTjJOm9FABV8NU9oRZfGfZHcgqkM7pK&s"*/}
                                {/*                   alt=""/>*/}

                                {/*            <div className={'ml-3'}>*/}
                                {/*                <p className={'mb-2'}>Leo Alvez</p>*/}
                                {/*                <div style={{fontSize: '0.8rem'}}>Available</div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="d-flex align-items-center justify-content-between mb-3">*/}
                                {/*        <div className={'d-flex align-items-center pointer'}*/}
                                {/*             onClick={() => setShowChat(true)}>*/}
                                {/*            <Image w={50} h={50}*/}
                                {/*                   src="/assets/images/Person2.png"*/}
                                {/*                   alt=""/>*/}

                                {/*            <div className={'ml-3'}>*/}
                                {/*                <p className={'mb-2'}>Johanna Bale</p>*/}
                                {/*                <div style={{fontSize: '0.8rem'}}>Available</div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                            </div>
                        </div>}
                    <div/>
                </Tabs>
            </div>
        </div>
    );
};

ChatHeaderMenu.propTypes = {};

export default ChatHeaderMenu;