import React, {useEffect, useState} from 'react';
import Checkbox from "../../../App/components/Checkbox";

const AdvancedSearchFields = ({name, children, allFilters, setAllFilters, index}) => {
    const [showFields, setShowFields] = useState(false)

    const handleClick = () => {
        setShowFields(!showFields)
    }

    useEffect(() => {
        setAllFilters([...allFilters.slice(0, index), {
            ...allFilters[index],
            selected: showFields
        }, ...allFilters.slice(index + 1)])
    }, [showFields])

    return (
        <div className={'mt-4'}>
            <Checkbox className={'label-large'}
                      onClick={handleClick}>
                <b>{name}</b>
            </Checkbox>
            {showFields && <div className={'border-left  mx-2'}>
                <div className="">
                    {children}
                </div>
            </div>}
        </div>
    );
};

AdvancedSearchFields.propTypes = {};

export default AdvancedSearchFields;