import React from 'react'
import '../styles/Checkbox.scss'

const Checkbox = ({children, name, className, disabled, onClick}) => {
    return (
        <div className={className}>
            <label className="label ">{children}
                <input type="checkbox" name={name} disabled={disabled} onClick={onClick}/>
                <span className="checkmark"> </span>
            </label>

        </div>
    )
}

export default Checkbox