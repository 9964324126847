import React, {useEffect, useState} from 'react';
import FormBoxSection from "./FormBoxSection";
import FormColumns from "./FormColumns";
import useForm from "../../helpers/customHooks/useForm";
import InputAndActionContainer from "./InputAndActionContainer";
import InputWithAutocomplete from "../../App/components/InputWithAutocomplete";
import InputField from "../../App/components/InputField";
import {useSelector} from "react-redux";

const LocationEditing = ({aliasType}) => {
    const aliasCreated = useSelector(state => {
        if (state.aliases.data[aliasType] === undefined)
            return {}
        else
            return state.aliases.data[aliasType]
    })
    const {handleChange, handleSubmit, values, setValues, errors, isEdited} = useForm({
        countryName: '',
        city: ''
    }, submit, validate)

    const [countryId, setCountryId] = useState(null)
    const [disabled, setDisabled] = useState(true)


    function submit() {
        console.log(values)
        console.log(errors)
    }

    function validate(v) {
        return {}
    }

    useEffect(() => {

        console.log({values})
    }, [values])


    const setNameInInput = (item, inputName) => {
        setValues({...values, [inputName]: item.name})
        setCountryId(item.id)
        setDisabled(false)
    }

    return (
        <form action="" onSubmit={handleSubmit}>
            <FormBoxSection title={"Location"}
                            style={!Object.keys(aliasCreated).length ? {backgroundColor: "#f9f7f7"} : {}}>
                <p>Only html preview in this phase!</p>
                <FormColumns>
                    <div className={'d-flex flex-column py-4'}>
                        <InputAndActionContainer>
                            <InputWithAutocomplete
                                disabled={!Object.keys(aliasCreated).length}
                                label={'Country'}
                                name={'countryName'}
                                value={values.countryName}
                                url={'/api/info/industries/'}
                                onClick={setNameInInput} onChange={handleChange}/>
                            {/*<div>*/}
                            {/*    <a href="" className={'link-small'}>Add second</a>*/}
                            {/*</div>*/}
                        </InputAndActionContainer>
                        <InputAndActionContainer>
                            <InputWithAutocomplete
                                label={'State/City'}
                                name={'city'}
                                value={values.city}
                                url={`/api/info/states/${countryId}/`}
                                onClick={setNameInInput} onChange={handleChange}
                                disabled={disabled}/>
                        </InputAndActionContainer>
                        <InputAndActionContainer>
                            <InputField label={"State/Region/Province"} disabled={!Object.keys(aliasCreated).length}/>
                        </InputAndActionContainer>
                    </div>
                    <div>
                        {/*{isEdited && <button className={'btn edit-link'} type='submit'>Done editing</button>}*/}
                    </div>
                </FormColumns>
            </FormBoxSection>
        </form>
    )
};

LocationEditing.propTypes = {};

export default LocationEditing;