import React from 'react';
import VerificationEditing from "../../Common/VerificationEditing";

const Verification = props => {
    return (
        <div>
            <VerificationEditing/>
        </div>
    );
};

Verification.propTypes = {};

export default Verification;