import React from 'react';
import ContactEditing from "../../Common/ContactEditing";
import CompanyTypeEditing from "../../Common/CompanyTypeEditing";
import LocationEditing from "../../Common/LocationEditing";
import IdentityEditing from "../../Common/IdentityEditing";
import useGetAliasIntroduction from "../../../helpers/customHooks/useGetAliasIntroduction";


const Introduction = props => {
    const aliasTypeStr = 'LEGAL_ENTITIES_COMPANY'
    const {aliasType, introduction, paramsId, activeId, isLoading} = useGetAliasIntroduction(aliasTypeStr)

    if (isLoading && paramsId)
        return <div> loading</div>
    return (
        <div>
            <IdentityEditing aliasType={aliasTypeStr} data={introduction}/>
            {/* <ContactEditing aliasType={aliasType}/>
            <CompanyTypeEditing aliasType={aliasType}/>
            <LocationEditing aliasType={aliasType}/> */}
        </div>
    );
};

Introduction.propTypes = {};

export default Introduction;