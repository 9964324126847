import React, {useEffect, useState} from 'react';
import '../styles/AdvancedSearchBar.scss'
import Modal from "../../../App/components/Modal";
import AdvancedSearchModal from "./AdvancedSearchModal";
import {useHistory} from "react-router";
import {useSearchParam} from "react-use";
import {getAdvancedSearchResults} from "../../../App/store/advancedSearch";
import {useDispatch} from "react-redux";

const AdvancedSearchBar = props => {
    const [openModal, setOpenModal] = useState(true)
    const history = useHistory()
    const dispatch = useDispatch()
    const [advancedSearch, setAdvancedSearch] = useState("")
    const modalParam = useSearchParam('modal')
    const allFilters = [
        {type: "post", fields: [], selected: false},
        {type: "individualUser", fields: [], selected: false},
        {type: "company", fields: [], selected: false},
        {type: "investmentFund", fields: [], selected: false},
        {type: "businessAssociation", fields: [], selected: false},
        {type: "freelanceProfile", fields: [], selected: false},
        {type: "serviceProvider", fields: [], selected: false},
        {type: "contract", fields: [], selected: false},
        {type: "opportunity", fields: [], selected: false},
        {type: "projects", fields: [], selected: false}
    ]
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            const newArr = allFilters.map((filter) => ({
                type: filter.type,
                fields: [{name: 'name', value: advancedSearch}]
            }))
            dispatch(getAdvancedSearchResults(newArr))
        }
    }

    const handleChange = (e) => {
        setAdvancedSearch(e.target.value)
    }
    useEffect(() => {
        modalParam && setOpenModal(modalParam === 'true')
    }, [modalParam])

    return (
        <div className={'advanced-search-bar corner-radius10 simple-shadow'}>
            {openModal &&
            <Modal onClick={() => {
                setOpenModal(false);
                history.push('/advanced-search')
            }} className={'modal-large'}>
                <AdvancedSearchModal handleChangeSearch={handleChange}
                                     advancedSearch={advancedSearch}
                                     setAdvancedSearch={setAdvancedSearch}
                                     setOpenModal={setOpenModal}/>
            </Modal>}
            <div className={'advanced-search-field'}>
                <input onKeyPress={handleKeyPress} onChange={handleChange} name={'advancedSearch'}
                       value={advancedSearch} placeholder={'Search'} className={'advanced-input'}
                       type="text"/>
                <a href="#" onClick={() => setOpenModal(true)}><b>Advanced</b></a>
            </div>
            {/*<div className={'quick-filters tabs-header-menu'}>*/}
            {/*    <ul className="d-flex justify-content-between ">*/}
            {/*        <QuickFilterItem>Post</QuickFilterItem>*/}
            {/*        <QuickFilterItem>Individual User</QuickFilterItem>*/}
            {/*        <QuickFilterItem>Company</QuickFilterItem>*/}
            {/*        <QuickFilterItem>Investment Fund</QuickFilterItem>*/}
            {/*        <QuickFilterItem>Business Association</QuickFilterItem>*/}
            {/*        <QuickFilterItem>Freelance Profile</QuickFilterItem>*/}
            {/*        <QuickFilterItem>More</QuickFilterItem>*/}
            {/*    </ul>*/}
            {/*</div>*/}
        </div>
    );
};

AdvancedSearchBar.propTypes = {};

export default AdvancedSearchBar;