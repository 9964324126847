import React, { useState, useEffect } from 'react';
import UCCss from './style/UserConnections.module.scss'



import { acceptFriendRequest, declineFriendRequest, handleRemove } from "../../../helpers/helpers";
import { useSelector } from "react-redux";


import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

import SearchInputField from '../../../App/components/SearchInputField';
import { adjustStringWidth } from '../../../helpers/helpers_3';

// const StyledTextField = withStyles(theme => ({
//   root: {
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderRadius: `90px`,
//       },
//     },
//     '& .MuiInputBase-root': {
//       color: 'black',
//       fontSize: 14,

//       [theme.breakpoints.down('768')]: {
//         fontSize: 11,
//       }
//     },
//   },
// }))(TextField);

const UserConnections = ({ title, btns, requests, setRequests, searchUserConnections, setSearchUserConnections, allUsers }) => {

  const { data, isLoading } = useSelector(state => state.user.account)
  const { individualUserProfile } = data

  const [searchWord, setSearchWord] = useState('');
  const [size, setSize] = useState(6);


  // console.log({ requests })

  useEffect(() => {
    if (searchWord) {
      setSearchUserConnections(requests.filter(n => n.firstName.toLowerCase().includes(searchWord) || n.lastName.toLowerCase().includes(searchWord)))
    }
    else {
      setSearchUserConnections(requests)
    }
  }, [searchWord])
  //-------------------------------------------------------------------
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //-------------------------------------------------------------------

  return (
    <div onClick={() => { console.log(requests); console.log('-----'); console.log(allUsers) }} className={"  " + UCCss.main_container}>
      <div className={UCCss.main_div}>
        <div className={UCCss.title_div}>
          <div onClick={() => { setSize(size > searchUserConnections.length ? size : size + 2) }} className={UCCss.title}>{`${requests ? requests.length : "0"} Connections`}</div>
        </div>

        <div className={UCCss.search_div}>
          <SearchInputField value={searchWord} onChange={(e) => { setSearchWord(e.currentTarget.value.toLowerCase()) }}
            // size='small'
            fullWidth id="standard-bare" variant="outlined" placeholder='Search by name'
            InputProps={{ endAdornment: (<IconButton size='small'><SearchOutlined /></IconButton>), }} />
        </div>

        <div className={UCCss.user_connections_container}>

          {/* {searchUserConnections && searchUserConnections.map((x, index) => { */}
          {searchUserConnections && searchUserConnections.slice(0, size).map((x, index) => {
            return (

              <div key={index} className={UCCss.main_list_div}>
                <div onClick={() => console.log(x)} className={' ' + UCCss.left_list_div}>
                  {/* <div className={UCCss.picture}><img className={UCCss.img} src='/assets/images/avatar-profile.svg' alt="" /></div> */}
                  <div className={UCCss.picture}><img className={UCCss.img} src={x.img || `http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${x.id}`} alt="" /></div>

                  <div className={UCCss.name_div}>
                    <div className={UCCss.names_wraper} >
                      {/* <Link to={`/profile/${id}`}><h5>{name || "Name"}</h5></Link> */}
                      {/* <div className={UCCss.name} >{`${x.firstName} ${x.lastName}`}</div> */}
                      <Link to={`/profile/${x.id}`} className={UCCss.name} >{windowSize.width < 768 ? adjustStringWidth(x.firstName.concat(' ', x.lastName), 180) : adjustStringWidth(x.firstName.concat(' ', x.lastName), 600)}</Link>
                      {/* <Link to={`/profile/${x.id}`} className={UCCss.name} >{`${x.firstName} ${x.lastName}`}</Link> */}
                      <div className={UCCss.small_text}>{windowSize.width < 768 ? adjustStringWidth(x.email, 300) : adjustStringWidth(x.email, 700)}</div>
                      {/* <div className={UCCss.small_text}>{x.email}</div> */}
                      <div className={UCCss.small_text}>Digital Marketing</div>
                    </div>
                  </div>
                </div>

                <div className={' ' + UCCss.right_list_div}>



                  {/* <div className={UCCss.verify_profile_div}>
                  <button className={UCCss.verify_profile_button} >Verified</button>
                </div> */}


                  <div className={UCCss.message_div}>
                    <Link to={`/profile/${x.id}`} className={UCCss.message_button} >View Profile</Link>
                    {/* <button className={UCCss.message_button} >Message</button> */}
                    {/* <div className={UCCss.menu_dots_div} ><img className={UCCss.img_menu_dots} src='/assets/images/menu-dots-normal-vertical-blue.svg' alt="" /></div> */}

                  </div>

                  {/* <div className={UCCss.message_div}>
                  <button className={UCCss.message_button} > Message </button>
                </div> */}
                  {/* <button className={UCCss.message_button + ' ' + (x.verified && UCCss.verify_button_false)} onClick={() => console.log(x.verified)} > {x.verified ? 'Verify' : 'Verified'}</button> */}
                </div>
              </div>

            )
          })}

        </div>
        {size < searchUserConnections.length && <div className={UCCss.show_more_users_div} >
          <button onClick={() => { setSize(size > searchUserConnections.length ? size : size + 10) }} className={UCCss.show_more_users_button} >Show More Users</button>
        </div>}
      </div>
    </div>
  )
}

export default UserConnections