import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import '../styles/InputField.scss'
import MenuItem from '@material-ui/core/MenuItem';

const Dropdown = ({className, onChange, label, name, value, options, onBlur, disabled}) => {

    return (
        <FormControl variant="outlined" className={' dropdown ' + className} disabled={disabled}>
            <InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>
            <Select
            className='select_element_from_MUI'
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                label={label}
                inputProps={{}}
            >
                {options.map((item, i) => {
                    return <MenuItem key={i} value={item.value}>{item.name}</MenuItem>
                })}
            </Select>
        </FormControl>
    );
};

Dropdown.propTypes = {};

export default Dropdown;