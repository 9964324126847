import React, { useState, useEffect } from 'react'
import { acceptFriendRequest, declineFriendRequest, handleRemove } from "../../../helpers/helpers";
import { useSelector, useDispatch } from "react-redux";
import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import RASICss from './style/ReceivedAndSentInvInvitations.module.scss'


import { globalMessage } from '../../../helpers/helpers_2'
import { adjustStringWidth } from '../../../helpers/helpers_3';

import SearchInputField from '../../../App/components/SearchInputField'

// const ReceivedAndSentInvInvitations = ({ title, btns, friends, setfriends }) => {
const ReceivedAndSentInvInvitations = ({ allUsers, friends, setAllUsers, friendRequests, setFriendRequests, setFriends, searchFriendRequests, setSearchFriendRequests }) => {

    const { data, isLoading } = useSelector(state => state.user.account)
    const { individualUserProfile } = data
    // console.log({ friends })

    const [received, setReceived] = useState(true)
    const [showArray, setShowArray] = useState([])

    const [searchWord, setSearchWord] = useState('');
    const [size, setSize] = useState(6);
    const dispatch = useDispatch()


    // const StyledTextField = withStyles(theme => ({
    //     root: {
    //         '& .MuiOutlinedInput-root': {
    //             '& fieldset': {
    //                 borderRadius: `90px`,
    //             },
    //         },
    //         '& .MuiInputBase-root': {
    //             color: 'black',
    //             fontSize: 14,

    //             [theme.breakpoints.down('768')]: {
    //                 fontSize: 11,
    //             }
    //         },
    //     },
    // }))(TextField);

    //-------------------------------------------------------------------
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    //-------------------------------------------------------------------

    useEffect(() => {
        if (received === true) {
            setShowArray(searchFriendRequests)
        } else {
            setShowArray([])
        }

    }, [received, searchFriendRequests])

    //-------------------------------------------------------------------

    useEffect(() => {
        if (searchWord) {
            setSearchFriendRequests(friendRequests.filter(n => n.firstName.toLowerCase().includes(searchWord) || n.lastName.toLowerCase().includes(searchWord)))
        }
        else {
            setSearchFriendRequests(friendRequests)
        }
    }, [searchWord])

    return (
        <>
            {/* <div onClick={() => { console.log([friends]) }}>show friends</div>
            <div onClick={() => { setFriends([...friends, { a: 'a', b: 'b' }]) }} >add friend</div> */}
            <div className={"  " + RASICss.main_container}>
                <div className={RASICss.main_div}>
                    <div className={RASICss.title_div}>
                        {/* <div className={RASICss.title}>{`${friends ? friends.length : "0"} Connections`}</div> */}
                        <div onClick={() => setReceived(!received)} className={RASICss.received + ' ' + (received ? RASICss.active : '')}>Received</div>
                        <div onClick={() => setReceived(!received)} className={RASICss.sent + ' ' + (!received ? RASICss.active : '')}>Sent</div>
                    </div>

                    <div className={RASICss.search_div}>
                        <SearchInputField
                            value={searchWord} onChange={(e) => { setSearchWord(e.currentTarget.value.toLowerCase()) }}
                            fullWidth id="standard-bare" variant="outlined" placeholder='Search by name'
                            InputProps={{ endAdornment: (<IconButton size='small'><SearchOutlined /></IconButton>), }} />
                    </div>

                    <div className={RASICss.user_connections_container}>


                        {showArray && showArray.slice(0, size).map((x, index) => {
                            return (

                                <div key={index} className={RASICss.main_list_div}>
                                    <div onClick={() => console.log(x)} className={' ' + RASICss.left_list_div}>
                                        {/* <div className={RASICss.picture}><img className={RASICss.img} src='/assets/images/avatar-profile.svg' alt="" /></div> */}
                                        <div className={RASICss.picture}><img className={RASICss.img} src={x.img || `http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${x.id}`} alt="" /></div>

                                        <div className={RASICss.name_div}>
                                            <div className={RASICss.names_wraper} >
                                                {/* <div className={RASICss.name} >{`${x.firstName} ${x.lastName}`}</div> */}
                                                <Link to={`/profile/${x.id}`} className={RASICss.name} >{windowSize.width < 768 ? adjustStringWidth(x.firstName.concat(' ', x.lastName), 180) : adjustStringWidth(x.firstName.concat(' ', x.lastName), 600)}</Link>
                                                {/* <Link to={`/profile/${x.id}`} className={RASICss.name} >{`${x.firstName} ${x.lastName}`}</Link> */}
                                                <div className={RASICss.small_text}>{windowSize.width < 768 ? adjustStringWidth(x.email, 300) : adjustStringWidth(x.email, 700)}</div>
                                                <div className={RASICss.small_text}>Digital Marketing</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={' ' + RASICss.right_list_div}>



                                        {/* <div className={RASICss.verify_profile_div}>
                  <button className={RASICss.verify_profile_button} >Verified</button>
                </div> */}


                                        <div className={RASICss.message_div}>
                                            {received === true ? <button onClick={
                                                () => {
                                                    setFriends([...friends, x]) // ova mi treba
                                                    acceptFriendRequest(x.id, individualUserProfile.id); // ova mi treba
                                                    handleRemove(x.id, showArray, setShowArray); // ova mi treba
                                                    dispatch(globalMessage({ name: `successfully added to your network`, type: 'simple_message' }))

                                                    // handleRemove(x.id, friendRequests, setFriendRequests); // ova NE mi treba
                                                }}
                                                className={RASICss.message_button} >Accept</button> : <button
                                                    className={RASICss.message_button} >asdasd</button>}

                                            {/* <div className={RASICss.menu_dots_div} ><img className={RASICss.img_menu_dots} src='/assets/images/menu-dots-normal-vertical-blue.svg' alt="" /></div> */}

                                        </div>

                                        {/* <div className={RASICss.message_div}>
                  <button className={RASICss.message_button} > Message </button>
                </div> */}
                                        {/* <button className={RASICss.message_button + ' ' + (x.verified && RASICss.verify_button_false)} onClick={() => console.log(x.verified)} > {x.verified ? 'Verify' : 'Verified'}</button> */}
                                    </div>
                                </div>

                            )
                        })}

                    </div>
                    {size < showArray.length && <div className={RASICss.show_more_users_div} >
                        <button onClick={() => { setSize(size > showArray.length ? size : size + 10) }} className={RASICss.show_more_users_button} >Show More Users</button>
                    </div>}
                </div>
            </div>
        </>
    )
}
export default ReceivedAndSentInvInvitations