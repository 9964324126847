import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loader from "./../../Common/Loader";
import useForm from "./../../../helpers/customHooks/useForm";
import Button from "../../../App/components/Button";

// import InfoEditOpportunity from './InfoEditOpportunity';
// import ExperienceEditOpportunity from './ExperienceEditOpportunity';
// import InsightEditOpportunity from './InsightEditOpportunity';
// import AddNewOpportunity from './AddNewOpportunity'
// import NEW_AddNewOpportunity from './NEW_AddNewOpportunity';
import InputField from "./../../../App/components/InputField";
// import InputContainer from "./../../Common/InputContainer";
import InputContainer from "./../../Common/InputContainer";
// import Message from "./MessageAlias";
// import Message from "../../../Dashboard/IndividualUserProfile/Creation/Message";
import Message from "../../IndividualUserProfile/Creation/Message";
import FormColumns from "./../../Common/FormColumns";
import Dropdown from "./../../../App/components/Dropdown";
import FormBoxSection from "./../../Common/FormBoxSection";
import FormBoxSectionDualScreen from "./../../Common/FormBoxSectionDualScreen";
import FormBoxSectionOpportunityCustom from "./../../Common/FormBoxSectionOpportunityCustom";

// import InfoExperienceEdit from './InfoExperienceEdit'
// import PitchEdit from './PitchEdit';

import TextAreaExpandable from "./../../../App/components/TextAreaExpandable";
// import { postOpportunity } from "../../../App/store/user";
// import { postOpportunity } from "./../../../App/store/user";
import { postNewOpportunity } from "../../../App/store/user";

// import api from '../../../App/config/api'
import api from '../../../App/config/api'

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from "react-places-autocomplete";
import Opportunity from "../../IndividualUserProfile/Creation/Opportunity";
import { useHistory, useParams } from "react-router";

import { millisecondsToDate } from '../../../helpers/helpers_2'




const OpportunityAlias = ({ setPurposeCreation }) => {
    const params = useParams()
    const handleClick = event => event.target.classList.add('.a:focus');
    const [openModal, setOpenModal] = useState(false)

    const [showMessageText, setShowMessageText] = useState('')

    const [showInfo, setShowInfo] = useState(true)
    // const [showExperience, setShowExperience] = useState(false)

    const { account } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    //--------------------
    const [userId, setUserId] = useState(params.id);
    // const [userId, setUserId] = useState(account.data.individualUserProfile.id);
    const [userType, setUserType] = useState(1);

    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [startDate, setStartDate] = useState();
    const [projectWebsite, setProjectWebsite] = useState();
    const [ownership, setOwnership] = useState();
    const [contribution, setContribution] = useState();
    const [budget, setBudget] = useState();
    const [currency, setCurrency] = useState();
    const [sector, setSector] = useState();
    const [subSector, setSubSector] = useState();
    const [experience, setExperience] = useState();



    const [market, setMarket] = useState();
    const [expenditures, setExpenditures] = useState();
    const [fullProjectDescription, setFullProjectDescription] = useState();
    const [opportunityDesc, setOpportunityDesc] = useState();


    const [location, setLocation] = useState();
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();


    //--------------------
    const [isNameAvailable, setIsNameAvailable] = useState();
    //--------------------
    const [address, setAddress] = React.useState("");
    const [coordinates, setCoordinates] = React.useState({
        lat: null,
        lng: null
    });

    const handleSelect = async value => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        setAddress(value);
        setCoordinates(latLng);

        //-----------------
        setOpportunity({ ...opportunity, location: value, latitude: latLng.lat.toString(), longitude: latLng.lng.toString() })

        // setOpportunity({ ...opportunity, location: value })
        // setOpportunity({ ...opportunity, latitude: latLng.lat })
        // setOpportunity({ ...opportunity, longitude: latLng.lng })

        setLocation(value)
        setLatitude(latLng.lat)
        setLongitude(latLng.lng)
    };
    //--------------------


    // get the data for edit
    const [data, setData] = useState([])


    const getOpportunity = async () => {
        try {
            const res = await api.get(`/api/opportunities?userId=${userId}&userType=1`)
            setData(res.data.body)
        } catch (e) {
            return console.error(e.message);
        }
    }

    useEffect(() => {
        getOpportunity()
    }, [])
    //--------------------
    let initialOpportunity = {
        userId,
        userType,

        name: '',
        description: '',
        startDate: '',
        projectWebsite: '',
        ownership: '',
        contribution: '',
        budget: '',
        currency: '',
        sector: '',
        subSector: '',
        experience: '',

        market: '',
        expenditures: '',
        fullProjectDescription: '',
        opportunityDesc: '',

        untangableAsset: '',
        location: '',
        latitude: '',
        longitude: ''


    }
    //--------------------
    const [opportunity, setOpportunity] = useState({
        userId,
        userType,

        name: '',
        description: '',
        startDate: '',
        projectWebsite: '',
        ownership: '',
        contribution: '',
        budget: '',
        currency: '',
        sector: '',
        subSector: '',
        experience: '',

        market: '',
        expenditures: '',
        fullProjectDescription: '',
        opportunityDesc: '',

        untangableAsset: '',
        location: '',
        latitude: '',
        longitude: ''

    });

    //--------------------





    const addMessage = (e, text) => {
        e.preventDefault();
        setShowMessageText(text)
        setTimeout(() => {
            setShowMessageText('')
        }, 3500);

    }

    // const millisecondsToDate = (milliseconds) => {
    //     let date = new Date(milliseconds); // Date 2011-05-09T06:08:45.178Z
    //     let y = date.getFullYear();
    //     let year_length = date.getFullYear().toString().length;

    //     let year = year_length == 1 ? "000" + y : year_length == 2 ? "00" + y : year_length == 3 ? "0" + y : y;

    //     let month = ("0" + (date.getMonth() + 1)).slice(-2);
    //     let day = ("0" + date.getDate()).slice(-2);


    //     let x = `${year}-${month}-${day}`
    //     return x
    // }







    const handleSubmit = (e) => {
        e.preventDefault();

        console.log('hi')
        console.log(opportunity)



        if (opportunity.name === '') {
            window.scrollTo(0, 0);
            addMessage(e, 'Name is required')
        }
        else if (!chechIfNameIsAvailable(e, opportunity.name)) {
            window.scrollTo(0, 0);
            addMessage(e, 'Name is not available')
        }

        else if (opportunity.name === '' || opportunity.description === '' || opportunity.startDate === '' || opportunity.projectWebsite === '' || opportunity.ownership === '' || opportunity.sector === '' || opportunity.subSector === '' || opportunity.experience === '' || opportunity.fullProjectDescription === '' || opportunity.opportunityDesc === '' || opportunity.market === '' || opportunity.expenditures === '') {
            window.scrollTo(0, 0);
            // addMessage(e, 'Please fill out all the required fields')
            addMessage(e, 'All fields are required')
        } else {
            if (opportunity.hasOwnProperty('id')) {
                console.log('this is for edit')
            } else {
                console.log('create new')
                console.log(opportunity)
                //----------------------
                addMessage(e, 'Created successfully')

                setData([...data, opportunity])
                setOpportunity(initialOpportunity)
                setAddress('')
                //----------------------

                dispatch(
                    postNewOpportunity(opportunity)
                );
            }
        }



    };

    const chechIfNameIsAvailable = (e, OpportunityName) => {
        e.preventDefault();
        let x = !data.some(x => x.name === OpportunityName)
        setIsNameAvailable(x)
        console.log('x: ', x)
        return x;
    }


    return (
        <form action='' className={"mt-4r"} onSubmit={handleSubmit}>

            <div className={'row'}>
                <div className="col-md-6">
                    <>

                        <FormBoxSectionOpportunityCustom message={showMessageText} initialOpportunity={initialOpportunity} setAddress={setAddress} setOpportunity={setOpportunity} opportunityList={data} opportunityCreate={true} title={"Info"} className={'margin-b-0'}>

                            {!true ? (
                                <Loader />
                            ) : (
                                < >
                                    <div className={"d-flex flex-column py-2"}>

                                        {/* old message - we dont use it now */}
                                        {/* <Message text={showMessageText} /> */}

                                        <div >
                                            {/* name */}
                                            <InputContainer  >
                                                <InputField
                                                    className={`${isNameAvailable === true ? 'availableName' : isNameAvailable === false ? 'notAvailableName' : ''}`}

                                                    type='text'
                                                    name='name'
                                                    value={opportunity.name || ''}
                                                    onChange={(e) => {
                                                        if (data) {

                                                            setIsNameAvailable(chechIfNameIsAvailable(e, e.currentTarget.value))

                                                            setOpportunity({ ...opportunity, name: e.currentTarget.value })
                                                        }
                                                    }}
                                                    label={"Name"}
                                                />
                                            </InputContainer>

                                            {/* Description */}
                                            <InputContainer  >
                                                <InputField
                                                    multiline
                                                    rows={6}
                                                    className={"mb-0"}
                                                    name='description'


                                                    type='text'
                                                    value={opportunity.description || ''}
                                                    onChange={(e) => setOpportunity({ ...opportunity, description: e.currentTarget.value })}

                                                    label={"Description"}
                                                />
                                            </InputContainer>

                                            {/* Start Date */}
                                            <InputContainer  >
                                                <InputField
                                                    type='date'
                                                    label={"Start Date"}
                                                    name={"startDate"}


                                                    value={opportunity.startDate && millisecondsToDate(opportunity.startDate)} //ova ver 2 treba

                                                    onChange={(e) => { e.currentTarget.value && setOpportunity({ ...opportunity, startDate: new Date(e.currentTarget.value).getTime() }) }}


                                                />
                                            </InputContainer>

                                            {/* Project Website */}
                                            <InputContainer  >
                                                <InputField

                                                    name={"projectWebsite"}
                                                    type='text'

                                                    value={opportunity.projectWebsite}
                                                    onChange={(e) => setOpportunity({ ...opportunity, projectWebsite: e.currentTarget.value })}


                                                    label={"Project Website"}
                                                />
                                            </InputContainer>

                                            {/* Ownership dropDown */}

                                            <InputContainer  >
                                                <Dropdown
                                                    options={[
                                                        {
                                                            name: "Public",
                                                            value: "Public",
                                                        },
                                                        {
                                                            name: "Private",
                                                            value: "Private",
                                                        }
                                                    ]}
                                                    label={"Ownership"}


                                                    name='ownership'
                                                    onChange={(e) => setOpportunity({ ...opportunity, ownership: e.currentTarget.getAttribute('data-value') })}

                                                    value={opportunity.ownership || ""}
                                                />
                                            </InputContainer>

                                            <InputContainer  >
                                                <Dropdown
                                                    options={[
                                                        {
                                                            name: "Tangible",
                                                            value: "Tangible",
                                                        },
                                                        {
                                                            name: "Untangible",
                                                            value: "Untangible",
                                                        }
                                                    ]}
                                                    label={"Contribution"}


                                                    onChange={(e) => setOpportunity({ ...opportunity, contribution: e.currentTarget.getAttribute('data-value') })}

                                                    name={"contribution"}
                                                    value={opportunity.contribution || ""}
                                                />
                                            </InputContainer>

                                            {opportunity.contribution == 'Tangible' &&
                                                <>
                                                    <InputContainer  >
                                                        <InputField


                                                            name='budget'
                                                            value={opportunity.budget || ""}
                                                            type='number'

                                                            onChange={(e) => setOpportunity({ ...opportunity, budget: parseFloat(e.currentTarget.value) })}

                                                            label={"Budget"}
                                                        />
                                                    </InputContainer>
                                                    <InputContainer  >
                                                        <InputField

                                                            name='currency'
                                                            value={opportunity.currency || ""}
                                                            type='text'

                                                            onChange={(e) => setOpportunity({ ...opportunity, currency: e.currentTarget.value })}

                                                            label={"Currency"}
                                                        />
                                                    </InputContainer>
                                                </>}
                                            {/* Project Budget */}

                                            {opportunity.contribution == 'Untangible' &&
                                                <InputContainer  >
                                                    <InputField
                                                        type="text"
                                                        value={opportunity.untangableAsset && opportunity.untangableAsset
                                                            .split(",")
                                                            .map((x) => x.trim())
                                                            .join(" ")}
                                                        onChange={(e) => {
                                                            setOpportunity({
                                                                ...opportunity,
                                                                untangableAsset: e.currentTarget.value
                                                                    .replace(/\s+/g, " ")
                                                                    .split(" ")
                                                                    .toString(),
                                                            });
                                                        }}
                                                        label={"untangable asset"}
                                                    />
                                                </InputContainer>
                                            }


                                            {/* Sector and Subsector */}
                                            <InputContainer  >
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <InputField

                                                            name='sector'
                                                            value={opportunity.sector || ""}
                                                            type='text'
                                                            onChange={(e) => setOpportunity({ ...opportunity, sector: e.currentTarget.value })}

                                                            label={"Sector"}
                                                        />
                                                    </div>
                                                    <div className='col-md-6 subsector-mt-0-1-res'>
                                                        <InputField

                                                            name='subSector'
                                                            value={opportunity.subSector || ""}
                                                            type='text'
                                                            onChange={(e) => setOpportunity({ ...opportunity, subSector: e.currentTarget.value })}

                                                            label={"Subsector"}
                                                        />
                                                    </div>
                                                </div>
                                            </InputContainer>


                                            <InputContainer  >
                                                <InputField
                                                    type="text"

                                                    value={opportunity.experience && opportunity.experience
                                                        .split(",")
                                                        .map((x) => x.trim())
                                                        .join(" ")}
                                                    onChange={(e) => {
                                                        setOpportunity({
                                                            ...opportunity,
                                                            experience: e.currentTarget.value
                                                                .replace(/\s+/g, " ")
                                                                .split(" ")
                                                                .toString(),
                                                        });
                                                    }}
                                                    label={"Experiences"}
                                                />
                                            </InputContainer>

                                        </div>
                                    </div>

                                </>
                            )}
                        </FormBoxSectionOpportunityCustom>
                    </>

                </div>

                <div className="col-md-6 ">
                    <>
                        <FormBoxSectionDualScreen className={' margin-b-0 '} title={"Pitch"}>
                            < >
                                <div className={"d-flex flex-column py-4"}>


                                    {/* Description */}
                                    <InputContainer >
                                        <TextAreaExpandable
                                            className={"mb-0"}

                                            name='fullProjectDescription'
                                            type='text'
                                            value={opportunity.fullProjectDescription}
                                            onChange={(e) => setOpportunity({ ...opportunity, fullProjectDescription: e.currentTarget.value })}

                                            label={"Full Project Description"}

                                        />
                                    </InputContainer>

                                    <InputContainer >
                                        <TextAreaExpandable

                                            className={"mb-0"}
                                            name='opportunityDesc'
                                            type='text'
                                            value={opportunity.opportunityDesc}
                                            onChange={(e) => setOpportunity({ ...opportunity, opportunityDesc: e.currentTarget.value })}

                                            label={"Opportunity Description"}
                                        />
                                    </InputContainer>

                                    <InputContainer >
                                        <TextAreaExpandable

                                            className={"mb-0"}
                                            type='text'
                                            name='market'
                                            value={opportunity.market}
                                            onChange={(e) => setOpportunity({ ...opportunity, market: e.currentTarget.value })}

                                            label={"Market"}
                                        />
                                    </InputContainer>

                                    <InputContainer >
                                        <TextAreaExpandable

                                            name='expenditures'
                                            className={"mb-0"}
                                            type='text'
                                            value={opportunity.expenditures}
                                            onChange={(e) => setOpportunity({ ...opportunity, expenditures: e.currentTarget.value })}

                                            label={"Expenditures"}
                                        />
                                    </InputContainer >


                                </div>

                            </>

                        </FormBoxSectionDualScreen>

                        <FormBoxSectionDualScreen title={"Location"}>
                            < >
                                <div className={"d-flex flex-column py-4"}>



                                    <PlacesAutocomplete
                                        value={address}
                                        onChange={setAddress}
                                        onSelect={handleSelect}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div>
                                                <InputContainer PlacesAutocomplete={true}>

                                                    <InputField
                                                        label={"Type your address"}
                                                        {...getInputProps({ placeholder: "Type address" })} />



                                                    <div className="">
                                                        {loading ? <div>...loading</div> : null}

                                                        {suggestions.map((suggestion, i) => {
                                                            const style = {
                                                                cursor: 'pointer',
                                                                fontSize: '0.9rem',
                                                                borderRadius: '3px',
                                                                paddingLeft: '0.5rem',
                                                                color: suggestion.active ? "white" : "black",
                                                                backgroundColor: suggestion.active ? "#2983fd" : "#fff"
                                                            };

                                                            return (
                                                                <div {...getSuggestionItemProps(suggestion, { style })} key={i}>
                                                                    {suggestion.description}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>

                                                </InputContainer>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                    <div className=' d-flex justify-content-end '>
                                        <InputContainer >
                                            {opportunity.name && <Button className={"btn-blue"} type='submit'>
                                                Save
                                            </Button>}
                                        </InputContainer>


                                    </div>
                                </div>

                            </>

                        </FormBoxSectionDualScreen>


                    </></div>
            </div>
        </form>

    )
};

OpportunityAlias.propTypes = {};
export default OpportunityAlias;

