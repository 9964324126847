import React, {useEffect} from 'react';
import InputField from "../../../App/components/InputField";
import Button from "../../../App/components/Button";
import ProjectBoxView from "../components/ProjectBoxView";
import ProjectUpdatesItem from "../components/ProjectUpdatesItem";
import MenuOpportunitiesProjects from "../../Common/MenuOpportunitiesProjects";
import CreateProjectModalContent from "../components/CreateProjectModalContent";
import Image from "../../../App/components/Image";
import {useDispatch, useSelector} from "react-redux";
import {fetchAccount} from "../../../App/store/user";
import Loader from "../../Common/Loader";

const Projects = props => {
    const {data, isLoading} = useSelector(state => state.user.account)
    const {individualUserProfile} = data
    const dispatch = useDispatch()

    useEffect(() => {
        if (!Object.keys(data).length)
            dispatch(fetchAccount())
    }, [])


    if (isLoading)
        return <Loader/>

    return (
        <div>
            <div className="">
                <MenuOpportunitiesProjects page={'Projects'} linkTo={'projects'}
                                           modalContent={<CreateProjectModalContent/>}/></div>
            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container d-flex p-0'} style={{background: '#f5f5f5'}}>
                    <div className="col-md-8 mx-auto pt-5">
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <h2 className={'font-weight-bold'}>Projects</h2>
                            <Button className={'btn btn-transparent'}>View All</Button>
                        </div>
                        <div>
                            <div className={'row flex-wrap justify-content-between mt-5'}>
                                <div className="col-md-6">
                                    <ProjectBoxView btn img={'/assets/images/p1.png'}
                                                    name={'AECF'} location={'AECF | Newark, US'}/></div>
                                <div className="col-md-6">
                                    <ProjectBoxView img={'/assets/images/p2.png'}
                                                    name={'inCharge 6'}
                                                    location={'Rolling Square | Lugano, Switzerland'}/></div>

                            </div>
                            <div className={'row flex-wrap justify-content-between mt-5'}>
                                <div className="col-md-6 col-sm-12">
                                    <ProjectBoxView
                                        img={'/assets/images/p3.png'}
                                        name={'purME'} location={'purME | Shanghai, China'}/></div>
                                <div className="col-md-6 col-sm-12">
                                    <ProjectBoxView
                                        img={'/assets/images/p4.png'}
                                        name={'Selpic P1'} location={'Selpic Team | Cerritos, US'}/></div>
                            </div>
                        </div>
                        <div className={'d-flex justify-content-between align-items-center my-5'}>
                            <h2 className={'font-weight-bold'}>Recent Updates</h2>
                            <Button className={'btn btn-transparent'}>View All</Button>
                        </div>
                        <div>
                            <ProjectUpdatesItem name={'Ismail Kaha'}
                                                src={'https://www.bigredcloud.com/wp-content/uploads/4-tips-for-taking-professional-profile-pictures.jpg'}/>
                            <ProjectUpdatesItem name={'Tom Matthew'}
                                                src={'https://preview.keenthemes.com/metronic-v4/theme/assets/pages/img/avatars/team1.jpg'}/>
                            <ProjectUpdatesItem name={'Sara Landlord'}
                                                src={'https://i.pinimg.com/600x315/a6/0d/68/a60d685194a7fd984d08a595a0a99ae7.jpg'}/>
                            <ProjectUpdatesItem name={'Dina Christian'}
                                                src={'https://i.pinimg.com/236x/67/5f/40/675f409cfcbea9281f6649ca096caf25--corporate-photography-headshot-photography.jpg'}/>
                            <ProjectUpdatesItem name={'Maya Tucker'}
                                                src={'https://osmey-milian-photography.com/_files/200002613-7c7f77d81f/OMD_7187-Website.jpg'}/>
                            <ProjectUpdatesItem name={'Ed Waltz'}
                                                src={'https://content1.getnarrativeapp.com/68f6039f-a2f9-4e15-9b91-4f7cebe2a378/img_ref/4e80a9d6-aef4-4875-ab94-5952b6926e07/Hamish-5_professional_headshots_photographer_headshot_750.jpg'}/>
                        </div>
                    </div>
                    <div className={'col-md-3 bg-white pt-3'}>
                        <div className={'d-flex justify-content-around w-100'}>
                            <InputField name={'search'} label={'Search'}/>
                            <img src="/assets/images/Notifications.svg" alt=""/>
                        </div>
                        <div className={'d-flex flex-column align-items-center mt-5'}>
                            <Image w={100} h={100} className={'simple-border'}
                                   src={`http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${individualUserProfile.id}`}/>
                            <div className={'mt-3'}>
                                <b>{individualUserProfile.firstName + " " + individualUserProfile.lastName}</b>
                            </div>
                            <span className={'small'}>Owner</span>
                        </div>
                        <div className={'border-bottom d-flex mt-4 px-2 justify-content-between'}>
                            <h6><b>On my list</b></h6>
                            <span>All</span>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Update supply change on
                                    some list somewhere
                                </div>
                                <a href={''} className={'link-small pt-2'}>@projectname</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold pb-'}>Jin Tan in applies in listing
                                    <a href={''}
                                       className={'link-small font-weight-normal pt-2'}> @CFO
                                        position opening</a></div>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Thomas M. Trevino requested
                                    NDA
                                </div>
                                <a href={''} className={'link-small pt-2'}>@Selpic P1</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Jin Tan reported something
                                    at
                                </div>
                                <a href={''} className={'link-small pt-2'}>@Selpic P1</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold pb- '}>Richard M. Hobbs in Follows <a
                                    href={''} className={'link-small font-weight-normal pt-2'}> @purME</a> Project.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Projects.propTypes = {};

export default Projects;