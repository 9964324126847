import React from 'react'
import UserConnections from './UserConnections.js'
import SuggestedUsers from './SuggestedUsers.js'


import NetworkActionsSection from "./NetworkActionsSection";
import NetworkSuggestionsSection from "./NetworkSuggestionsSection";
import Button from "../../../App/components/Button";





const Connections = ({ allUsers, friends, setAllUsers, xxx, setXxx, searchUserConnections, setSearchUserConnections,
    suggestedUsers, setSuggestedUsers, setShowAddToNetworkMessage
}) => {

    return (
        <div className={'row'}>
            <div className={'col-md-7 p-0'}>
                <UserConnections searchUserConnections={searchUserConnections} setSearchUserConnections={setSearchUserConnections} requests={friends} allUsers={allUsers} />
                {/* <NetworkActionsSection title={'Connections'} btns={btns} requests={friends} /> */}
            </div>
            <div className={'col-md-5 p-0 d-flex justify-content-center '}>

                {/* <NetworkSuggestionsSection allUsers={allUsers} setAllUsers={setAllUsers} /> */}
                <SuggestedUsers setShowAddToNetworkMessage={setShowAddToNetworkMessage} friends={friends} xxx={xxx} setXxx={setXxx} allUsers={allUsers} setAllUsers={setAllUsers} suggestedUsers={suggestedUsers} setSuggestedUsers={setSuggestedUsers} />
            </div>
        </div>
    )
}

export default Connections