import React, {useEffect, useState} from 'react';
import MenuOpportunitiesProjects from "../../Common/MenuOpportunitiesProjects";
import CreateProjectModalContent from "./CreateProjectModalContent";
import MenuDots from "../../../App/components/MenuDots";
import CreateProjectBoardBox from "./CreateProjectBoardBox";
import InfoBoardItem from "./InfoBoardItem";
import CKEditor from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import Button from "../../../App/components/Button";
import {useHistory} from "react-router";
import InputField from "../../../App/components/InputField";
import Modal from "../../../App/components/Modal";
import Checkbox from "../../../App/components/Checkbox";
import {Link} from "react-router-dom";
import Image from "../../../App/components/Image";
import {useDispatch, useSelector} from "react-redux";
import {fetchAccount} from "../../../App/store/user";
import Loader from "../../Common/Loader";

const CreatePostInfoBoard = props => {
    const [openModal, setOpenModal] = useState(false)
    const history = useHistory()

    const {data, isLoading} = useSelector(state => state.user.account)
    const {individualUserProfile} = data
    const dispatch = useDispatch()

    useEffect(() => {
        if (!Object.keys(data).length)
            dispatch(fetchAccount())
    }, [])

    const handleEditorChange = (e, editor) => {
        const data = editor.getData();
    }


    if (isLoading)
        return <Loader/>
    return (
        <div>
            <div className="">
                <MenuOpportunitiesProjects page={'Projects'} linkTo={'projects'}
                                           modalContent={<CreateProjectModalContent/>}/></div>
            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container d-flex p-0'} style={{background: '#f5f5f5'}}>
                    <div className="col-md-8 mx-auto">
                        <div className={'d-flex justify-content-between align-items-center mb-4 mt-5'}>
                            <h2 className={'font-weight-bold'}>Project</h2>
                            <div className={'flex-grow-1 ml-3'} style={{height: 1, background: '#ccc'}}>
                            </div>
                            <div className={'ml-3 '}>
                                <MenuDots
                                    className={'dots-vertical '}/>
                            </div>
                        </div>

                        <CreateProjectBoardBox title={<><Link to={'/info-board'}>Info Board</Link> - Create Post</>}>
                            <div className={'pb-4 pt-3 px-4 border-bottom'}><InfoBoardItem
                                src={'/assets/images/DV-Avatar-Icon.svg'}/></div>
                            <div className={'create-post-project'}>
                                <div className="ck-wrapper d-flex flex-column">

                                    <div id="toolbar-container"></div>
                                    <CKEditor
                                        editor={DecoupledEditor}
                                        config={{
                                            height: 100,
                                            // exportWord: {
                                            //     fileName: ,
                                            // }
                                        }}
                                        data={""}
                                        onInit={editor => {
                                            const toolbarContainer = document.querySelector('#toolbar-container');
                                            toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                                            window.editor = editor;
                                            // You can store the "editor" and use when it is needed.
                                            console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => handleEditorChange(event, editor)}
                                    />
                                    <div className={'d-flex justify-content-between  pt-3'}>
                                        <div className={'d-flex'}>
                                            <div className={'d-flex flex-wrap'}
                                                 style={{width: 100}}>{['https://preview.keenthemes.com/metronic-v4/theme/assets/pages/img/avatars/team1.jpg', 'https://i.pinimg.com/600x315/a6/0d/68/a60d685194a7fd984d08a595a0a99ae7.jpg', 'https://i.pinimg.com/236x/67/5f/40/675f409cfcbea9281f6649ca096caf25--corporate-photography-headshot-photography.jpg', 'https://osmey-milian-photography.com/_files/200002613-7c7f77d81f/OMD_7187-Website.jpg', 'https://content1.getnarrativeapp.com/68f6039f-a2f9-4e15-9b91-4f7cebe2a378/img_ref/4e80a9d6-aef4-4875-ab94-5952b6926e07/Hamish-5_professional_headshots_photographer_headshot_750.jpg', 'https://pixomatic.us/blog/wp-content/uploads/2019/11/pixomatic_1572877223091.png',].map((item, i) => {
                                                return <Image key={i}
                                                              style={{
                                                                  minWidth: 20,
                                                                  maxWidth: 20,
                                                                  height: 20,
                                                                  marginLeft: i !== 0 ? -10 : 0
                                                              }}
                                                              className={'mr-1 d-flex '} src={item}>
                                                </Image>
                                            })}</div>
                                            <a href="" onClick={(e) => {
                                                e.preventDefault();
                                                setOpenModal(true)
                                            }}>Select people to notify</a>
                                        </div>
                                        <div><Button className={'btn-white mr-3'} onClick={() => {
                                            history.push('/info-board')
                                        }}>Cancel
                                        </Button>
                                            <Button className={'btn-blue'} onClick={() => {
                                                history.push('/info-board')
                                            }}>Post
                                            </Button></div>
                                    </div>
                                </div>
                            </div>
                        </CreateProjectBoardBox>

                        {openModal && <Modal onClick={() => setOpenModal(false)}>
                            <div className=" corner-radius10 bg-white p-3">
                                <div className={'d-flex justify-content-between'}>
                                    <h4 className={'font-weight-bold'}>Select people to be notified</h4>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu neque
                                    pulvinar,
                                    tristique
                                    orci ut, feugiat justo.</p>
                                <div>
                                    <InputField/>
                                    <div className={'d-flex justify-content-end pt-2 my-2'}><a className={'link-small'}
                                                                                               href=""><b>Select
                                        All</b></a></div>
                                    <div>
                                        <div className={'d-flex pt-2'}>
                                            <div style={{minWidth: 40, maxWidth: 40, height: 40}} className={'mr-3'}>
                                                <img className={'img-responsive w-100 h-100 rounded-circle'}
                                                     style={{objectFit: 'cover'}}
                                                     src={'https://preview.keenthemes.com/metronic-v4/theme/assets/pages/img/avatars/team1.jpg'}
                                                     alt=""/>
                                            </div>
                                            <div className={'border-bottom pb-2 d-flex w-100  justify-content-between'}>
                                                <div className={' '}><h6>Steven Miller</h6>
                                                    <div className="small">Management - Lawyer</div>
                                                </div>
                                                <div><Checkbox></Checkbox></div>
                                            </div>
                                        </div>
                                        <div className={'d-flex pt-2'}>
                                            <div style={{minWidth: 40, maxWidth: 40, height: 40}} className={'mr-3'}>
                                                <img className={'img-responsive w-100 h-100 rounded-circle'}
                                                     style={{objectFit: 'cover'}}
                                                     src={'https://www.josari.io/wp-content/uploads/2019/07/smiling-man-in-suit-1920x1080-1024x576.jpg'}
                                                     alt=""/>
                                            </div>
                                            <div className={'border-bottom pb-2 d-flex w-100 justify-content-between'}>
                                                <div className={' '}><h6>Paul Evens</h6>
                                                    <div className="small">Management - Lawyer</div>
                                                </div>
                                                <div><Checkbox></Checkbox></div>
                                            </div>
                                        </div>
                                        <div className={'d-flex pt-2'}>
                                            <div style={{minWidth: 40, maxWidth: 40, height: 40}} className={'mr-3'}>
                                                <img className={'img-responsive w-100 h-100 rounded-circle'}
                                                     style={{objectFit: 'cover'}}
                                                     src={'https://pixomatic.us/blog/wp-content/uploads/2019/11/pixomatic_1572877223091.png'}
                                                     alt=""/>
                                            </div>
                                            <div className={'border-bottom pb-2 d-flex w-100 justify-content-between'}>
                                                <div className={' '}><h6>Augusto Memeli</h6>
                                                    <div className="small">Management - Lawyer</div>
                                                </div>
                                                <div><Checkbox></Checkbox></div>
                                            </div>
                                        </div>
                                        <div className={'d-flex pt-2'}>
                                            <div style={{minWidth: 40, maxWidth: 40, height: 40}} className={'mr-3'}>
                                                <img className={'img-responsive w-100 h-100 rounded-circle'}
                                                     style={{objectFit: 'cover'}}
                                                     src={'https://images.squarespace-cdn.com/content/v1/5d96fc780e85b877a919f4f2/1570777847921-A6W51WBD9SWVQYVPGJA4/ke17ZwdGBToddI8pDm48kMXRibDYMhUiookWqwUxEZ97gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0luUmcNM2NMBIHLdYyXL-Jww_XBra4mrrAHD6FMA3bNKOBm5vyMDUBjVQdcIrt03OQ/Angela-16.jpg'}
                                                     alt=""/>
                                            </div>
                                            <div className={'border-bottom pb-2 d-flex w-100 justify-content-between'}>
                                                <div className={' '}><h6>Hanna Collins</h6>
                                                    <div className="small">Management - Lawyer</div>
                                                </div>
                                                <div><Checkbox></Checkbox></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="d-flex justify-content-end mt-4">
                                    <Button className={'btn-white mr-3'}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setOpenModal(false)
                                            }}>Back</Button> <Button className={'btn-blue'}
                                                                     onClick={(e) => {
                                                                         e.preventDefault()
                                                                         setOpenModal(false)
                                                                     }}>Save</Button>
                                </div>
                            </div>
                        </Modal>}
                    </div>
                    <div className={'col-md-3 bg-white pt-3'}>
                        <div className={'d-flex justify-content-around w-100'}>
                            <InputField name={'search'} label={'Search'}/>
                            <img src="/assets/images/Notifications.svg" alt=""/>
                        </div>
                        <div className={'d-flex flex-column align-items-center mt-5'}>
                            <Image w={100} h={100} className={'simple-border'}
                                   src={`http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${individualUserProfile.id}`}/>
                            <div className={'mt-3'}>
                                <b>{individualUserProfile.firstName + " " + individualUserProfile.lastName}</b>
                            </div>
                            <span className={'small'}>Owner</span>
                        </div>
                        <div className={'border-bottom d-flex mt-4 px-2 justify-content-between'}>
                            <h6><b>On my list</b></h6>
                            <span>All</span>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Update supply change on
                                    some list somewhere
                                </div>
                                <a href={''} className={'link-small pt-2'}>@projectname</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold pb-'}>Jin Tan in applies in listing
                                    <a href={''}
                                       className={'link-small font-weight-normal pt-2'}> @CFO
                                        position opening</a></div>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Thomas M. Trevino requested
                                    NDA
                                </div>
                                <a href={''} className={'link-small pt-2'}>@Selpic P1</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Jin Tan reported something
                                    at
                                </div>
                                <a href={''} className={'link-small pt-2'}>@Selpic P1</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold pb- '}>Richard M. Hobbs in Follows <a
                                    href={''} className={'link-small font-weight-normal pt-2'}> @purME</a> Project.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CreatePostInfoBoard.propTypes = {};

export default CreatePostInfoBoard;