import React from 'react'
import DueDiligenceSharedComponent from './DueDiligenceSharedComponent';

const AMLCheck = () => {
    return (<>
        <DueDiligenceSharedComponent />
        <div>AMLCheck</div>
    </>
    )
}

export default AMLCheck