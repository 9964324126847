import React from 'react';
import MenuBar from "../../Common/MenuBar";
import GrayInfoBox from "../../Common/GrayInfoBox";
import MenuDots from "../../../App/components/MenuDots";
import Tabs from "../../../App/components/Tabs";
import BriefInfo from "../View/BriefInfo";
import PurposeInfo from "../View/PurposeInfo";
import WorkHistoryInfo from "../View/WorkHistoryInfo";
import Button from "../../../App/components/Button";
import MediaInfo from "../View/MediaInfo";
import Header from "../../Common/Header";

const FreelanceProfileView = props => {
    return (
        <div>
            <Header/>
            <div className=""><MenuBar page={'Profile'}/></div>
            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container'}>
                    <div className={'row'}>
                        <div className="col-md-3">
                            <GrayInfoBox className={'user-info-box'}>
                                <div className={'text-center user-info'}>
                                    <MenuDots className={'user-box-dots'}/>
                                    <div>
                                        <img src="https://via.placeholder.com/100" alt=""/>
                                    </div>
                                    <p>Tim Cook</p>
                                    <span>Apple • Chief Executive Officer • 4 Years</span><br/>
                                    <span>University of Oxford • Biochemistry • PhD</span><br/>
                                    <span>United States of America • Washington, DC</span><br/>
                                    <div className="d-flex justify-content-around user-box-links">
                                        <a href="" className={'link-small'}>Email</a>
                                        <a href="" className={'link-small'}>Message</a>
                                        <a href="" className={'link-small'}>Call</a>
                                        <a href="" className={'link-small'}>Website</a>
                                    </div>
                                    <div className={'mx-2 mt-3'}>
                                        <Button className={'btn-blue btn-block'}>Hire</Button>
                                    </div>
                                </div>
                            </GrayInfoBox>
                        </div>
                        <div className="col-md-9">
                            <Tabs
                                className={'tabs-dashboard p-none'}
                                tabClassName={'flex-grow-1'}
                                tabs={['Media', 'Brief', 'Work History', 'Purpose']}>
                                <MediaInfo/>
                                <BriefInfo/>
                                <WorkHistoryInfo/>
                                <PurposeInfo/>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

FreelanceProfileView.propTypes = {};

export default FreelanceProfileView;