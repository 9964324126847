import axios from 'axios'

const api_multy_data = axios.create({
    // baseURL: 'http://d589-46-217-7-132.ngrok.io',
    // baseURL: 'https://606c-89-205-99-22.ngrok.io/', // 
    baseURL: 'http://directventures.ekonet.mk:7777', // original
    headers: {
        'content-type': 'multipart/form-data'
    }
})
api_multy_data.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }
        return config
    },
    error => Promise.reject(error)
)
export default api_multy_data