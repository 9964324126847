import {useEffect} from 'react';
import useForm from "./useForm";

const useAdvancedSearchFilters = (initialState, type, setFilter, fields, setFields) => {
    const {handleChange, values} = useForm(initialState)

    useEffect(() => {
        setFilter({type, fields, selected: true})
    }, [fields])

    useEffect(() => {
        return () => setFilter([])
    }, [])

    const handleOnBlur = (e) => {
        const field = {
            name: e.target.name,
            value: e.target.value
        };
        const fieldIndex = fields.findIndex(item => item.name === field.name);

        if (fieldIndex > -1) {
            if (!field.value) {
                const newArr = [...fields]
                newArr.splice(fieldIndex, 1)
                return setFields(newArr)
            }
            return setFields([...fields.slice(0, fieldIndex), {
                ...fields[fieldIndex],
                value: field.value
            }, ...fields.slice(fieldIndex + 1)])
        } else {
            if (!field.value) return
            return setFields([...fields, field])
        }
    }

    return {
        handleOnBlur,
        handleChange,
        values
    }
};

export default useAdvancedSearchFilters;