import React from 'react';
import CoverImgBox from "../../Common/CoverImgBox";
import Button from "../../../App/components/Button";
import MenuDots from "../../../App/components/MenuDots";

const OpportunityCover = props => {
    return (
        <div className={''}>
            <CoverImgBox> <Button className={'btn-blue small-btn'}>Enroll</Button>
                <MenuDots className={'dots-vertical'}/></CoverImgBox>
        </div>
    );
};

OpportunityCover.propTypes = {};

export default OpportunityCover;