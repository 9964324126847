import React, { useState, useEffect } from 'react';
// import Experience from './OpportunityComponents_2/Experience';
import Experience_2 from './OpportunityComponents_2/Experience_2';
// import Info from './OpportunityComponents_2/Info';
// import Insight from './OpportunityComponents_2/Insight';
import Location from './OpportunityComponents_2/Location';
import Loader from "../../Common/Loader";
import Button from '../../../App/components/Button';
// import InfoView from '../shared_components/InfoView';
import InfoView_2 from '../shared_components/InfoView_2';
import Pitch from '../shared_components/Pitch';

// import Places from './OpportunityComponents_2/Places';

// import Test_view_location from './Test_view_location.js'

import api from "../../../App/config/api";

import { useDispatch, useSelector } from "react-redux";

import { useHistory, useParams } from "react-router";
import NoDataMessageDiv from '../../IndividualUserProfile/View/NoDataMessageDiv';



const OpportunityTab = (props) => {

  const params = useParams()

  const { account } = useSelector((state) => state.user);
  const [userId, setUserId] = useState(account.data.individualUserProfile.id);

  const [data, setData] = useState()
  const [length, setLength] = useState()
  const [opp, setOpp] = useState()

  const [isDataLoaded, setIsDataLoaded] = useState(false)



  // const [aliases_2, setAliases_2] = useState([])


  const getOpportunity = async () => {
    try {
      // const res = await api.get(`/api/opportunities?userId=17&userType=1`)
      // const res = await api.get(`/api/opportunities?userId=${params.id ? params.id : userId}&userType=0`)
      const res = await api.get(`/api/opportunities?userId=${params.id}&userType=1`)
      // const res = await api.get(`/api/opportunities?userId=${userId}&userType=1`)
      // setAliases_2(res.data.body)

      setLength(res.data.body.length - 1)
      // new
      setOpp({
        opportunity: res.data.body[0],
        index: 0
      })
      // setData(res.data.body.length > 0 ? res.data.body : null)
      // setData(res.data.body.length > 0 ? res.data.body.length : null)
      setData(res.data.body)
      setIsDataLoaded(true)

    } catch (e) {
      return console.error(e.message);
    }
  }

  useEffect(() => {
    getOpportunity()
  }, [])


  // const testFetch = async () => {

  //   let res = await fetch('https://mocki.io/v1/0f30355e-c032-4ea4-ba9d-537e0b6d780c')
  //   let data = await res.json()

  //   setData(data)
  //   setLength(data.length - 1)
  //   // new
  //   setOpp({
  //     opportunity: data[0],
  //     index: 0
  //   })

  // }

  // useEffect(() => {
  //   testFetch()
  // }, [])

  return (
    <>

      {/* {!data ? <Loader /> : */}
      {!isDataLoaded ? <Loader /> : data.length < 1 ?
        <NoDataMessageDiv message={'no opportunity for this company yet'} />
        : <div>
       
          <InfoView_2 props={opp.opportunity} />
          {opp.opportunity.experience && <Experience_2 experience={opp.opportunity.experience} />}
          <Pitch props={opp.opportunity} />

          <Location props={opp.opportunity} />
          {/* <div className={"d-flex justify-content-between "}>
            <Button className={"btn-white"}
              onClick={() => {
                opp.index - 1 >= 0 && setOpp({ opportunity: data[opp.index - 1], index: opp.index - 1 });
                window.scrollTo(0, 0);
              }}
            >
              Back
            </Button>

            <div className={"d-flex align-items-center "}>
              <div>{opp.index + 1} out of {length + 1} opportunities</div>
            </div>
            <Button className={"btn-white"}
              onClick={() => {
                opp.index + 1 <= length && setOpp({ opportunity: data[opp.index + 1], index: opp.index + 1 });
                window.scrollTo(0, 0);
              }}
            >
              Next
            </Button>

          </div> */}
          {/* NAVIGATION BUTTONS */}
          <div className='d-flex justify-content-end g-opportunity-view-naviation-buttons-div'>

            {/* Back */}
            <div className='g-opportunity-view-naviation-buttons'
              onClick={() => {
                opp.index - 1 >= 0 && setOpp({ opportunity: data[opp.index - 1], index: opp.index - 1 });
                window.scrollTo(0, 0);
              }}>
              <img className={'g-opportunity-view-naviation-arrow-img'} src="\assets\images\left_arrow_gray.svg" alt="" />
            </div>

            {/* First List Item */}
            {data && <div className={'g-opportunity-view-naviation-buttons ' + (opp.index == 0 ? ' active-navigation-button ' : null)} onClick={() => {
              setOpp({ opportunity: data[0], index: 0 })
              window.scrollTo(0, 0);
            }} >1</div>}

            {/*listing rest of the items */}
            {data && data.map((x, i) => {
              if (i != data.length - 1 && i !== 0) {
                if (i == opp.index - 1 || i == opp.index || i == opp.index + 1 || i == opp.index + 2) {
                  return (
                    <div key={i} className={' g-opportunity-view-naviation-buttons ' + (opp.index == i ? ' active-navigation-button ' : null)} onClick={(e) => {

                      setOpp({ opportunity: data[i], index: i })
                      window.scrollTo(0, 0);
                    }} >{i + 1}</div>
                  )
                }
              }
            })}

            {/* Three dots ... */}
            {data && (data.length - opp.index) > 4 && <div className='g-opportunity-view-naviation-buttons'  >...</div>}

            {/* Last List Item */}
            {data && data.length > 1 && <div className={'g-opportunity-view-naviation-buttons ' + (opp.index == data.length - 1 ? ' active-navigation-button ' : null)} onClick={() => {
              setOpp({ opportunity: data[data.length - 1], index: data.length - 1 })
              window.scrollTo(0, 0);
            }} >{data.length}</div>}

            <div className='g-opportunity-view-naviation-input-page-wrap'>
              <div className='g-opportunity-view-naviation-input-page-text'>
                go to page:
              </div>
            </div>

            {/* input 'page' that you wanna visit */}
            {data && <input type='number' className='g-opportunity-view-naviation-buttons g-opportunity-view-select-opp-input '
              onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  console.log('enter press here! ')
                  if (e.currentTarget.value > 0 && e.currentTarget.value <= data.length) {
                    setOpp({ opportunity: data[e.currentTarget.value - 1], index: e.currentTarget.value - 1 })
                    window.scrollTo(0, 0);
                  }
                }
              }} />}

            {/* Next */}
            <div className='g-opportunity-view-naviation-buttons'
              onClick={() => {
                opp.index + 1 <= length && setOpp({ opportunity: data[opp.index + 1], index: opp.index + 1 });
                window.scrollTo(0, 0);
              }}>
              <img className={'g-opportunity-view-naviation-arrow-img'} src="\assets\images\right_arrow_gray.svg" alt="" />
            </div>
          </div>
        </div>}
    </>
  );
};

OpportunityTab.propTypes = {};
export default OpportunityTab;
