import React from 'react';
import '../styles/GrayInfoBox.scss'

const GrayInfoBox = ({className, children}) => {
    return (
        <div className={'gray-info-box  ' + className}>
            {children}
        </div>
    );
};

GrayInfoBox.propTypes = {};

export default GrayInfoBox;