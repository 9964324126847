import React, { useEffect, useState } from 'react';
import MenuActionIcon from "./MenuActionIcon";
// import '../styles/MenuBar.scss'
import './common_styles/MenuBar_2.scss'

import api from "../../App/config/api";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccount } from "../../App/store/user";

const MenuBar = ({ page }) => {
    const { data, isLoading } = useSelector(state => state.user.account)
    const { individualUserProfile, locations } = data
    const [friendRequests, setFriendRequests] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchAccount())
        if (individualUserProfile) {
            api.get(`/api/friendship-request?userId=${individualUserProfile.id}&userType=INDIVIDUAL_USER`)
                .then(res => setFriendRequests(res.data))
                .catch(e => console.log(e))
            setInterval(() => {
                api.get(`/api/friendship-request?userId=${individualUserProfile.id}&userType=INDIVIDUAL_USER`)
                    .then(res => setFriendRequests(res.data))
                    .catch(e => console.log(e))
            }, 50000)
        }
    }, [])

    if (isLoading)
        return <div></div>
    return (
        <>
            {/* <div className={'bor  menu-bar d-flex flex-column align-items-center justify-content-around text-center left_side_manu_responsive'}> */}
            {/* <div className={' menu-bar d-flex flex-column align-items-center justify-content-between text-center left_side_manu_responsive'}> */}
            <div className={' menu-bar d-flex flex-column align-items-center justify-content-between  left_side_manu_responsive'}>
                
                <div className="menu-actions">
                    <MenuActionIcon href={'/'}
                        name={'Home'}
                        // src={page === 'Home' ? 'Home-2' : 'Home'}
                        src={page === 'Home' ? 'Home-blue' : 'Home-blue'}
                        customImgHeight={25}
                        color={'transparent'}
                        margin_bot={true}
                        className={page === 'Home' ? 'active-icon' : ''} />
                    <MenuActionIcon
                        href={'/network'}
                        // src={page === 'Network' ? 'Network-2' : 'Network'}
                        src={page === 'Network' ? 'Network-2-Home-Page-2' : 'Network-2-Home-Page-2'}
                        name={'Network'}
                        friendRequests={friendRequests}
                        color={'blue-gradient'}
                        // className={page === 'Network' ? 'active-icon' : ''} 
                        />
                    <MenuActionIcon
                        href={'/all-alias'}
                        name={'Alias'}
                        src={page === 'Opportunities' ? 'Aliases' : 'Aliases'}
                        customImgHeight={18}
                        // color={'green'}
                        color={'dark-blue-gradient'}
                        className={page === 'Opportunities' ? 'active-icon' : ''} />
                    <MenuActionIcon
                        href={'/opportunities'}
                        name={'Opportunities'}
                        // src={page === 'Opportunities' ? 'Opportunities-2' : 'Opportunities'}
                        src={page === 'Opportunities' ? 'Opportunities-Home-Page-2' : 'Opportunities-Home-Page-2'}
                        // color={'green'}
                        color={'purple-gradient'}
                        className={page === 'Opportunities' ? 'active-icon' : ''} />
                    <MenuActionIcon
                        href={'/contracting'}
                        name={'Contracting'}
                        // src={page === 'Opportunities' ? 'Opportunities-2' : 'Opportunities'}
                        src={page === 'Opportunities' ? 'Contracts_white' : 'Contracts_white'}
                        // color={'green'}
                        color={'just-blue-gradient'}
                        className={page === 'Opportunities' ? 'active-icon' : ''} />
                    {/* <MenuActionIcon
                        href={'/'}
                        name={'Conferencing'}
                        // src={page === 'Opportunities' ? 'Opportunities-2' : 'Opportunities'}
                        src={page === 'Opportunities' ? 'Camera' : 'Camera'}
                        // color={'green'}
                        color={'just-blue-gradient'}
                        className={page === 'Opportunities' ? 'active-icon' : ''} /> */}
                </div>
                {/* <div className="menu-footer d-flex flex-column"> */}
                <div className="menu-footer">
                  
                    <a className={'footer-link'} href="">Privacy Policy</a>
                    <a className={'footer-link'} href="">Terms</a>
                    <a className={'footer-link'} href="">Contact us</a>
                    <a className={'footer-link'} href="">About</a>
                    <div className={'footer-name'} >&copy;&nbsp;2020 Direct Ventures</div>
                   
                </div>
            </div>
        </>
    );
};

MenuBar.propTypes = {};

export default MenuBar;