import React, {useState} from 'react';
import {useHistory} from "react-router";
import InputField from "../../../App/components/InputField";
import Button from "../../../App/components/Button";

const CreateOpportunityModalContent = props => {
    const [name, setName] = useState('')
    const history = useHistory()

    const handleChange = (e) => {
        [e.target.name] = e.target.value
        setName(e.target.value)
    }

    return (
        <form>
            <div className=" corner-radius10 bg-white p-3">
                <div className={'d-flex justify-content-between'}>
                    <h4 className={'font-weight-bold'}>Create New Project!!!</h4>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu neque pulvinar,
                    tristique
                    orci ut, feugiat justo.</p>
                <div>
                    <InputField onChange={handleChange} name={'name'} label={"Project Name"}
                                className={'mb-4'}/>
                    {/*<DropZone*/}
                    {/*    title={<div>Drag your image or <a href={''} className={'link-small'}>browse</a>*/}
                    {/*    </div>}/>*/}
                </div>
                <div className="d-flex justify-content-end mt-4">
                    <Button className={'btn-blue'}
                            onClick={(e) => {
                                e.preventDefault()
                                history.push(`/create-opportunity?name=${name || 'AECF'}`)

                            }}>Create</Button>
                </div>
            </div>
        </form>
    );
};

CreateOpportunityModalContent.propTypes = {};

export default CreateOpportunityModalContent;