import React from 'react';
import Image from "../../../App/components/Image";

const Statistics = props => {
    return (
        <div className={'corner-radius5 simple-shadow p-4 bg-white'}>
            <h4><b>Statistics</b></h4>
            <div className="row mt-5">
                <div className="col-md-3">
                    <Image src={'/assets/images/ne.png'} corners={'corner-radius10'}/>
                </div>
                <div className="col-md-3">
                    <Image src={'/assets/images/pv.png'} corners={'corner-radius10'}/>
                </div>
                <div className="col-md-3">
                    <Image src={'/assets/images/pi.png'} corners={'corner-radius10'}/>
                </div>
                <div className="col-md-3">
                    <Image src={'/assets/images/sc.png'} corners={'corner-radius10'}/>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-12">
                    <Image src={'/assets/images/Time-s.png'} corners={'corner-radius10'}/>
                </div>
            </div>
        </div>
    );
};

Statistics.propTypes = {};

export default Statistics;