import React from 'react';
import Image from "../../../App/components/Image";
import {useSearchParam} from "react-use";
import {Link} from "react-router-dom";

const ProfileActions = props => {
    const firstname = useSearchParam('firstname')
    const lastname = useSearchParam('lastname')
    const id = useSearchParam('id')
    return (
        <div className={'corner-radius5 simple-shadow  bg-white'}>
            <Image corners={'rounded-top'}
                   src={'https://www.exploregeorgia.org/sites/default/files/styles/slideshow_large/public/2018-05/hot-air-balloons-at-callaway-gardens-1522773465.jpg?itok=DQMPLXzg'}
                   h={200} w={'100%'}
            />
            <div style={{position: 'relative'}} className={'bg-white rounded-bottom'}>
                <div style={{position: 'absolute', top: -75, left: 40}}>
                    <Image w={150} h={150}
                           className={'bg-white'}
                           style={{border: '3px solid white'}}
                           src={`http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${id}`}/>
                </div>
                <div style={{position: 'relative', marginLeft: 200}} className={'row py-3'}>
                    <div className="col-md-6">
                        <div className={'border-bottom pb-2'}><h4><b>{firstname} {lastname}</b></h4></div>
                        <div className="d-flex justify-content-between pt-3 px-2">
                            <Link to={`/profile/${id}`}><b>View Profile</b></Link>
                            <a href="#"><b>Email</b></a>
                            <a href="#"><b>Message</b></a>
                            <a href="#"><b>Call</b></a>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                        <div className={'d-flex flex-wrap align-items-center'}
                        >{['https://preview.keenthemes.com/metronic-v4/theme/assets/pages/img/avatars/team1.jpg', 'https://i.pinimg.com/600x315/a6/0d/68/a60d685194a7fd984d08a595a0a99ae7.jpg', 'https://i.pinimg.com/236x/67/5f/40/675f409cfcbea9281f6649ca096caf25--corporate-photography-headshot-photography.jpg', 'https://osmey-milian-photography.com/_files/200002613-7c7f77d81f/OMD_7187-Website.jpg', 'https://content1.getnarrativeapp.com/68f6039f-a2f9-4e15-9b91-4f7cebe2a378/img_ref/4e80a9d6-aef4-4875-ab94-5952b6926e07/Hamish-5_professional_headshots_photographer_headshot_750.jpg', 'https://pixomatic.us/blog/wp-content/uploads/2019/11/pixomatic_1572877223091.png',].map((item, i) => {
                            return <Image key={i}
                                          style={{
                                              minWidth: 30,
                                              maxWidth: 30,
                                              height: 30,
                                              marginLeft: i !== 0 ? -10 : 0
                                          }}
                                          className={'mr-1 d-flex '} src={item}>
                            </Image>
                        })}</div>
                        <div className={'ml-3'}><b>134 Commendations</b></div>
                    </div>

                </div>


            </div>
        </div>
    );
};

ProfileActions.propTypes = {};

export default ProfileActions;