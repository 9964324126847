import React from 'react';
import '../styles/HeaderMenuContainer.scss'

const HeaderMenuContainer = React.forwardRef(({children}, ref) => {

    return (
        <div ref={ref} className={'header-menu form-box corner-radius10'}>
            <div className={'top-actions '}>{children}</div>
        </div>
    );
});

HeaderMenuContainer.propTypes = {};

export default HeaderMenuContainer;