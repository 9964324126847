import React from 'react';
import useForm from "../../helpers/customHooks/useForm";
import FormBoxSection from "./FormBoxSection";
import FormColumns from "./FormColumns";
import InputAndActionContainer from "./InputAndActionContainer";
import InputField from "../../App/components/InputField";
import Dropdown from "../../App/components/Dropdown";

const BranchesEditing = props => {
    const {handleChange, handleSubmit, values, errors} = useForm({email: '', password: ""}, submit, validate)

    function submit() {
        console.log(values)
        console.log(errors)
    }

    function validate(v) {
        return {}
    }

    return (
        <form action="" className={'mt-4r'} onSubmit={handleSubmit}>
        {/* <form action="" className={'mt-5'} onSubmit={handleSubmit}> */}

            <FormBoxSection title={"Branches"}>
                {/* <p>Only html preview in this phase!</p> */}
                <FormColumns>
                    <div className={'d-flex flex-column py-4'}>
                        <InputAndActionContainer>
                            <InputField label={"Number of Branches"} name={''}
                                        onChange={handleChange}/>

                        </InputAndActionContainer>
                        <InputAndActionContainer>
                            <InputField label={"Location"} name={''}
                                        onChange={handleChange}/>
                            {/*<div>*/}
                            {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elionec ut arcu etfsa.</p>*/}
                            {/*    <a href="" className={'link-small'}>Add second</a>*/}
                            {/*</div>*/}
                        </InputAndActionContainer>
                        <InputAndActionContainer>
                            <Dropdown options={[{name: 'Type1', value: 'type1'},
                                {name: 'Type2', value: 'type2'}
                            ]} label={"Select Type"}/>
                        </InputAndActionContainer>
                        <InputAndActionContainer>
                            <InputField label={"Leadership"}/>
                            <></>
                        </InputAndActionContainer>
                        <InputAndActionContainer>
                            <InputField label={"Phone"}/>
                        </InputAndActionContainer>
                    </div>
                    <div>
                        {/*<button className={'btn edit-link'} type='submit'>Done editing</button>*/}
                    </div>
                </FormColumns>
            </FormBoxSection>
        </form>
    )
};

BranchesEditing.propTypes = {};

export default BranchesEditing;