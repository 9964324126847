import React from 'react';
import '../styles/PublicHeader.scss'

const PublicHeader = ({children}) => {
    return (
        <>
            <div className='container-fluid px-5 py-3'>
                <span className='float-right lng'><a>English</a></span>
            </div>

            <div className='container py-3 px-0'>
                <nav className='nav-bar d-flex justify-content-between align-items-center'>
                    <div className={'d-flex align-items-center'}>
                        <img src="/assets/images/logo.svg" alt=""/>
                        <div className={'color-mainBlue'}
                        >DIRECT <br></br>VENTURES
                        </div>
                    </div>
                    <div>{children}</div>
                </nav>
            </div>
        </>
    )
}

PublicHeader.propTypes = {};

export default PublicHeader;