import React from 'react';
import ProfileInfoBoxSection from "./ProfileInfoBoxSection";
import MenuDots from "../../App/components/MenuDots";
import '../styles/SharedLink.scss'
import Image from "../../App/components/Image";

const SharedLink = ({src, accountImg, accountName, postDescription, description, children, margin, following, ...rest}) => {
    return (
        <ProfileInfoBoxSection className={''}>
            <div className={'media-box'}>
                <div className={' d-flex justify-content-between'}>
                    <div className={'d-flex'}>
                        <div className={'media-profile-picture'}>
                            <Image w={60} h={60}
                                   corners={'corner-radius10'}
                                   src={accountImg}
                                   {...rest}
                                   alt=""/>
                        </div>
                        <div>
                            <h5>{accountName}</h5>
                            <p>3d • Opportunity • Public</p>
                        </div>
                    </div>
                    <div className={'d-flex'}><a style={{color: 'lightgray'}}>{following && <b>Following</b>}</a>
                        <MenuDots className={'dots-vertical'}/>
                    </div>
                </div>
                <div className={'mt-3'}>
                    <p>{postDescription}</p>
                    <div className={'media-shared-link'}>
                        {children || <div className={'media-image '} style={{backgroundImage: `url(${src})`}}>
                        </div>}
                        {description && <div className={'shared-link-desc p-3'}>
                            {/*<h6>Building resilient systems to weather the unexpected</h6>*/}
                            {/*<p>cloud.google.com • 2 min read</p>*/}
                            <div>{description}</div>
                        </div>}
                    </div>
                    <div className={'d-flex likes-comments-info'}>
                        <div><img src="/assets/images/like.svg" alt=""/></div>
                        <div><img src="/assets/images/chat-alt-outline.svg" alt=""/></div>
                        <p>8,527 • 105 Comments</p>
                    </div>
                </div>
                <div className={'media-actions d-flex'}>
                    <a href=""><img src="/assets/images/like-action.svg" alt=""/>Like</a>
                    <a href=""><img src="/assets/images/comment-action.svg" alt=""/>Comment</a>
                    <a href=""><img src="/assets/images/share-action.svg" alt=""/>Share</a>
                    <a href=""><img src="/assets/images/send-action.svg" alt=""/>Send</a>
                </div>
            </div>
        </ProfileInfoBoxSection>
    );
};

SharedLink.propTypes = {};

export default SharedLink;