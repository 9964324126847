import React from 'react';
import axios from "axios";
import '../../styles/DocumentUploader.scss'

const DocumentUploader = ({setFileContent, setDocumentTitle, setIsSaved}) => {

    const handleChange = async (e) => {
        const file = e.target.files[0];
        setDocumentTitle(file.name)
        const data = new FormData();
        data.append('file', file);
        const headers = {
            'Content-Type': 'multipart/form-data',
        }
        const res = await axios.post('http://directventures.ekonet.mk:5000/docx-convert', data, {
            headers: headers,
        })
        setFileContent(res.data)
        setIsSaved(false)
    }

    return (
        <div className={'document-uploader'}>
            <label htmlFor={'file'} className="custom-file-upload">
                <img src="/assets/images/upload.svg" alt=""/> Select or Drag and Drop Document
            </label>
            <input type="file" name={'file'} onChange={handleChange}/>
        </div>
    );
};

DocumentUploader.propTypes = {};

export default DocumentUploader;