// import React from 'react'
// import { useDispatch } from "react-redux";
import { displayMessage } from '../App/store/mixed'

export const globalMessage = (o) => async dispatch => {
    // console.log('name', name)
    // console.log('type', type)
    dispatch(displayMessage({ show: true, text: o.name, type: o.type }));
    setTimeout(() => {
        dispatch(displayMessage({ show: false, text: '', type: '' }))
    }, 2000);
}


export const cutString = (string, num) => {
    // {x.name && x.name.length <= 16 ? x.name : x.name && x.name.length > 16 ? `${x.name.substring(0, 13)}...` : ''}
    // { x.name && x.name.length <= 16 ? x.name : x.name && x.name.length > 16 ? `${x.name.substring(0, 13)}...` : '' }
    let str = string.length <= num ? string : `${string.substring(0, num - 4)}...`
    return str
}

export const millisecondsToDate = (milliseconds) => {
    let date = new Date(milliseconds); // Date 2011-05-09T06:08:45.178Z
    let y = date.getFullYear();
    let year_length = date.getFullYear().toString().length;

    let year = year_length == 1 ? "000" + y : year_length == 2 ? "00" + y : year_length == 3 ? "0" + y : y;

    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);


    let x = `${year}-${month}-${day}`
    return x
}