import React from 'react';
import MenuBar from "../../Common/MenuBar";
import GrayInfoBox from "../../Common/GrayInfoBox";
import MenuDots from "../../../App/components/MenuDots";
import Tabs from "../../../App/components/Tabs";
import TabsTestView from "../../../App/components/TabsTestView";
import TabsViewMode from "../../../App/components/TabsViewMode";
import BriefInfo from "../View/BriefInfo";
import PurposeInfo from "../View/PurposeInfo";
import MediaInfo from "../View/MediaInfo";
import Header from "../../Common/Header";
import EmployeesInfo from "../View/EmployeesInfo";
import useGetAliasIntroduction from "../../../helpers/customHooks/useGetAliasIntroduction";
import Image from "../../../App/components/Image";
import CompanyProfileInfo from '../../Common/CompanyProfileInfo';
import OpportunityInfo from '../View/OpportunityInfo';
import OpportunityTabAlias from '../View/OpportunityTabAlias';
import { cutString } from '../../../helpers/helpers_2';

const CompanyProfileView = props => {
    const aliasTypeStr = 'LEGAL_ENTITIES_COMPANY'
    const {aliasType, introduction, paramsId, activeId, isLoading} = useGetAliasIntroduction(aliasTypeStr)
    // if (isLoading)
    // console.log('isLoading ', isLoading)
    // console.log('introduction ', introduction)
    // console.log('hi ', Object.keys(introduction).length === 0)


    if (isLoading || introduction == undefined || Object.keys(introduction).length === 0)
        return <div>loading...</div>
    return (
        <div>
            <Header/>
            <div className=""><MenuBar page={'Profile'}/></div>
            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container'}>
                <div className={'row'}>
                        <div className="col-md-3 alias-profile-info-desktop">
                            <GrayInfoBox className={'user-info-box border-0'}>
                                <div className={'text-center user-info'}>
                                    {/* <MenuDots className={'user-box-dots'} /> */}
                                    <div className={'m-auto pb-3'} style={{ width: 90 }}>
                                        <Image corners={'corner-radius10'}
                                            w={100} h={100}
                                            src={`http://directventures.ekonet.mk:7777/api/auth/alias-profile-image/${paramsId}`}
                                            alt="" />
                                    </div>
                                    <p>{cutString(introduction.aliasIdentity.name, 18)}</p>
                                    <span>{introduction.aliasIdentity.openSince}</span><br/>
                                    <span>Multinational Technology Company</span><br/>
                                    <span>{introduction.aliasIdentity.country}</span><br/>
                                    <span>{introduction.aliasIdentity.city}</span><br/>
                                </div>
                            </GrayInfoBox>
                        </div>
                        <div className="col-md-9">
                            <TabsTestView
                             profilePicture={<CompanyProfileInfo paramsId={paramsId} introduction={introduction}/>}
                                className={'tabs-dashboard p-none'}
                                tabClassName={'flex-grow-1'}
                                tabs={['Brief', 'Purpose', 'Opportunity']}>
                                {/* tabs={['Media', 'Brief', 'Purpose', 'Portfolio', 'Employees']}> */}
                                {/* <MediaInfo aliasTypeStr={aliasTypeStr}/> */}
                                <BriefInfo introduction={introduction} />
                                <PurposeInfo />
                                <OpportunityTabAlias />
                                {/* <PortfolioInfo/> */}
                                {/* <EmployeesInfo/> */}
                            </TabsTestView>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CompanyProfileView.propTypes = {};

export default CompanyProfileView;


// <div className={'row'}>
// <div className="col-md-12">
//     <Tabs
//     // <TabsViewMode
//     profilePicture={<CompanyProfileInfo/>}
//         className={'tabs-dashboard p-none'}
//         tabClassName={'flex-grow-1'}
//         tabs={['Brief', 'Purpose', 'Opportunity']}>
     
//         <BriefInfo introduction={introduction}/>
      
//         <PurposeInfo/>
//         <OpportunityTabAlias/>
       
//     {/* </TabsViewMode> */}
//     </Tabs>
// </div>
// </div>