import React, { useEffect } from 'react';
import EducationEditing from "./EducationEditing";
import ExperienceEditing from "./ExperienceEditing";
import { useDispatch, useSelector } from "react-redux";
import { fetchIntroduction } from "../../../App/store/user";
import SkillsEditing from "./SkillsEditing";


const Introduction = props => {
    const dispatch = useDispatch()
    const { data, isLoading } = useSelector(state => state.user.introduction)

    useEffect(() => {
        if (!Object.keys(data).length)
            dispatch(fetchIntroduction())
    }, [])

    if (isLoading)
        return <div> loading</div>
    return (



        <div className={'row'}>
            <div className="col-md-6"><SkillsEditing /><EducationEditing/></div>
            <div className="col-md-6 mt-4r-0r-res"><ExperienceEditing /></div>
        </div>
        // <div>
        //     <SkillsEditing/>
        //     <EducationEditing/>
        //     <ExperienceEditing/>
        // </div>
    );
};

Introduction.propTypes = {};

export default Introduction;