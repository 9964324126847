import React from 'react';
import SpecialityEditing from "./SpecialityEditing";
import SectorEditing from "./SectorEditing";
import LanguageEditing from "./LanguageEditing";
import SkillsEditing from "./SkillsEditing";


const Profession = props => {
    return (
        <div>
            <SpecialityEditing/>
            <SectorEditing/>
            <LanguageEditing/>
            <SkillsEditing/>
        </div>
    );
};

Profession.propTypes = {};

export default Profession;