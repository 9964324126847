import React, { useEffect, useState } from 'react'
import HPOCss from './container_styles/HomePageOpportunities.module.scss'

import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

// import api from "../../../App/config/api";
import api from "../../App/config/api";

// import { chooseViewTab } from '../../../App/store/tabs_navigation'
import { chooseViewTab } from '../../App/store/tabs_navigation'
import { cutString } from '../../helpers/helpers_2'
import { adjustStringWidth } from '../../helpers/helpers_3'

const SearchIcon = withStyles(theme => ({

    root: {
        fontSize: 35,
        [theme.breakpoints.down('768')]: {
            fontSize: 19,
        }
    },

}))(SearchOutlined);


const StyledTextField = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `10px`,

                [theme.breakpoints.down('768')]: {
                    borderRadius: `20px`,
                }
            },
        },
        '& .MuiInputBase-root': {
            padding: 10,
            // padding: 15,
            color: 'black',
            fontSize: 18,
            // fontSize: 20,

            [theme.breakpoints.down('768')]: {
                fontSize: 11,
                paddingTop: 5,
                paddingBottom: 5,


            }
        },
    },
}))(TextField);

const HomePageOpportunities = () => {


    //-------------------------------------------------------------------
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    //-------------------------------------------------------------------
    const history = useHistory()
    const dispatch = useDispatch()


    const { data, isLoading } = useSelector(state => state.user.account)
    const { individualUserProfile } = data

    // res.data.sort(() => .5 - Math.random())
    const [searchWord, setSearchWord] = useState('');
    const [suggestedOpportunities, setSuggestedOpportunities] = useState([])
    const [searchAllOpportunities, setSearchAllOpportunities] = useState([])

    const getAllOpportunites = async () => {
        const res = await api.get('/api/all-opportunities')
        // let data = await res.json()
        let x = res.data.body.sort(() => .5 - Math.random())
        setSuggestedOpportunities(x)
        setSearchAllOpportunities(x)
        // setSuggestedOpportunities(res.data.body)
        // setSearchAllOpportunities(res.data.body)

    }

    useEffect(() => {
        getAllOpportunites()
    }, [])

    //--------------------------------------------------------------------
    useEffect(() => {
        if (searchWord) {
            setSearchAllOpportunities(suggestedOpportunities.filter(n => n.name.toLowerCase().includes(searchWord) || n.projectWebsite.toLowerCase().includes(searchWord)))
        }
        else {
            setSearchAllOpportunities(suggestedOpportunities)
        }
    }, [searchWord])
    //--------------------------------------------------------------------
    //-------------------------------------------------------------------
    const [temp, setTemp] = useState()

    const testFetch = async () => {
        let res = await fetch('https://mocki.io/v1/0f30355e-c032-4ea4-ba9d-537e0b6d780c')
        let data = await res.json()
        setTemp(data)
    }

    useEffect(() => {
        testFetch()
    }, [])

    // return (
    //     <div className={HPOCss.ww}>HomePageOpportunities</div>
    // )

    return (
        <div>
            {/* <Header /> */}
            {/* <div className=""><MenuBar page={'opportunities'} /></div> */}
            <div className={'d-flex justify-content-center '} >

                {/* <div className={' ' + HPOCss.main_container}> */}
                <div className={'col-md-8 ' + HPOCss.main_container}>
                    {/* <div className={HPOCss.navigation_div} >
                        <Link to='/' className={HPOCss.back} ><img className={HPOCss.back_img} src='/assets/images/left_arrow.svg' alt="" /></Link>
                        <div className={HPOCss.navigation_title}>Opportunity</div>
                        <div className={HPOCss.next}></div>
                    </div> */}
                    {/* {windowSize.width < 768 && <div>Hi There</div>} */}
                    <div className={HPOCss.title} >Suggested opportunities</div>
                    <div className={HPOCss.main_search_div}>
                        {/* <div className={HPOCss.left_div_search}> <button className={HPOCss.button} >Create New</button> </div> */}
                        <div className={HPOCss.right_div_search}>
                            {/* <input placeholder='Search by name' className={'bor-o ' + HPOCss.input} type="text" /> */}
                            <StyledTextField value={searchWord} onChange={(e) => { setSearchWord(e.currentTarget.value.toLowerCase()) }} size='small' fullWidth id="standard-bare" variant="outlined" placeholder='Search by name'
                                InputProps={{ endAdornment: (<IconButton size='small'><SearchIcon /></IconButton>), }} />
                            {/* InputProps={{ endAdornment: (<IconButton size='small'><SearchOutlined style={{ fontSize: 35 }} /></IconButton>), }} />  */}
                            {/* InputProps={{ endAdornment: (<IconButton size='small'><SearchOutlined fontSize="large" /></IconButton>), }} /> */}
                        </div>
                    </div>





                    {searchAllOpportunities && searchAllOpportunities.slice(0, 5).map((x, i) => {
                        return (
                            <div key={i} className={HPOCss.main_list_div}>
                                <div className={HPOCss.left_list_div}>
                                    {/* <div className={HPOCss.picture}><img className={HPOCss.img} src='/assets/images/avatar-profile.svg' alt="" /></div> */}
                                    <div className={HPOCss.picture}><img className={HPOCss.img} src='/assets/images/avatar_no_picture.svg' alt="" /></div>

                                    <div className={HPOCss.name_div}>
                                        <div className={HPOCss.names_wraper} >
                                            <div className={HPOCss.name} >{windowSize.width < 768 ? adjustStringWidth(x.name, 200) : adjustStringWidth(x.name, 600)}</div>
                                            {/* <div className={HPOCss.name} >{x.name}</div> */}
                                            <div className={HPOCss.small_text}>{windowSize.width < 768 ? adjustStringWidth(x.projectWebsite, 250) : adjustStringWidth(x.projectWebsite, 880)}</div>
                                            {/* <div className={HPOCss.small_text}>{x.projectWebsite}</div> */}
                                            <div className={HPOCss.small_text + ' ' + HPOCss.phone_date}>{new Date(x.startDate).toDateString()}</div>
                                            {/* <div className={HPOCss.small_text + ' ' + HPOCss.phone_date}>{new Date(x.startDate).toLocaleDateString()}</div> */}
                                        </div>
                                    </div>
                                </div>

                                <div className={HPOCss.right_list_div}>
                                    <div className={HPOCss.date + ' ' + HPOCss.desktop_date}>{new Date(x.startDate).toDateString()}</div>
                                    {/* <div className={HPOCss.date + ' ' + HPOCss.desktop_date}>{new Date(x.startDate).toLocaleDateString()}</div> */}
                                    <div className={HPOCss.button_div}>
                                        <button className={HPOCss.button} onClick={() => {
                                            dispatch(chooseViewTab(2))
                                            setTimeout(() => {
                                                dispatch(chooseViewTab(0))
                                            }, 5000);
                                            if (x.userType == 0) {
                                                history.push(`/profile/${x.userId}`)

                                            } else if (x.userType == 1) {
                                                console.log(x.userId)

                                                //---------------------------------------------------

                                                api.get(`/api/alias-introduction/${x.userId}`)
                                                    .then(res => {
                                                        let aliasType = res.data.aliasIdentity.aliasType
                                                        console.log(aliasType)
                                                        if (aliasType == 'LEGAL_ENTITIES_COMPANY') {
                                                            history.push(`/company-profile/${x.userId}`)
                                                        } else if (aliasType == 'LEGAL_ENTITIES_INVESTMENT_FUND') {
                                                            history.push(`/investment-fund-profile/${x.userId}`)
                                                        } else if (aliasType == 'BUSINESS_ASSOCIATIONS_GOVERNMENTAL') {
                                                            history.push(`/governmental-profile-view/${x.userId}`)
                                                        } else if (aliasType == 'BUSINESS_ASSOCIATIONS_NON_GOVERNMENTAL') {
                                                            history.push(`/nongovernmental-profile-view/${x.userId}`)
                                                        } else {
                                                            console.log('this should not happen')
                                                        }

                                                    })
                                                    .catch(e => console.log(e))
                                                //---------------------------------------------------
                                            }

                                        }}  >View Profile</button>
                                        {/* <button onClick={() => {
                                            dispatch(chooseViewTab(2))
                                            setTimeout(() => {
                                                dispatch(chooseViewTab(0))
                                            }, 5000);
                                            history.push(`/profile/${x.userId}`)
                                        }} className={HPOCss.button} >View Project</button> */}
                                        {/* <div className={HPOCss.menu_dots_div} ><img className={HPOCss.img_menu_dots} src='/assets/images/menu-dots-normal-vertical-blue.svg' alt="" /></div> */}

                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default HomePageOpportunities