import React from 'react'
// import './styles/Footer.scss'
import SocialLinks from './SocialLinks';
import '../styles/PublicFooter.scss'

const Footer = ({children}) => {
    const footerLinks = ['About', 'Contact Us', 'Terms', 'Privacy Policy']
    return (
        <div className='public-footer container-fluid py-4'>
            <div className='row py-3 align-items-center'>
                <div className={'col-md-4 col-sm-12 d-flex justify-content-center social-links'}>
                    <SocialLinks/>
                </div>
                <div className={'col-md-4 col-sm-12 d-flex justify-content-center '}>
                    <ul className='d-flex footer-links'>
                        {footerLinks.map((link, i) => {
                            return (
                                <li key={i} className='mx-3 pointer'>
                                    <a>{link}</a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className='col-md-4 col-sm-12 d-flex justify-content-center'>
                    {children}
                </div>
            </div>
            <div className={'row'}>
                <div className='col-md-12 d-flex justify-content-center py-2'>
                    <span>&copy; 2020 Direct Ventures</span>
                </div>
            </div>
        </div>
    )
}

export default Footer
