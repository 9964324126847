import React, {useEffect} from 'react'
import PropTypes from 'prop-types';
import '../styles/Modal.scss'

export const Modal = ({onClick, children, title, className}) => {

    useEffect(() => {
        // body nonscrollable on opening the modal
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    return (
        <div onClick={onClick} className='modal-backdrop'>
            <div onClick={e => e.stopPropagation()} className={`modal-body ${className}`}>
                <h5 className="modal-title">{title}</h5>
                {children}
            </div>
        </div>
    )
}

export default Modal

Modal.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.element.isRequired,
    title: PropTypes.string
};