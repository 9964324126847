import React, { useEffect, useState } from 'react';
import Header from "../../Common/Header";
import MenuBar from "../../Common/MenuBar";
import Button from "../../../App/components/Button";
import ProfileInfoBoxSection from "../../Common/ProfileInfoBoxSection";
import AdvancedSearchBar from "../components/AdvancedSearchBar";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Image from "../../../App/components/Image";
import MenuDots from "../../../App/components/MenuDots";
import GrayInfoBox from "../../Common/GrayInfoBox";
import { checkAliasType } from "../../../helpers/helpers";
import { sendFriendRequest, sendFriendRequest_ver_2, acceptFriendRequest, declineFriendRequest, handleRemove } from "../../../helpers/helpers";
// import api from "../../../App/config/api";
import api from "../../../App/config/api";
import { globalMessage } from '../../../helpers/helpers_2'
import { useDispatch } from "react-redux";

import ASCss from './styles/AdvancedSearch.module.scss'

const AdvancedSearch = props => {
    const [resultsData, setResultsData] = useState([])
    const advancedSearchData = useSelector(state => state.advancedSearch.data)
    const history = useHistory()

    const { data, isLoading } = useSelector(state => state.user.account)
    const { individualUserProfile } = data
    const [friends, setFriends] = useState([])

  const dispatch = useDispatch()

  
    const anchorClick = (e) => {
        e.preventDefault()
    }


    useEffect(() => {
        if (individualUserProfile) {
            const getFrineds = async () => {
                const res = await api.get(`/api/friends?userId=${individualUserProfile.id}&userType=INDIVIDUAL_USER`)
                setFriends(res.data)
            }
            getFrineds()
            // api.get(`/api/friendship-request?userId=${individualUserProfile.id}&userType=INDIVIDUAL_USER`)
            //     .then(res => setFriendRequests(res.data))
            //     .catch(e => console.log(e))
            // setInterval(() => {
            //     api.get(`/api/friendship-request?userId=${individualUserProfile.id}&userType=INDIVIDUAL_USER`)
            //         .then(res => setFriendRequests(res.data))
            //         .catch(e => console.log(e))
            // }, 50000)
        }

    }, [individualUserProfile])


    useEffect(() => {
        if (advancedSearchData && Object.keys(advancedSearchData).length) {
            const arr = []
            for (const filter in advancedSearchData) {
                arr.push({ filter: filter, data: advancedSearchData[filter] })
            }
            setResultsData(arr)
        }
    }, [advancedSearchData])

    return (
        <div>
            <Header />
            <div className=""><MenuBar page={'Home'} /></div>
            <div className="col-md-11 offset-md-1 mt-2">
                <div className={'dashboard-inner-container dashboard-homepage'}>
                    <div className="media-section my-0">
                        <AdvancedSearchBar />
                        {resultsData.map((item, i) => {
                            return item.data && <div key={i}>
                                <h5 className={'mb-3 text-capitalize'}>{item.filter.split(/(?=[A-Z])/).join(" ")}</h5>
                                {item.data.map((data, index) => {
                                    return <ProfileInfoBoxSection key={index} className={'margin-b-0'}>
                                        <div className={'media-box'}>
                                            <div className={' d-flex justify-content-between'}>
                                                <div className={'d-flex'}>
                                                    <div
                                                        className={'media-profile-picture'}>
                                                        {/* className={'media-profile-picture simple-border corner-radius10'}> */}
                                                        <Image
                                                            w={58}
                                                            h={58}
                                                            imgStyle={{ borderRadius: '100px' }} // ako sakame nazad samo ova treba da go izbriseme od tuka
                                                            corners={'corner-radius10'}
                                                            src={item.filter === 'individualUsers' ? `http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${data.id}` : `http://directventures.ekonet.mk:7777/api/auth/alias-profile-image/${data.id}`}
                                                            alt="" />
                                                    </div>
                                                    <div>
                                                        <h5 className={'mt-2'}>
                                                            <div className={ASCss.user_name_text} >{data.name || (data.firstName + ' ' + data.lastName)}</div>
                                                            <div className={ASCss.email_text}>{data.email}</div>
                                                        </h5>
                                                        {/*<a href={''}>Suggested</a>*/}
                                                    </div>

                                                </div>
                                                <div className={'d-flex '}>
                                                    <div>
                                                        {/*  */}
                                                        {/* <Button
                                                            className={'btn-white small-btn mr-2'}
                                                            onClick={() => {
                                                                sendFriendRequest(individualUserProfile.id, data.id);
                                                                // handleRemove(x.id, searchAllUsers, setSearchAllUsers);
                                                                // handleRemove(x.id, allUsers, setAllUsers);
                                                            }}
                                                        >Add To Network</Button> */}
                                                        {/*  */}

                                                        {data.id == individualUserProfile.id ? null : friends.some(item => item.id == data.id) ?
                                                            // {friends.some(item => item.id == data.id) ?
                                                            <Button className={'btn-white small-btn mr-2'} >Connected</Button> :
                                                            <Button
                                                                className={'btn-white small-btn mr-2'}
                                                                onClick={() => {
                                                                    // sendFriendRequest_ver_2(individualUserProfile.id, data.id);
                                                                    // handleRemove(data.id, searchAllUsers, setSearchAllUsers);

                                                                    api.get(`/api/friendship-request?userId=${data.id}&userType=INDIVIDUAL_USER`)
                                                                    .then(res => {
                                                                      const found = res.data.some(item => item.id === individualUserProfile.id);
                                                                      if (found) {
                                                                        console.log('invite already sent')
                                                                        dispatch(globalMessage({ name: `invite has already been sent`, type: 'simple_message' }))
                                                                      } else {
                                                                        console.log('sending now')
                                            
                                                                        dispatch(globalMessage({ name: `${data.firstName} ${data.lastName}`, type: 'add_to_network' }))
                                                                        sendFriendRequest(individualUserProfile.id, data.id);
                                                          
                                                                      }
                                                                    })
                                                                    .catch(e => console.log(e))

                                                                }}
                                                            >Add To Network</Button>

                                                        }
                                                        {/*  */}
                                                        <Button
                                                            className={'btn-white small-btn mr-2'}
                                                            onClick={() => item.filter === 'individualUsers' ? history.push(`/profile/${data.id}`) : window.location.href = (`${checkAliasType(data.aliasType).routeProfile}${data.id}`)}
                                                        >View
                                                            Profile</Button>

                                                        {/* {item.filter === 'individualUsers' &&
                                                        <Button className={'btn-blue small-btn '}
                                                                onClick={() => history.push(`/hire-profile?firstname=${data.firstName}&lastname=${data.lastName}&id=${data.id}`)}
                                                        >Hire</Button>} */}
                                                    </div>
                                                    {/* <MenuDots className={'dots-vertical mt-2'}/> */}
                                                </div>
                                            </div>
                                            {(data.bio || data.description) && <div className={'p-3'}>
                                                {data.bio || data.description}
                                            </div>}
                                            {/* <div className={'media-actions '}>
                                                <a href="" onClick={anchorClick}><img
                                                    src="/assets/images/like-action.svg"
                                                    alt=""/>Save</a>
                                                <a href="" onClick={anchorClick}><img
                                                    src="/assets/images/share-action.svg" alt=""/>Share</a>
                                                <a href="" onClick={anchorClick}><img
                                                    src="/assets/images/send-action.svg"
                                                    alt=""/>Send</a>
                                            </div> */}
                                        </div>
                                    </ProfileInfoBoxSection>
                                })}
                            </div>
                        })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

AdvancedSearch.propTypes = {};

export default AdvancedSearch;