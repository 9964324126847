import React from 'react';
import ProfileInfoBoxSection from "../../Common/ProfileInfoBoxSection";

const Projects = props => {
    return (
        <ProfileInfoBoxSection className={'mt-5'} title={<div className={'d-flex justify-content-between'}>
            <div>Projects</div>
            <div><a href="" className={'link-small'}>View All</a></div>
        </div>}>
            <div className="profile-info ">
                <div className="row">
                    <div className="col-md-3">
                        <img src="https://via.placeholder.com/150" className={'w-100 mb-3'} alt=""/>
                    </div>
                    <div className="col-md-9">
                        <p>Mac mini Pro</p>
                        <p>A small form factor computer had been widely speculated and requested long before the release
                            of the Mac mini. Rumors predicted that the “headless iMac” would be extremely small, include
                            display, and would be positioned as Apple’s entry-level desktop computer.</p>
                        <p><a href="" className={'link-small'}>View Page</a></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <img src="https://via.placeholder.com/150" className={'w-100 mb-3'} alt=""/>
                    </div>
                    <div className="col-md-9">
                        <p>Apple Card</p>
                        <p>Commodo gravida. Proin non nulla lorem. Ut convallis, orci quis ultrices gravida, nisl mi
                            maximus turpis, non commodo lorem sapien eget fshaosjo sapien. In tincidunt urna a libero
                            pharetra vulputate.</p>
                        <p><a href="" className={'link-small'}>View Page</a></p>
                    </div>
                </div>
            </div>
        </ProfileInfoBoxSection>
    );
};

Projects.propTypes = {};

export default Projects;