import React, { useEffect, useState } from 'react';
import GrayInfoBox from "./GrayInfoBox";
import MenuDots from "../../App/components/MenuDots";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import Button from "../../App/components/Button";
import Image from "../../App/components/Image";
import { sendFriendRequest, sendFriendRequest_ver_2 } from "../../helpers/helpers";
// import api from "../../../App/config/api";
import api from "../../App/config/api";


import { globalMessage } from '../../helpers/helpers_2'
import { useDispatch } from "react-redux";

const UserProfileInfo = (props) => {
    const params = useParams()
    const history = useHistory()
    const { individualUserProfile, locations } = useSelector(state => state.user.account.data)
    const { firstName, lastName, id } = individualUserProfile || {}
    const { educations, experiences } = useSelector(state => state.user.introduction.data)
    const { userProfileData } = useSelector(state => state.users)
    const { individualUserAccountResponse, individualUserIntroductionResponse } = userProfileData.data || {}

  const dispatch = useDispatch()


    // const { data, isLoading } = useSelector(state => state.user.account)
    // const { individualUserProfile } = data

    // const [friends, setFriends] = useState([])
    const [isFriendOrNot, setIsFriendOrNot] = useState([])

    useEffect(() => {
        if (individualUserProfile) {
            const getFrineds = async () => {
                const res = await api.get(`/api/friends?userId=${individualUserProfile.id}&userType=INDIVIDUAL_USER`)
                // setFriends(res.data)
                setIsFriendOrNot(res.data.some(item => item.id == params.id))
            }
            getFrineds()
            // api.get(`/api/friendship-request?userId=${individualUserProfile.id}&userType=INDIVIDUAL_USER`)
            //     .then(res => setFriendRequests(res.data))
            //     .catch(e => console.log(e))
            // setInterval(() => {
            //     api.get(`/api/friendship-request?userId=${individualUserProfile.id}&userType=INDIVIDUAL_USER`)
            //         .then(res => setFriendRequests(res.data))
            //         .catch(e => console.log(e))
            // }, 50000)
        }

    }, [individualUserProfile])
    return (
        <GrayInfoBox className={'user-info-box-margin no-border'}>
            <div onClick={() => { console.log(educations) }} className={'text-center user-info'}>
                {/* <div onClick={() => { console.log(individualUserProfile) }} className={'text-center user-info'}> */}
                {/* <MenuDots className={'user-box-dots'} /> */}
                <div className={'m-auto pb-3'} style={{ width: 90 }}>
                    <Image
                        // corners={'corner-radius10'}
                        w={100} h={100}
                        src={`http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${!params.id ? individualUserProfile.id : individualUserAccountResponse.body.individualUserProfile.id}`}
                        alt="" />
                </div>
                <p>{!params.id ? firstName : individualUserAccountResponse.body.individualUserProfile.firstName} {!params.id ? lastName : individualUserAccountResponse.body.individualUserProfile.lastName}</p>
                {((!params.id ? educations : individualUserIntroductionResponse.body.educations) || []).map((item, i) => {
                    return <div key={i}><span
                        key={i}>{item.institution} • {item.subject} • {item.typeOfDegree}</span><br /></div>
                })}<br />
                {((!params.id ? experiences : individualUserIntroductionResponse.body.experiences) || []).map((item, i) => {
                    return <div key={i}>
                        <span>{item.company} • {item.position} • {item.startDate} - {item.endDate}</span><br />
                    </div>
                })}<br />
                {((!params.id ? locations : individualUserIntroductionResponse.body.locations) || []).map((item, i) => {
                    return <div key={i}><span>{item.stateOrCity} • {item.country}</span><br /></div>
                })
                }

                {/* <div className="d-flex justify-content-around user-box-links">
                    <a href="" className={'link-small'}>Email</a>
                    <a href="" className={'link-small'}>Message</a>
                    <a href="" className={'link-small'}>Call</a>
                    <a href="" className={'link-small'}>Website</a>
                </div> */}

                {/* {friends.some(item => item.id == data.id) ?
                    <Button className={'btn-white small-btn mr-2'} >Connected</Button> :
                    <Button
                        className={'btn-white small-btn mr-2'}
                        onClick={() => {
                            sendFriendRequest(individualUserProfile.id, data.id);
                            // handleRemove(data.id, searchAllUsers, setSearchAllUsers);
                        }}
                    >Add To Network</Button>

                } */}


                {params.id && <div className={'d-flex flex-column'}>

                    {/* {friends.some(item => item.id == params.id) ?
                        <Button className={'btn-white  mt-2'} >Connected</Button> :
                        <Button className={'btn-white  mt-2'}
                            onClick={() => sendFriendRequest(individualUserProfile.id, individualUserAccountResponse.body.individualUserProfile.id)}
                        >Add to Network</Button>

                    } */}

                    {/* {!params.id ? */}

                    {/* testing */}
                    {/* <div>{id}</div>
                    <div>{params.id}</div>


                    {id == params.id ?
                        <div>ja sum</div> :
                        <div>drug</div>
                    }
                    {id ?
                        <div>ja sum</div> :
                        <div>drug</div>
                    }

                    {id ?
                        console.log('jas sum') :
                        console.log('drug')

                    } */}
                    {/* testing */}

                    {id == params.id ? null : isFriendOrNot === true ?
                        <Button className={'btn-white  mt-2'} >Connected</Button> : isFriendOrNot === false ?
                            <Button className={'btn-white  mt-2'}
                                onClick={() => {
                                    // sendFriendRequest_ver_2(individualUserProfile.id, individualUserAccountResponse.body.individualUserProfile.id)
                                    api.get(`/api/friendship-request?userId=${individualUserAccountResponse.body.individualUserProfile.id}&userType=INDIVIDUAL_USER`)
                                        .then(res => {
                                            const found = res.data.some(item => item.id === individualUserProfile.id);
                                            if (found) {
                                                console.log('invite already sent')
                                                dispatch(globalMessage({ name: `invite has already been sent`, type: 'simple_message' }))
                                            } else {
                                                console.log('sending now')

                                                dispatch(globalMessage({ name: `${individualUserAccountResponse.body.individualUserProfile.firstName} ${individualUserAccountResponse.body.individualUserProfile.lastName}`, type: 'add_to_network' }))
                                                sendFriendRequest(individualUserProfile.id, individualUserAccountResponse.body.individualUserProfile.id);

                                            }
                                        })
                                        .catch(e => console.log(e))
                                }
                                }
                            >Add to Network</Button> : null
                    }

                    {/* vaka bese i rabotese */}
                    {/* {isFriendOrNot === true ?
                        <Button className={'btn-white  mt-2'} >Connected</Button> : isFriendOrNot === false ?
                            <Button className={'btn-white  mt-2'}
                                onClick={() => sendFriendRequest(individualUserProfile.id, individualUserAccountResponse.body.individualUserProfile.id)}
                            >Add to Network</Button> : null
                    } */}

                </div>}

                {/* <Button className={'btn-blue  mt-4'} onClick={() => history.push(`/hire-profile?firstname=${!params.id ? firstName : individualUserAccountResponse.body.individualUserProfile.firstName}&lastname=${!params.id ? lastName : individualUserAccountResponse.body.individualUserProfile.lastName}&id=${!params.id ? id : individualUserAccountResponse.body.individualUserProfile.id}`)}>Hire</Button> */}
                {/* {params.id && <div className={'d-flex flex-column'}>
                    <Button className={'btn-white  mt-2'}
                        onClick={() => sendFriendRequest(individualUserProfile.id, individualUserAccountResponse.body.individualUserProfile.id)}
                    >Add to Network</Button>
                </div>} */}
            </div>
        </GrayInfoBox>
    );
};

UserProfileInfo.propTypes = {};

export default UserProfileInfo;