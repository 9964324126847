import React from 'react';
import Projects from "./Projects";

const WorkHistoryInfo = props => {
    return (
        <div>
            <Projects/>
        </div>
    );
};

WorkHistoryInfo.propTypes = {};

export default WorkHistoryInfo;