import React from 'react';
import useForm from "../../../helpers/customHooks/useForm";
import FormBoxSection from "../../Common/FormBoxSection";
import FormColumns from "../../Common/FormColumns";
import InputAndActionContainer from "../../Common/InputAndActionContainer";
import InputField from "../../../App/components/InputField";
import Dropdown from "../../../App/components/Dropdown";

const LanguageEditing = props => {
    const {handleChange, handleSubmit, values, errors} = useForm({email: '', password: ""}, submit, validate)

    function submit() {
        console.log(values)
        console.log(errors)
    }

    function validate(v) {
        return {}
    }

    return (
        <form action="" onSubmit={handleSubmit}>
            <FormBoxSection title={"Language"}>
                <FormColumns>
                    <div className={'d-flex flex-column py-4'}>
                        <InputAndActionContainer>
                            <InputField label={"Main Language"}/>
                            <div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elionec ut arcu etfsa.</p>
                                <a href="" className={'link-small'}>Add second</a>
                            </div>
                        </InputAndActionContainer>
                        <InputAndActionContainer>
                            <Dropdown options={[]} label={"Level"} name={''}
                                      onChange={handleChange}/>
                        </InputAndActionContainer>
                    </div>
                    <div>
                        <button className={'btn edit-link'} type='submit'>Done editing</button>
                    </div>
                </FormColumns>
            </FormBoxSection>
        </form>
    )
};

LanguageEditing.propTypes = {};

export default LanguageEditing;