import React, {useEffect, useState} from 'react';
import useForm from "../../helpers/customHooks/useForm";
import validate from "../../helpers/validateLogin";
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import InputField from "../../App/components/InputField";
import {login} from '../../App/store/user'
import Checkbox from "../../App/components/Checkbox";
import Button from "../../App/components/Button";

const LoginForm = ({className,}) => {
    const {handleChange, handleSubmit, values, errors} = useForm({email: "", password: ""}, submit, validate)
    let history = useHistory();
    const dispatch = useDispatch()
    const {auth} = useSelector(state => state.user);
    const [loginError, setLoginError] = useState(false)

    useEffect(() => {
        if (auth && auth.error) {
            setLoginError(auth.error)
        } else if (auth && auth.accessToken) {
            history.push('/')
        }
    }, [auth]);


    function submit() {
        dispatch(login(values.email, values.password))
    }


    return (
        <>
            <div className={className}>
                <form action="" onSubmit={handleSubmit}>
                    <div className="row justify-content-center py-2 py-md-5">
                        <div className="col-md-8 col-lg-6 col-sm-12">
                            <div className="text-center">
                                <h5 className={'color-mainBlue'}>Log In Information</h5>
                                <p>This will be your login information. Your email is set to private by default.</p>
                                <div className="px-4 mb-3 text-left">
                                    <InputField onChange={handleChange} className={'input-action'} name={'email'}
                                                label={'Email'}
                                                value={values.email}/>
                                    {errors.email && <span className={'error-message pl-2'}>{errors.email}</span>}
                                    <InputField onChange={handleChange} className={'input-action'} name={'password'}
                                                type={'password'}
                                                label={'Password'}
                                                value={values.password}/>
                                    {errors.password && <span className={'error-message pl-2'}>{errors.password}</span>}
                                    {loginError && !errors.password && !errors.email &&
                                    <span className={'error-message pl-2'}>{loginError}</span>}
                                </div>
                                <div className={'text-left link-small pl-4'}>
                                    <a href="#">Reset password?</a>
                                </div>
                                <div className="px-4 mt-3 d-flex align-items-center">
                                    <div className="col-md-5 pl-0">
                                        <Checkbox>Remember me</Checkbox>
                                    </div>
                                    <div className="col-md-7 pr-0">
                                        <Button type={'submit'} className={'btn-block btn-blue'}>Login</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

LoginForm.propTypes = {};

export default LoginForm;