import React, {useEffect} from 'react';
import MenuOpportunitiesProjects from "../../Common/MenuOpportunitiesProjects";
import InputField from "../../../App/components/InputField";
import OpportunityCover from "../components/OpportunityCover";
import MenuDots from "../../../App/components/MenuDots";
import Button from "../../../App/components/Button";
import EditModeModal from "../../Common/EditModeModal";
import InfoEditing from "../components/InfoEditing";
import ExperienceEditing from "../components/ExperienceEditing";
import LocationEditing from "../components/LocationEditing";
import PartnersEditing from "../components/PartnersEditing";
import PitchEditing from "../components/PitchEditing";
import CreateOpportunityModalContent from "../components/CreateOpportunityModalContent";
import Image from "../../../App/components/Image";
import {useDispatch, useSelector} from "react-redux";
import {fetchAccount} from "../../../App/store/user";
import Loader from "../../Common/Loader";

const CreateOpportunity = props => {
    const {data, isLoading} = useSelector(state => state.user.account)
    const {individualUserProfile} = data
    const dispatch = useDispatch()

    useEffect(() => {
        if (!Object.keys(data).length)
            dispatch(fetchAccount())
    }, [])


    if (isLoading)
        return <Loader/>

    return (
        <div>
            <div className=""><MenuOpportunitiesProjects page={'Opportunities'}
                                                         linkTo={'opportunities'}
                                                         modalContent={<CreateOpportunityModalContent/>}/></div>
            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container d-flex'} style={{background: '#f5f5f5'}}>
                    <div className="col-md-6 mx-auto">
                        <OpportunityCover/>
                        <div className="simple-border corner-radius10 bg-white p-5 mb-4">
                            <div className={'d-flex justify-content-between'}>
                                <div className={'d-flex align-items-center'}>
                                    <h4 className={'font-weight-bold'}>Info</h4>
                                    <EditModeModal><InfoEditing/></EditModeModal>
                                </div>
                                <MenuDots className={'dots-vertical'}/>
                            </div>
                            <div className={'border-bottom'}>
                                <div className="small mb-3">3d - Updated - Public</div>
                                <p>The AECF is a development institution which supports businesses to innovate, create
                                    jobs, leverage investments and markets in an effort to create resilience and
                                    sustainable incomes in rural and marginalized communities in Africa. Launched in
                                    2008, the AECF has mobilized over US $356 million to date, leveraging more than US
                                    $658 million in matching capital and improving the lives of more than 16 million
                                    people in 2017 alone through jobs and increased household incomes. AECF has so far
                                    supported 268 companies in 26 countries in sub-Saharan Africa across 40 value chains
                                    in our focal sectors of agribusiness and renewable energy.</p>
                            </div>
                            <div className={'py-3'}>
                                <div className="small">Start Date</div>
                                <p>12.02.2020</p>
                                <div className="small">Project Website</div>
                                <p>https://www.aecfafrica.org/</p>
                                <div className="small">Ownership</div>
                                <p>Private</p>
                                <div className="small">Project Budget</div>
                                <p>$200,000</p>
                                <div className="small">Sector</div>
                                <p>Agriculture - Organic Farming</p>
                            </div>
                        </div>
                        {/*/////*/}
                        <div className="simple-border corner-radius10 bg-white p-5 mb-4">
                            <div className={'d-flex justify-content-between'}>
                                <h4 className={'font-weight-bold'}>Insight</h4>
                                <MenuDots className={'dots-vertical'}/>
                            </div>
                            <div>
                                <div className="small mb-3">3d - Updated - Public</div>
                            </div>
                            <div className={'row flex-wrap justify-content-between py-3'}>
                                <div style={{width: 170, height: 170}}
                                     className="d-flex justify-content-center flex-column mx-auto simple-border corner-radius10 bg-gray p-3 mb-2">
                                    <h3><b>+200</b></h3>
                                    <div>Project Views</div>
                                    <div className="small mb-2">Daily increase by +40%</div>
                                    <div className="small">Total: 4.342</div>
                                </div>
                                <div style={{width: 170, height: 170}}
                                     className="d-flex justify-content-center flex-column mx-auto simple-border corner-radius10 bg-gray p-3 mb-2">
                                    <h3><b>+1</b></h3>
                                    <div>Project NDA</div>
                                    <div className="small mb-2">Daily increase by +1%</div>
                                    <div className="small">Total: 18</div>
                                </div>
                                <div style={{width: 170, height: 170}}
                                     className="d-flex justify-content-center flex-column mx-auto simple-border corner-radius10 bg-gray p-3 mb-2">
                                    <h3><b>+1</b></h3>
                                    <div>Signed contracts</div>
                                    <div className="small mb-2">Daily increase by +1%</div>
                                    <div className="small">Total: 500</div>
                                </div>
                            </div>
                        </div>
                        {/**/}
                        <div className="simple-border corner-radius10 bg-white p-5 mb-4">
                            <div className={'d-flex justify-content-between'}>
                                <div className={'d-flex align-items-center'}>
                                    <h4 className={'font-weight-bold'}>Experience</h4>
                                    <EditModeModal>
                                        <ExperienceEditing/>
                                    </EditModeModal>
                                </div>
                                <MenuDots className={'dots-vertical'}/>
                            </div>
                            <div>
                                <div className="small mb-3">3d - Updated - Public</div>
                            </div>
                            <div className={'d-flex flex-column '}>
                                <div className="border-bottom py-3">
                                    <h6 className={'font-weight-bold'}>Chief Financial Officer</h6>
                                    <h6>Previous 5-6 year’s experience</h6>
                                    <a href={'#'} className={'link-small'}>View full requirements</a>
                                </div>
                                <div className="border-bottom py-3">
                                    <h6 className={'font-weight-bold'}>Financial Advisor</h6>
                                    <h6>Previous 2-3 year’s experience</h6>
                                    <a href={'#'} className={'link-small'}>View full requirements</a>
                                </div>
                                <div className="border-bottom py-3">
                                    <h6 className={'font-weight-bold'}>12% Target investment</h6>
                                    <h6>Investment amount is $150,000.00</h6>
                                    <a href={'#'} className={'link-small'}>View full requirements</a>
                                </div>
                            </div>
                        </div>
                        {/**/}
                        <div className="simple-border corner-radius10 bg-white p-5 mb-4">
                            <div className={'d-flex justify-content-between'}>
                                <div className={'d-flex align-items-center'}>
                                    <h4 className={'font-weight-bold'}>Partners</h4>
                                    <EditModeModal>
                                        <PartnersEditing/>
                                    </EditModeModal>
                                </div>
                                <div className={'d-flex align-items-center'}><Button
                                    className={'btn-white small-btn'}>View All</Button>
                                    <MenuDots className={'dots-vertical'}/></div>
                            </div>
                            <div>
                                <div className="small mb-3">3d - Updated - Public</div>
                            </div>
                            <div className={'row flex-wrap justify-content-between align-items-start py-3'}>
                                <div className="p-2 col-md-3 mx-auto">
                                    <Image h={120} w={120}
                                           className={'mb-2'} corners={'corner-radius10'}
                                           src="/assets/images/Vicky.jpg"
                                    />
                                    <div>
                                        <h6>Victoria Sabula</h6>
                                        <div className="small">Chief Executive Officer</div>
                                    </div>
                                </div>
                                <div className="p-2 col-md-3 mx-auto">
                                    <Image h={120} w={120}
                                           className={'mb-2'} corners={'corner-radius10'}
                                           src="/assets/images/peter-ngigi.jpg"
                                           alt=""/>
                                    <div>
                                        <h6>Peter Ngigi</h6>
                                        <div className="small">Director of Operations</div>
                                    </div>
                                </div>
                                <div className="p-2 col-md-3 mx-auto">
                                    <Image h={120} w={120}
                                           className={'mb-2'} corners={'corner-radius10'}
                                           src="/assets/images/sally.png"
                                           alt=""/>
                                    <div>
                                        <h6>Sally Gitonga</h6>
                                        <div className="small">Director of Investments and Portfolio</div>
                                    </div>
                                </div>
                                <div className="p-2 col-md-3 mx-auto ">
                                    <Image h={120} w={120}
                                           className={'mb-2'} corners={'corner-radius10'}
                                           src="/assets/images/tituswambua_1.jpg"
                                           alt=""/>
                                    <div>
                                        <h6>Titus Wambua</h6>
                                        <div className="small">Head of Internal Audit</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*    */}
                        <div className="simple-border corner-radius10 bg-white p-5 mb-4">
                            <div className={'d-flex justify-content-between'}>
                                <div className={'d-flex align-items-center'}>
                                    <h4 className={'font-weight-bold'}>Location</h4>
                                    <EditModeModal>
                                        <LocationEditing/>
                                    </EditModeModal>
                                </div>
                                <MenuDots className={'dots-vertical'}/>
                            </div>
                            <div>
                                <div className="small mb-3">3d - Updated - Public</div>
                            </div>
                            <div>
                                <div className="small">Primary</div>
                                <p>Nairobi, Kenya, East Africa</p>
                                <div>
                                    <Image corners={'rounded-0'}
                                           src="/assets/images/nairobi.png"
                                           alt=""/>
                                </div>
                            </div>
                        </div>
                        {/**/}
                        <div className="simple-border corner-radius10 bg-white p-5 mb-4">
                            <div className={'d-flex justify-content-between'}>
                                <div className={'d-flex align-items-center'}>
                                    <h4 className={'font-weight-bold'}>Pitch</h4>
                                    <EditModeModal>
                                        <PitchEditing/>
                                    </EditModeModal>
                                </div>

                                <MenuDots className={'dots-vertical'}/>
                            </div>
                            <div>
                                <div className="small mb-3 ">3d - Updated - Public</div>
                            </div>
                            <div className={'border-bottom pb-2 mb-4'}>
                                <div className=" mb-2 font-weight-bold">Full Project Description</div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent maximus convallis
                                    elit vitae molestie. Nunc nec finibus nibh. Quisque finibus, nisi id malesuada
                                    cursus, leo ligula posuere ipsum, ac mollis elit enim euismod tortor. Ut nec tempus
                                    erat. Quisque ullamcorper gravida laoreet. Vestibulum vel arcu viverra, maximus
                                    massa eu, tempus orci. Mauris dui urna, consectetur vel luctus sed, auctor sed
                                    felis. Praesent et vulputate turpis. Maecenas facilisis, lacus a posuere interdum,
                                    quam lorem consequat lectus, id lobortis magna sapien a erat. Vivamus vel erat id
                                    dolor pretium mattis fermentum at purus. Vivamus semper a urna nec tempor. Curabitur
                                    gravida sollicitudin massa, non mattis lectus sollicitudin blandit. Aliquam feugiat
                                    elit vitae massa vulputate cursus. Mauris consequat enim eget lectus semper
                                    luctus.</p>
                                <div className={'d-flex justify-content-end'}>
                                    <a href="" className={'link-small'}>Read More</a>
                                </div>
                            </div>
                            <div className={'border-bottom pb-2 mb-4'}>
                                <div className=" mb-2 font-weight-bold">Opportunity</div>
                                <p>Problem statement: Morbi rhoncus orci id libero consequat tristique. Fusce id urna in
                                    nisl egestas hendrerit. Nullam volutpat quis enim nec venenatis. Nam feugiat est
                                    leo, in vehicula leo vestibulum a. Phasellus ac molestie sem. Mauris sed pharetra
                                    neque, id semper urna. Sed feugiat quis quam sed tincidunt. Donec massa orci,
                                    facilisis a gravida id, hendrerit non nunc. Vestibulum ante ipsum primis in faucibus
                                    orci luctus et ultrices posuere cubilia curae; Nulla interdum leo ex, ut laoreet
                                    velit auctor sed. Vivamus finibus quam venenatis, iaculis mi et, vehicula quam.
                                    Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
                                    himenaeos. Vivamus rutrum leo eu consectetur cursus. Nullam sit amet convallis
                                    quam.</p>
                                <p>Solution statement: Auris sed pharetra neque, id semper urna. Sed feugiat quis quam
                                    sed tincidunt. Donec massa orci, facilisis a gravida id, hendrerit non nunc.
                                    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
                                    curae; Nulla interdum leo ex, ut laoreet velit auctor sed. Vivamus finibus quam
                                    venenatis, iaculis mi et, vehicula quam. Class aptent taciti sociosqu ad litora
                                    torquent per conubia nostra, per inceptos himenaeos. Vivamus rutrum leo eu
                                    consectetur cursus. Nullam sit amet convallis quam.</p>
                                <div className={'d-flex justify-content-end'}>
                                    <a href="" className={'link-small'}>Read More</a>
                                </div>
                            </div>
                            <div className={'border-bottom pb-2 mb-4'}>
                                <div className=" mb-2 font-weight-bold">Market</div>
                                <p>Problem statement: Morbi rhoncus orci id libero consequat tristique. Fusce id urna in
                                    nisl egestas hendrerit. Nullam volutpat quis enim nec venenatis. Nam feugiat est
                                    leo, in vehicula leo vestibulum a. Phasellus ac molestie sem. Mauris sed pharetra
                                    neque, id semper urna. Sed feugiat quis quam sed tincidunt. Donec massa orci,
                                    facilisis a gravida id, hendrerit non nunc. Vestibulum ante ipsum primis in faucibus
                                    orci luctus et ultrices posuere cubilia curae; Nulla interdum leo ex, ut laoreet
                                    velit auctor sed. Vivamus finibus quam venenatis, iaculis mi et, vehicula quam.
                                    Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
                                    himenaeos. Vivamus rutrum leo eu consectetur cursus. Nullam sit amet convallis
                                    quam.</p>
                                <p>Solution statement: Auris sed pharetra neque, id semper urna. Sed feugiat quis quam
                                    sed tincidunt. Donec massa orci, facilisis a gravida id, hendrerit non nunc.
                                    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
                                    curae; Nulla interdum leo ex, ut laoreet velit auctor sed. Vivamus finibus quam
                                    venenatis, iaculis mi et, vehicula quam. Class aptent taciti sociosqu ad litora
                                    torquent per conubia nostra, per inceptos himenaeos. Vivamus rutrum leo eu
                                    consectetur cursus. Nullam sit amet convallis quam.</p>
                                <div className={'d-flex justify-content-end'}>
                                    <a href="" className={'link-small'}>Read More</a>
                                </div>
                            </div>
                            <div className={'border-bottom pb-2 mb-4'}>
                                <div className=" mb-2 font-weight-bold">Expenditures</div>
                                <p>Problem statement: Morbi rhoncus orci id libero consequat tristique. Fusce id urna in
                                    nisl egestas hendrerit. Nullam volutpat quis enim nec venenatis. Nam feugiat est
                                    leo, in vehicula leo vestibulum a. Phasellus ac molestie sem. Mauris sed pharetra
                                    neque, id semper urna. Sed feugiat quis quam sed tincidunt. Donec massa orci,
                                    facilisis a gravida id, hendrerit non nunc. Vestibulum ante ipsum primis in faucibus
                                    orci luctus et ultrices posuere cubilia curae; Nulla interdum leo ex, ut laoreet
                                    velit auctor sed. Vivamus finibus quam venenatis, iaculis mi et, vehicula quam.
                                    Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
                                    himenaeos. Vivamus rutrum leo eu consectetur cursus. Nullam sit amet convallis
                                    quam.</p>
                                <p>Solution statement: Auris sed pharetra neque, id semper urna. Sed feugiat quis quam
                                    sed tincidunt. Donec massa orci, facilisis a gravida id, hendrerit non nunc.
                                    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
                                    curae; Nulla interdum leo ex, ut laoreet velit auctor sed. Vivamus finibus quam
                                    venenatis, iaculis mi et, vehicula quam. Class aptent taciti sociosqu ad litora
                                    torquent per conubia nostra, per inceptos himenaeos. Vivamus rutrum leo eu
                                    consectetur cursus. Nullam sit amet convallis quam.</p>
                                <div className={'d-flex justify-content-end'}>
                                    <a href="" className={'link-small'}>Read More</a>
                                </div>
                            </div>
                            <div className={'border-bottom pb-2 mb-4'}>
                                <div className=" mb-2 font-weight-bold">Sales & Marketing</div>
                                <p>Problem statement: Morbi rhoncus orci id libero consequat tristique. Fusce id urna in
                                    nisl egestas hendrerit. Nullam volutpat quis enim nec venenatis. Nam feugiat est
                                    leo, in vehicula leo vestibulum a. Phasellus ac molestie sem. Mauris sed pharetra
                                    neque, id semper urna. Sed feugiat quis quam sed tincidunt. Donec massa orci,
                                    facilisis a gravida id, hendrerit non nunc. Vestibulum ante ipsum primis in faucibus
                                    orci luctus et ultrices posuere cubilia curae; Nulla interdum leo ex, ut laoreet
                                    velit auctor sed. Vivamus finibus quam venenatis, iaculis mi et, vehicula quam.
                                    Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
                                    himenaeos. Vivamus rutrum leo eu consectetur cursus. Nullam sit amet convallis
                                    quam.</p>
                                <p>Solution statement: Auris sed pharetra neque, id semper urna. Sed feugiat quis quam
                                    sed tincidunt. Donec massa orci, facilisis a gravida id, hendrerit non nunc.
                                    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
                                    curae; Nulla interdum leo ex, ut laoreet velit auctor sed. Vivamus finibus quam
                                    venenatis, iaculis mi et, vehicula quam. Class aptent taciti sociosqu ad litora
                                    torquent per conubia nostra, per inceptos himenaeos. Vivamus rutrum leo eu
                                    consectetur cursus. Nullam sit amet convallis quam.</p>
                                <div className={'d-flex justify-content-end'}>
                                    <a href="" className={'link-small'}>Read More</a>
                                </div>
                            </div>
                        </div>
                        {/*    */}
                    </div>
                    <div className={'col-md-3 bg-white pt-3'}>
                        <div className={'d-flex justify-content-around w-100'}>
                            <InputField name={'search'} label={'Search'}/>
                            <img src="/assets/images/Notifications.svg" alt=""/>
                        </div>
                        <div className={'d-flex flex-column align-items-center mt-5'}>
                            <img style={{width: 100, height: 100}} className={'rounded-circle simple-border'}
                                 src={`http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${individualUserProfile.id}`}
                                 alt=""/>
                            <div className={'mt-3'}>
                                <b>{individualUserProfile.firstName + " " + individualUserProfile.lastName}</b></div>
                            <span className={'small'}>Owner</span>
                        </div>
                        <div className={'border-bottom d-flex mt-4 px-2 justify-content-between'}>
                            <h6><b>Notifications</b></h6>
                            <span>All</span>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Update supply change on
                                    some list somewhere
                                </div>
                                <a href={''} className={'link-small pt-2'}>@projectname</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold pb-'}>Jin Tan in applies in listing
                                    <a href={''}
                                       className={'link-small font-weight-normal pt-2'}> @CFO
                                        position opening</a></div>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Thomas M. Trevino requested
                                    NDA
                                </div>
                                <a href={''} className={'link-small pt-2'}>@Selpic P1</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Jin Tan reported something
                                    at
                                </div>
                                <a href={''} className={'link-small pt-2'}>@Selpic P1</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold pb- '}>Richard M. Hobbs in Follows <a
                                    href={''} className={'link-small font-weight-normal pt-2'}> @purME</a> Project.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CreateOpportunity.propTypes = {};

export default CreateOpportunity;