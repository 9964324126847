
import React, { useState, useEffect } from "react";


import OVCss from './styles/OpportunitiesView.module.scss'

const PitchOpportunitiesView = ({ props }) => {

    const { fullProjectDescription, opportunityDesc, market, expenditures } = props
    const [readMore, setReadMore] = useState(false);
    let readMoreLength = 218;

    //-------------------------------------------------------------------
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    //-------------------------------------------------------------------

    return (
        // <ProfileInfoBoxSection custom={'Overview'} className={'mt-3 border-gray margin-b-1'} title={'Pitch'}>

        <section className={` mt-3 margin-b-1 d-flex flex-wrap  ${OVCss.border_gray} ${OVCss.form_box} `}>
            <div className="col-md-12 d-flex flex-column">

                <div>
                    <div className={OVCss.view_profile_form_title + ' mt-2 '}>Pitch</div>
                </div>

                <div>
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}>full Project Description</div>
                    {/* moe za proba */}
                    <div className={OVCss.view_profile_form_normal_text}>{fullProjectDescription}</div>
                    {/* moe za proba */}
                </div>
                <div>
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Opportunity Description</div>
                    {/* moe za proba */}
                    <div className={OVCss.view_profile_form_normal_text}>{opportunityDesc}</div>
                    {/* moe za proba */}
                </div>
                <div>
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Market</div>
                    {/* moe za proba */}
                    <div className={OVCss.view_profile_form_normal_text}>{market}</div>
                    {/* moe za proba */}
                </div>
                <div>
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Expenditures</div>
                    {/* moe za proba */}
                    <div className={OVCss.view_profile_form_normal_text}>{expenditures}</div>
                    {/* moe za proba */}
                </div>
                {/* </ProfileInfoBoxSection> */}
            </div>
        </section>
    )
}

export default PitchOpportunitiesView