import React from 'react';
import InputField from "../../../App/components/InputField";
import Dropdown from "../../../App/components/Dropdown";
import Button from "../../../App/components/Button";
import TagsInput from "../../../App/components/TagsInput";

const ExperienceEditing = props => {
    return (
        <form>
            <div className=" corner-radius10 bg-white p-3">
                {/* <div className={'d-flex justify-content-between'}>
                    <h4 className={'font-weight-bold'}>Experience</h4>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu neque pulvinar, tristique
                    orci ut, feugiat justo.</p> */}
                <div>
                    <Dropdown options={[{name: 'Tangible', value: 'Tangible'}]} label={"Select Industry"}
                              className={'mb-4'}/>
                    <TagsInput placeholder={"Type your Skils"} className={'mb-4'}/>
                    <InputField label={"Years Experience"} className={'mb-4'}/>

                    <div className="d-flex justify-content-between">
                        <Button className={'btn-white'}>Delete</Button>
                        <Button className={'btn-blue'}>Update</Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

ExperienceEditing.propTypes = {};

export default ExperienceEditing;