import api from "../App/config/api";

export const autocompleteCall = async (url, searchField) => {
    let data;
    if (searchField.length > 0) {
        let response = await api.get(`${url}${searchField}`,
            // {
            //     method: 'GET', // *GET, POST, PUT, DELETE, etc.
            //     // mode: 'cors', // no-cors, *cors, same-origin
            //     // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            //     // credentials: 'same-origin', // include, *same-origin, omit
            // }
        );

        data = await response.data
        return data;
    }
    return []
}


export const calculateWordFromNum = (num) => {
    switch (num) {
        case 1:
            return "Second";
        case 2:
            return "Third";
        case 3:
            return "Fourth";
        case 4:
            return "Fifth";
        default:
            return "More";
    }
}


export const checkAliasType = (aliasType) => {
    switch (aliasType) {
        case 'LEGAL_ENTITIES_COMPANY':
            return {
                route: "/create-company/",
                routeProfile: "/company-profile/",
                text: 'Company',
                variable: 'companies'
            };
        case 'LEGAL_ENTITIES_SERVICE_COMPANY':
            return {
                route: "/create-service-company/",
                routeProfile: "/create-service-company-profile/",
                text: 'Service Company',
                variable: 'serviceCompanies'
            };
        case 'LEGAL_ENTITIES_INVESTMENT_FUND':
            return {
                route: "/create-investment-fund/",
                routeProfile: "/investment-fund-profile/",
                text: 'Investment Fund',
                variable: 'investmentFunds'
            };
        case 'BUSINESS_ASSOCIATIONS_GOVERNMENTAL':
            return {
                route: "/create-governmental-profile/",
                routeProfile: "/governmental-profile-view/",
                text: 'Governmental Organization',
                variable: 'governmentalOrganisations'
            }
        case 'BUSINESS_ASSOCIATIONS_NON_GOVERNMENTAL':
            return {
                route: "/create-nongovernmental-profile/",
                routeProfile: "/nongovernmental-profile-view/",
                text: 'Non-governmental Organization',
                variable: 'nonGovernmentalOrganisations'
            };
        default:
            return {
                route: "/all-alias",
                text: '',
                variable: ''
            };
    }
}

export const purposeTitle = (value) => {
    switch (value) {
        case 'invest':
            return "Invest"
        case 'businessAndTrade':
            return "Business and Trade"
        case 'general':
            return "General"
        case 'startups':
            return "Startups"
        case 'governmentProjects':
            return "Government Projects"
        case 'establishedProjects':
            return "Established Projects"
        case 'serviceBusiness':
            return "Service Business"
        case 'merchandiseBusiness':
            return "Merchandise Business"
        case 'manufactureBusiness':
            return "Manufacture Business"
        default:
            return "";
    }
}


export const randomWeekday = () => {
    switch (Math.floor((Math.random() * 5) + 1)) {
        case 1:
            return "Mon"
        case 2:
            return "Tue"
        case 3:
            return "Wed"
        case 4:
            return "Thu"
        case 5:
            return "Fri"
        default:
            return "Mon";
    }
}

export const randomMonth = () => {
    switch (Math.floor((Math.random() * 5) + 1)) {
        case 1:
            return "Jan"
        case 2:
            return "Feb"
        case 3:
            return "March"
        case 4:
            return "April"
        case 5:
            return "May"
        case 6:
            return "June"
        case 7:
            return "July"
        case 8:
            return "Aug"
        case 9:
            return "Sep"
        case 10:
            return "Oct"
        case 11:
            return "Nov"
        case 12:
            return "Dec"
        default:
            return "Jan";
    }
}

export const sendFriendRequest_ver_2 = (requestFrom, requestTo, requestFromUserType = "INDIVIDUAL_USER", requestToUserType = "INDIVIDUAL_USER") => {

    const data = {
        requestFrom,
        requestTo,
        requestFromUserType,
        requestToUserType,
    }
    api.get(`/api/friendship-request?userId=${requestTo}&userType=INDIVIDUAL_USER`)
        .then(res => {
            const found = res.data.some(item => item.id === requestFrom);
            if (found) {
                console.log('invite already sent')
            } else {
                console.log('sending now')
                api.post('/api/friendship-request', data).then(res => {
                    console.log(res)
                }).catch(e => console.log(e))

            }
            console.log(res.data)

        })
        .catch(e => console.log(e))
   
}


export const sendFriendRequest = (requestFrom, requestTo, requestFromUserType = "INDIVIDUAL_USER", requestToUserType = "INDIVIDUAL_USER") => {

    const data = {
        requestFrom,
        requestTo,
        requestFromUserType,
        requestToUserType,
    }
    api.post('/api/friendship-request', data).then(res => {
        console.log(res)
    }).catch(e => console.log(e))
}

export function handleRemove(id, allUsers, setAllUsers) {
    console.log("deleted" + id);
    if (allUsers[0].hasOwnProperty('userSender')) {
        const user = allUsers.filter(item => item.userSender.id !== id);
        setAllUsers(user)
    } else {
        const user = allUsers.filter(item => item.id !== id);
        setAllUsers(user)
    }
}


export const acceptFriendRequest = (requestFrom, requestTo, requestFromUserType = "INDIVIDUAL_USER", requestToUserType = "INDIVIDUAL_USER") => {

    const data = {
        requestFrom,
        requestTo,
        requestFromUserType,
        requestToUserType,
    }
    api.post('/api/friendship-accept', data).then(res => {
        console.log(res)
    }).catch(e => console.log(e))
}
export const declineFriendRequest = (requestFrom, requestTo, requestFromUserType = "INDIVIDUAL_USER", requestToUserType = "INDIVIDUAL_USER") => {

    const data = {
        requestFrom,
        requestTo,
        requestFromUserType,
        requestToUserType,
    }
    api.post('/api/friendship-decline', data).then(res => {
        console.log(res)
    }).catch(e => console.log(e))
}
