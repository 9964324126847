import React from 'react';
import ProfileInfoBoxSection from "../../Common/ProfileInfoBoxSection";

const Purpose = ({children}) => {
    return (
        <ProfileInfoBoxSection className={'mt-5'}>
            <div className="profile-info ">
                <div className="row">
                    <div className="col-md-12">
                        {children}
                    </div>
                </div>

            </div>
        </ProfileInfoBoxSection>

    );
};

Purpose.propTypes = {};

export default Purpose;