import React, { useEffect, useState } from 'react';
import Header from "../../Common/Header";
import MenuBar from "../../Common/MenuBar";
import api from "../../../App/config/api";
import Button from "../../../App/components/Button";
import { useHistory } from "react-router";
import InputField from "../../../App/components/InputField";
import ManageAliasBox from "../../Common/ManageAliasBox";
import AllAliasCSS from './AllAlias.module.scss';
import { useDispatch, useSelector } from "react-redux";

import MenuDots from "../../../App/components/MenuDots";
import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

import { cutString } from '../../../helpers/helpers_2'


const StyledTextField = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `90px`,
            },
        },
        '& .MuiInputBase-root': {
            color: 'black',
            fontSize: 14,

            [theme.breakpoints.down('768')]: {
                fontSize: 11,
            }
        },
    },
}))(TextField);

const AllAlias = props => {
    const history = useHistory()
    const [aliases, setAliases] = useState([])
    const [initialAliases, setInitialAliases] = useState([])
    const { account } = useSelector((state) => state.user);

    // // const [userId, setUserId] = useState(account.data.individualUserProfile.id);
    // console.log('asd', account.data.individualUserProfile)
    // // const { userId } = useSelector((state) => state.user.account.data.individualUserProfile.id);

    //----------------------------------------------------------------------------------

    //-------------------------------------------------------------------
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    //-------------------------------------------------------------------



    const [searchWord, setSearchWord] = useState('');



    const [allAlias, setAllAlias] = useState([])
    const [searchAllAlias, setSearchAllAlias] = useState([])

    const getAllOpportunites = async () => {
        try {
            const res = await api.get('/api/aliases')

            setAllAlias(res.data)
            setSearchAllAlias(res.data)

        } catch (e) {
            return console.error(e.message);
        }
    }

    useEffect(() => {
        getAllOpportunites()
    }, [])

    //--------------------------------------------------------------------
    useEffect(() => {
        if (searchWord) {
            setSearchAllAlias(allAlias.filter(n => n.name.toLowerCase().includes(searchWord) || n.name.toLowerCase().includes(searchWord)))
        }
        else {
            setSearchAllAlias(allAlias)
        }
    }, [searchWord])
    //----------------------------------------------------------------------------------
    // const getAliases = async () => {
    //     try {
    //         const res = await api.get('/api/aliases')
    //         setAliases(res.data)
    //         setInitialAliases(res.data)
    //     } catch (e) {
    //         return console.error(e.message);
    //     }
    // }

    // useEffect(() => {
    //     getAliases()
    // }, [])

    // const searchByName = (e) => {
    //     const search = e.target.value
    //     if (search.length > 0) {
    //         console.log(aliases)
    //         const res = aliases.filter(item => {
    //             console.log(item.name)
    //             return item.name !== null && item.name.includes(search)
    //         })
    //         return setAliases(res)
    //     }
    //     return setAliases(initialAliases)
    // }




    return (
        <div>
            <Header />
            <div className=""><MenuBar page={'opportunities'} /></div>
            <div className={'d-flex justify-content-start ' + AllAliasCSS.main_div} >


                <div className={'col-md-7 ' + AllAliasCSS.main_container}>

                    <div className={AllAliasCSS.navigation_div} >
                        <Link to='/' className={AllAliasCSS.back} ><img className={AllAliasCSS.back_img} src='/assets/images/left_arrow.svg' alt="" /></Link>
                        <div className={AllAliasCSS.navigation_title}>Aliases</div>
                        <div className={AllAliasCSS.next}></div>
                    </div>
                    {/* <Link to={'/create-alias'}
                    ><b>Add
                        new Alias</b></Link> */}

                    <div className={AllAliasCSS.title} >{`My Alias (${allAlias.length || 0})`}</div>
                    <div className={AllAliasCSS.main_search_div}>
                        <div className={AllAliasCSS.left_div_search}> <Link to={'/create-alias'} className={AllAliasCSS.button} >Create New</Link> </div>
                        <div className={AllAliasCSS.right_div_search}>
                            {/* <input placeholder='Search by name' className={AllAliasCSS.input} type="text" /> */}
                            <StyledTextField value={searchWord} onChange={(e) => { setSearchWord(e.currentTarget.value.toLowerCase()) }} size='small' fullWidth id="standard-bare" variant="outlined" placeholder='Search by name'
                                InputProps={{ endAdornment: (<IconButton size='small'><SearchOutlined /></IconButton>), }} />
                        </div>
                    </div>


                    {account.data.individualUserProfile && searchAllAlias && searchAllAlias.map((x, i) => {
                        return (
                            <div key={i} onClick={() => { console.log(searchAllAlias) }} className={AllAliasCSS.main_list_div}>
                                <div className={AllAliasCSS.left_list_div}>
                                    <div className={AllAliasCSS.picture}><img className={AllAliasCSS.img} src={`/assets/images/alias/${x.aliasType == "LEGAL_ENTITIES_INVESTMENT_FUND" ? 'Investment Fund' : x.aliasType == "LEGAL_ENTITIES_COMPANY" ? 'CompanyAlias' : x.aliasType == "BUSINESS_ASSOCIATIONS_GOVERNMENTAL" ? 'Governmental' : 'Non-Governmental'}.svg`} alt="" /></div>
                                    {/* <div className={AllAliasCSS.picture}><img className={AllAliasCSS.img} src={`/assets/images/alias/${x.aliasType == "LEGAL_ENTITIES_INVESTMENT_FUND" ? 'Investment Fund' : 'CompanyAlias'}.svg`} alt="" /></div> */}
                                    {/* <div className={AllAliasCSS.picture}><img className={AllAliasCSS.img} src='/assets/images/avatar-profile.svg' alt="" /></div> */}

                                    {account.data.individualUserProfile.id && <div className={AllAliasCSS.name_div}>
                                        <div className={AllAliasCSS.names_wraper} >

                                            {/* kako sto bese */}
                                            {/* <div onClick={() => history.push(`/profile/${account.data.individualUserProfile.id}`)} className={AllAliasCSS.name} >{x.name}</div> */}

                                            {/* <div onClick={() => history.push(`/company-profile/${x.id}`)} className={AllAliasCSS.name} >{x.name}</div> */}

                                            {x.aliasType == 'LEGAL_ENTITIES_COMPANY' ?
                                                <div onClick={() => history.push(`/company-profile/${x.id}`)} className={AllAliasCSS.name} >{windowSize.width < 768 ? cutString(x.name, 15) : cutString(x.name, 22)}</div>
                                                : x.aliasType == 'LEGAL_ENTITIES_INVESTMENT_FUND' ?
                                                    <div onClick={() => history.push(`/investment-fund-profile/${x.id}`)} className={AllAliasCSS.name} >{windowSize.width < 768 ? cutString(x.name, 15) : cutString(x.name, 22)}</div>
                                                    : x.aliasType == 'BUSINESS_ASSOCIATIONS_GOVERNMENTAL' ?
                                                        <div onClick={() => history.push(`/governmental-profile-view/${x.id}`)} className={AllAliasCSS.name} >{windowSize.width < 768 ? cutString(x.name, 15) : cutString(x.name, 22)}</div>
                                                        : x.aliasType == 'BUSINESS_ASSOCIATIONS_NON_GOVERNMENTAL' ?
                                                            <div onClick={() => history.push(`/nongovernmental-profile-view/${x.id}`)} className={AllAliasCSS.name} >{windowSize.width < 768 ? cutString(x.name, 15) : cutString(x.name, 22)}</div>
                                                            : null

                                            }

                                            <div className={AllAliasCSS.small_text}>{windowSize.width < 768 ? cutString(x.name, 20) : cutString(x.name, 30)}</div>
                                            <div className={AllAliasCSS.small_text}>{windowSize.width < 768 ? cutString(x.name, 20) : cutString(x.name, 30)}</div>
                                            {/* <div className={AllAliasCSS.small_text}>{x.name}</div>
                                            <div className={AllAliasCSS.small_text}>{x.name}</div> */}
                                        </div>
                                    </div>}
                                </div>

                                <div className={AllAliasCSS.right_list_div}>


                                    <div className={AllAliasCSS.ownership_div} >
                                        <div className={AllAliasCSS.ownership_wraper} >
                                            <div className={AllAliasCSS.medium_text} >{cutString(x.name, 22)}</div>
                                            <div className={AllAliasCSS.small_text}>{cutString(x.name, 25)}</div>
                                        </div>
                                    </div>

                                    {/* <div className={AllAliasCSS.date}>{new Date(x.startDate).toLocaleDateString()}</div> */}
                                    <div className={AllAliasCSS.right_list_div_small}>
                                        {/* <div className={AllAliasCSS.view_profile_div}>
                                            {account.data.individualUserProfile.id && <button onClick={() => history.push(`/profile/${account.data.individualUserProfile.id}`)} className={AllAliasCSS.view_profile_button} >View Profile</button>}

                                        </div> */}

                                        <div onClick={() => { console.log(cutString(x.name, 25)) }} className={AllAliasCSS.verify_div}>
                                            <button className={AllAliasCSS.verify_button + ' ' + (x.verified && AllAliasCSS.verify_button_false)} onClick={() => console.log(x.verified)} > {x.verified ? 'Verify' : 'Verified'}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div >
    );
};

AllAlias.propTypes = {};

export default AllAlias;



// return (
//     <div>
//         <Header/>
//         <div className=""><MenuBar page={'Company'}/></div>
//         <div className="col-md-11 offset-md-1 ">
//             <div className={'dashboard-inner-container '}>
//                 <div className={' mt-5'}><h4>All Alias</h4></div>
//                 <div className="d-flex">
//                     <div className="col-md-8 m-auto">
//                         <div className={'d-flex'}>
//                             <Button className={'btn-blue small-btn mr-5'}
//                                     onClick={() => history.push('/create-alias')}>Create
//                                 New</Button>
//                             <InputField name={'search'} label={'Search By Name'} onChange={searchByName}/>
//                         </div>
//                         {aliases.length ? aliases.map((item, i) => {
//                             return <ManageAliasBox alias={item} key={i}/>
//                         }) : <div>No created aliases</div>}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
// );