import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const english = require('./english');
const chinese = require('./chinese');
const german = require('./german');
const arabic = require('./arabic');


export const initFni18next = (lng) => {


  i18next.use(initReactI18next).init({
    // lng: localStorage.getItem('lng') || 'en',
    compatibilityJSON: 'v3',
    lng: lng,
    fallbackLng: 'en',
    // debug: true,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    },
    resources: {
      en: { translation: english },
      // 'ch': { translation: chinese },
      zh_Hans: { translation: chinese },
      de: { translation: german },
      ar: { translation: arabic },
    },
  });
}
export default i18next;
// Add this line to your app entrypoint. Usually it is src/index.js
// import './i18n/init';
