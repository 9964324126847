import React from 'react';
import GrayInfoBox from "./GrayInfoBox";
import '../styles/SubscriptionCard.scss'

const SubscriptionCard = ({src, title, price, pricePer, children}) => {
    return (
        <GrayInfoBox className={'subscription-card'}>
            <div className={'subscription-content'}>
                <img src={src} alt=""/>
                <h3>{title}</h3>
                <h5><b>${price}</b></h5>
                <span>{pricePer}</span>
                <div>{children}</div>
            </div>
        </GrayInfoBox>
    );
};

SubscriptionCard.propTypes = {};

export default SubscriptionCard;