import React from 'react'

const NoDataMessageDiv = ({message}) => {
    return (
        <section className={'d-flex  p-4  mt-3 border-gray g-border-gray-radius-5x  '}>
            <div className="col-md-12 d-flex justify-content-center">
                {message}
            </div>
        </section>
    )
}

export default NoDataMessageDiv