import React from 'react';
import '../styles/FormBoxSection.scss'

const FormBoxSection = ({ title, className, children, style, noTitle, custom_css, titleCss, opportunityCreate, opportunityList, setOpportunity, initialOpportunity, setAddress }) => {
    if (noTitle) {
        return (
            <section className={className + ' d-flex flex-wrap form-box corner-radius10 ' + custom_css + ' ' + titleCss} style={style} >
                {/* <div className="col-md-3 d-flex flex-column"> */}

                <div className="col-md-12 d-flex flex-column">
                    {children}
                </div>

            </section>
        );
    } else if (opportunityCreate) {
        return (
            <section className={className + '  d-flex flex-wrap form-box corner-radius10 ' + custom_css + ' ' + titleCss} style={style} >
                <div className="  col-md-3 text-left">
                    <div className={'  form-title ' + titleCss}>{title}</div>
                    <div className={' form-title opportunity-lits-div ' + titleCss}>
                        {/* {opportunityList && opportunityList.} */}
                        <div
                            onClick={() => {
                                console.log(initialOpportunity)
                                setOpportunity(initialOpportunity)
                                setAddress('')
                            }}

                            className=' opportunity-lits-name opportunity-lits-create-new '>
                            Create New
                        </div>
                        {opportunityList && opportunityList.slice(-16).map(x => {
                            return (
                                // <div onClick={() => { console.log(x.id) }} className=' opportunity-lits-name'>
                                <div
                                    onClick={() => {
                                        let xxx = opportunityList.find(data => data.id === x.id)
                                        console.log(xxx)
                                        // console.log(opportunityList.find(data => data.id === x.id))
                                        // setOpportunity(opportunityList.find(data => data.id === x.id))
                                        setOpportunity(xxx)
                                        setAddress(xxx.location ? xxx.location : '')
                                    }}
                                    // onClick={() => { console.log(opportunityList.find(data => data.id === x.id)) }}
                                    className=' opportunity-lits-name'>
                                    {x.name && x.name.length <= 16 ? x.name : x.name && x.name.length > 16 ? `${x.name.substring(0, 13)}...` : ''}
                                    {/* {x.name && x.length <= 7 ? x.name : x.name && x.length > 7 ? x.name.substring(0, 7) : ''} */}
                                    {/* {x.name ? x.name.substring(0, 7) : ''} */}
                                    {/* {x.name} */}
                                    {/* {x.id} */}
                                </div>
                            )
                        })}

                    </div>
                </div>
                <div className="col-md-9 d-flex flex-column">
                    {children}
                </div>
            </section>
        );
    } else {
        return (
            <section className={className + ' d-flex flex-wrap form-box corner-radius10 ' + custom_css + ' ' + titleCss} style={style} >
                <div className="col-md-3 text-left">
                    <div className={'form-title ' + titleCss}>{title}</div>
                </div>
                <div className="col-md-9 d-flex flex-column">
                    {children}
                </div>
            </section>
        );
    }

};

FormBoxSection.propTypes = {};

export default FormBoxSection;