import React from 'react';
import InputField from "../../../App/components/InputField";
import Checkbox from "../../../App/components/Checkbox";
import useAdvancedSearchFilters from "../../../helpers/customHooks/useAdvancedSearchFilters";

const IndividualUserFields = ({setSearchFields, searchFields, setFilter}) => {
    const type = 'individualUser'
    const initialState = {
        country: '',
        city: '',
        institution: '',
        subject: '',
        company: '',
        position: '',
        role: '',
        identity: '',
        sector: '',
        subSector: '',
        agenda: ''
    }
    const {handleChange, handleOnBlur, values} = useAdvancedSearchFilters(initialState, type, setFilter, searchFields, setSearchFields)

    return (
        <div className={'px-4 mb-2'}>
            <h6>Location</h6>
            <div className={'row mb-4 px-1'}>
                <div className="col-md-6">
                    <InputField onChange={handleChange} name={'country'} value={values.country} label={"Country"}
                                onBlur={handleOnBlur}/>
                </div>
                <div className="col-md-6">
                    <InputField onChange={handleChange} name={'city'} value={values.city} label={'City'}
                                onBlur={handleOnBlur}/>
                </div>
            </div>
            <h6>Education</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <InputField onChange={handleChange} name={'institution'} value={values.institution}
                                label={'Institution'} onBlur={handleOnBlur}/>
                </div>
                <div className="col-md-6">
                    <InputField onChange={handleChange} name={'subject'} value={values.subject} label={'Subject'}
                                onBlur={handleOnBlur}/>
                </div>
            </div>
            <h6>Experience</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <InputField onChange={handleChange} name={'company'} value={values.company} label={'Company'}
                                onBlur={handleOnBlur}/>
                </div>
                <div className="col-md-6">
                    <InputField onChange={handleChange} name={'position'} value={values.position} label={'Position'}
                                onBlur={handleOnBlur}/>
                </div>
            </div>
            <h6>Role</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <InputField onChange={handleChange} name={'role'} value={values.role} label={'Role'}
                                onBlur={handleOnBlur}/>
                </div>
                <div className="col-md-6">
                    <InputField onChange={handleChange} name={'identity'} value={values.identity} label={'Identity'}
                                onBlur={handleOnBlur}/>
                </div>
            </div>
            <h6>Sector</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <InputField onChange={handleChange} name={'sector'} value={values.sector} label={'Sector'}
                                onBlur={handleOnBlur}/>
                </div>
                <div className="col-md-6">
                    <InputField onChange={handleChange} name={'subSector'} value={values.subSector}
                                label={'Sub-sector'} onBlur={handleOnBlur}/>
                </div>
            </div>
            <h6>Agenda</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <InputField onChange={handleChange} name={'agenda'} value={values.agenda} label={'Agenda'}
                                onBlur={handleOnBlur}/>
                </div>
            </div>
            <Checkbox className={'label-large'}><b>Account Verified</b></Checkbox>
        </div>
    );
};

IndividualUserFields.propTypes = {};

export default IndividualUserFields;