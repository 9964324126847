import React from 'react'
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import OVCss from './styles/OpportunitiesView.module.scss';

const SkillsBriefView = props => {
    const params = useParams()
    const { skills, industry } = useSelector(state => state.user.introduction.data)
    const { userProfileData } = useSelector(state => state.users)
    const { individualUserIntroductionResponse } = userProfileData.data || {}

    //-----------------------------------------
    // const { individualUserAccountResponse, individualUserIntroductionResponse } = userProfileData.data || {}
    const { individualUserAccountResponse } = userProfileData.data || {}

    const { individualUserProfile, locations } = useSelector(state => state.user.account.data)

    const { firstName, lastName, id, linkedinProfile, email, phone } = individualUserProfile || {}


    //-----------------------------------------


    return (
        <section className={` mt-3 margin-b-1 d-flex flex-wrap  ${OVCss.border_gray} ${OVCss.form_box} `}>
            <div className="col-md-12 d-flex flex-column">

                <div>
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}> Skills</div>


                    <div>{(!params.id ? skills : individualUserIntroductionResponse.body.skills || []).map((item, i) => {
                        return <span className={OVCss.view_profile_form_string_with_commas_text}
                            key={i}>{` ${item.name}${i < (!params.id ? skills.length : individualUserIntroductionResponse.body.skills.length) - 1 && ', '}`}</span>
                    })}</div>
                    {/* <div>{(!params.id ? skills : individualUserIntroductionResponse.body.skills || []).map((item, i) => {
                        return <span className='skills-div-text'
                            key={i}>{` ${item.name}${i < (!params.id ? skills.length : individualUserIntroductionResponse.body.skills.length) - 1 && ', '}`}</span>
                    })}</div> */}

                </div>

                <div>
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Contact</div>
                    {/* moe za proba */}
                    <div className={OVCss.view_profile_form_normal_text}>Email: {!params.id ? email : individualUserAccountResponse.body.individualUserProfile.email} </div>
                    <div className={OVCss.view_profile_form_normal_text}>Phone: {!params.id ? phone : individualUserAccountResponse.body.individualUserProfile.phone}</div>
                    <div className={OVCss.view_profile_form_normal_text}>Linkeding: {!params.id ? linkedinProfile : individualUserAccountResponse.body.individualUserProfile.linkedinProfile}</div>
                    {/* moe za proba */}

                </div>
            </div>
        </section >
    );

}

export default SkillsBriefView