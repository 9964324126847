import React from 'react';
import Purpose from "../../CompanyProfile/View/Purpose";

const PurposeInfo = props => {
    return (
        <div>
            <Purpose/>
            <Purpose/>
        </div>
    );
};

PurposeInfo.propTypes = {};

export default PurposeInfo;