import React from 'react';
import VerificationEditing from "../../Common/VerificationEditing";
import {useSelector} from "react-redux";

const Verification = () => {
    const verified = useSelector(state => state.user.account.data.individualUserProfile.verified)


    return (
        <div>
            <VerificationEditing verified={verified} id={0} type={"INDIVIDUAL_USER"}/>
        </div>
    );
};

Verification.propTypes = {};

export default Verification;