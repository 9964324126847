import React from 'react';
import PublicHeader from "../../App/components/PublicHeader";
import Button from "../../App/components/Button";
import LoginForm from "../components/LoginForm";

const LoginScreen = props => {
    return (
        <div>
            <PublicHeader>
                <Button className={'btn-blue'} onClick={() => window.location.href = '/register'}>Register</Button>
            </PublicHeader>
            <section className={'container pt-5'}>
                <LoginForm/>
            </section>

        </div>
    );
};

LoginScreen.propTypes = {};

export default LoginScreen;