import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import RightNav from './RightNav';

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;

  top: 15px;
  left: 20px;
  z-index: 20;

  display: none;
  margin-left: 6px;
  cursor: pointer;
  
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => open ? '#ccc' : '#2983FD'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${({ open }) =>open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }

    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;


const Burger = () => {
  const [open, setOpen] = useState(false);

  // https://stackoverflow.com/questions/64887975/disable-note-hide-vertical-scroll-when-the-modal-is-open
  useEffect(() => {
    if (open == false) {
      document.body.style.position = "static";
      document.body.style.overflow = "unset";
      document.body.className = '';
    }
    else {
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.overflowY = "scroll";
    }
  }, [open])

  // useEffect(() => {
  //   document.body.className = 'signin';
  //   return () => { document.body.className = ''; }
  // });

  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <RightNav
        click={(e) => { e.target.classList.contains("hi") && setOpen(!open) }}
        open={open} />
    </>
  )
}

export default Burger
