import React, {useState} from 'react';
import InputField from "./InputField";
import AutocompleteList from "./AutocompleteList";
import {useDebounce} from "react-use";
import {autocompleteCall} from "../../helpers/helpers";

const InputWithAutocomplete = ({label, name, idx, onChange, value, onClick, url, disabled}) => {
    const [autocompleteOpen, setAutocompleteOpen] = useState(false)
    const [result, setResult] = useState([])

    const [isReady, cancel] = useDebounce(
        async () => {
            if (autocompleteOpen) {
                const res = await autocompleteCall(url, value)
                setResult(res)
            }
        },
        400,
        [value]
    );

    return (
        <div className={'input-autocomplete'}>
            <InputField label={label} name={name}
                        onChange={(e) => {
                            onChange(e);
                            setAutocompleteOpen(true)
                        }}
                        value={value}
                        disabled={disabled}
                        autocomplete/>
            <AutocompleteList inputName={name} inputValue={value} autocompleteOpen={autocompleteOpen}
                              setAutocompleteOpen={setAutocompleteOpen} idx={idx}
                              list={result} onClick={onClick}/>
        </div>
    );
};

InputWithAutocomplete.propTypes = {};

export default InputWithAutocomplete;