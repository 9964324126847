import React from 'react';
import ProfileInfoBoxSection from "../../Common/ProfileInfoBoxSection";

const Skills = props => {
    return (
        <ProfileInfoBoxSection className={''} title={"Skills"}>
            <div className="profile-info">
                <div>
                    <span>Industry</span>
                    <p>Multinational Technology</p>
                    <p> Integrity/ethics, Analytic intelligence, Sense of urgency, Willingness to ask for help, Decision
                        making, Ability to simplify, Creative, Questioning approach, Leadership, Openness,
                        Communications skills, People empathy, Potential problem identification, Vision/perspective, Ego
                        drive, Non-political, Endurance, Willingness to take unqualified responsibility, Willingness to
                        take the lead</p>
                </div>

            </div>
        </ProfileInfoBoxSection>
    );
};

Skills.propTypes = {};

export default Skills;