import React from 'react';
import AvailabilityEditing from "./AvailabilityEditing";

const Availability = props => {
    return (
        <div>
            <AvailabilityEditing/>
        </div>
    );
};

Availability.propTypes = {};

export default Availability;