import React, {useRef, useState} from 'react';
import {useHistory} from "react-router";
import MenuActionIcon from "../../Common/MenuActionIcon";
import MenuDots from "../../../App/components/MenuDots";
import {useClickAway} from "react-use";
import {Link} from "react-router-dom";

const MenuHire = () => {
    const [openDotsDropdown, setOpenDotsDropdown] = useState(false)
    const ref = useRef(null);
    const history = useHistory()
    useClickAway(ref, (event) => {
        setOpenDotsDropdown(false);
    });
    return (
        <>
            <div style={{top: 0}}
                 className={'menu-bar d-flex flex-column align-items-center justify-content-around text-center'}>
                <div className={'logo d-flex align-items-center'}>
                    <img className={'pointer'}
                         onClick={() => history.push("/")}
                         src="/assets/images/logo.svg" alt=""/>
                </div>
                {/*<Link to="/">&laquo; Back to DV</Link>*/}
                <div><MenuDots onClick={() => setOpenDotsDropdown(true)} style={{marginTop: 0}}/></div>
                <div style={{position: 'relative'}}>
                    {openDotsDropdown &&
                    <div style={{position: "absolute", left: 0, top: 5, minWidth: 200}}
                         className={'dots-dropdown text-left p-4'}
                         ref={ref}>
                        <div className={'mb-3'}>
                            <Link to="/opportunities">Opportunity</Link>
                        </div>
                        <div className={'mb-3'}>
                            <Link to="/projects">Projects</Link>
                        </div>
                        <div className={'mb-3'}>
                            <Link to="/contracting">Contracts</Link>
                        </div>
                    </div>
                    }</div>
                <div className="menu-actions">
                    <MenuActionIcon href={'/hire-dashboard'}
                                    name={'Dashboard'}
                                    src={'all-opportunities'}
                                    className={' mb-4'}/>
                    <MenuActionIcon
                        href={'/work-history'}
                        name={'Work History'}
                        src={'create-opportunity'}
                        className={' mb-4'}
                    />
                </div>
            </div>
        </>
    );
};

MenuHire.propTypes = {};

export default MenuHire;