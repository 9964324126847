import React, {useState} from 'react';
import CKEditor from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

const CommentPosting = ({setCommentsContent, commentsContent}) => {
    const [openEditor, setOpenEditor] = useState(false)
    const [comment, setComment] = useState(false)


    const handleEditorChange = (e, editor) => {
        const data = editor.getData();
        setComment(data)

    }

    const postComment = (e) => {
        e.preventDefault()
        setOpenEditor(false)
        setCommentsContent([...commentsContent, {comment: comment}])
    }


    return (
        <div className={' bg-white '}>
            {!openEditor ?
                <div className={'px-2 py-1 pointer'} onClick={() => setOpenEditor(true)}>Comment or upload a
                    file...</div> :
                <div className="ck-wrapper d-flex flex-column">

                    <div id="toolbar-container"></div>
                    <CKEditor
                        editor={DecoupledEditor}
                        config={{
                            height: 100,
                            // exportWord: {
                            //     fileName: ,
                            // }
                        }}
                        data={""}
                        onInit={editor => {
                            const toolbarContainer = document.querySelector('#toolbar-container');
                            toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                            window.editor = editor;
                            // You can store the "editor" and use when it is needed.
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => handleEditorChange(event, editor)}
                    />
                    <div className={'d-flex justify-content-end bg-gray pt-3'}>
                        <a href={''} onClick={postComment} className={'link-small'}
                        ><b>Post
                            Comment</b></a>
                    </div>
                </div>}

        </div>
    );
};

CommentPosting.propTypes = {};

export default CommentPosting;