import React from 'react';
import VerificationEditing from "../../Common/VerificationEditing";


const Verification = ({data}) => {
    // const params = useParams()
    // const id = params.id
    // const verified = data[id].aliasIdentity.verified

    return (
        <div>
            <VerificationEditing/>
        </div>
    );
};

Verification.propTypes = {};

export default Verification;