import React from 'react';
import ProfileInfoBoxSection from "../../Common/ProfileInfoBoxSection";

const Overview = props => {
    return (
        <ProfileInfoBoxSection className={'mt-5'} title={"Overview"}>
            <div className="profile-info">
                <div>
                    <p>Tim Cook is the CEO of Apple and serves on its board of directors. Before being named CEO in
                        August
                        2011, Tim was Apple’s chief operating officer and was responsible for all of the company’s
                        worldwide
                        sales and operations, including end-to-end management of Apple’s supply chain, sales activities,
                        and
                        service and support in all markets and countries. He also headed Apple’s Macintosh division and
                        played a key role in the continued development of strategic reseller and supplier relationships,
                        ensuring flexibility in response to an increasingly demanding marketplace.</p>
                </div>
                <div>
                    <span>Location</span>
                    <p>United States of America • Washington, DC</p>
                    <span>Occupation</span>
                    <p>Lawyer • Associate • 10 years</p>
                    <span>Education</span>
                    <p>University of Oxford • Biochemistry • PhD</p>
                    <span>Speciality</span>
                    <p>Criminal Law • Business Law</p>
                    <span>Sector</span>
                    <p>Telecommunication • Education</p>
                    <span>Rate</span>
                    <p>Flexible • 2000 • Monthly</p>
                    <span>Availability</span>
                    <p>Weekdays • 9AM to 10PM</p>
                    <span>Language</span>
                    <p>French • Advanced <br/>English • Advanced</p>
                </div>
            </div>
        </ProfileInfoBoxSection>
    );
};

Overview.propTypes = {};

export default Overview;