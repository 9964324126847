import React, { useEffect } from 'react';
import Header from "../../Common/Header";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccount, fetchIntroduction } from "../../../App/store/user";
import { useParams } from "react-router";
import { fetchUserById } from "../../../App/store/users";
import MenuBar from "../../Common/MenuBar";
import UserProfileInfo from "../../Common/UserProfileInfo";
// import TabsViewMode from "../../../App/components/TabsViewMode";
import Tabs from "../../../App/components/Tabs";
import TabsTestView from "../../../App/components/TabsTestView";
// import MediaInfo from "../View/MediaInfo";
import BriefInfo from "../View/BriefInfo";
import PurposeInfo from "../View/PurposeInfo";
import OpportunityTab from '../View/OpportunityTab';

const IndividualUserProfileView = props => {
    const params = useParams()
    const { userProfileData } = useSelector(state => state.users)
    const { account } = useSelector(state => state.user)
    const { introduction } = useSelector(state => state.user)
    const dispatch = useDispatch()

    useEffect(() => {
        if (params.id) {
            dispatch(fetchUserById(params.id))
        } else {
            if (!Object.keys(account.data).length)
                dispatch(fetchAccount())
            if (!Object.keys(introduction.data).length)
                dispatch(fetchIntroduction())
        }
    }, [])

    const component = <div>
        <Header />
        <div className=""><MenuBar page={'Profile'} /></div>
        <div className="col-md-11 offset-md-1 ">
            <div className={'dashboard-inner-container'}>
                <div className={'row'}>
                    <div className="col-md-3 alias-profile-info-desktop">
                        <UserProfileInfo />
                    </div>
                    <div className="col-md-9">
                        <TabsTestView
                            UserProfileInfo={<UserProfileInfo />}
                            className={'tabs-dashboard p-none tabs-margin-top'}
                            tabClassName={'flex-grow-1'}
                            tabs={['Brief', 'Purpose', 'Opportunities']}>
                            <BriefInfo />
                            <PurposeInfo />
                            <OpportunityTab />
                        </TabsTestView>
                    </div>
                </div>
            </div>
        </div>
    </div>

    if (params.id) {
        if (userProfileData.isLoading)
            return <div>loading</div>
        return component
    } else if (!params.id) {
        if (account.isLoading || introduction.isLoading)
            return <div>loading</div>
        return component
    }
};

IndividualUserProfileView.propTypes = {};

export default IndividualUserProfileView;


