import React from 'react';
import Tabs from "../../App/components/Tabs";
import Subscription from "../Common/Subscription";
import MenuBar from "../Common/MenuBar";
import Header from "../Common/Header";

const Membership = props => {
    return (

        <div>
            <Header/>
            <div className=""><MenuBar page={'Profile'}/></div>
            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container'}>
                    <Tabs className={'tabs-dashboard '} tabs={["Statistics", "Clubs", "Subscription"]}>
                        <></>
                        <></>
                        <Subscription/>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

Membership.propTypes = {};

export default Membership;