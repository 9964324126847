import React from 'react';
import Image from "../../../App/components/Image";

const InfoBoardItem = ({name, src}) => {
    return (
        <div>
            <div className={'d-flex align-items-center '}>

                <Image w={40} h={40} className={'mr-3 '}
                       className={'rounded-circle simple-border'}

                       src={src}
                />
                <div className="col-md-11">
                    <div className=" mt-2">
                        <span className={'mr-4 font-weight-bold'}>{name}</span> <b>Announcements: </b>Title - A formal
                        public statement
                        about
                        a fact,
                        occurrence, or intention. "the minister was about to make annoucement"
                    </div>
                </div>
            </div>

        </div>
    );
};

InfoBoardItem.propTypes = {};

export default InfoBoardItem;