import React from 'react';
import Tabs from "../../App/components/Tabs";
import Image from "../../App/components/Image";
import Button from "../../App/components/Button";
import {acceptFriendRequest, declineFriendRequest} from "../../helpers/helpers";
import {Link} from "react-router-dom";

const NotificationHeaderMenu = ({requests = [], setRequests, individualUserProfile}) => {

    const filterRequests = (id) => {
        const filtered = requests.filter(item => item.userSender.id !== id)
        setRequests(filtered)

    }
    return (
        <div className={'tabs-border-bottom'}>
            <Tabs className={'tabs-header-menu pt-0'}
                  tabs={['All', 'Unread', 'Followers', 'Mentions', 'Projects', 'Network']}>
                <div>
                    <div>
                        <h6>Today</h6>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className={'d-flex align-items-center'}>
                                <Image w={40} h={40}
                                       src="https://seeklogo.com/images/R/r-letter-company-logo-9A44363CA1-seeklogo.com.png"
                                       alt=""/>

                                <div className={'ml-3'}>
                                    <p className={'mb-2'}>Rutller</p>
                                    <div style={{fontSize: '0.8rem'}}>Shared a post: Learn how to recognize the
                                        data-driven opportunities and take business to the next level..
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className={'d-flex align-items-center'}>
                                <Image w={40} h={40}
                                       src="https://dynamic.brandcrowd.com/asset/logo/7a61d944-addb-44ab-91c8-cda41786e9e4/logo?v=4"
                                       alt=""/>

                                <div className={'ml-3'}>
                                    <p className={'mb-2'}>Klarks Invenstment</p>
                                    <div style={{fontSize: '0.8rem'}}>Shared a post: Learn how to recognize the
                                        data-driven opportunities and take business to the next level..
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className={'d-flex align-items-center'}>
                                <Image w={40} h={40}
                                       src="https://www.venmond.com/demo/vendroid/img/avatar/big.jpg"
                                       alt=""/>

                                <div className={'ml-3'}>
                                    <p className={'mb-2'}>Sarah Cox</p>
                                    <div style={{fontSize: '0.8rem'}}>Shared a post: Learn how to recognize the
                                        data-driven opportunities and take business to the next level..
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className={'d-flex align-items-center'}>
                                <Image w={40} h={40}
                                       src="https://www.designhill.com/resize_img.php?atyp=page_file&pth=ft_ca_ct||117||contestfile_1&flp=1554116576-13511971185ca1efe0bcd5a0-26602492.jpg"
                                       alt=""/>

                                <div className={'ml-3'}>
                                    <p className={'mb-2'}>Millenium Investment</p>
                                    <div style={{fontSize: '0.8rem'}}>Shared a post: Learn how to recognize the
                                        data-driven opportunities and take business to the next level..
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h6>Yesterday</h6>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className={'d-flex align-items-center'}>
                                <Image w={40} h={40}
                                       src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjQi5Fu6uoEDlbj_IIJCL37PCgBGNQGbS6Xd4L8FCg3fwWHnE&s"
                                       alt=""/>

                                <div className={'ml-3'}>
                                    <p className={'mb-2'}>Raul and Co.</p>
                                    <div style={{fontSize: '0.8rem'}}>Shared a post: Learn how to recognize the
                                        data-driven opportunities and take business to the next level..
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className={'d-flex align-items-center'}>
                                <Image w={40} h={40}
                                       src="https://preview.keenthemes.com/metronic-v4/theme/assets/pages/img/avatars/team1.jpg"
                                       alt=""/>

                                <div className={'ml-3'}>
                                    <p className={'mb-2'}>Chris Evans</p>
                                    <div style={{fontSize: '0.8rem'}}>Shared a post: Learn how to recognize the
                                        data-driven opportunities and take business to the next level..
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className={'d-flex align-items-center'}>
                                <Image w={40} h={40}
                                       src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT33o0dGZM6sHmhPdDTB0PuArZ8ciUxav7nbQk52u7_kvSiPGgI&s"
                                       alt=""/>

                                <div className={'ml-3'}>
                                    <p className={'mb-2'}>Will Wader</p>
                                    <div style={{fontSize: '0.8rem'}}>Shared a post: Learn how to recognize the
                                        data-driven opportunities and take business to the next level..
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className={'d-flex align-items-center'}>
                                <Image w={40} h={40}
                                       src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpIFXpNdTEawMOEYmqIsB7P86N1Z4BtPh9N6z7rW8s-O3SieKk&s"
                                       alt=""/>

                                <div className={'ml-3'}>
                                    <p className={'mb-2'}>Matt Patson</p>
                                    <div style={{fontSize: '0.8rem'}}>Shared a post: Learn how to recognize the
                                        data-driven opportunities and take business to the next level..
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h6>Last week</h6>

                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className={'d-flex align-items-center'}>
                                <Image w={40} h={40}
                                       src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzLLS2NN740CyfRWoMN6mUk-4y_eFQCbdb6CCdShwW_CkfYW8T&s"
                                       alt=""/>
                                <div className={'ml-3'}>
                                    <p className={'mb-2'}>Alvery solutions</p>
                                    <div style={{fontSize: '0.8rem'}}>Shared a post: Learn how to recognize the
                                        data-driven opportunities and take business to the next level..
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className={'d-flex align-items-center'}>
                                <Image w={40} h={40}
                                       src="https://www.fotojet.com/template-imgs-thumb/design/company-logo/company-logo-01.jpg"
                                       alt=""/>

                                <div className={'ml-3'}>
                                    <p className={'mb-2'}>AG Pro</p>
                                    <div style={{fontSize: '0.8rem'}}>Shared a post: Learn how to recognize the
                                        data-driven opportunities and take business to the next level..
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div>{requests.map((item, i) => {
                    return <div key={i} className="d-flex align-items-center justify-content-between mb-3">
                        <div className={'d-flex align-items-center'}>
                            <Image w={50} h={50}
                                   src={`http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${item.userSender.id}`}
                                   alt=""/>
                            <div className={'ml-3'}>
                                <Link to={`/profile/${item.userSender.id}`}><p
                                    className={'mb-2'}>{item.userSender.firstName} {item.userSender.lastName}</p></Link>
                                <div className={'d-flex'}>
                                    <Button className="small-btn btn-blue mr-2"
                                            onClick={() => {
                                                acceptFriendRequest(item.userSender.id, individualUserProfile.id);
                                                filterRequests(item.userSender.id)
                                            }}>Accept</Button>
                                    <Button className="small-btn btn-white"
                                            onClick={() => {
                                                declineFriendRequest(item.userSender.id, individualUserProfile.id);
                                                filterRequests(item.userSender.id)
                                            }
                                            }>Ignore</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                })}
                </div>
            </Tabs>
        </div>
    );
};

NotificationHeaderMenu.propTypes = {};

export default NotificationHeaderMenu;