import React, {useEffect} from 'react';
import InputAndActionContainer from "../../Common/InputAndActionContainer";
import InputField from "../../../App/components/InputField";
import Dropdown from "../../../App/components/Dropdown";
import useForm from "../../../helpers/customHooks/useForm";
import {useDispatch} from "react-redux";
import Checkbox from "../../../App/components/Checkbox";
import TagsInput from "../../../App/components/TagsInput";

const ContractingOverview = ({documentTitle, setDocumentTitle, fileContent, setIsSaved}) => {
    const {handleChange, handleSubmit, values, setValues, setIsEdited, errors} = useForm({
        title: documentTitle,
        description: "",
        category: ""
    }, submit, validate)
    const dispatch = useDispatch()

    function submit() {

    }

    function validate(v) {
        return {}
    }

    useEffect(() => {
        setValues({...values, title: documentTitle})
    }, [documentTitle])

    const handleDocumentTitle = (e) => {
        handleChange(e)
        setIsSaved(false)
        setDocumentTitle(e.target.value)
    }


    return (
        <form action="" onSubmit={handleSubmit}>
            <div>
                <div className={'d-flex flex-column py-4'}>
                    <h4 className={'mt-4'}>Summary</h4>
                    <InputAndActionContainer>
                        <InputField value={values.title} label={"Document title"} name={'title'}
                                    onChange={handleDocumentTitle}/>
                    </InputAndActionContainer>
                    <InputAndActionContainer>
                        <InputField multiline value={values.description} label={"Description"} name={'description'}
                                    onChange={handleChange}/>
                        <></>
                    </InputAndActionContainer>
                    <InputAndActionContainer>
                        <div><InputField type={'date'} label={"Validity"} name={'validity'}
                                         value={values.dateOfBirth}
                                         onChange={handleChange}/>
                            <Checkbox className={'mt-3'}>Recurring</Checkbox>
                        </div>
                    </InputAndActionContainer>
                    <InputAndActionContainer>
                        <InputField label={"Owner"} name={'owner'} value={values.owner}
                                    onChange={handleChange}/>
                    </InputAndActionContainer>

                    <InputAndActionContainer>
                        <Dropdown name={'category'} value={values.category}
                                  options={[{name: 'Category 1', value: 'Category1'}, {
                                      name: 'Category 2',
                                      value: 'Category2'
                                  }]}
                                  onChange={handleChange}
                                  label={"Category"}/>
                    </InputAndActionContainer>
                    <h4 className={'mt-4'}>Tags</h4>
                    <InputAndActionContainer>
                        <TagsInput placeholder={'Tags'}
                                   url={'/api/info/skills/'}
                                   tags={''} setTags={''}
                                   setIsEdited={setIsEdited}
                        />
                    </InputAndActionContainer>
                    <InputAndActionContainer className={'text-left-dark'}>
                        <div>
                            <h4 className={'mt-4'}>Linked Documents</h4>
                            <p>Add linked document dipiscing elionec ut arcu etfs</p>
                            <div>Some-document title 1</div>
                            <div>Some-document title 2</div>
                            <hr/>
                            <div className={'float-right'}>
                                <a href="#">Add Document</a></div>
                        </div>
                    </InputAndActionContainer>

                </div>

            </div>
        </form>

    )
}

ContractingOverview.propTypes = {};

export default ContractingOverview;