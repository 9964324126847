import React from 'react';
import IdentityEditing from "./IdentityEditing";
import RateEditing from "./RateEditing";
import LocationEditing from "../../Common/LocationEditing";

const Introduction = props => {
    return (
        <div>
            <IdentityEditing/>
            <LocationEditing/>
            <RateEditing/>
        </div>
    );
};

Introduction.propTypes = {};

export default Introduction;