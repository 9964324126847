import React from 'react';
import Overview from "./Overview";
import Skills from "./Skills";
import LicencesAndCertificates from "./LicencesAndCertificates";
import Network from "./Network";
import Alias from "./Alias";

const BriefInfo = props => {
    return (
        <div>
            <Overview/>
            <Skills/>
            <LicencesAndCertificates/>
            <Network/>
            <Alias/>
        </div>
    );
};

BriefInfo.propTypes = {};

export default BriefInfo;