import React, { useState, useEffect } from 'react';
import './App.css';
import IndividualUserProfileCreation from "./Dashboard/IndividualUserProfile/containers/IndividualUserProfileCreation";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import IndividualUserProfileView from "./Dashboard/IndividualUserProfile/containers/IndividualUserProfileView";
import RegisterScreen from "./Identity/containers/RegisterScreen";
import CreateAlias from "./Dashboard/Alias/containers/CreateAlias";
import { Provider } from "react-redux";
import LoginScreen from "./Identity/containers/LoginScreen";
import PrivateRoute from "./helpers/PrivateRoute";
import CompanyProfileCreation from "./Dashboard/CompanyProfile/containers/CompanyProfileCreation";
import FreelanceProfileCreation from "./Dashboard/FreelanceProfile/containers/FreelanceProfileCreation";
import CompanyProfileView from "./Dashboard/CompanyProfile/containers/CompanyProfileView";
import FreelanceProfileView from "./Dashboard/FreelanceProfile/containers/FreelanceProfileView";
import Membership from "./Dashboard/containers/Membership";
import HomePage from "./Dashboard/containers/HomePage";
import InvestmentFundProfileCreation from "./Dashboard/InvestmentFundProfile/containers/InvestmentFundProfileCreation";
import NonGovernmentalProfileCreation
    from "./Dashboard/NonGovernmentalProfile/containers/NonGovernmentalProfileCreation";
import GovernmentalProfileCreation from "./Dashboard/GovernmentalProfile/containers/GovernmentalProfileCreation";
import InvestmentFundProfileView from "./Dashboard/InvestmentFundProfile/containers/InvestmentFundProfileView";
import store from './App/store/index'
import Contracting from "./Dashboard/Contracting/containers/Contracting";
import Contract from "./Dashboard/Contracting/containers/Contract";
import AllAlias from "./Dashboard/Alias/containers/AllAlias";
import ServiceCompanyProfileCreation from "./Dashboard/ServiceCompany/containers/ServiceCompanyProfileCreation";
import Network from "./Dashboard/Network/containers/Network";
import Opportunities from "./Dashboard/Opportunities/containers/Opportunities";
import CreateOpportunity from "./Dashboard/Opportunities/containers/CreateOpportunity";
import Projects from "./Dashboard/Projects/containers/Projects";
import CreateProject from "./Dashboard/Projects/containers/CreateProject";
import InfoBoard from "./Dashboard/Projects/containers/InfoBoard";
import InfoBoardPost from "./Dashboard/Projects/components/InfoBoardPost";
import CreatePostInfoBoard from "./Dashboard/Projects/components/CreatePostInfoBoard";
import ScrollToTop from "./helpers/ScrollToTop";
import AdvancedSearch from "./Dashboard/AdvancedSearch/containers/AdvancedSearch";
import Dashboard from "./Dashboard/Hire/containers/Dashboard";
import WorkHistory from "./Dashboard/Hire/containers/WorkHistory";
import HireProfile from "./Dashboard/Hire/containers/HireProfile";
import GovernmentalProfileView from "./Dashboard/GovernmentalProfile/containers/GovernmentalProfileView";
import NonGovernmentalProfileView from "./Dashboard/NonGovernmentalProfile/containers/NonGovernmentalProfileView";

import DueDiligence from "./Dashboard/DueDiligence/containers/DueDiligence";

import { initFni18next } from './i18n/init';

function App() {
    //--------------------------------------
    // const getLanguageFromLocalStorage = () => {
    //     let lng = localStorage.getItem('lng')
    //     if (!lng) { lng = 'en' }
    //     initFni18next(lng)
    // }
    // useEffect(() => {
    //     getLanguageFromLocalStorage();
    // }, [])
    //--------------------------------------
    let lng = localStorage.getItem('lng')
    if (!lng) { lng = 'en' }
    initFni18next(localStorage.getItem('lng'))
    //--------------------------------------

    return (
        <>
            <Provider store={store}>
                <Router>
                    <ScrollToTop />
                    <Switch>
                        <PrivateRoute exact path='/' component={HomePage} />
                        <Route path='/register' component={RegisterScreen} />
                        <Route path='/login' component={LoginScreen} />
                        <PrivateRoute path='/individual-user' component={IndividualUserProfileCreation} />
                        <PrivateRoute path='/profile/:id?' component={(props) => (
                            <IndividualUserProfileView key={props.match.params.id} {...props} />)
                        } />
                        <PrivateRoute path='/create-alias' component={CreateAlias} />
                        <PrivateRoute path='/all-alias' component={AllAlias} />
                        <PrivateRoute path='/create-company/:id?' component={(props) => (
                            <CompanyProfileCreation key={props.match.params.id} {...props} />)
                        } />
                        <PrivateRoute path='/company-profile/:id' component={(props) => (
                            <CompanyProfileView key={props.match.params.id} {...props} />)
                        } />
                        <PrivateRoute path='/create-freelance' component={FreelanceProfileCreation} />
                        <PrivateRoute path='/freelance-view' component={FreelanceProfileView} />
                        <PrivateRoute path='/create-service-company' component={ServiceCompanyProfileCreation} />
                        <PrivateRoute path='/create-investment-fund/:id?' component={(props) => (
                            <InvestmentFundProfileCreation key={props.match.params.id} {...props} />)
                        } />
                        <PrivateRoute path='/investment-fund-profile/:id' component={(props) => (
                            <InvestmentFundProfileView key={props.match.params.id} {...props} />)
                        } />
                        <PrivateRoute path='/create-nongovernmental-profile/:id?'
                            component={(props) => (
                                <NonGovernmentalProfileCreation key={props.match.params.id} {...props} />)
                            } />
                        <PrivateRoute path='/governmental-profile-view/:id'
                            component={(props) => (
                                <GovernmentalProfileView key={props.match.params.id} {...props} />)
                            } />
                        <PrivateRoute path='/create-governmental-profile/:id?'
                            component={(props) => (
                                <GovernmentalProfileCreation key={props.match.params.id} {...props} />)
                            } />
                        <PrivateRoute path='/nongovernmental-profile-view/:id/'
                            component={(props) => (
                                <NonGovernmentalProfileView key={props.match.params.id} {...props} />)
                            } />
                        <PrivateRoute path='/membership' component={Membership} />
                        <PrivateRoute path='/contracting' component={Contracting} />
                        <PrivateRoute path='/contract/:id?' component={(props) => (
                            <Contract key={props.match.params.id} {...props} />)
                        } />
                        <PrivateRoute path='/due-diligence' component={DueDiligence} />
                        <PrivateRoute path='/network' component={Network} />
                        <PrivateRoute path='/opportunities' component={Opportunities} />
                        <PrivateRoute path='/create-opportunity' component={CreateOpportunity} />
                        <PrivateRoute path='/projects' component={Projects} />
                        <PrivateRoute path='/create-project' component={CreateProject} />
                        <PrivateRoute path='/info-board' component={InfoBoard} />
                        <PrivateRoute path='/info-board-post' component={InfoBoardPost} />
                        <PrivateRoute path='/info-board-create-post' component={CreatePostInfoBoard} />
                        <PrivateRoute path='/advanced-search' component={AdvancedSearch} />
                        <PrivateRoute path='/hire-dashboard' component={Dashboard} />
                        <PrivateRoute path='/work-history' component={WorkHistory} />
                        <PrivateRoute path='/hire-profile' component={HireProfile} />
                        {/*<Route path="*" component={Error404Screen} />*/}
                    </Switch>
                </Router>
            </Provider>
        </>
    );
}

export default App;
