import React, {useEffect} from 'react';
import MenuOpportunitiesProjects from "../../Common/MenuOpportunitiesProjects";
import CreateProjectModalContent from "../components/CreateProjectModalContent";
import ProjectUpdatesItem from "../components/ProjectUpdatesItem";
import InputField from "../../../App/components/InputField";
import CoverImgBox from "../../Common/CoverImgBox";
import InfoBoardItem from "../components/InfoBoardItem";
import CreateProjectBoardBox from "../components/CreateProjectBoardBox";
import MenuDots from "../../../App/components/MenuDots";
import {Link} from "react-router-dom";
import Image from "../../../App/components/Image";
import {useDispatch, useSelector} from "react-redux";
import {fetchAccount} from "../../../App/store/user";
import Loader from "../../Common/Loader";

const CreateProject = props => {

    const {data, isLoading} = useSelector(state => state.user.account)
    const {individualUserProfile} = data
    const dispatch = useDispatch()

    useEffect(() => {
        if (!Object.keys(data).length)
            dispatch(fetchAccount())
    }, [])


    if (isLoading)
        return <Loader/>
    return (
        <div>
            <div className="">
                <MenuOpportunitiesProjects page={'Projects'} linkTo={'projects'}
                                           modalContent={<CreateProjectModalContent/>}/></div>
            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container d-flex p-0'} style={{background: '#f5f5f5'}}>
                    <div className="col-md-8 mx-auto">
                        <div className={'d-flex justify-content-between align-items-center mb-4 mt-5'}>
                            <h2 className={'font-weight-bold'}>Project</h2>
                            <div className={'flex-grow-1 ml-3'} style={{height: 1, background: '#ccc'}}>
                            </div>
                            <div className={'ml-3 '}>
                                <MenuDots
                                    className={'dots-vertical '}/>
                            </div>
                        </div>
                        <div><CoverImgBox/></div>
                        <CreateProjectBoardBox title={<Link to={'/info-board'}>Info Board</Link>}>
                            <div>
                                <InfoBoardItem name={'Ray Opal'}
                                               src={'https://digitalsignagesummit.org/europe/wp-content/uploads/2017/05/Mike-Blackman-2.jpg'}/>
                                <InfoBoardItem name={'Denise Jolie'}
                                               src={'https://i.pinimg.com/600x315/a6/0d/68/a60d685194a7fd984d08a595a0a99ae7.jpg'}/>
                            </div>
                        </CreateProjectBoardBox>
                        <div className="row mt-4">
                            <div className="col-md-6">
                                <CreateProjectBoardBox title={'Project To Do\'s'}>
                                    <div>
                                        <h5><b>Task list name</b></h5>
                                        <h6>Task Name </h6>
                                        <h6>Task name that is unusually long and takes up almost all the space</h6>
                                        <h6>This task is done and still has a question</h6>
                                    </div>
                                    <div>
                                        <h5><b>Other task list name</b></h5>
                                        <h6>Task Name</h6>
                                    </div>
                                </CreateProjectBoardBox>
                            </div>
                            <div className="col-md-6">
                                <CreateProjectBoardBox title={'Schedule'}>
                                    <div className={'mb-3'}>
                                        <h6>Schedule title</h6>
                                        <h6>Schedule title for the same day</h6>
                                        <div className={'p-1 small bg-mainBlue text-center corner-radius10'}>Mon 12
                                            July,
                                            2020 -
                                            4.00pm - 4.30pm
                                        </div>
                                    </div>
                                    <div>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard d</p>
                                        <div className={'p-1 small bg-mainBlue text-center corner-radius10'}>Mon 12
                                            July,
                                            2020 -
                                            at 4.00pm - Wed, 20 July, 2020 at 4:45pm CEST
                                        </div>
                                    </div>
                                </CreateProjectBoardBox>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-6">
                                <CreateProjectBoardBox title={'Documents and Files'}>
                                    <div className={'row'}>
                                        <div className={'col-md-3'}>
                                            <img className={'w-100 mb-2'} src={"/assets/images/Screenshot_3.png"}
                                                 alt=""/>
                                            <div>Doc Title</div>
                                        </div>
                                        <div className={'col-md-3'}>
                                            <img className={'w-100 mb-2'} src={"/assets/images/Screenshot_3.png"}
                                                 alt=""/>
                                            <div>Doc Title</div>
                                        </div>
                                        <div className={'col-md-3'}>
                                            <img className={'w-100 mb-2'} src={"/assets/images/Screenshot_3.png"}
                                                 alt=""/>
                                            <div>Doc Title</div>
                                        </div>
                                        <div className={'col-md-3'}>
                                            <img className={'w-100 mb-2'} src={"/assets/images/Screenshot_3.png"}
                                                 alt=""/>
                                            <div>Doc Title</div>
                                        </div>
                                    </div>
                                </CreateProjectBoardBox>
                            </div>
                            <div className="col-md-6">
                                <CreateProjectBoardBox title={'Check Ins'}>
                                    <div>
                                        <h5><b>Questions Asked?</b></h5>
                                        <div>Scheduled for every Monday at 4:30pm</div>
                                        <div className={'d-flex flex-wrap mt-2 mb-3'}
                                             style={{width: 100}}>{['https://preview.keenthemes.com/metronic-v4/theme/assets/pages/img/avatars/team1.jpg', 'https://i.pinimg.com/600x315/a6/0d/68/a60d685194a7fd984d08a595a0a99ae7.jpg', 'https://i.pinimg.com/236x/67/5f/40/675f409cfcbea9281f6649ca096caf25--corporate-photography-headshot-photography.jpg', 'https://osmey-milian-photography.com/_files/200002613-7c7f77d81f/OMD_7187-Website.jpg', 'https://content1.getnarrativeapp.com/68f6039f-a2f9-4e15-9b91-4f7cebe2a378/img_ref/4e80a9d6-aef4-4875-ab94-5952b6926e07/Hamish-5_professional_headshots_photographer_headshot_750.jpg', 'https://pixomatic.us/blog/wp-content/uploads/2019/11/pixomatic_1572877223091.png',].map((item, i) => {
                                            return <Image key={i}
                                                          style={{
                                                              minWidth: 20,
                                                              maxWidth: 20,
                                                              height: 20,
                                                              marginLeft: i !== 0 ? -10 : 0
                                                          }}
                                                          className={'mr-1 d-flex '} src={item}>
                                            </Image>
                                        })}</div>
                                    </div>
                                    <div>
                                        <h5><b>Opinions on something</b></h5>
                                        <div className={'d-flex flex-wrap mt-2'}
                                             style={{width: 100}}>{['https://preview.keenthemes.com/metronic-v4/theme/assets/pages/img/avatars/team1.jpg', 'https://i.pinimg.com/600x315/a6/0d/68/a60d685194a7fd984d08a595a0a99ae7.jpg', 'https://i.pinimg.com/236x/67/5f/40/675f409cfcbea9281f6649ca096caf25--corporate-photography-headshot-photography.jpg', 'https://osmey-milian-photography.com/_files/200002613-7c7f77d81f/OMD_7187-Website.jpg', 'https://content1.getnarrativeapp.com/68f6039f-a2f9-4e15-9b91-4f7cebe2a378/img_ref/4e80a9d6-aef4-4875-ab94-5952b6926e07/Hamish-5_professional_headshots_photographer_headshot_750.jpg', 'https://pixomatic.us/blog/wp-content/uploads/2019/11/pixomatic_1572877223091.png',].sort().map((item, i) => {
                                            return <Image key={i}
                                                          style={{
                                                              minWidth: 20,
                                                              maxWidth: 20,
                                                              height: 20,
                                                              marginLeft: i !== 0 ? -10 : 0
                                                          }}
                                                          className={'mr-1 d-flex '} src={item}>
                                            </Image>
                                        })}</div>
                                    </div>
                                </CreateProjectBoardBox>
                            </div>
                        </div>
                        <div className={'d-flex justify-content-between align-items-center mb-4 mt-5'}>
                            <h2 className={'font-weight-bold'}>Project Teams</h2>
                            <div className={'flex-grow-1 ml-3'} style={{height: 1, background: '#ccc'}}>
                            </div>
                            <div className={'ml-3 '}>
                                <MenuDots
                                    className={'dots-vertical '}/>
                            </div>
                        </div>
                        <div className={'row '}>
                            <div className="col-md-4">
                                <CreateProjectBoardBox title={'Management'}>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard d</p>
                                    <div className={'d-flex'}>
                                        <div className={'d-flex flex-wrap'}
                                             style={{width: 100}}>{['https://preview.keenthemes.com/metronic-v4/theme/assets/pages/img/avatars/team1.jpg', 'https://i.pinimg.com/600x315/a6/0d/68/a60d685194a7fd984d08a595a0a99ae7.jpg', 'https://i.pinimg.com/236x/67/5f/40/675f409cfcbea9281f6649ca096caf25--corporate-photography-headshot-photography.jpg', 'https://osmey-milian-photography.com/_files/200002613-7c7f77d81f/OMD_7187-Website.jpg', 'https://content1.getnarrativeapp.com/68f6039f-a2f9-4e15-9b91-4f7cebe2a378/img_ref/4e80a9d6-aef4-4875-ab94-5952b6926e07/Hamish-5_professional_headshots_photographer_headshot_750.jpg', 'https://pixomatic.us/blog/wp-content/uploads/2019/11/pixomatic_1572877223091.png',].map((item, i) => {
                                            return <Image key={i}
                                                          style={{
                                                              minWidth: 20,
                                                              maxWidth: 20,
                                                              height: 20,
                                                              marginLeft: i !== 0 ? -10 : 0
                                                          }}
                                                          className={'mr-1 d-flex '} src={item}>
                                            </Image>
                                        })}</div>
                                    </div>
                                </CreateProjectBoardBox>
                            </div>
                            <div className="col-md-4">
                                <CreateProjectBoardBox title={'Front-end'}>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard d</p>
                                    <div className={'d-flex flex-wrap'}
                                         style={{width: 100}}>{['https://preview.keenthemes.com/metronic-v4/theme/assets/pages/img/avatars/team1.jpg', 'https://i.pinimg.com/600x315/a6/0d/68/a60d685194a7fd984d08a595a0a99ae7.jpg', 'https://i.pinimg.com/236x/67/5f/40/675f409cfcbea9281f6649ca096caf25--corporate-photography-headshot-photography.jpg', 'https://osmey-milian-photography.com/_files/200002613-7c7f77d81f/OMD_7187-Website.jpg', 'https://content1.getnarrativeapp.com/68f6039f-a2f9-4e15-9b91-4f7cebe2a378/img_ref/4e80a9d6-aef4-4875-ab94-5952b6926e07/Hamish-5_professional_headshots_photographer_headshot_750.jpg', 'https://pixomatic.us/blog/wp-content/uploads/2019/11/pixomatic_1572877223091.png'].sort().map((item, i) => {
                                        return <Image key={i}
                                                      style={{
                                                          minWidth: 20,
                                                          maxWidth: 20,
                                                          height: 20,
                                                          marginLeft: i !== 0 ? -10 : 0
                                                      }}
                                                      className={'mr-1 d-flex '} src={item}>
                                        </Image>
                                    })}</div>
                                </CreateProjectBoardBox>
                            </div>
                            <div className="col-md-4">
                                <CreateProjectBoardBox title={'Dev-Ops'}>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard d</p>
                                    <div className={'d-flex flex-wrap'}
                                         style={{width: 100}}>{['https://preview.keenthemes.com/metronic-v4/theme/assets/pages/img/avatars/team1.jpg', 'https://i.pinimg.com/600x315/a6/0d/68/a60d685194a7fd984d08a595a0a99ae7.jpg', 'https://i.pinimg.com/236x/67/5f/40/675f409cfcbea9281f6649ca096caf25--corporate-photography-headshot-photography.jpg', 'https://osmey-milian-photography.com/_files/200002613-7c7f77d81f/OMD_7187-Website.jpg', 'https://content1.getnarrativeapp.com/68f6039f-a2f9-4e15-9b91-4f7cebe2a378/img_ref/4e80a9d6-aef4-4875-ab94-5952b6926e07/Hamish-5_professional_headshots_photographer_headshot_750.jpg', 'https://pixomatic.us/blog/wp-content/uploads/2019/11/pixomatic_1572877223091.png',].map((item, i) => {
                                        return <Image key={i}
                                                      style={{
                                                          minWidth: 20,
                                                          maxWidth: 20,
                                                          height: 20,
                                                          marginLeft: i !== 0 ? -10 : 0
                                                      }}
                                                      className={'mr-1 d-flex '} src={item}>
                                        </Image>
                                    })}</div>
                                </CreateProjectBoardBox>
                            </div>
                        </div>
                        <div className={'d-flex justify-content-between align-items-center mb-4 mt-5'}>
                            <h2 className={'font-weight-bold'}>Project Activity</h2>
                            <div className={'flex-grow-1 ml-3'} style={{height: 1, background: '#ccc'}}>
                            </div>
                            <div className={'ml-3 '}>
                                <MenuDots
                                    className={'dots-vertical '}/>
                            </div>
                        </div>
                        <div>
                            <ProjectUpdatesItem name={'Sara Landlord'}
                                                src={'https://i.pinimg.com/600x315/a6/0d/68/a60d685194a7fd984d08a595a0a99ae7.jpg'}/>
                            <ProjectUpdatesItem name={'Dina Christian'}
                                                src={'https://i.pinimg.com/236x/67/5f/40/675f409cfcbea9281f6649ca096caf25--corporate-photography-headshot-photography.jpg'}/>
                            <ProjectUpdatesItem name={'Maya Tucker'}
                                                src={'https://osmey-milian-photography.com/_files/200002613-7c7f77d81f/OMD_7187-Website.jpg'}/>
                            <ProjectUpdatesItem name={'Ed Waltz'}
                                                src={'https://content1.getnarrativeapp.com/68f6039f-a2f9-4e15-9b91-4f7cebe2a378/img_ref/4e80a9d6-aef4-4875-ab94-5952b6926e07/Hamish-5_professional_headshots_photographer_headshot_750.jpg'}/>

                        </div>
                    </div>
                    <div className={'col-md-3 bg-white pt-3'}>
                        <div className={'d-flex justify-content-around w-100'}>
                            <InputField name={'search'} label={'Search'}/>
                            <img src="/assets/images/Notifications.svg" alt=""/>
                        </div>
                        <div className={'d-flex flex-column align-items-center mt-5'}>
                            <Image w={100} h={100} className={'simple-border'}
                                   src={`http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${individualUserProfile.id}`}/>
                            <div className={'mt-3'}>
                                <b>{individualUserProfile.firstName + " " + individualUserProfile.lastName}</b>
                            </div>
                            <span className={'small'}>Owner</span>
                        </div>
                        <div className={'border-bottom d-flex mt-4 px-2 justify-content-between'}>
                            <h6><b>On my list</b></h6>
                            <span>All</span>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Update supply change on
                                    some list somewhere
                                </div>
                                <a href={''} className={'link-small pt-2'}>@projectname</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold pb-'}>Jin Tan in applies in listing
                                    <a href={''}
                                       className={'link-small font-weight-normal pt-2'}> @CFO
                                        position opening</a></div>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Thomas M. Trevino requested
                                    NDA
                                </div>
                                <a href={''} className={'link-small pt-2'}>@Selpic P1</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Jin Tan reported something
                                    at
                                </div>
                                <a href={''} className={'link-small pt-2'}>@Selpic P1</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold pb- '}>Richard M. Hobbs in Follows <a
                                    href={''} className={'link-small font-weight-normal pt-2'}> @purME</a> Project.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CreateProject.propTypes = {};

export default CreateProject;