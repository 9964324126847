import React, { useEffect, useState } from 'react';
import Image from "../../App/components/Image";
import DropZone from "../../App/components/Dropzone";
import { useSelector } from "react-redux";

const ImageUploader = ({ setIsEdited, setImgFile }) => {
    const [meta, setMeta] = useState()
    const { data } = useSelector(state => state.user.account)
    const { individualUserProfile } = data
    const id = individualUserProfile.id

    useEffect(() => {
        meta ? setIsEdited(true) : setIsEdited(false)
        // console.log(meta)
    }, [meta])

    return (
        <>
            <div className={'d-flex justify-content-center'}>
                <div className='pb-2'>
                    {/* <div className={'d-flex justify-content-between align-items-center'}>
                    <div className="small py-2">Profile Picture</div>
                </div> */}
                    <Image
                        // className={'simple-border'}
                        w={90} h={90}
                        src={(meta && meta.previewUrl) || `http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${id}` || '/assets/images/DV-Avatar-Icon.svg'}
                        imgStyle={{ borderRadius: '100%' }}
                        corners={'corner-radius10'} />
                </div>
                {/* <div className={'w-100 ml-3 align-self-end'}>
                <DropZone setFiles={setImgFile} setMeta={setMeta}
                          title={'Upload Picture'}
                          color={'#ffffff'}
                          maxFiles={1} accept={'image/*'}
                          border={'1px solid #2983FD'}
                          background={'#2983FD'}/>
            </div> */}
            </div>

            {/* <div className={'w-100 ml-3 align-self-end'}> */}
            <div className={''}>
                <DropZone setFiles={setImgFile} setMeta={setMeta}
                    title={'Upload Picture'}
                    color={'#ffffff'}
                    maxFiles={1} accept={'image/*'}
                    border={'1px solid #2983FD'}
                    background={'#2983FD'}
                    maxWidth={150} />
            </div>
        </>
    );
};

ImageUploader.propTypes = {};

export default ImageUploader;