import React from 'react';
import ActivityItem from "./ActivityItem";
import Button from "../../../App/components/Button";

const Activity = props => {
    return (
        <div className={'corner-radius5 simple-shadow p-4 bg-white mt-5'}>
            <h4><b>Activity</b></h4>
            <div className="mt-5">
                <div className="d-flex mb-3">
                    <div className="col-md-3 small">
                        From
                    </div>
                    <div className="col-md-2 small ">
                        Status
                    </div>
                    <div className="col-md-1 small">Hours</div>
                    <div className="col-md-3 small">
                        Date
                    </div>
                </div>
                <ActivityItem name={'Gretchen White'}
                              src={'https://images.squarespace-cdn.com/content/v1/5d96fc780e85b877a919f4f2/1570777847921-A6W51WBD9SWVQYVPGJA4/ke17ZwdGBToddI8pDm48kMXRibDYMhUiookWqwUxEZ97gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0luUmcNM2NMBIHLdYyXL-Jww_XBra4mrrAHD6FMA3bNKOBm5vyMDUBjVQdcIrt03OQ/Angela-16.jpg'}
                              status={'Awaiting Approval'}
                              statusColor={'greenyellow'}
                              hours={40}
                              className={'simple-border'}
                >
                    <Button className={'mr-2 btn-blue small-btn'}>Approve</Button>
                    <Button className={'mr-2 btn-white small-btn'}>Cancel</Button>
                </ActivityItem>
                <ActivityItem name={'Lio Yong'}
                              src={'https://saint313.com/wp-content/uploads/2018/04/pexels-photo-428341-1024x683.jpeg'}
                              status={'Approved'}
                              statusColor={'limegreen'}
                              hours={40}
                              className={'simple-border'}
                >
                    <a href="#"><b>View project</b></a>
                </ActivityItem>
                <ActivityItem name={'Mustafa Gianni'}
                              src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3YbaqxuMYrNcSKgubbBpVQ1oInLahxstezw&usqp=CAU'}
                              status={'Canceled'}
                              statusColor={'red'}
                              hours={35}
                              className={'simple-border'}
                >
                    <a href="#"><b>View project</b></a>
                </ActivityItem>
                <ActivityItem name={'Silvia Looper'}
                              src={'https://mlk5vm21jtgn.i.optimole.com/uMYairY.FNSH~38b5/w:300/h:auto/q:75/https://artofheadshots.com/wp-content/uploads/2018/11/001Elizabeth-WEB.jpg'}
                              status={'Awaiting Approval'}
                              statusColor={'greenyellow'}
                              hours={30}
                              className={'simple-border'}
                >
                    <a href="#"><b>Contract Signed</b></a>
                </ActivityItem>

            </div>
        </div>
    );
};

Activity.propTypes = {};

export default Activity;