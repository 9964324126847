import React from "react";
import ReactDom from 'react-dom';
import GoogleMapReact from "google-map-react";
import './marker.scss'

const Marker = () => {
  return <>
    <div className="pin"></div>
    <div className="pulse"></div>
  </>
}

const Map = ({ latitude, longitude }) => {
  return (
    <div style={{ height: "300px", width: "700px" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyC4bshlppNOywv6wafbyjcU7v1urNo6yfo",
          language: "en",
          region: "US"
        }}
        center={{ lat: Number(latitude) || 51.506, lng: Number(longitude) || -0.169 }}
        zoom={latitude ? 6 : 2}
      >
        {latitude && longitude && < Marker lat={Number(latitude)} lng={Number(longitude)} />}
      </GoogleMapReact>
    </div>
  );
};


export default Map;
