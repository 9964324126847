import React from 'react';
import BranchesEditing from "../../Common/BranchesEditing";

const BranchesAndSubsidiary = props => {
    return (
        <div>
            {/*<SubsidiaryEditing/>*/}
            <BranchesEditing/>

        </div>
    );
};

BranchesAndSubsidiary.propTypes = {};

export default BranchesAndSubsidiary;