import React, { useState, useEffect } from "react";
// import React from 'react'
// import Purpose from "../Purpose";
import Purpose from "../View/Purpose";
import ProfileInfoBoxSection from "../../Common/ProfileInfoBoxSection";

const InfoView = ({ props }) => {

  const { name, description, startDate, projectWebsite, ownership, contribution, budget, currency, sector, subSector, untangableAsset } = props
  const [readMore, setReadMore] = useState(false);
  let readMoreLength = 218;

  useEffect(() => {
    setReadMore(false)
  }, [props])

  return (
    <ProfileInfoBoxSection custom={'Overview'} className={'mt-3 border-gray margin-b-1'} title={name}>
      <div className="profile-info">
        <div className='overview-form-title-small mt-2'>Description</div>
        {description.length <= readMoreLength ? (
          <div>{description}</div>
        ) : description.length > readMoreLength && readMore ? (
          <div>
            <div className='overview-div-text'>{description}</div>
            <a
              href={"javascript:void(0)"}
              className={"link-small"}
              onClick={() => setReadMore(!readMore)}>
              Read Less
            </a>
          </div>
        ) : (
          <div>
            <div className='overview-div-text'>{description.substr(0, readMoreLength) + ' ...'}</div>
            <a
              href={"javascript:void(0)"}
              className={"link-small"}
              onClick={() => setReadMore(!readMore)}>
              Read More...
            </a>
          </div>
        )}
      </div>


      <div>
        <div className='overview-form-title-small mt-2'>Start Date</div>
        {/* moe za proba */}
        <div className='overview-div-text'>{new Date(startDate).toLocaleDateString()}</div>
        {/* moe za proba */}
      </div>
      <div>
        <div className='overview-form-title-small mt-2'>Project Website</div>
        {/* moe za proba */}
        <div className='overview-div-text'>{projectWebsite}</div>
        {/* moe za proba */}
      </div>
      <div>
        <div className='overview-form-title-small mt-2'>Ownership</div>
        {/* moe za proba */}
        <div className='overview-div-text'>{ownership}</div>
        {/* moe za proba */}
      </div>
      <div>
        <div className='overview-form-title-small mt-2'>Contribution</div>
        {/* moe za proba */}
        <div className='overview-div-text'>{contribution}</div>
        {/* moe za proba */}
      </div>
      {budget && currency && < div >
        <div className='overview-form-title-small mt-2'>Project Budget</div>
        {/* moe za proba */}
        <div className='overview-div-text'>{budget + ' / ' + currency}</div>
        {/* moe za proba */}
      </div>}
      {
        untangableAsset && <div>
          <div className='overview-form-title-small mt-2'>Untangible Asset</div>
          {/* moe za proba */}
          <div className='overview-div-text'>{untangableAsset.split(",").join(' ')}</div>
          {/* <div className='overview-div-text'>{untangableAsset}</div> */}
          {/* moe za proba */}
        </div>
      }
      <div>
        <div className='overview-form-title-small mt-2'>Sector</div>
        {/* moe za proba */}
        <div className='overview-div-text'>{sector}</div>
        {/* moe za proba */}
      </div>
      <div>
        <div className='overview-form-title-small mt-2'>Subector</div>
        {/* moe za proba */}
        <div className='overview-div-text'>{subSector}</div>
        {/* moe za proba */}
      </div>
    </ProfileInfoBoxSection >
  )
}

export default InfoView



//---------------------------------

// import React, { useState, useEffect } from "react";
// // import React from 'react'
// // import Purpose from "../Purpose";
// import Purpose from "../View/Purpose";
// import ProfileInfoBoxSection from "../../Common/ProfileInfoBoxSection";

// const InfoView = ({ props }) => {

//   const { name, description, startDate, projectWebsite, ownership, contribution, budget, currency, sector, subSector } = props
//   const [readMore, setReadMore] = useState(false);
//   let readMoreLength = 218;

//   useEffect(() => {
//     setReadMore(false)
//   }, [props])

//   return (
//     <ProfileInfoBoxSection custom={'Overview'} className={'mt-3 border-gray margin-b-1'} title={name}>
//       <div className="profile-info">
//         <div className='overview-form-title-small mt-2'>Description</div>
//         {description.length <= readMoreLength ? (
//           <div>{description}</div>
//         ) : description.length > readMoreLength && readMore ? (
//           <div>
//             <div className='overview-div-text'>{description}</div>
//             <a
//               href={"javascript:void(0)"}
//               className={"link-small"}
//               onClick={() => setReadMore(!readMore)}>
//               Read Less
//             </a>
//           </div>
//         ) : (
//           <div>
//             <div className='overview-div-text'>{description.substr(0, readMoreLength) + ' ...'}</div>
//             <a
//               href={"javascript:void(0)"}
//               className={"link-small"}
//               onClick={() => setReadMore(!readMore)}>
//               Read More...
//             </a>
//           </div>
//         )}
//       </div>


//       <div>
//         <div className='overview-form-title-small mt-2'>Start Date</div>
//         {/* moe za proba */}
//         <div className='overview-div-text'>{new Date(startDate).toLocaleDateString()}</div>
//         {/* moe za proba */}
//       </div>
//       <div>
//         <div className='overview-form-title-small mt-2'>Project Website</div>
//         {/* moe za proba */}
//         <div className='overview-div-text'>{projectWebsite}</div>
//         {/* moe za proba */}
//       </div>
//       <div>
//         <div className='overview-form-title-small mt-2'>Ownership</div>
//         {/* moe za proba */}
//         <div className='overview-div-text'>{ownership}</div>
//         {/* moe za proba */}
//       </div>
//       <div>
//         <div className='overview-form-title-small mt-2'>Contribution</div>
//         {/* moe za proba */}
//         <div className='overview-div-text'>{contribution}</div>
//         {/* moe za proba */}
//       </div>
//       <div>
//         <div className='overview-form-title-small mt-2'>Project Budget</div>
//         {/* moe za proba */}
//         <div className='overview-div-text'>{budget + ' / ' + currency}</div>
//         {/* moe za proba */}
//       </div>
//       <div>
//         <div className='overview-form-title-small mt-2'>Sector</div>
//         {/* moe za proba */}
//         <div className='overview-div-text'>{sector}</div>
//         {/* moe za proba */}
//       </div>
//       <div>
//         <div className='overview-form-title-small mt-2'>Subector</div>
//         {/* moe za proba */}
//         <div className='overview-div-text'>{subSector}</div>
//         {/* moe za proba */}
//       </div>
//     </ProfileInfoBoxSection>
//   )
// }

// export default InfoView
