import React, {useEffect, useState} from 'react';
import MenuOpportunitiesProjects from "../../Common/MenuOpportunitiesProjects";
import CreateProjectModalContent from "./CreateProjectModalContent";
import MenuDots from "../../../App/components/MenuDots";
import CreateProjectBoardBox from "./CreateProjectBoardBox";
import InfoBoardItem from "./InfoBoardItem";
import InputField from "../../../App/components/InputField";
import CommentInfoBoard from "./CommentInfoBoard";
import CommentPosting from "./CommentPosting";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {fetchAccount} from "../../../App/store/user";
import Loader from "../../Common/Loader";
import Image from "../../../App/components/Image";

const InfoBoardPost = props => {
    const [commentsContent, setCommentsContent] = useState([{
        comment: ' <p>I remember we used to (maybe 3 summers ago?) take time to do this and it was so\n' +
            '                                        fun! Great idea! I think it was your idea then too!! I’d love to see what you\n' +
            '                                        end up sending out! </p>'
    }, {
        comment: '<p>I remember we used to (maybe 3 summers ago?) take time to do this and it was so\n' +
            '                                        fun! Great idea! I think it was your idea then too!! I’d love to see what you\n' +
            '                                        end up sending out!I remember we used to (maybe 3 summers ago?) take time to do\n' +
            '                                        this and it was so\n' +
            '                                        fun! Great idea! I think it was your idea then too!! I’d love to see what you\n' +
            '                                        end up sending out!</p>'
    }, {
        comment: '<p>We used to (maybe idea then too!! I’d love to see what you\n' +
            '                                        end up sending o</p>'
    },])
    const history = useHistory()
    const {data, isLoading} = useSelector(state => state.user.account)
    const {individualUserProfile} = data
    const dispatch = useDispatch()

    useEffect(() => {
        if (!Object.keys(data).length)
            dispatch(fetchAccount())
    }, [])

    if (isLoading)
        return <Loader/>
    return (
        <div>
            <div className="">
                <MenuOpportunitiesProjects page={'Projects'} linkTo={'projects'}
                                           modalContent={<CreateProjectModalContent/>}/></div>
            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container d-flex p-0'} style={{background: '#f5f5f5'}}>
                    <div className="col-md-8 mx-auto">
                        <div className={'d-flex justify-content-between align-items-center mb-4 mt-5'}>
                            <h2 className={'font-weight-bold'}>Project</h2>
                            <div className={'flex-grow-1 ml-3'} style={{height: 1, background: '#ccc'}}>
                            </div>
                            <div className={'ml-3 '}>
                                <MenuDots
                                    className={'dots-vertical '}/>
                            </div>
                        </div>

                        <CreateProjectBoardBox
                            title={<><Link to={'/info-board'}>Info Board</Link> - Chris's Announcement</>}>
                            <div className={'pb-4 pt-3 px-4 border-bottom'}><InfoBoardItem name={'Chris Miller'}
                                                                                           src={'https://pixomatic.us/blog/wp-content/uploads/2019/11/pixomatic_1572877223091.png'}/>
                            </div>
                            <div className={'p-4'}>
                                <h4><b>Thank You Cards</b></h4>
                                <div className={'pt-3'}>
                                    <p>I'd like to spend sa bit more time delighting our customers.</p>
                                    <p>Sometimes we get too busy to send a card or gift. Or perhaps we get distracted
                                        and sometimes we forget! </p>
                                    <p>I'm here to help with that!</p>
                                    <p>If there is a customer that wants to send me something too, please add their ame
                                        as a to-do item. If you're able to find their physical mailing address, please
                                        add that as a comment or note.</p>
                                    <p>If there is a customer that wants to send me something too, please add their ame
                                        as a to-do item. If you're able to find their physical mailing address, please
                                        add that as a comment or note.</p>
                                    <p>Here's to more delighting!</p>
                                </div>
                                <div className={'mt-5 mb-3 py-1 px-3 small bg-mainBlue corner-radius10'}>
                                    {commentsContent.length} Comments
                                </div>
                                <CommentInfoBoard>
                                    <CommentPosting commentsContent={commentsContent}
                                                    setCommentsContent={setCommentsContent}/>
                                </CommentInfoBoard>
                                {commentsContent.map((item, i) => {
                                    return <CommentInfoBoard key={i}>
                                        <div dangerouslySetInnerHTML={{__html: item.comment}}>
                                            {/*{}*/}
                                        </div>
                                    </CommentInfoBoard>
                                })}
                            </div>
                        </CreateProjectBoardBox>


                    </div>
                    <div className={'col-md-3 bg-white pt-3'}>
                        <div className={'d-flex justify-content-around w-100'}>
                            <InputField name={'search'} label={'Search'}/>
                            <img src="/assets/images/Notifications.svg" alt=""/>
                        </div>
                        <div className={'d-flex flex-column align-items-center mt-5'}>
                            <Image w={100} h={100} className={'simple-border'}
                                   src={`http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${individualUserProfile.id}`}/>
                            <div className={'mt-3'}>
                                <b>{individualUserProfile.firstName + " " + individualUserProfile.lastName}</b>
                            </div>
                            <span className={'small'}>Owner</span>
                        </div>
                        <div className={'border-bottom d-flex mt-4 px-2 justify-content-between'}>
                            <h6><b>On my list</b></h6>
                            <span>All</span>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Update supply change on
                                    some list somewhere
                                </div>
                                <a href={''} className={'link-small pt-2'}>@projectname</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold pb-'}>Jin Tan in applies in listing
                                    <a href={''}
                                       className={'link-small font-weight-normal pt-2'}> @CFO
                                        position opening</a></div>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Thomas M. Trevino requested
                                    NDA
                                </div>
                                <a href={''} className={'link-small pt-2'}>@Selpic P1</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Jin Tan reported something
                                    at
                                </div>
                                <a href={''} className={'link-small pt-2'}>@Selpic P1</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold pb- '}>Richard M. Hobbs in Follows <a
                                    href={''} className={'link-small font-weight-normal pt-2'}> @purME</a> Project.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

InfoBoardPost.propTypes = {};

export default InfoBoardPost;