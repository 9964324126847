import React from 'react';
import InputField from "../../../App/components/InputField";
import Checkbox from "../../../App/components/Checkbox";
import Dropdown from "../../../App/components/Dropdown";
import useAdvancedSearchFilters from "../../../helpers/customHooks/useAdvancedSearchFilters";

const CompanyFields = ({setSearchFields, searchFields, setFilter}) => {
    const type = 'company'
    const initialState = {
        country: '',
        city: '',
        sector: '',
        subSector: '',
        ownership: '',
        branchesAndSubsidiaries: '',
        values: ''
    }
    const {handleChange, handleOnBlur, values} = useAdvancedSearchFilters(initialState, type, setFilter, searchFields, setSearchFields)

    return (
        <div className={'px-4 mb-2'}>
            <h6>Location</h6>
            <div className={'row mb-4 px-1'}>
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.country} name={'country'}
                                label={"Country"}/>
                </div>
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.city} name={'city'}
                                label={'City'}/>
                </div>
            </div>
            <h6>Sector</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.sector} name={'sector'}
                                label={'Sector'}/>
                </div>
                <div className="col-md-6">
                    <InputField name={'subSector'} label={'Sub-sector'} value={values.subSector} onChange={handleChange}
                                onBlur={handleOnBlur}/>
                </div>
            </div>
            <h6>Ownership</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <Dropdown options={[{name: <i>None</i>, value: ""}, {name: "Invest", value: "invest"}, {
                        name: "Business and Trade",
                        value: "businessAndTrade"
                    }]} name={'ownership'} value={values.ownership} label={"Ownership"} onChange={handleChange}
                              onBlur={handleOnBlur}/>
                </div>
            </div>
            <h6>Branches & Subsidiaries</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <Dropdown options={[{name: <i>None</i>, value: ""}, {name: "Invest", value: "invest"}, {
                        name: "Business and Trade",
                        value: "businessAndTrade"
                    }]} name={'branchesAndSubsidiaries'} value={values.branchesAndSubsidiaries}
                              label={'Branches & Subsidiaries'} onChange={handleChange} onBlur={handleOnBlur}/>
                </div>
            </div>
            <h6>Values</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <InputField name={'values'} value={values.values} label={'Values'} onChange={handleChange}
                                onBlur={handleOnBlur}/>
                </div>
            </div>
            <Checkbox className={'label-large'}><b>Account Verified</b></Checkbox>
        </div>
    );
};

CompanyFields.propTypes = {};

export default CompanyFields;