import React from 'react';
import { Link } from "react-router-dom";
import './common_styles/MenuActionIcon.scss';


const MenuActionIcon = ({ src, onClick, className, href, active, name, target, friendRequests, color, customImgHeight, margin_bot }) => {

    return (
        href ? <Link to={href} className={'pointer'} target={target}>
            <div className={(margin_bot ? 'margin_bottom ' : '') + " " + color + ' menu-action d-flex align-items-center justify-content-center red ' + className}
                // style={{ backgroundColor: 'red' }}
                onClick={onClick}>
                {/* <img src={`/assets/images/${src}.svg`} alt="" /> */}
                {/* <img src={`/assets/images/camera.svg`} alt="" /> */}
                {/* <img src={`/assets/images/aliases.svg`} height={20} alt="" /> */}

                {customImgHeight ?
                    <img src={`/assets/images/${src}.svg`} height={customImgHeight} alt="" />
                    :
                    <img src={`/assets/images/${src}.svg`} alt="" />}

{/* background: transparent linear-gradient(68deg, #1895dd 0%, #025c90 100%) 0% 0% no-repeat padding-box; */}
                <div className={'menu-icon-name'}>
                    <div className={'menu-icon-arrow'} />
                    {name}</div>
                {friendRequests && friendRequests.length ? <div style={{
                    width: 11,
                    height: 11,
                    // background: 'red',
                    // backgroundImage: "linear-gradient(to right, red, cyan)",
                    // background: "linear-gradient(68deg, #1895dd 0%, #025c90 100%)",
                    // background: "linear-gradient(68deg, #ed5e5e 0%, #d30000 100%)",
                    background: "linear-gradient(68deg, #ed5e5e 0%, #d30000 100%)",
                    position: 'absolute',
                    border: '2px solid white',
                    padding: 0,
                    right: -3,
                    top: -3,
                    borderRadius: '100%',
                }}>
                </div> : null}
            </div>
        </Link> :
            <a>
                <div className={'menu-action d-flex align-items-center justify-content-center ' + className}
                    onClick={onClick}>
                    <img src={`/assets/images/${src}.svg`} alt="" />
                    <div className={'menu-icon-name'}>
                        <div className={'menu-icon-arrow'}></div>
                        {name}</div>
                </div>
            </a>
    );
};

MenuActionIcon.propTypes = {};

export default MenuActionIcon;