import React from 'react';
import InfoBoxSection from "../../Common/InfoBoxSection";
import ProgressBar from "../../../App/components/ProgressBar";
import SubsidiaryEditing from "../../Common/SubsidiaryEditing";
import BranchesEditing from "../../Common/BranchesEditing";
import FormBoxSection from "../../Common/FormBoxSection";

const BranchesAndSubsidiary = props => {
    return (
        <div>
            {/*<InfoBoxSection title={'Create your Company Profile'}>*/}
            {/*    /!*<p>The following information will be displayed in your profile please make sure that is*!/*/}
            {/*    /!*    correct.<br></br> Any*!/*/}
            {/*    /!*    information changes will result in new verification. <br></br>Until verified, old information will*!/*/}
            {/*    /!*    be*!/*/}
            {/*    /!*    displayed.</p>*!/*/}
            {/*    /!*<ProgressBar complete={15}/>*!/*/}
            {/*</InfoBoxSection>*/}

            {/* <SubsidiaryEditing/> */}
            <BranchesEditing/>
        </div>
    );
};

BranchesAndSubsidiary.propTypes = {};

export default BranchesAndSubsidiary;