import React from 'react';
import InputField from "../../../App/components/InputField";
import Checkbox from "../../../App/components/Checkbox";
import Dropdown from "../../../App/components/Dropdown";
import useAdvancedSearchFilters from "../../../helpers/customHooks/useAdvancedSearchFilters";

const FreelanceProfileFields = ({setSearchFields, searchFields, setFilter}) => {
    const type = 'freelanceProfile'
    const initialState = {
        country: '',
        city: '',
        language: '',
        languageLevel: '',
        sector: '',
        subSector: '',
        skills: '',
        skillsLevel: '',
        institution: '',
        subject: ''
    }
    const {handleChange, handleOnBlur, values} = useAdvancedSearchFilters(initialState, type, setFilter, searchFields, setSearchFields)

    return (
        <div className={'px-4 mb-2'}>
            <h6>Location</h6>
            <div className={'row mb-4 px-1'}>
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.country} name={'country'}
                                label={"Country"}/></div>
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.city} name={'city'}
                                label={'City'}/>
                </div>
            </div>
            <h6>Language</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.language}
                                name={'language'} label={'Language'}/>
                </div>
                <div className="col-md-6">
                    <Dropdown onBlur={handleOnBlur} onChange={handleChange} value={values.languageLevel} options={[]}
                              name={'languageLevel'}
                              label={'Level'}/>
                </div>
            </div>
            <h6>Sector</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.sector} name={'sector'}
                                label={'Sector'}/>
                </div>
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.subSector}
                                name={'subSector'}
                                label={'Sub-sector'}/>
                </div>
            </div>
            <h6>Skills</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.skills} name={'skills'}
                                label={'Skill'}/>
                </div>
                <div className="col-md-6">
                    <Dropdown onBlur={handleOnBlur} onChange={handleChange} value={values.skillsLevel} options={[]}
                              name={'skillsLevel'} label={'Level'}/>
                </div>
            </div>
            <h6>Education</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.institution}
                                name={'institution'} label={'Institution'}/>
                </div>
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.subject}
                                name={'subject'} label={'Subject'}/>
                </div>
            </div>
            <Checkbox className={'label-large'}><b>Account Verified</b></Checkbox>
            <Checkbox className={'label-large'}><b>Portfolio</b></Checkbox>
        </div>
    );
};

FreelanceProfileFields.propTypes = {};

export default FreelanceProfileFields;