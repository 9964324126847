import React, {useState} from 'react';
import Modal from "../../App/components/Modal";

const EditModeModal = ({children}) => {
    const [openModal, setOpenModal] = useState(false)

    return (
        <>
            <a className={'pointer'} onClick={() => setOpenModal(true)}>
                <i className="fas fa-pencil-alt mb-2 ml-3 color-mainBlue"> </i>
            </a>
            {openModal && <Modal onClick={() => setOpenModal(false)}>
                {children}
            </Modal>}
        </>
    );
};

EditModeModal.propTypes = {};

export default EditModeModal;