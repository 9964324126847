import React from 'react';
import InputField from "../../App/components/InputField";
import Checkbox from "../../App/components/Checkbox";
import Button from "../../App/components/Button";
import {useHistory} from 'react-router'
import useForm from "../../helpers/customHooks/useForm";
import validate from "../../helpers/validateRegistration";
import {useDispatch} from "react-redux";
import {register} from "../../App/store/user";


const RegisterForm = ({className}) => {
        const history = useHistory()
        const dispatch = useDispatch()
        const {handleChange, handleSubmit, values, errors} = useForm({
            email: "",
            password: "",
            dateOfBirth: "",
            firstName: "",
            lastName: ""
        }, submit, validate)

        function submit() {
            dispatch(register(values))
        }

        return (
            <div className={className}>
                <form action="" onSubmit={handleSubmit}>
                    <div className="row justify-content-center py-2 py-md-5">
                        <div className="col-md-8 col-lg-6 col-sm-12">
                            <div className="text-center">
                                <h5 className={'color-mainBlue'}>Log In Information</h5>
                                <p>These will be your login information. Your email is set to private by default.</p>
                                <div className="px-4 mb-3 text-left">
                                    <InputField className={'input-action'} name={'email'} label={'Email'}
                                                onChange={handleChange}
                                                value={values.email}/>
                                    {errors.email && <span className={'error-message pl-2'}>{errors.email}</span>}
                                    <InputField className={'input-action'} name={'password'} type={'password'}
                                                label={'Password'}
                                                onChange={handleChange}
                                                value={values.password}/>
                                    {errors.password && <span className={'error-message pl-2'}>{errors.password}</span>}
                                </div>
                                <h5 className={'color-mainBlue'}>Personal Information</h5>
                                <p>The following information will be displayed in your profile please make sure that is
                                    correct. Your identity is very important to the network.</p>
                                <div className="px-4 text-left">
                                    <InputField className={'input-action'} name={'firstName'} label={'First Name'}
                                                onChange={handleChange}
                                                value={values.firstName}/>
                                    {errors.firstName && <span className={'error-message pl-2'}>{errors.firstName}</span>}
                                    <InputField className={'input-action'} name={'lastName'} label={'Last Name'}
                                                onChange={handleChange} value={values.lastName}/>
                                    {errors.lastName && <span className={'error-message pl-2'}>{errors.lastName}</span>}
                                    <InputField className={'input-action'} name={'birthday'} label={'Date of Birth'}
                                                type='date' onChange={handleChange}/>
                                </div>
                                <div className="px-4 mt-3">
                                    <Checkbox>I agree with the <a href="">Terms Of Service</a> and <a href="">Privacy
                                        Policy</a>.</Checkbox>
                                    <Button type={'submit'} className={'btn-block btn-blue mt-3'}>Register</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
;


RegisterForm.propTypes = {};

export default RegisterForm;