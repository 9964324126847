import React from 'react';
import EducationEditing from "./EducationEditing";
import LicencesAndCertificates from "./LicencesAndCertificates";

const Qualification = props => {
    return (
        <div>
            <EducationEditing/>
            <LicencesAndCertificates/>
        </div>
    );
};

Qualification.propTypes = {};

export default Qualification;