import React from 'react';
import ActivityItem from "./ActivityItem";
import Button from "../../../App/components/Button";

const History = props => {
    return (
        <div className={'corner-radius5 simple-shadow p-4 bg-white mt-5'}>
            <h4><b>History</b></h4>
            <div className="d-flex mt-4 mb-3">
                <div className="col-md-4">Status</div>
                <div className="col-md-1">Hours</div>
                <div className="col-md-3">Date</div>
            </div>
            <div>
                <ActivityItem status={'Awaiting Approval'}
                              statusColor={'greenyellow'}
                              hours={40}
                              className={'simple-border'}>
                    <Button className={'btn-blue'}>Initiate Contract</Button>
                </ActivityItem>
                <ActivityItem status={'Contract Signed'}
                              statusColor={'greenyellow'}
                              hours={30}
                              className={'simple-border'}>
                    <Button className={'btn-blue'}>Go to projects</Button>
                </ActivityItem>
                <ActivityItem status={'Canceled'}
                              statusColor={'red'}
                              hours={35}
                              className={'simple-border'}>
                </ActivityItem>
            </div>
        </div>
    );
};

History.propTypes = {};

export default History;