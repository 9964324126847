import React, {useState} from 'react';
import Image from "../../../App/components/Image";
import Button from "../../../App/components/Button";
import Modal from "../../../App/components/Modal";
import AddParties from "./AddParties";

const ContractingParties = props => {
    const [openModalMain, setOpenModalMain] = useState(false)
    const [openModalSupp, setOpenModalSupp] = useState(false)
    return (
        <div className={'px-3 pt-4'}>
            <div className={'d-flex justify-content-between align-items-center mb-3'}>
                <h5>Main Parties</h5>
                <Button className={'btn-blue small-btn'} onClick={() => setOpenModalMain(true)}>Add to Main</Button>
            </div>
            <div className={'row'}>
                <div className={'col-md-3 d-flex small'}><Image className={'border mr-2'} w={40} h={40}
                                                                src={'/assets/images/logo.svg'}
                /> Direct Ventures
                </div>
                <div className={'col-md-2 small'}>
                    <span style={{color: '#9a9a9a'}} className={'color-gray'}>Status</span>
                    <div>Online</div>
                </div>
                <div className={'col-md-2 small'}>
                    <span style={{color: '#9a9a9a'}}
                          className={'color-gray'}>Signature</span>
                    <div>N/A</div>
                </div>
                <div className={'col-md-2 small'}>
                    <span style={{color: '#9a9a9a'}}
                          className={'color-gray'}>Last Edit</span>
                    <div>10.10.2020</div>
                </div>
                <div className={'col-md-2 small'}>
                    <span style={{color: '#9a9a9a'}}
                          className={'color-gray'}>Permission</span>
                    <div>Can Sign</div>
                </div>
                <div className={'col-md-1'}>
                    <div className={'pointer'} style={{color: 'red', fontSize: 28}}>&times;</div>
                </div>
            </div>

            <div className={'d-flex justify-content-between align-items-center mt-5 mb-3'}>
                <h5>Supporting Parties</h5>
                <Button className={'btn-blue small-btn'} onClick={() => setOpenModalSupp(true)}>Add to
                    Supporting</Button>
            </div>
            <div className={'row'}>
                <div className={'col-md-3 d-flex small'}><Image className={'border mr-2'} w={40} h={40}
                                                                src={'/assets/images/logo.svg'}
                /> Direct Ventures
                </div>
                <div className={'col-md-2 small'}>
                    <span style={{color: '#9a9a9a'}} className={'color-gray'}>Status</span>
                    <div>Online</div>
                </div>
                <div className={'col-md-2 small'}>
                    <span style={{color: '#9a9a9a'}}
                          className={'color-gray'}>Signature</span>
                    <div>N/A</div>
                </div>
                <div className={'col-md-2 small'}>
                    <span style={{color: '#9a9a9a'}}
                          className={'color-gray'}>Last Edit</span>
                    <div>10.10.2020</div>
                </div>
                <div className={'col-md-2 small'}>
                    <span style={{color: '#9a9a9a'}}
                          className={'color-gray'}>Permission</span>
                    <div>Can Sign</div>
                </div>
                <div className={'col-md-1'}>
                    <div className={'pointer'} style={{color: 'red', fontSize: 28}}>&times;</div>
                </div>
            </div>
            {openModalMain && <Modal onClick={() => setOpenModalMain(false)}>
                <AddParties title="Add Main Parties" setOpenModal={setOpenModalMain}/>
            </Modal>}

            {openModalSupp && <Modal onClick={() => setOpenModalSupp(false)}>
                <AddParties title="Add Supporting Parties" setOpenModal={setOpenModalSupp}/>
            </Modal>}
        </div>
    );
};

ContractingParties.propTypes = {};

export default ContractingParties;