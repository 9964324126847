
const k = require('./keys');

let Create_New_Post = `Create New Post`;
let Direct_Ventures = `Direct Ventures`;
let Network =  `Network`;
let Opportunities = `Opportunities`;
let Aliases = `Aliases`;
let Due_Diligence =`Due Diligence`;

export {Create_New_Post,
  Direct_Ventures,
Network,
    Opportunities,
    Aliases,
    Due_Diligence
}