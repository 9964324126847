import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";


const XxlDiv = styled.div`
  display:none;
  li, a {
    display:none;
  }

  @media (max-width: 768px) {
    display: flex;
    z-index: 9999;
    flex-flow: column nowrap;
    background: transparent linear-gradient(60deg, #1d4362 0%, #0D2538 100%) 0% 0% no-repeat padding-box;
    position: fixed;
    padding-top: 3.5rem;

    top: 0;
    left: 0px;
    height: 101vh;
    width: 80%;
   

    li, a {
      display: list-item;
      padding: 18px 20px;
      color: #fff;

      text-align: right;
      float: right;
    }
  }
`;
const Div = styled.div`
 display: none;

 @media (max-width: 768px) {
    display: flex;
    z-index: 9000;
    flex-flow: column nowrap;
    background-color: transparent;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
    top: 0;
    left: -100%;
    height: 101vh;
    width: 100vw;
    transition: transform 0.3s ease-in-out;
    
  }
`;

const RightNav = ({ open, click, className }) => {
  return (
    <Div className={'hi'} open={open} onClick={click}>
      <XxlDiv className={className}>
        <Link onClick={click} className={'hi'} to='/' >Home</Link>
        <Link onClick={click} className={'hi'} to='/all-alias'>Alias</Link>
        <Link onClick={click} className={'hi'} to='/opportunities' >Opportunities</Link>
        <Link onClick={click} className={'hi'} to='/due-diligence' >Due Diligence</Link>
        <Link onClick={click} className={'hi'} to='/network' >Networking</Link>
      </XxlDiv>
    </Div>
  )
}

export default RightNav

// if u want semi transparent background:
// background-color: rgba(255,255,255,0.3);
