import React from 'react';
import ProfileInfoBoxSection from "../../Common/ProfileInfoBoxSection";

const LicencesAndCertificates = props => {
    return (
        <ProfileInfoBoxSection className={'mt-5'} title={"Licences and Certificates"}>
            <div className="profile-info">
                <div>
                    <span>Institution</span>
                    <p>Oxford University</p>
                    <span>Course</span>
                    <p>Criminal Law • Business Law</p>
                    <span>Completion</span>
                    <p>September 2005 • May 2008</p>
                </div>
            </div>
        </ProfileInfoBoxSection>
    );
};

LicencesAndCertificates.propTypes = {};

export default LicencesAndCertificates;