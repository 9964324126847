import React, { useEffect, useState } from 'react';

import '../styles/FormBoxSection.scss'
import { cutString } from '../../helpers/helpers_2';

const ProfileInfoBoxSection = ({ title, children, className, custom }) => {
    //-------------------------------------------------------------------
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    //-------------------------------------------------------------------


    if (custom === 'Overview' || 'Skills') {
        // let margin_bottom_small = (custom === 'Overview' ? 'margin-bottom-small' : '')
        return (
            <section className={'d-flex flex-wrap form-box profile-box-smaller-padding corner-radius10 ' + className}>
                {title && <div className="col-md-12 text-left">
                    <div className={'form-title mb-0'}>{windowSize.width < 768 ? cutString(title, 20) : cutString(title, 42)}</div>
                </div>}
                <div className="col-md-12 d-flex flex-column">
                    {children}
                </div>
            </section>
        );
    } else {
        return (
            <section className={'d-flex flex-wrap form-box profile-box corner-radius10 ' + className}>
                {title && <div className="col-md-12 text-left">
                    <div className={'form-title mb-4'}>{title}</div>
                </div>}
                <div className="col-md-12 d-flex flex-column">
                    {children}
                </div>
            </section>
        );
    }
};

ProfileInfoBoxSection.propTypes = {};

export default ProfileInfoBoxSection;