import React, { useEffect, useRef, useState } from 'react';
import '../styles/Header.scss'
import { useClickAway } from "react-use";
import '../styles/MenuBar.scss'
import ManageAccountsMenu from "./ManageAccountsMenu";
import Image from "../../App/components/Image";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccount, fetchAlerts } from "../../App/store/user";
import HeaderMenuContainer from "./HeaderMenuContainer";
import SearchHeaderMenu from "./SearchHeaderMenu";
import ChatHeaderMenu from "./ChatHeaderMenu";
import NotificationHeaderMenu from "./NotificationHeaderMenu";
import chatapi from "../../App/config/chatapi";


import Burger from './BurgerMenu/Burger';
import { useHistory, useLocation } from "react-router";


import k from "./../../i18n/keys";
import i18next from '../../i18n/init';
import { useTranslation } from 'react-i18next'

// import AddToNetworkMessage from "../components/AddToNetworkMessage";
import AddToNetworkMessage from "../../Dashboard/Network/components/AddToNetworkMessage";
import DeleteUserMessage from "./DeleteUserMessage";
import { canShowMessage } from '../../App/store/mixed'


// directventures-frontend\src\Dashboard\Network\components\AddToNetworkMessage.js



// const Nav = styled.nav`
//   width: 100%;
//   height: 55px;
//   border-bottom: 2px solid #f1f1f1;
//   padding: 0 20px;
//   display: flex;
//   justify-content: space-between;

//   .logo {
//     padding: 15px 0;
//   }
// `

const Header = props => {
    const [activeSearch, setActiveSearch] = useState(false)
    const [activeChat, setActiveChat] = useState(false)
    const [activeNotifications, setActiveNotifications] = useState(false)
    const [openManageMenu, setOpenManageMenu] = useState(false)
    const [timeout, setTimeout] = useState(null);
    const [activeAlias, setActiveAlias] = useState()
    const active = useSelector(state => state.aliases.data.activeAlias || null)
    const ref = useRef(null);
    const refSearch = useRef(null);
    const refChat = useRef(null);
    const refNotification = useRef(null);
    const dispatch = useDispatch()
    const { data, isLoading } = useSelector(state => state.user.account)
    const { individualUserProfile, locations } = data

    //----------------------------------
    const [advancedSearch, setAdvancedSearch] = useState('')
    const history = useHistory()
    const location = useLocation()

    //----------------------------------

    const displayMessage = useSelector(state => state.mixed.message)
    const [showMessage, setShowMessage] = useState(displayMessage.show)

    const deleteUser = useSelector(state => state.mixed.deleteUserMessage)


    //----------------------------------
    // const [englishLanguage, setEnglishLanguage] = useState(true)
    const [englishLanguage, setEnglishLanguage] = useState(localStorage.getItem('lng') == 'en' ? true : false)
    //----------------------------------
    const { t } = useTranslation();

    // 'zh_Hans', 'en', 'de'
    const changeLanguage = (language) => {
        localStorage.setItem('lng', language);
        i18next.changeLanguage(language)
    }
    const alerts = useSelector(state => state.user.alert_num)

    useEffect(() => {
        setActiveAlias(active)
    }, [active])
    useEffect(() => {
        dispatch(fetchAccount())
    }, [])

    useEffect(() => {
        dispatch(fetchAlerts())


        const interval = setInterval(() => {
            dispatch(fetchAlerts())
        }, 10000);
        return () => clearInterval(interval);


    }, [alerts])

    useEffect(() => {
        setShowMessage(displayMessage.show)
    }, [displayMessage])


    const loginChat = () => {
        console.log(localStorage.getItem('chat-token'))
        let response = document.querySelector('iframe').contentWindow.postMessage({
            externalCommand: 'login-with-token',
            token: localStorage.getItem('chat-token')
        }, '*');
        console.log(response)
    }

    useClickAway(ref, (event) => {
        if (!(refSearch.current && refSearch.current.contains(event.target)) && !(refChat.current && refChat.current.contains(event.target)) && !(refNotification.current && refNotification.current.contains(event.target))) {
            setActiveSearch(false);
            setActiveNotifications(false)
            setActiveChat(false)
        }
    });

    const handleClick = () => {
        if (location.pathname !== '/advanced-search')
            history.push(`/advanced-search?search=${advancedSearch}`)
        else history.push(`/advanced-search?search=${advancedSearch}&modal=true`)
    }

    if (isLoading)
        return <div></div>

    return (
        <>
            {/* <Nav>
                <Burger />
            </Nav> */}

            {/* <div onClick={() => { console.log(displayMessage) }} >displayMessage</div>
            <div onClick={() => { console.log(deleteUser) }} >displayMessage</div> */}


            {showMessage && <AddToNetworkMessage name={displayMessage.text} type={displayMessage.type} />}
            {deleteUser.show && <DeleteUserMessage id={deleteUser.id} />}

            <div className="d-flex justify-content-center header-container" >
                <iframe style={{ height: 0, width: "0%" }}
                    src={`http://devchat.devdv.ekonet.mk/`}
                    frameBorder="0" />

                <div className={'header d-flex justify-content-between'}>

                    <div className={'logo d-flex align-items-center'}>
                        <img className={'pointer'}
                            src={`/assets/images/logo.svg`} alt="" />
                        <div className={'ml-3 logo-text'}>{t(k.Direct_Ventures)}</div>
                        {/* <div className={'ml-3 logo-text'}>DIRECT VENTURES</div> */}
                    </div>

                    <div className={'d-flex align-items-center'}>
                        <Burger />
                    </div>
                    <div className={'header-actions d-flex align-items-center '}>


                        <div className="header-icons d-flex header-icons-right-container align-item-center">
                            {/*  */}
                            <div className="d-flex align-item-center" onClick={handleClick}
                            ><img src="/assets/images/Search-blue.svg" alt="" /></div>

                            <div className="d-flex align-item-center chat-icon-div" ref={refChat} onClick={() => {
                                setActiveSearch(false);
                                setActiveChat(!activeChat)
                                loginChat()
                                setActiveNotifications(false)

                            }}>

                                {/* <div className="d-flex align-item-center chat-icon-div"> */}
                                <img className={'chat-icon-img'} src="/assets/images/Chat-white.svg" alt="" />
                                {/* </div> */}
                            </div>
                            {alerts > 0 ? <span style={{ color: 'red' }}>{alerts}</span> : null}

                            <div className="d-flex align-item-center conference-icon-div" ref={refChat} onClick={() => {
                                let r = (Math.random() + 1).toString(36).substring(7);
                                window.open('https://meet.jit.si/dventures-' + r, '_blank')
                            }}>
                                {/* <div className="d-flex align-item-center chat-icon-div"> */}
                                <img className={'conference-icon-img'} src="/assets/images/Camera.svg" alt="" />
                                {/* </div> */}
                            </div>

                            {/* https://www.svgrepo.com/vectors/united-arab-emirates/ */}
                            {/* https://flagicons.lipis.dev/ */}
                            {/* us ae gb gb-eng */}
                            <div onClick={() => { setEnglishLanguage(!englishLanguage) }} className="d-flex align-item-center language-icon-div" ref={refChat} >
                                {/* <div className="d-flex align-item-center chat-icon-div"> */}
                                {englishLanguage ?
                                    // <img onClick={() => changeLanguage('de')} className={'language-icon-img'} src={`/assets/images/ae.svg`} alt="" /> :
                                    <img onClick={() => changeLanguage('ar')} className={'language-icon-img'} src={`/assets/images/flags/gb_2.svg`} alt="" /> :
                                    <img onClick={() => changeLanguage('en')} className={'language-icon-img'} src={`/assets/images/flags/ae_2.svg`} alt="" />

                                }
                                {/* </div> */}
                            </div>
                            {/*  */}

                            {/* chat icon - work */}
                            {/* <div className="fixed-chat-icon-container">
                                <div className="fixed-chat-icon-div">
                                    <img className={'fixed-chat-icon-img'} src="/assets/images/Chat-white.svg" alt="" />
                                </div>
                            </div> */}

                            {/*  */}
                            {/* <div ref={refSearch} onClick={() => {
                                setActiveSearch(!activeSearch);
                                setActiveChat(false)
                                setActiveNotifications(false)
                            }}><img src="/assets/images/Search.svg" alt="" /></div> */}
                            {/* <div ref={refChat} onClick={() => {
                                setActiveSearch(false);
                                setActiveChat(!activeChat)
                                setActiveNotifications(false)
                            }}><img src="/assets/images/Chat.svg" alt="" /></div> */}
                            {/* <div style={{ position: 'relative' }} ref={refNotification} onClick={() => {
                                setActiveSearch(false);
                                setActiveChat(false)
                                setActiveNotifications(!activeNotifications)
                            }}> */}
                            {/*{friendRequests.length ? <div style={{*/}
                            {/*    width: 12,*/}
                            {/*    height: 12,*/}
                            {/*    background: 'red',*/}
                            {/*    position: 'absolute',*/}
                            {/*    padding: 0,*/}
                            {/*    left: 0*/}
                            {/*}}>*/}
                            {/*</div> : null}*/}
                            {/* <img src="/assets/images/Notifications.svg" alt="" /></div> */}
                        </div>
                        {openManageMenu && <ManageAccountsMenu activeAlias={activeAlias} setActiveAlias={setActiveAlias}
                            setOpenManageMenu={setOpenManageMenu} />}

                        <Image
                            src={activeAlias ? `http://directventures.ekonet.mk:7777/api/auth/alias-profile-image/${activeAlias.id}` : `http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${individualUserProfile.id}`}
                            w={35} h={35}
                            className="menu-profile-box-img simple-shadow rounded-circle pointer"
                            onClick={() => setOpenManageMenu(true)}>
                        </Image>
                    </div>
                    {/* <HeaderMenuContainer ref={ref}>
                        <SearchHeaderMenu />
                    </HeaderMenuContainer> */}
                    {activeSearch && <HeaderMenuContainer ref={ref}>
                        <SearchHeaderMenu />
                    </HeaderMenuContainer>}
                    {activeChat && <HeaderMenuContainer ref={ref}>
                        <ChatHeaderMenu />
                    </HeaderMenuContainer>}
                    {activeNotifications && <HeaderMenuContainer ref={ref}>
                        <NotificationHeaderMenu
                            individualUserProfile={individualUserProfile} />
                    </HeaderMenuContainer>}
                </div>
            </div>
        </>
    )
        ;
};

Header.propTypes = {};

export default Header;

// return (
//     <Nav>
//       <Burger />
//     </Nav>
//   )