import React from 'react';
// import '../styles/InputAndActionContainer.scss'
import ICCss from './styles/InputContainer.module.scss'

const InputAndActionContainer = ({ children, className }) => {
    return (
        <div className={ICCss.main_container + " "} >
            {children}
        </div>
    );
};

InputAndActionContainer.propTypes = {};

export default InputAndActionContainer;