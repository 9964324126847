import React, {useEffect} from 'react';
import Button from "../../../App/components/Button";
import InputField from "../../../App/components/InputField";
import ActivityItem from "../components/ActivityItem";
import Image from "../../../App/components/Image";
import MenuHire from "../components/MenuHire";
import {useDispatch, useSelector} from "react-redux";
import {fetchAccount} from "../../../App/store/user";
import Loader from "../../Common/Loader";

const WorkHistory = props => {
    const {data, isLoading} = useSelector(state => state.user.account)
    const {individualUserProfile} = data
    const dispatch = useDispatch()

    useEffect(() => {
        if (!Object.keys(data).length)
            dispatch(fetchAccount())
    }, [])


    if (isLoading)
        return <Loader/>
    return (
        <>
            <div className="">
                <MenuHire/>
            </div>
            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container d-flex p-0'} style={{background: '#f5f5f5'}}>
                    <div className="mx-auto pt-5 col-md-8 px-0">
                        <div className={'corner-radius5 simple-shadow px-4 pt-4 pb-3 bg-white'}>
                            <h4><b>Work History</b></h4>
                            <div className="d-flex justify-content-between mt-4">
                                <div className={'d-flex align-items-center'}>
                                    <Button className={'btn-blue mr-4'}>Initiate Project</Button>
                                    <InputField label={'Search'}/>
                                </div>
                                <div className={'d-flex align-items-center'}>View by: <b>My offers</b></div>
                            </div>
                            <div className="d-flex mt-5">
                                <div className="col-md-3 small">
                                    From
                                </div>
                                <div className="col-md-2 small ">
                                    Status
                                </div>
                                <div className="col-md-1 small">Hours</div>
                                <div className="col-md-3 small">
                                    Date
                                </div>
                            </div>
                        </div>
                        <div className={'mt-4 pt-2'}>
                            <ActivityItem name={'Gretchen White'}
                                          src={'https://images.squarespace-cdn.com/content/v1/5d96fc780e85b877a919f4f2/1570777847921-A6W51WBD9SWVQYVPGJA4/ke17ZwdGBToddI8pDm48kMXRibDYMhUiookWqwUxEZ97gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0luUmcNM2NMBIHLdYyXL-Jww_XBra4mrrAHD6FMA3bNKOBm5vyMDUBjVQdcIrt03OQ/Angela-16.jpg'}
                                          status={'Awaiting Approval'}
                                          statusColor={'greenyellow'}
                                          hours={40}
                                          checkbox
                            >
                                <Button className={'mr-3 btn-blue'}>Initiate Contact</Button>

                            </ActivityItem>
                            <ActivityItem name={'Lio Yong'}
                                          src={'https://saint313.com/wp-content/uploads/2018/04/pexels-photo-428341-1024x683.jpeg'}
                                          status={'Approved'}
                                          statusColor={'limegreen'}
                                          hours={40}
                                          checkbox
                            >
                                <b>Contract Signed</b>

                            </ActivityItem>
                            <ActivityItem name={'Mustafa Gianni'}
                                          src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3YbaqxuMYrNcSKgubbBpVQ1oInLahxstezw&usqp=CAU'}
                                          status={'Canceled'}
                                          statusColor={'red'}
                                          checkbox
                                          hours={35}>
                                <b>Project Ended</b>

                            </ActivityItem>
                            <ActivityItem name={'Silvia Looper'}
                                          src={'https://mlk5vm21jtgn.i.optimole.com/uMYairY.FNSH~38b5/w:300/h:auto/q:75/https://artofheadshots.com/wp-content/uploads/2018/11/001Elizabeth-WEB.jpg'}
                                          status={'Awaiting Approval'}
                                          statusColor={'greenyellow'}
                                          checkbox
                                          hours={30}>
                                <b>Project Ended</b>
                            </ActivityItem>
                            <ActivityItem name={'Jolanda Trace'}
                                          src={'/assets/images/Person1.png'}
                                          status={'Awaiting Approval'}
                                          statusColor={'greenyellow'}
                                          hours={40}
                                          checkbox
                            >
                                <Button className={'mr-3 btn-blue'}>Initiate Contact</Button>

                            </ActivityItem>
                            <ActivityItem name={'Lilly Moore'}
                                          src={'/assets/images/Person2.png'}
                                          status={'Approved'}
                                          statusColor={'limegreen'}
                                          hours={40}
                                          checkbox
                            >
                                <b>Contract Signed</b>

                            </ActivityItem>
                            <ActivityItem name={'Ron Dallas'}
                                          src={'/assets/images/Person3.png'}
                                          status={'Canceled'}
                                          statusColor={'red'}
                                          checkbox
                                          hours={35}>
                                <b>Project Ended</b>

                            </ActivityItem>
                            <ActivityItem name={'David Green'}
                                          src={'/assets/images/Person4.png'}
                                          status={'Awaiting Approval'}
                                          statusColor={'greenyellow'}
                                          checkbox
                                          hours={30}>
                                <b>Project Ended</b>
                            </ActivityItem>
                        </div>
                    </div>
                    <div className={'col-md-3 bg-white pt-3'}>
                        <div className={'d-flex justify-content-around w-100'}>
                            <InputField name={'search'} label={'Search'}/>
                            <img src="/assets/images/Notifications.svg" alt=""/>
                        </div>
                        <div className={'d-flex flex-column align-items-center mt-5'}>
                            <Image w={100} h={100} className={'simple-border'}
                                   src={`http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${individualUserProfile.id}`}/>
                            <div className={'mt-3'}>
                                <b>{individualUserProfile.firstName + " " + individualUserProfile.lastName}</b>
                            </div>
                            <span className={'small'}>Owner</span>
                        </div>
                        <div className={'border-bottom d-flex mt-4 px-2 justify-content-between'}>
                            <h6><b>Notifications</b></h6>
                            <span>All</span>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Update supply change on
                                    some list somewhere
                                </div>
                                <a href={''} className={'link-small pt-2'}>@projectname</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold pb-'}>Jin Tan in applies in listing
                                    <a href={''}
                                       className={'link-small font-weight-normal pt-2'}> @CFO
                                        position opening</a></div>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Thomas M. Trevino requested
                                    NDA
                                </div>
                                <a href={''} className={'link-small pt-2'}>@Selpic P1</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Jin Tan reported something
                                    at
                                </div>
                                <a href={''} className={'link-small pt-2'}>@Selpic P1</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold pb- '}>Richard M. Hobbs in Follows <a
                                    href={''} className={'link-small font-weight-normal pt-2'}> @purME</a> Project.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

WorkHistory.propTypes = {};

export default WorkHistory;