import {useEffect, useState} from 'react';

const useForm = (fields, callback, validate) => {
    const [values, setValues] = useState(fields)
    const [errors, setErrors] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isEdited, setIsEdited] = useState(false)
 
    const handleChange = (event, index, type) => {
        // const {name, value} = event.target; // original
        let {name, value} = event.target;
        
        if(type === 'date') value = new Date(value).getTime() // mine added
        if(type === 'number') value = parseInt(value) // mine added
        
        // if(isDate) value = new Date(value).getTime() // mine added
        
        
        if (index !== null && index !== undefined && !index.hasOwnProperty('props')) {
            const array = [...values];
            const updatedObj = {...array[index], [name]: value};
            const updatedValues = [
                ...array.slice(0, index),
                updatedObj,
                ...array.slice(index + 1),
            ];
            setValues(updatedValues)
            return setIsEdited(true)
        }
        setValues({
            ...values,
            [name]: value
        })
        return setIsEdited(true)
    }


    const handleSubmit = event => {
        event.preventDefault()
        setErrors(validate(values))
        setIsSubmitting(true)
    }


    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback()
        }
    }, [errors])

    return {
        handleChange,
        handleSubmit,
        values,
        setValues,
        errors,
        isEdited,
        setIsEdited
    }
};

export default useForm;