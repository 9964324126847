import React, { useState, useEffect } from 'react'
import DdscCss from './styles/DueDiligenceSharedComponent.module.scss';
import api from '../../../App/config/api';

const DueDiligenceSharedComponent = () => {

    const [aliases, setAliases] = useState([])
    const [showAliases, setShowAliases] = useState(true)


    const getAliases = async () => {
        try {
            const res = await api.get('/api/aliases')
            setAliases(res.data)
        } catch (e) {
            return console.error(e.message);
        }
    }

    useEffect(() => {
        getAliases()
    }, [])


    return (
        // <div className='bor-o '>DueDiligenceSharedComponent</div>

        <div className={'row'}>
            {/* <div className={"col-md-9 d-flex justify-content-end " + DdscCss.left_big_Container}> */}
            <div className={"col-md-10 " + DdscCss.left_big_Container}>
                <div className={DdscCss.left_big_div} >
                    <div className={DdscCss.welcome_div} >
                        <div className={DdscCss.welcome}>Welcome Jane.</div>
                    </div>
                    <div className={DdscCss.anti_money_laundry_wrapper}>
                        <div className={DdscCss.anti_money_laundry_text}>Anti Money Laundry system provides protection</div>
                        <div className={DdscCss.anti_money_laundry_text}>& help to grow your business</div>
                    </div>

                    <div className={DdscCss.check_network_connections_wrapper}>
                        <div className={DdscCss.check_network_connections}>Here you can check your network connections if they are eligible and legal </div>
                        <div className={DdscCss.check_network_connections}>You can do a check on individuals or companies that are in your network or outside. You can invite a user to join Direct Ventures so you can do a AML check</div>

                        {/* <div className={'bor-o ' + DdscCss.check_network_connections}>Here you can check your network connections if they are eligible and legal</div> */}
                        {/* <div className={'bor-o ' + DdscCss.check_network_connections}>You can do a check on individuals or companies that are in your network or outside. You can invite usre to</div> */}
                        {/* <div className={'bor-o ' + DdscCss.check_network_connections}>join Direct Ventures so you can do a AML check</div> */}
                    </div>

                    <div className={DdscCss.you_have_permission_for_this_alias_wrapper}>
                        <div className={DdscCss.you_have_permission_img_div}> <img className={DdscCss.you_have_permission_img} src="/assets/images/check_mark_1.svg" alt="" /> </div>
                        <div className={DdscCss.you_have_permission_text_div}><div className={DdscCss.you_have_permission_text}>You have permission for this alias</div></div>
                    </div>
                    <div className={DdscCss.you_have_purchased_div} >
                        <div className={DdscCss.you_have_purchased}>You have purchased one of our packages. See your remaining transactions in the packages section.</div>
                    </div>
                </div>
            </div>
            <div className={"col-md-2 " + DdscCss.right_big_Container}>
                <div className={DdscCss.right_big_div}>
                    <div className={DdscCss.get_alias_div} onClick={() => setShowAliases(!showAliases)}>
                        <div className={DdscCss.get_alias_text}>Beco Capital</div> <img className={DdscCss.arrow_img} src={`assets/images/angle-small-${showAliases ? 'down' : 'left'}.svg`} alt="" />
                    </div>

                    {showAliases && aliases.length && <div className={DdscCss.all_alias_wraper}>
                        {aliases.map((x) => {
                            return (
                                <div className={DdscCss.all_alias_div} onClick={() => console.log(x)}>
                                    <div className={DdscCss.left_list_div}>
                                        <div className={DdscCss.alias_picture}><img className={DdscCss.alias_img} src='/assets/images/avatar-profile.svg' alt="" /></div>
                                        <div className={DdscCss.alias_name_div}>
                                            <div className={DdscCss.alias_name_wraper}>
                                                <div className={DdscCss.alias_name} >{x.name}</div>
                                                <div className={DdscCss.alias_small_text} >{x.verified ? 'verified' : 'not verified'}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default DueDiligenceSharedComponent