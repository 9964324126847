import React from 'react';
import ProfileInfoBoxSection from "../../Common/ProfileInfoBoxSection";

const Alias = props => {
    return (
        <ProfileInfoBoxSection className={''} title={"Alias"}>
            <div className="profile-info row">
                <div className={'col-md-6 d-flex flex-wrap'}>
                    <div className={'col-md-5'}>
                        <img src="https://via.placeholder.com/150" className={'w-100'} alt=""/>
                    </div>
                    <div className={'col-md-7'}>
                        <p>Apple Inc</p>
                        <span>Multinational Technology Company</span>
                        <span>USA • Cupertino • California</span>
                        <span>Company • Public • Global</span>
                        <p><a href="" className={'link-small'}>View Page</a></p>
                    </div>
                </div>
                <div className={'col-md-6 d-flex flex-wrap'}>
                    <div className={'col-md-5'}>
                        <img src="https://via.placeholder.com/150" className={'w-100'} alt=""/>
                    </div>
                    <div className={'col-md-7'}>
                        <p>Apple Store</p>
                        <span>Multinational Technology Company</span>
                        <span>USA • Cupertino • California</span>
                        <span>Company • Public • Global</span>
                        <p><a href="" className={'link-small'}>View Page</a></p>
                    </div>
                </div>
            </div>
        </ProfileInfoBoxSection>
    );
};

Alias.propTypes = {};

export default Alias;