import React, { useState, useEffect } from 'react';

import { sendFriendRequest } from "../../../helpers/helpers";
import { sendFriendRequest_ver_2 } from "../../../helpers/helpers";
import { handleRemove } from "../../../helpers/helpers";
import { useSelector } from "react-redux";
import SCCss from './style/SuggestedUsers.module.scss'

import { useDispatch } from "react-redux";


import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

import api from '../../../App/config/api'
import { globalMessage } from '../../../helpers/helpers_2'
import { adjustStringWidth } from '../../../helpers/helpers_3';


const SuggestedUsers = ({ btnName, allUsers, setAllUsers, xxx, setXxx, suggestedUsers, setSuggestedUsers, friends, setShowAddToNetworkMessage }) => {

  const { data, isLoading } = useSelector(state => state.user.account)
  const { individualUserProfile } = data
  const dispatch = useDispatch()


  //-------------------------------------------------------------------
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //-------------------------------------------------------------------

  return (
    <div className={SCCss.main_container}>
      <div className={SCCss.main_div}>
        <div className={SCCss.title_div}>
          <div onClick={() => console.log(individualUserProfile.id)} className={SCCss.title}>Suggested Users</div>
        </div>



        <div className={SCCss.user_connections_container}>


          {/* {xxx && xxx.map((x, index) => { */}
          {/* {suggestedUsers && suggestedUsers.map((x, index) => { */}
          {/* {suggestedUsers && suggestedUsers.filter(ar => !friends.find(x => (x.id === ar.id))).slice(0, 3).map((x, index) => { */}
          {suggestedUsers && suggestedUsers.filter(x => x.id !== individualUserProfile.id).filter(ar => !friends.find(x => (x.id === ar.id))).slice(0, 3).map((x, index) => {
            return (

              <div key={index} className={SCCss.main_list_div}>
                <div className={' ' + SCCss.left_list_div}>
                  {/* <div onClick={() => console.log(x)} className={' ' + SCCss.left_list_div}> */}
                  {/* <div className={SCCss.picture}><img className={SCCss.img} src='/assets/images/avatar-profile.svg' alt="" /></div> */}
                  <div className={SCCss.picture}><img className={SCCss.img} src={x.img || `http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${x.id}`} alt="" /></div>

                  <div className={SCCss.name_div}>
                    <div className={SCCss.names_wraper} >
                      <Link to={`/profile/${x.id}`} className={SCCss.name} >{windowSize.width < 768 ? adjustStringWidth(x.firstName.concat(' ', x.lastName), 180) : adjustStringWidth(x.firstName.concat(' ', x.lastName), 300)}</Link>
                      {/* <Link to={`/profile/${x.id}`} className={SCCss.name} >{`${x.firstName} ${x.lastName}`}</Link> */}
                      <div className={SCCss.small_text}>{windowSize.width < 768 ? adjustStringWidth(x.email, 300) : adjustStringWidth(x.email, 500)}</div>
                      {/* <div className={SCCss.small_text}>{x.email}</div> */}
                      <div
                        // onClick={(() => {
                        //   setShowAddToNetworkMessage({ show: true, name: `${x.firstName} ${x.lastName}` });
                        //   setTimeout(() => {
                        //     setShowAddToNetworkMessage({ show: false, name: `` })
                        //   }, 1000);
                        // })} 
                        className={SCCss.small_text}>Digital Marketing</div>
                    </div>
                  </div>
                </div>

                <div className={' ' + SCCss.right_list_div}>



                  {/* <div className={SCCss.verify_profile_div}>
                  <button className={SCCss.verify_profile_button} >Verified</button>
                </div> */}


                  <div className={SCCss.message_div}>
                    <button onClick={() => {
                      // sendFriendRequest_ver_2(individualUserProfile.id, x.id);
                      // handleRemove(x.id, suggestedUsers, setSuggestedUsers);
                      // handleRemove(x.id, allUsers, setAllUsers);

                      //------------------------------------------

                      api.get(`/api/friendship-request?userId=${x.id}&userType=INDIVIDUAL_USER`)
                        .then(res => {
                          const found = res.data.some(item => item.id === individualUserProfile.id);
                          if (found) {
                            console.log('invite already sent')
                            dispatch(globalMessage({ name: `invite has already been sent`, type: 'simple_message' }))
                          } else {
                            console.log('sending now')

                            dispatch(globalMessage({ name: `${x.firstName} ${x.lastName}`, type: 'add_to_network' }))
                            sendFriendRequest(individualUserProfile.id, x.id);
                            handleRemove(x.id, suggestedUsers, setSuggestedUsers);
                          }
                        })
                        .catch(e => console.log(e))
                    }}
                      className={SCCss.message_button + ' ' + SCCss.desktop} >Add to Network</button>
                    <button onClick={() => {
                      // sendFriendRequest_ver_2(individualUserProfile.id, x.id);
                      // handleRemove(x.id, suggestedUsers, setSuggestedUsers);
                      // handleRemove(x.id, allUsers, setAllUsers);

                      //------------------------------------------

                      api.get(`/api/friendship-request?userId=${x.id}&userType=INDIVIDUAL_USER`)
                        .then(res => {
                          const found = res.data.some(item => item.id === individualUserProfile.id);
                          if (found) {
                            console.log('invite already sent')
                            dispatch(globalMessage({ name: `invite has already been sent`, type: 'simple_message' }))
                          } else {
                            console.log('sending now')

                            dispatch(globalMessage({ name: `${x.firstName} ${x.lastName}`, type: 'add_to_network' }))
                            sendFriendRequest(individualUserProfile.id, x.id);
                            handleRemove(x.id, suggestedUsers, setSuggestedUsers);
                          }
                        })
                        .catch(e => console.log(e))
                    }} className={SCCss.message_button + ' ' + SCCss.phone} >Add</button>
                    {/* remove user from showList */}
                    {/* <div className={SCCss.menu_dots_div} onClick={() => { handleRemove(x.id, suggestedUsers, setSuggestedUsers) }} ><img className={SCCss.img_menu_dots} src='/assets/images/cross.svg' alt="" /></div> */}
                   
                    {/* <button onClick={() => { sendFriendRequest(individualUserProfile.id, x.id) }} className={SCCss.message_button + ' ' + SCCss.phone} >Add</button> */}

                  </div>

                  {/* <div className={SCCss.message_div}>
                  <button className={SCCss.message_button} > Message </button>
                </div> */}
                  {/* <button className={SCCss.message_button + ' ' + (x.verified && SCCss.verify_button_false)} onClick={() => console.log(x.verified)} > {x.verified ? 'Verify' : 'Verified'}</button> */}
                </div>
              </div>

            )
          })}

        </div>
      </div>
    </div>
  )
}

export default SuggestedUsers


// return (<>
//   <div onClick={() => console.log(allUsers)} >SuggestedUsers</div>
//   {
//     allUsers.map((item, i) => {
//       return (<div>{item.firstName}</div>)
//     })
//   }
// </>)


// /---------------------------------

// return (<>
//   <div>SuggestedUsers</div>
//   {
//     allUsers.map((item, i) => {
//       return i < 5 && <div key={i} btnName={btnName} name={item.firstName + " " + item.lastName}
//         info1={"Pixel Perfect"}
//         info2={"Spain - Barcelona"}
//         onClick={() => {
//           sendFriendRequest(individualUserProfile.id, item.id);
//           handleRemove(item.id, allUsers, setAllUsers);
//         }}
//         img={`http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${item.id}`} />
//     })
//   }
// </>)
// return (
//   <div className={'bor-o ' + SCCss.main_container} >

//     <div className={'bor-o ' + SCCss.Suggested_Users_div}>
//       <div className={'bor-o ' + SCCss.Suggested_Users_title} >Suggested Users</div>
//     </div>

//     <div className={'bor-o ' + SCCss.Suggested_Users_List_div}>
//       {
//         allUsers.map((x, i) => {
//           return (<>

//             <div className={SCCss.main_list_div}>
//               <div onClick={() => console.log(x)} className={' ' + SCCss.left_list_div}>
//                 <div className={SCCss.picture}><img className={SCCss.img} src={x.img || `http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${x.id}`} alt="" /></div>

//                 <div className={SCCss.name_div}>
//                   <div className={SCCss.names_wraper} >
//                     <div className={SCCss.name} >{`${x.firstName} ${x.lastName}`}</div>
//                     <div className={SCCss.small_text}>{x.email}</div>
//                     <div className={SCCss.small_text}>Digital Marketing</div>
//                   </div>
//                 </div>
//               </div>

//               <div className={' ' + SCCss.right_list_div}>



//                 <div className={SCCss.message_div}>
//                   <button className={SCCss.message_button} >Message</button>
//                   <div className={SCCss.menu_dots_div} ><img className={SCCss.img_menu_dots} src='/assets/images/menu-dots-normal-vertical-blue.svg' alt="" /></div>

//                 </div>


//               </div>
//             </div>

//           </>)
//         })
//       }
//     </div>


//   </div>
// )