import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    // value: 0
    edit_tabs_value: 0,
    view_tabs_value: 0
}

export const tabsNavigationSlice = createSlice({

    name: 'tabsNavigation',
    initialState,
    reducers: {

        chooseEditTab: (state, action) => {
            state.edit_tabs_value = action.payload
        },
        chooseViewTab: (state, action) => {
            state.view_tabs_value = action.payload
        },
    }
})

export const { chooseEditTab, chooseViewTab } = tabsNavigationSlice.actions
// export const { chooseTab } = tabsNavigationSlice.actions

export default tabsNavigationSlice.reducer