import React, { useEffect, useState } from 'react';
import FormColumns from "../../Common/FormColumns";
import InputAndActionContainer from "../../Common/InputAndActionContainer";
import InputContainer from "../../Common/InputContainer";
import InputField from "../../../App/components/InputField";
import FormBoxSection from "../../Common/FormBoxSection";
import FormBoxSectionDualScreen from "../../Common/FormBoxSectionDualScreen";
import useForm from '../../../helpers/customHooks/useForm'
import Dropdown from "../../../App/components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { updateIndividualUserProfile } from "../../../App/store/user";
import ImageUploader from "../../Common/ImageUploader";
import Loader from "../../Common/Loader";
import api from "../../../App/config/api";
// import validate from '../../helpers/validateLogin'

const ProfileEditing = ({ }) => {
    const { data } = useSelector(state => state.user.account)
    const { individualUserProfile } = data
    const { handleChange, handleSubmit, values, setValues, errors, isEdited, setIsEdited } = useForm(individualUserProfile, submit, validate)
    const dispatch = useDispatch()
    const [imgFile, setImgFile] = useState()


    function submit() {
        if (imgFile) {
            const data = new FormData()
            data.append('profileImage', imgFile)

            api.put('/api/individual-user-profile-image', data)
                .then((res) => {
                    console.log(res.data)
                }).catch(e => console.log(e))
        }
        dispatch(updateIndividualUserProfile(values))
    }

    function validate(v) {
        return {}
    }

    useEffect(() => {
        setValues(individualUserProfile)
    }, [individualUserProfile])

    if (!individualUserProfile)
        return <div>loading</div>
    return (
        <form action="" className={'mt-1'} onSubmit={handleSubmit}>
            {/* <form action="" className={'mt-5'} onSubmit={handleSubmit}> */}
            <FormBoxSectionDualScreen noTitle={true} className={'margin-b-0'} title={"Profile"}>
                {individualUserProfile.updating ? <Loader /> :
                    <  >
                        <div className={'d-flex flex-column pb-2'}>
                            {/* <div className={'d-flex flex-column py-4'}> */}
                            <InputContainer>
                                {<ImageUploader setImgFile={setImgFile} setIsEdited={setIsEdited} />}
                            </InputContainer>
                            <InputContainer>
                                <InputField value={values.firstName || ''} label={"First Name"} name={'firstName'}
                                    onChange={handleChange} />

                            </InputContainer>
                            <InputContainer>
                                <InputField value={values.lastName || ''} label={"Last Name"} name={'lastName'}
                                    onChange={handleChange} />

                            </InputContainer>
                            <InputContainer>
                                <InputField type={'date'} label={"Date of birth"} name={'dateOfBirth'}
                                    value={values.dateOfBirth || ''}
                                    onChange={handleChange} />

                            </InputContainer>
                            <InputContainer>
                                <InputField multiline rows={2} label={"My Bio"} name={'bio'} value={values.bio || ''}
                                    onChange={handleChange} />

                            </InputContainer>
                            <InputContainer>
                                <Dropdown options={[{ name: 'Verified by Email', value: 'Verified by Email' }, {
                                    name: 'Verified by option1',
                                    value: 'Verified by option1'
                                }, {
                                    name: 'Verified by option2',
                                    value: 'Verified by option2'
                                }
                                ]}
                                    label={"Account Verification Method"}
                                    onChange={handleChange}
                                    name={'verifiedByEmail'}
                                    value={values.verifiedByEmail || ''}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputField label={"Email"} name={'email'} value={values.email || ''}
                                    onChange={handleChange} />
                                {/*<div>*/}
                                {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elionec ut arcu etfsa.</p>*/}
                                {/*    <a href="" className={'link-small'}>Change Email</a>*/}
                                {/*</div>*/}
                            </InputContainer>
                            <InputContainer>
                                <InputField label={"Phone Number"} name={'phone'} value={values.phone || ''}
                                    onChange={handleChange} />
                                {/*<div>*/}
                                {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elionec ut arcu etfsa.</p>*/}
                                {/*    <Checkbox>Public</Checkbox>*/}
                                {/*</div>*/}
                            </InputContainer>
                            {/*<InputContainer>*/}
                            {/*    <InputField type="number" label={"Age"}/>*/}
                            {/*    <></>*/}
                            {/*</InputContainer>*/}
                            <InputContainer>
                                <InputField label={"LinkedIn Profile"} name={'linkedinProfile'}
                                    value={values.linkedinProfile || ''} onChange={handleChange} />
                            </InputContainer>

                            <div className=' d-flex justify-content-end ' >
                                {/* samo za da nastimam css e ova dole */}
                                {/* <button className={'btn btn-primary'} type='submit'>Save</button> */}
                                {/* <button className={'btn btn-primary custom-small-btn mt-1'} type='submit'>Save</button> */}
                                {/* kako sto treba e ova dole */}
                                {isEdited && <button className={'btn btn-primary px-5 py-2 mt-1'} type='submit'>Save</button>}
                                {/* {isEdited && <button className={'btn btn-primary custom-small-btn mt-1'} type='submit'>Save</button>} */}
                            </div>
                        </div>

                    </>}
            </FormBoxSectionDualScreen>
        </form>
    )
}
    ;

ProfileEditing.propTypes = {};

export default ProfileEditing;