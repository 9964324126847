import React, {useEffect} from 'react';
import ProfileActions from "../components/ProfileActions";
import InputField from "../../../App/components/InputField";
import Image from "../../../App/components/Image";
import Schedule from "../components/Schedule";
import History from "../components/History";
import MenuHire from "../components/MenuHire";
import {useDispatch, useSelector} from "react-redux";
import {fetchAccount} from "../../../App/store/user";
import Loader from "../../Common/Loader";

const HireProfile = props => {
    const {data, isLoading} = useSelector(state => state.user.account)
    const {individualUserProfile} = data
    const dispatch = useDispatch()

    useEffect(() => {
        if (!Object.keys(data).length)
            dispatch(fetchAccount())
    }, [])


    if (isLoading)
        return <Loader/>
    return (
        <div>
            <div className="">
                <MenuHire/>
            </div>
            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container d-flex p-0'} style={{background: '#f5f5f5'}}>
                    <div className="mx-auto pt-2 col-md-8 px-0">
                        <ProfileActions/>
                        <Schedule/>
                        <History/>
                    </div>
                    <div className={'col-md-3 bg-white pt-3'}>
                        <div className={'d-flex justify-content-around w-100'}>
                            <InputField name={'search'} label={'Search'}/>
                            <img src="/assets/images/Notifications.svg" alt=""/>
                        </div>
                        <div className={'d-flex flex-column align-items-center mt-5'}>
                            <Image w={100} h={100} className={'simple-border'}
                                   src={`http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${individualUserProfile.id}`}/>
                            <div className={'mt-3'}>
                                <b>{individualUserProfile.firstName + " " + individualUserProfile.lastName}</b>
                            </div>
                            <span className={'small'}>Owner</span>
                        </div>
                        <div className={'border-bottom d-flex mt-4 px-2 justify-content-between'}>
                            <h6><b>Notifications</b></h6>
                            <span>All</span>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Update supply change on
                                    some list somewhere
                                </div>
                                <a href={''} className={'link-small pt-2'}>@projectname</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold pb-'}>Jin Tan in applies in listing
                                    <a href={''}
                                       className={'link-small font-weight-normal pt-2'}> @CFO
                                        position opening</a></div>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Thomas M. Trevino requested
                                    NDA
                                </div>
                                <a href={''} className={'link-small pt-2'}>@Selpic P1</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold border-bottom pb-2'}>Jin Tan reported something
                                    at
                                </div>
                                <a href={''} className={'link-small pt-2'}>@Selpic P1</a>
                            </div>
                        </div>
                        <div className={'px-3 pt-3'}>
                            <div className={'simple-border corner-radius10 p-3'}>
                                <div className={'small font-weight-bold pb- '}>Richard M. Hobbs in Follows <a
                                    href={''} className={'link-small font-weight-normal pt-2'}> @purME</a> Project.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

HireProfile.propTypes = {};

export default HireProfile;