import React from 'react';
import ProfileInfoBoxSection from "../../Common/ProfileInfoBoxSection";

const Contact = ({data}) => {
    const {aliasIdentity} = data
    return (
        <ProfileInfoBoxSection className={''} title={"Contact"}>
            <div className="profile-info">
                <div>
                    <span>Email</span>
                    <p><a href="" className={'edit-link'}>{aliasIdentity.email || "no Email yet"}</a></p>
                    {/* <p><a href="" className={'edit-link'}>company@email.com</a></p> */}
                    <span>Phone</span>
                    <p><a href="" className={'edit-link'}>{aliasIdentity.phoneNumber || "no Phone Number yet"}</a></p>
                    {/* <p><a href="" className={'edit-link'}>+1 (626) 295-3800</a></p> */}
                    <span>Company Website</span>
                    <p><a href={aliasIdentity.publicUrl}
                          className={'edit-link'}>{aliasIdentity.publicUrl || "no website yet"}</a></p>
                    <span>LinkedIn</span>
                    <p><a href="" className={'edit-link'}>{aliasIdentity.linkedin || "no LinkedIn Profile yet yet"}</a></p>
                    {/* <p><a href="" className={'edit-link'}>www.linkedin.com/company/12/</a></p> */}
                    <span>YouTube</span>
                    <p><a href="" className={'edit-link'}>{aliasIdentity.youtubeChanel || "no YouTube Chanel Profile yet yet"}</a></p>
                    {/* <p><a href="" className={'edit-link'}>www.youtube.com/user/company/</a></p> */}
                </div>
            </div>
        </ProfileInfoBoxSection>
    );
};

Contact.propTypes = {};

export default Contact;