import React, {useEffect, useState} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import '../styles/Editor.scss';
import Button from "../../App/components/Button";
import {useDispatch, useSelector} from "react-redux";
import {createUpdateContract} from "../../App/store/contracting";
import {useParams} from "react-router";

const Editor = ({fileContent, setFileContent, documentTitle, isSaved, setIsSaved}) => {
    const [didMount, setDidMount] = useState(false)
    const dispatch = useDispatch()
    const {data} = useSelector(state => state.contracting.contract)
    const params = useParams()

    const handleEditorChange = (e, editor) => {
        if (didMount) {
            const data = editor.getData();
            setFileContent(data)
            setIsSaved(false)
        }
        setDidMount(true)
    }


    const saveDocument = async () => {
        const contract = {
            id: params.id || data.id || null,
            name: documentTitle || 'document',
            data: fileContent
        }
        dispatch(createUpdateContract(contract))
    }

    useEffect(() => {
        const onLeave = function () {
            return 'You have unsaved changes on this page!';
        }
        if (didMount && !isSaved) {
            window.onbeforeunload = onLeave
        } else if (didMount && isSaved) window.onbeforeunload = null;

        return window.removeEventListener('onbeforeunload', onLeave)
    }, [isSaved])


    useEffect(() => {
        setIsSaved(true)
    }, [data])

    return (
        <div className="ck-wrapper d-flex flex-column">
            <div className={'p-2 simple-border'}>
                <div className={'float-right'}>
                    {fileContent && <Button disabled={isSaved} className={'btn-blue  mr-3'}
                                            onClick={saveDocument}>{isSaved ? 'Saved' : 'Save'}</Button>}
                    <Button className={'btn-blue'}>Sign</Button>
                </div>
            </div>
            <div id="toolbar-container"></div>
            <CKEditor
                editor={DecoupledEditor}
                config={{
                    height: 100,
                    exportWord: {
                        fileName: documentTitle,
                    }
                }}
                data={fileContent || ""}
                onInit={editor => {
                    const toolbarContainer = document.querySelector('#toolbar-container');
                    toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                    window.editor = editor;
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => handleEditorChange(event, editor)}
            />
        </div>
    );
}

export default Editor
