import React from 'react';
import PublicHeader from "../../App/components/PublicHeader";
import Button from "../../App/components/Button";
import '../styles/RegisterScreen.scss'
import RegisterForm from "../components/RegisterForm";
import PublicFooter from '../../App/components/PublicFooter'

const RegisterScreen = props => {
    return (
        <div>
            <PublicHeader>
                <Button className={'btn-blue'} onClick={() => window.location.href = '/login'}>Login</Button>
            </PublicHeader>
            <section className={'register-section'}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 pl-3 pl-md-0 ">
                            <h2 className={'mb-5'}>Register an account</h2>
                            <h5 className={'mb-5'}>Individual!<br></br> Welcome aboard.</h5>
                            <p>We need a few more things before we can continue. <br></br>Let’s start by filling the
                                fields
                                bellow so we
                                get to know you. </p>
                            <p>Don’t worry, your information is safe with us and only wen you’re ready you can
                                go public with your profile to the network.</p>
                        </div>
                        <div className="col-md-5">
                            <img className={'w-100'} src="/assets/images/Image 10.png" alt=""/>
                        </div>
                    </div>
                    <RegisterForm className={'mt-5'}/>
                </div>
            </section>
            <PublicFooter>
                <Button className={'btn-blue'}>Join Now</Button>
            </PublicFooter>
        </div>
    );
};

RegisterScreen.propTypes = {};

export default RegisterScreen;