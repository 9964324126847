import {createSlice} from '@reduxjs/toolkit'
import api from "../config/api";

const initialState = {
    data: {},
    isLoading: true,
    error: false,
}

const slice = createSlice({
    name: 'contracting',
    initialState: {
        contract: initialState,
    },
    reducers: {
        getContractSuccess: (state, action) => {

            state.contract.data = action.payload.body
            state.contract.isLoading = false
        },
        createUpdateContractSuccess: (state, action) => {

            state.contract.data = action.payload
            state.contract.isLoading = false
        },
    },
});
export default slice.reducer
// Actions
const {
    createUpdateContractSuccess, getContractSuccess
} = slice.actions


export const createUpdateContract = (data) => async dispatch => {
    try {
        const res = await api.post('/api/contract/contract', data)
        dispatch(createUpdateContractSuccess(res.data))
    } catch (e) {
        return console.error(e.message);
    }
}

export const getContractById = (id) => async dispatch => {
    try {
        const res = await api.get('api/contract/contract-content/' + id)
        return dispatch(getContractSuccess(res.data))
    } catch (e) {
        return console.error(e.message);
    }
}


