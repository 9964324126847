import React, { useState, useEffect } from 'react';
// import UCCss from './style/UserConnections.module.scss'
import UCCss from './style/SearchForUsers.module.scss'

// import { sendFriendRequest } from "../../../helpers/helpers";
// import { handleRemove } from "../../../helpers/helpers";

import { sendFriendRequest, sendFriendRequest_ver_2, acceptFriendRequest, declineFriendRequest, handleRemove } from "../../../helpers/helpers";
import { useDispatch, useSelector } from "react-redux";



import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

import { canShowMessage } from '../../../App/store/mixed'
import { displayMessage } from '../../../App/store/mixed'
import { globalMessage } from '../../../helpers/helpers_2'
// import helpers_2 from '../../../helpers/helpers_2'
import api from '../../../App/config/api'

import { adjustStringWidth } from '../../../helpers/helpers_3';

import SearchInputField from '../../../App/components/SearchInputField'



// const StyledTextField = withStyles(theme => ({
//   root: {
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderRadius: `90px`,
//       },
//     },
//     '& .MuiInputBase-root': {
//       color: 'black',
//       fontSize: 14,

//       [theme.breakpoints.down('768')]: {
//         fontSize: 11,
//       }
//     },
//   },
// }))(TextField);


const SearchForUsers = ({ title, btns, friends, setFriends, searchAllUsers, setSearchAllUsers, allUsers, setShowAddToNetworkMessage }) => {

  const { data, isLoading } = useSelector(state => state.user.account)
  const { individualUserProfile } = data

  const [searchWord, setSearchWord] = useState('');
  const [size, setSize] = useState(6);
  // console.log({ friends })

  const dispatch = useDispatch()

  useEffect(() => {
    if (searchWord) {
      setSearchAllUsers(allUsers.filter(n => n.firstName.toLowerCase().includes(searchWord) || n.lastName.toLowerCase().includes(searchWord)))
    }
    else {
      setSearchAllUsers(allUsers)
    }
  }, [searchWord])
  //-------------------------------------------------------------------
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //-------------------------------------------------------------------

  return (
    <div className={"  " + UCCss.main_container}>
      <div className={UCCss.main_div}>
        <div className={UCCss.title_div}>
          <div
            // onClick={() => {
            //   setShowAddToNetworkMessage(true);
            //   setTimeout(() => {
            //     setShowAddToNetworkMessage(false)
            //   }, 1000);
            // }}
            className={UCCss.title}>{`Search Users / ${allUsers ? allUsers.length - 1 : "0"}`}</div>
        </div>

        <div className={UCCss.search_div}>
          <SearchInputField value={searchWord} onChange={(e) => { setSearchWord(e.currentTarget.value.toLowerCase()) }}
            // size='medium'
            // size='small'
            fullWidth id="standard-bare" variant="outlined" placeholder='Search by name'
            InputProps={{ endAdornment: (<IconButton size='small'><SearchOutlined /></IconButton>), }} />
        </div>

        <div className={UCCss.user_connections_container}>

          {searchAllUsers && searchAllUsers.filter(x => x.id !== individualUserProfile.id).slice(0, size).map((x, index) => {
            return (

              <div key={index} className={UCCss.main_list_div}>
                <div className={' ' + UCCss.left_list_div}>
                  {/* <div className={UCCss.picture}><img className={UCCss.img} src='/assets/images/avatar-profile.svg' alt="" /></div> */}
                  <div className={UCCss.picture}><img className={UCCss.img} src={x.img || `http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${x.id}`} alt="" /></div>

                  <div className={UCCss.name_div}>
                    <div className={UCCss.names_wraper} >
                      {/* <div className={UCCss.name} >{`${x.firstName} ${x.lastName}`}</div> */}
                      <Link to={`/profile/${x.id}`} className={UCCss.name} >{windowSize.width < 768 ? adjustStringWidth(x.firstName.concat(' ', x.lastName), 180) : adjustStringWidth(x.firstName.concat(' ', x.lastName), 600)}</Link>
                      {/* <Link to={`/profile/${x.id}`} className={UCCss.name} >{x.firstName.concat(x.lastName)}</Link> */}
                      {/* <Link to={`/profile/${x.id}`} className={UCCss.name} >{`${x.firstName} ${x.lastName}`}</Link> */}

                      {/* {windowSize.width < 768 ? adjustStringWidth(x.name, 170) : adjustStringWidth(x.name, 320)} */}
                      <div className={UCCss.small_text}>{windowSize.width < 768 ? adjustStringWidth(x.email, 300) : adjustStringWidth(x.email, 700)}</div>
                      {/* <div className={UCCss.small_text}>{x.email}</div> */}
                      <div onClick={() => {
                        // dispatch(globalMessage({ name: `${x.firstName} ${x.lastName}`, type: 'invite_has_already_been_sent' }))
                        // dispatch(globalMessage({ name: `${x.firstName} ${x.lastName}`, type: 'add_to_network' }))

                        // dispatch(globalMessage(`${x.firstName} ${x.lastName}`, 'invite_has_already_been_sent'))
                        // dispatch(globalMessage(`${x.firstName} ${x.lastName}`, 'add_to_network'))
                      }} className={UCCss.small_text}>Digital Marketing</div>
                    </div>
                  </div>
                </div>

                <div className={' ' + UCCss.right_list_div}>


                  <div className={UCCss.message_div}>
                    {friends.some(item => item.id == x.id) ?
                      <button className={UCCss.friend_button} >Connected</button> : <>
                       
                        <button onClick={() => {
                          // sendFriendRequest_ver_2(individualUserProfile.id, x.id);
                          // handleRemove(x.id, searchAllUsers, setSearchAllUsers);


                          api.get(`/api/friendship-request?userId=${x.id}&userType=INDIVIDUAL_USER`)
                            .then(res => {
                              const found = res.data.some(item => item.id === individualUserProfile.id);
                              if (found) {
                                console.log('invite already sent')
                                dispatch(globalMessage({ name: `invite has already been sent`, type: 'simple_message' }))
                              } else {
                                console.log('sending now')
                                dispatch(globalMessage({ name: `${x.firstName} ${x.lastName}`, type: 'add_to_network' }))

                                sendFriendRequest(individualUserProfile.id, x.id);
                                handleRemove(x.id, searchAllUsers, setSearchAllUsers);
                              }
                            })
                            .catch(e => console.log(e))

                        }} className={UCCss.message_button} >{windowSize.width < 768 ? 'Add' : 'Add to Network'}</button>
                        {/* }} className={UCCss.message_button} >Add to Network</button> */}
                      </>
                    }



                    {/* <div className={UCCss.menu_dots_div} ><img className={UCCss.img_menu_dots} src='/assets/images/menu-dots-normal-vertical-blue.svg' alt="" /></div> */}

                  </div>

                  {/* <div className={UCCss.message_div}>
                  <button className={UCCss.message_button} > Message </button>
                </div> */}
                  {/* <button className={UCCss.message_button + ' ' + (x.verified && UCCss.verify_button_false)} onClick={() => console.log(x.verified)} > {x.verified ? 'Verify' : 'Verified'}</button> */}
                </div>
              </div>

            )
          })}

        </div>
        {size < searchAllUsers.length && <div className={UCCss.show_more_users_div} >
          <button onClick={() => { setSize(size > searchAllUsers.length ? size : size + 10) }} className={UCCss.show_more_users_button} >Show More Users</button>
        </div>}
      </div>
    </div>
  )
}


export default SearchForUsers



// <button onClick={() => {
//   // sendFriendRequest_ver_2(individualUserProfile.id, x.id);
//   // handleRemove(x.id, searchAllUsers, setSearchAllUsers);


//   api.get(`/api/friendship-request?userId=${x.id}&userType=INDIVIDUAL_USER`)
//     .then(res => {
//       const found = res.data.some(item => item.id === individualUserProfile.id);
//       if (found) {
//         console.log('invite already sent')
//         dispatch(globalMessage({ name: `invite has already been sent`, type: 'simple_message' }))
//       } else {
//         console.log('sending now')
//         dispatch(globalMessage({ name: `${x.firstName} ${x.lastName}`, type: 'add_to_network' }))

//         sendFriendRequest(individualUserProfile.id, x.id);
//         handleRemove(x.id, searchAllUsers, setSearchAllUsers);
//       }
//     })
//     .catch(e => console.log(e))

// }} className={UCCss.message_button_phone} >Add</button>