import React, { useEffect, useState } from 'react';
import Header from "../../Common/Header";
import MenuBar from "../../Common/MenuBar";
import Button from "../../../App/components/Button";
import InputField from "../../../App/components/InputField";
import OpportunityItem from "../components/OpportunityItem";
import MenuOpportunitiesProjects from "../../Common/MenuOpportunitiesProjects";
import Modal from "../../../App/components/Modal";
import { useHistory } from "react-router";
import CreateOpportunityModalContent from "../components/CreateOpportunityModalContent";
import Image from "../../../App/components/Image";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccount } from "../../../App/store/user";
import Loader from "../../Common/Loader";
import OppCSS from './Opportunities.module.scss'

import MenuDots from "../../../App/components/MenuDots";

import { TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

// import api from "../../../App/config/api";
import api from "../../../App/config/api";

import { chooseEditTab } from '../../../App/store/tabs_navigation'
import { chooseViewTab } from '../../../App/store/tabs_navigation'
// import { chooseEditTab } from '../../../App/store/tabs_navigation'
// import { addLocation } from '../../../App/store/test_location'

import { cutString } from '../../../helpers/helpers_2'
import { adjustStringWidth } from '../../../helpers/helpers_3';

const StyledTextField = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `90px`,
            },
        },
        '& .MuiInputBase-root': {
            color: 'black',
            fontSize: 14,

            [theme.breakpoints.down('768')]: {
                fontSize: 11,
            }
        },
    },
}))(TextField);


const Opportunities = props => {
    const [openModal, setOpenModal] = useState(false)
    const [name, setName] = useState(false)
    const history = useHistory()
    const { data, isLoading } = useSelector(state => state.user.account)
    const { individualUserProfile } = data

    const [searchWord, setSearchWord] = useState('');



    const dispatch = useDispatch()

    useEffect(() => {
        if (!Object.keys(data).length)
            dispatch(fetchAccount())
    }, [])

    const handleChange = (e) => {
        [e.target.name] = e.target.value
        setName(e.target.value)
    }


    //---------------------------------------------
    //-------------------------------------------------------------------
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    //-------------------------------------------------------------------

    //---------------------------------------------


    // media queries
    // https://levelup.gitconnected.com/using-breakpoints-and-media-queries-in-material-ui-47470d3c43d9
    //---------------------------------------------

    const [suggestedOpportunities, setSuggestedOpportunities] = useState([])
    const [searchAllOpportunities, setSearchAllOpportunities] = useState([])

    const getAllOpportunites = async () => {
        const res = await api.get('/api/all-opportunities')
        // let data = await res.json()
        setSuggestedOpportunities(res.data.body)
        setSearchAllOpportunities(res.data.body)

    }

    useEffect(() => {
        getAllOpportunites()
    }, [])

    //--------------------------------------------------------------------
    useEffect(() => {
        if (searchWord) {
            setSearchAllOpportunities(suggestedOpportunities.filter(n => n.name.toLowerCase().includes(searchWord) || n.projectWebsite.toLowerCase().includes(searchWord)))
        }
        else {
            setSearchAllOpportunities(suggestedOpportunities)
        }
    }, [searchWord])


    if (isLoading)
        return <Loader />

    return (
        <div>
            <Header />
            <div className=""><MenuBar page={'opportunities'} /></div>

            <div className="col-md-11 offset-md-1 ">
            <div className={'dashboard-inner-container'}>
                <div className={'row  '}>
                    <div className={'col-md-7 p-0'}>

                        <div className={'  ' + OppCSS.main_container}>
                            <div className={OppCSS.navigation_div} >
                                <Link to='/' className={OppCSS.back} ><img className={OppCSS.back_img} src='/assets/images/left_arrow.svg' alt="" /></Link>
                                <div className={OppCSS.navigation_title}>Opportunity</div>
                                <div className={OppCSS.next}></div>
                            </div>

                            <div className={OppCSS.title} >{`${suggestedOpportunities && suggestedOpportunities.length} Opportunities`}</div>
                            <div className={OppCSS.main_search_div}>
                                <div className={OppCSS.left_div_search}> <button onClick={() => {
                                    dispatch(chooseEditTab(3))
                                    setTimeout(() => {
                                        dispatch(chooseEditTab(0))
                                    }, 5000);
                                    history.push(`/individual-user`)
                                }}
                                    className={OppCSS.button} >Create New</button> </div>
                                <div className={OppCSS.right_div_search}>
                                    {/* <input placeholder='Search by name' className={'bor-o ' + OppCSS.input} type="text" /> */}
                                    <StyledTextField value={searchWord} onChange={(e) => { setSearchWord(e.currentTarget.value.toLowerCase()) }} size='small' fullWidth id="standard-bare" variant="outlined" placeholder='Search by name'
                                        InputProps={{ endAdornment: (<IconButton size='small'><SearchOutlined /></IconButton>), }} />
                                </div>
                            </div>


                            {searchAllOpportunities && searchAllOpportunities.map((x, i) => {
                                // {temp && temp.map((x) => {
                                return (
                                    <div key={i} className={OppCSS.main_list_div}>
                                        <div className={OppCSS.left_list_div}>
                                            <div className={OppCSS.picture}><img className={OppCSS.img} src='/assets/images/avatar_no_picture.svg' alt="" /></div>
                                            {/* <div className={OppCSS.picture}><img className={OppCSS.img} src='/assets/images/avatar-profile.svg' alt="" /></div> */}

                                            <div className={OppCSS.name_div}>
                                                <div className={OppCSS.names_wraper} >
                                                    {/* <div className={OppCSS.name} >{x.name}</div> */}
                                                    <div className={OppCSS.name} >{windowSize.width < 768 ? adjustStringWidth(x.name, 170) : adjustStringWidth(x.name, 320)}</div>
                                                    {/* <div className={OppCSS.name} >{windowSize.width < 768 ? cutString(x.name, 14) :  cutString(x.name, 23)}</div> */}
                                                    <div className={OppCSS.small_text}>{x.projectWebsite}</div>
                                                    <div className={OppCSS.small_text + ' ' + OppCSS.phone_date}>{new Date(x.startDate).toLocaleDateString()}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={OppCSS.right_list_div}>
                                            {/* <div>{x.userType}</div> */}
                                            <div className={OppCSS.date + ' ' + OppCSS.desktop_date}>{new Date(x.startDate).toLocaleDateString()}</div>
                                            <div className={OppCSS.button_div}>
                                                <button className={OppCSS.button} onClick={() => {
                                                    dispatch(chooseViewTab(2))
                                                    setTimeout(() => {
                                                        dispatch(chooseViewTab(0))
                                                    }, 5000);
                                                    if (x.userType == 0) {
                                                        history.push(`/profile/${x.userId}`)

                                                    } else if (x.userType == 1) {
                                                        console.log(x.userId)

                                                        //---------------------------------------------------

                                                        api.get(`/api/alias-introduction/${x.userId}`)
                                                            .then(res => {
                                                                let aliasType = res.data.aliasIdentity.aliasType
                                                                console.log(aliasType)
                                                                if (aliasType == 'LEGAL_ENTITIES_COMPANY') {
                                                                    history.push(`/company-profile/${x.userId}`)
                                                                } else if (aliasType == 'LEGAL_ENTITIES_INVESTMENT_FUND') {
                                                                    history.push(`/investment-fund-profile/${x.userId}`)
                                                                } else if (aliasType == 'BUSINESS_ASSOCIATIONS_GOVERNMENTAL') {
                                                                    history.push(`/governmental-profile-view/${x.userId}`)
                                                                } else if (aliasType == 'BUSINESS_ASSOCIATIONS_NON_GOVERNMENTAL') {
                                                                    history.push(`/nongovernmental-profile-view/${x.userId}`)
                                                                } else {
                                                                    console.log('this should not happen')
                                                                }

                                                            })
                                                            .catch(e => console.log(e))
                                                        //---------------------------------------------------
                                                    }

                                                }}  >View User</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div> {/* opportunites - main_container */}
                    </div> {/* col-md-7 */}
                    <div className={'col-md-5 p-0 d-flex justify-content-center '}>

                        <div>asdasd</div>
                    </div>{/* col-md-5 */}
                </div> {/* row */}
            </div> {/* dashboard-inner-container */}
            </div> {/* offset */}

        </div>
    );
};

Opportunities.propTypes = {};

export default Opportunities;

