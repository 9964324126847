import React from 'react';
import {useSearchParam} from "react-use";

const CoverImgBox = ({children}) => {
    const projectName = useSearchParam('name')
    return (
        <div className={'simple-border corner-radius10 mb-4 bg-white'} style={{position: 'relative'}}>
            <img className={'w-100'} src="/assets/images/prBg.png" alt="" style={{height: 160, objectFit: 'cover'}}/>
            <div className={'d-flex w-100'} style={{position: 'absolute', top: 90}}>
                <img
                    className={'ml-3'} style={{height: 150, width: 150}}
                    src="/assets/images/p1.png"
                    alt=""/>
                <div
                    className={'d-flex justify-content-between align-items-end px-4 pb-3 w-100'}>
                    <div>
                        <b>{projectName}</b> <br/>
                        <div className={'small'}>Followers 120</div>
                    </div>
                    <div className={'d-flex align-items-center'}>
                        {children}
                    </div>
                </div>
            </div>
            <div style={{marginTop: 110}}>

            </div>
        </div>
    );
};

CoverImgBox.propTypes = {};

export default CoverImgBox;