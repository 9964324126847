import React, { useEffect, useState } from 'react'
// import '../styles/FormBoxSection.scss'
import FBSOCCss from './styles/FormBoxSectionOpportunityCustom.module.scss'

const FormBoxSectionOpportunityCustom = ({ title, className, children, style, opportunityList, setOpportunity, initialOpportunity, setAddress, message }) => {

    const messageColor = message == 'Created successfully' ? 'green' : '#9e241b'
    //-------------------------------------------------------------------
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    //-------------------------------------------------------------------

    return (
        <section className={FBSOCCss.form_box_section + " " + className + '  d-flex flex-wrap  ' + ' '} style={style} >

            <div className={' col-md-12  '}>
                <div className={'row'}>
                    <div className={FBSOCCss.form_box_title_div + "  col-md-10"}>
                        <div className={FBSOCCss.form_box_title}>
                            <div>{title}</div>
                            <div style={{ color: messageColor }} >{message}</div>
                        </div>


                    </div>
                </div>
            </div>

            <div className={' col-md-12 '}>
                <div className={'row'}>
                    <div className="  col-md-3 text-left">
                        {/*  */}
                        <div className={FBSOCCss.opportunity_list_div + " "}>
                            <div
                                onClick={() => {
                                    console.log(initialOpportunity)
                                    setOpportunity(initialOpportunity)
                                    setAddress('')
                                }}

                                className={FBSOCCss.opportunity_list_name + " " + FBSOCCss.opportunity_list_create_new}>
                                <img className={FBSOCCss.create_new_img} src="\assets\images\plus_sign_blue.svg" alt="" />
                            </div>
                            {opportunityList && opportunityList.slice(windowSize.width < 768 ? -7 : -17).map((x, i) => {
                                // {opportunityList && opportunityList.slice(-17).map(x => {
                                return (
                                    <div
                                        key={i}
                                        onClick={() => {
                                            let xxx = opportunityList.find(data => data.id === x.id)
                                            console.log(xxx)
                                            setOpportunity(xxx)
                                            setAddress(xxx.location ? xxx.location : '')
                                        }}
                                        className={FBSOCCss.opportunity_list_name}>
                                        {x.name && `${x.name.substring(0, 2)}`}
                                        {/* {x.name && x.name.length <= 16 ? x.name : x.name && x.name.length > 16 ? `${x.name.substring(0, 13)}...` : ''} */}
                                    </div>
                                )
                            })}
                        </div>
                        {/*  */}
                    </div>
                    <div className="  col-md-7 d-flex flex-column">
                        {children}
                    </div>
                </div>
            </div>
        </section>
    );
};

FormBoxSectionOpportunityCustom.propTypes = {};

export default FormBoxSectionOpportunityCustom;

