import React, { useState } from 'react';
import GrayInfoBox from "./GrayInfoBox";
import MenuDots from "../../App/components/MenuDots";
import {useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import Button from "../../App/components/Button";
import Image from "../../App/components/Image";
import {sendFriendRequest} from "../../helpers/helpers";

import useGetAliasIntroduction from "../../helpers/customHooks/useGetAliasIntroduction";
import { cutString } from '../../helpers/helpers_2'; 



const CompanyProfileInfo = (props) => {
    console.log('asdasd', props)
    // const params = useParams()
    // const history = useHistory()
    // const {individualUserProfile, locations} = useSelector(state => state.user.account.data)
    // const {firstName, lastName, id} = individualUserProfile || {}
    // const {educations, experiences} = useSelector(state => state.user.introduction.data)
    // const {userProfileData} = useSelector(state => state.users)
    // const {individualUserAccountResponse, individualUserIntroductionResponse} = userProfileData.data || {}

    // const aliasTypeStr = 'LEGAL_ENTITIES_COMPANY'
    // const [activeTab, setActiveTab] = useState(0)
    // const {aliasType, introduction, paramsId, activeId, isLoading} = useGetAliasIntroduction(aliasTypeStr);

    return (
        <GrayInfoBox className={'user-info-box border-0 user-info-box-margin'}>
                                <div className={'text-center user-info'}>
                                    {/* <MenuDots className={'user-box-dots'}/> */}
                                    <div className={'m-auto pb-3'} style={{width: 90}}>
                                        <Image corners={'corner-radius10'}
                                               w={100} h={100}
                                               src={`http://directventures.ekonet.mk:7777/api/auth/alias-profile-image/${props.paramsId}`}
                                               alt=""/>
                                    </div>
                                    <p>{cutString(props.introduction.aliasIdentity.name, 22)}</p>
                                    <span>{props.introduction.aliasIdentity.openSince}</span><br/>
                                    <span>Multinational Technology Company</span><br/>
                                    <span>{props.introduction.aliasIdentity.country}</span><br/>
                                    <span>{props.introduction.aliasIdentity.city}</span><br/>
                                    {/* <span>Company • Public • Global</span>
                                    <div className="d-flex justify-content-around user-box-links">
                                        <a href="" className={'link-small'}>Email</a>
                                        <a href="" className={'link-small'}>Call</a>
                                        <a href="" className={'link-small'}>Website</a>
                                    </div> */}
                                </div>
                            </GrayInfoBox>
    );
};

CompanyProfileInfo.propTypes = {};

export default CompanyProfileInfo;