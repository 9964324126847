import React, {useState} from 'react';
import '../styles/InputField.scss'
import {useDebounce} from "react-use";
import {autocompleteCall} from "../../helpers/helpers";

const TagsInput = ({url, placeholder, tags, setTags, name, setIsEdited, className}) => {
    const [resultTags, setResultTags] = useState([])
    const [searchValue, setSearchValue] = useState('')

    const [isReady, cancel] = useDebounce(
        async () => {
            const res = await autocompleteCall(url, searchValue)
            setResultTags(res)
            // console.log(res)
        },
        400,
        [searchValue]
    );

//remove item from array
    const removeTag = (index) => {
        const filteredTags = tags.filter((item, i) => {
            return i !== index
        })
        setTags(filteredTags)
        setIsEdited(true)
    }

//add tag in array
    const addTag = (item) => {
        if (!tags.some(tag => tag.id === item.id)) {
            setTags([...tags, item]);
        }
    }

    const handleChange = (e) => {
        setSearchValue(e.target.value)
        setIsEdited(true)
    }

    return (
        <>
            <div className={"tags-input " + className}>
                {
                    (tags || []).map((tag, i) => {
                        return <span key={i} className="tag ">{tag.name}
                            <span onClick={() => removeTag(i)}
                                  className={'ml-2 pointer'}>&times; </span>
                    </span>
                    })
                }
                <input name={name} type="text" onChange={handleChange} placeholder={placeholder}
                       size="1"/>

            </div>

            {resultTags && <div className={'d-flex flex-wrap mt-1'}>{(resultTags || []).map((item, i) => {
                return <span className={'res-tag pointer'} key={i} onClick={() => addTag(item)}>{item.name}</span>
            })}</div>}
        </>
    );
};

TagsInput.propTypes = {};

export default TagsInput;