import React from 'react';
import InputField from "../../../App/components/InputField";
import Checkbox from "../../../App/components/Checkbox";
import Dropdown from "../../../App/components/Dropdown";
import useAdvancedSearchFilters from "../../../helpers/customHooks/useAdvancedSearchFilters";

const InvestmentFundFields = ({setSearchFields, searchFields, setFilter}) => {
    const type = 'investmentFund'
    const initialState = {
        country: '',
        city: '',
        sector: '',
        subSector: '',
        ownership: '',
        influence: ''
    }
    const {handleChange, handleOnBlur, values} = useAdvancedSearchFilters(initialState, type, setFilter, searchFields, setSearchFields)
    return (
        <div className={'px-4 mb-2'}>
            <h6>Location</h6>
            <div className={'row mb-4 px-1'}>
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.country} name={'country'}
                                label={"Country"}/>
                </div>
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.city} name={'city'}
                                label={'City'}/>
                </div>
            </div>
            <h6>Sector</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.sector} name={'sector'}
                                label={'Sector'}/>
                </div>
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.subSector}
                                name={'subSector'}
                                label={'Sub-sector'}/>
                </div>
            </div>
            <h6>Ownership</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <Dropdown onBlur={handleOnBlur} onChange={handleChange} value={values.ownership}
                              options={[{name: <i>None</i>, value: ""}, {name: "Invest", value: "invest"}]}
                              name={'ownership'} label={'Ownership'}/>
                </div>
            </div>
            <h6>Influence</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <Dropdown onBlur={handleOnBlur} onChange={handleChange} value={values.influence}
                              options={[{name: <i>None</i>, value: ""}, {name: "Invest", value: "invest"}]}
                              name={'influence'} label={'Influence'}/>
                </div>
            </div>
            <Checkbox className={'label-large'}><b>Account Verified</b></Checkbox>
            <Checkbox className={'label-large'}><b>Portfolio</b></Checkbox>
        </div>
    );
};

InvestmentFundFields.propTypes = {};

export default InvestmentFundFields;