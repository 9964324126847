import React from "react";
import ReactDom from 'react-dom';
import GoogleMapReact from "google-map-react";
// import './marker.scss'
import './OpportunityComponents_2/marker.scss'

const Marker = () => {

  return <>
    <div className="pin"></div>
    <div className="pulse"></div>
  </>
}

const Map = ({ latitude, longitude }) => {
  return (
    <>
      {/* <div onClick={() => { console.log('latitude: ', latitude, 'longitude: ', longitude) }}>adasdasd</div> */}
      <div style={{ height: "300px", width: "700px" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            // key: "AIzaSyC4bshlppNOywv6wafbyjcU7v1urNo6yfo", // kaj mene veke ne raboti
            key: "AIzaSyBbBLkCPazpovjw0gwFNONaBvH4MhRzKH4", // od darko raboti
            language: "en",
            region: "US"
          }}
          center={{ lat: Number(latitude) || 51.506, lng: Number(longitude) || -0.169 }}
          // center={{ lat: Number(latitude), lng: Number(longitude) }}
          // defaultCenter={{ lat: Number(latitude), lng: Number(longitude) }}
          // defaultCenter={{ lat: latitude ? Number(latitude) : 51.506, lng: longitude ? Number(longitude) : -0.169 }}
          // defaultCenter={{ lat: 51.506, lng: -0.169 }}
          zoom={latitude ? 6 : 2}
        // defaultZoom={8}
        // defaultZoom={15}
        >
          {latitude && longitude && < Marker lat={Number(latitude)} lng={Number(longitude)} />}
          {/* <Marker lat={51.506} lng={-0.169} /> */}
        </GoogleMapReact>
      </div>
    </>
  );
};


export default Map;
