import React from 'react';
import '../../styles/MenuBar.scss'
import MenuActionIcon from "../../Common/MenuActionIcon";
import {useHistory, useLocation, useRouteMatch} from "react-router";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import Loader from "../../Common/Loader";
import Image from "../../../App/components/Image";

const MenuContracting = ({setModalOpen}) => {
    const history = useHistory();
    const location = useLocation()
    const match = useRouteMatch("/contract/:id")
    const {data, isLoading} = useSelector(state => state.user.account)

    const uploadDocument = () => {
        if (location.pathname !== '/contract' && match === null) {
            return history.push('/contract?modal=true')
        }
        return setModalOpen(true)
    }

    if (isLoading)
        return <Loader/>

    return (
        <>
            <div className={'menu-bar d-flex flex-column align-items-center justify-content-around text-center'}>
                <Link to="/">&laquo; Back to DV</Link>
                <div
                    className="menu-profile-box d-flex flex-column justify-content-between corner-radius10 bg-white ">
                    <div className="menu-profile-box-img simple-shadow">
                        <Link to="/profile">
                            <Image w={60} h={60}
                                   src={`http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${data.individualUserProfile.id}`}
                                   alt=""/>
                        </Link>
                    </div>
                </div>
                <div className="menu-actions">
                    <MenuActionIcon href={'/contracting'}
                                    name={'New Document'}
                                    src={'newDoc'}
                                    className={'bg-gray mb-4'}/>
                    <MenuActionIcon name={'Upload Document'}
                                    src={'upload'}
                                    className={'bg-gray mb-4'}
                                    onClick={uploadDocument}
                    />

                    <MenuActionIcon src={'allFiles'}
                                    name={'All Documents'}
                                    href={'/contracting'}
                                    className={'bg-gray mb-4'}/>
                    <MenuActionIcon
                        src={'notf'}
                        name={'Notifications'}
                        className={'bg-gray mb-4'}/>
                </div>
            </div>
        </>
    );
};

MenuContracting.propTypes = {};

export default MenuContracting;