import axios from 'axios'

const chatapi = axios.create({
    baseURL: 'http://devchat.devdv.ekonet.mk', // original
    headers: {
        'Content-Type': 'application/json'

    },
})
chatapi.interceptors.request.use(
    config => {
        const token = localStorage.getItem('chat-token')
        if (token) {
            config.headers['X-Auth-Token'] = token
        }
        const user_id = localStorage.getItem('chat-user-id')

        if (user_id) {
            config.headers['X-User-Id'] = user_id
        }
        return config
    },
    error => Promise.reject(error)
)
export default chatapi