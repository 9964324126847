import React from 'react';
import InputField from "../../../App/components/InputField";
import Button from "../../../App/components/Button";

const LocationEditing = props => {
    return (
        <form>
            <div className=" corner-radius10 bg-white p-3">
                <div className={'d-flex justify-content-between'}>
                    <h4 className={'font-weight-bold'}>Location</h4>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu neque pulvinar, tristique
                    orci ut, feugiat justo.</p>
                <div>
                    <InputField label={"Country"} className={'mb-4'}/>
                    <InputField label={"State/City"} className={'mb-4'}/>
                    <InputField label={"State/Region/Province"} className={'mb-4'}/>
                    <a href="" className={'link-small'}>Add second location</a>
                    <div className="d-flex justify-content-between mt-4">
                        <Button className={'btn-white'}>Delete</Button>
                        <Button className={'btn-blue'}>Update</Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

LocationEditing.propTypes = {};

export default LocationEditing;