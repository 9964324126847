import React, {useState} from 'react';
import MenuActionIcon from "./MenuActionIcon";
import {useHistory} from "react-router";
import Modal from "../../App/components/Modal";
import {Link} from "react-router-dom";

const MenuOpportunitiesProjects = ({modalContent, linkTo, page}) => {
    const [openModal, setOpenModal] = useState(false)
    const history = useHistory()
    return (
        <>
            <div style={{top: 0}}
                 className={'menu-bar d-flex flex-column align-items-center justify-content-around text-center'}>
                <div className={'logo d-flex align-items-center'}>
                    <img className={'pointer'}
                         onClick={() => history.push("/")}
                         src="/assets/images/logo.svg" alt=""/>
                </div>
                <Link to="/">&laquo; Back to DV</Link>

                <div className="menu-actions">
                    <MenuActionIcon href={'/' + linkTo}
                                    name={'All ' + page}
                                    src={'all-opportunities'}
                                    className={' mb-4'}/>
                    <MenuActionIcon
                        name={'Create ' + page}
                        src={'create-opportunity'}
                        className={' mb-4'}
                        onClick={() => setOpenModal(true)}
                    />
                </div>

            </div>
            {openModal && <Modal onClick={() => setOpenModal(false)}>
                {modalContent}
            </Modal>}
        </>
    );
};

MenuOpportunitiesProjects.propTypes = {};

export default MenuOpportunitiesProjects;