import {configureStore} from '@reduxjs/toolkit'
import {combineReducers} from 'redux'
import user from './user'
import contracting from './contracting'
import aliases from './aliases'
import advancedSearch from './advancedSearch'
import users from './users'
import test_location from './test_location'
import tabs_navigation from './tabs_navigation'
import mixed from './mixed'

const reducer = combineReducers({
    // here we will be adding reducers
    mixed,
    tabs_navigation,
    test_location,
    user,
    contracting,
    aliases,
    advancedSearch,
    users,
})
const store = configureStore({
    reducer,
})
export default store;