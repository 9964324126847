import React, { useEffect, useState } from 'react';
import FormBoxSection from "../../Common/FormBoxSection";
import FormColumns from "../../Common/FormColumns";
import InputAndActionContainer from "../../Common/InputAndActionContainer";
import InputContainer from "../../Common/InputContainer";
import TagsInput from "../../../App/components/TagsInput";
import IndTagsInput from '../../../App/components/IndTagsInput';
import useForm from "../../../helpers/customHooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { updateIndustry, updateSkills } from "../../../App/store/user";
import InputWithAutocomplete from "../../../App/components/InputWithAutocomplete";
import Loader from "../../Common/Loader";

import FormBoxSectionDualScreen from "../../Common/FormBoxSectionDualScreen";
const SkillsEditing = () => {
    const { data } = useSelector(state => state.user.introduction)
    const { skills, industry } = data
    const initialState = {
        name: '',
    }
    const { handleChange, handleSubmit, values, setValues, isEdited, setIsEdited } = useForm(initialState, submit, validate)
    const [tags, setTags] = useState([])

    //ova e nase
    const [indTags, setIndTags] = useState([])
    //----------------------- 

    const dispatch = useDispatch()

    function submit() {
        dispatch(updateSkills(tags))

        // dispatch(updateIndustry(values))
        setIsEdited(false)
    }

    function validate(v) {
        return {}
    }

    // useEffect(() => {
    //     if (!industry)
    //         return setValues(initialState)
    //     return setValues(industry)
    // }, [industry])

    useEffect(() => {
        typeof industry !== 'boolean' && setIndTags(industry)
    }, [industry])

    useEffect(() => {
        typeof skills !== 'boolean' && setTags(skills)
    }, [skills])

    const setNameInInput = (item) => {
        setValues(item)
    }
    return (
        <form action="" className={'mt-4r'} onSubmit={handleSubmit}>
            {/* <form action="" className={'mt-5'} onSubmit={handleSubmit}> */}
            <FormBoxSectionDualScreen title={"Skills"}>
                {(!skills || (industry && industry.updating)) ? <Loader /> :
                    <>
                        <div className={'d-flex flex-column py-2'}>
                            <InputContainer>
                                <TagsInput placeholder={'Type your skills'}
                                    url={'/api/info/skills/'}
                                    tags={tags} setTags={setTags}
                                    name={'skills'}
                                    setIsEdited={setIsEdited}
                                />
                            </InputContainer>
                            <div>
                                {isEdited && <button className={'btn btn-primary'} type='submit'>Save</button>}
                            </div>
                        </div>

                    </>
                }
            </FormBoxSectionDualScreen>
        </form>
    );
};

SkillsEditing.propTypes = {};

export default SkillsEditing;