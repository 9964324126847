import React from 'react';
// import '../styles/FormBoxSection.scss'
import FBSCss from './styles/FormBoxSectionDualScreen.module.scss'

const FormBoxSectionDualScreen = ({ title, className, children, style, noTitle, type }) => {
    if (noTitle) {
        return (
            <section className={className + ' d-flex flex-wrap justify-content-center  ' + ' '} style={style} >

                <div className="col-md-10 d-flex flex-column">
                    {children}
                </div>

            </section>
        );
    }
    else if (type == 'no_title_md_8_center_start') {
        return (
            <section className={className + ' d-flex flex-wrap justify-content-start  ' + ' '} style={style} >

                <div className="col-md-8 d-flex flex-column">
                    {children}
                </div>

            </section>
        );

    }
    else if (type == 'title_even_with_inputs_md_8_center_start') {
        return (
            <section className={FBSCss.form_box_section + " " + className + '  d-flex flex-wrap  ' + ' '} style={style} >

                <div className={' col-md-12  '}>
                    <div className={'row d-flex  justify-content-start'}>

                        <div className={FBSCss.form_box_title_div + "  col-md-8 text-left"}>
                            <div className={FBSCss.form_box_title + '   '}>{title}</div>
                        </div>

                    </div>
                </div>

                <div className={' col-md-12  '}>
                    <div className={'row d-flex  justify-content-start'}>

                        <div className="  col-md-8 d-flex flex-column">
                            {children}
                        </div>


                    </div>
                </div>
            </section>
        );
    }


    else {
        return (
            <section className={FBSCss.form_box_section + " " + className + '  d-flex flex-wrap  ' + ' '} style={style} >

                <div className={' col-md-12  '}>
                    <div className={'row'}>
                        <div className={FBSCss.form_box_title_div + "  col-md-10 text-left"}>
                            <div className={FBSCss.form_box_title + '   '}>{title}</div>
                        </div>
                    </div>
                </div>

                <div className={' col-md-12 '}>
                    <div className={'row'}>
                        <div className="  col-md-3 text-left">

                        </div>
                        <div className="  col-md-7 d-flex flex-column">
                            {children}
                        </div>
                    </div>
                </div>
            </section>
        );
    }

};

FormBoxSectionDualScreen.propTypes = {};

export default FormBoxSectionDualScreen;

