import React, {useEffect, useState} from 'react';
import Button from "../../App/components/Button";
import Tabs from "../../App/components/Tabs";
import Image from "../../App/components/Image";
import {useHistory, useLocation} from "react-router";
import InputField from "../../App/components/InputField";
import {getAdvancedSearchResults} from "../../App/store/advancedSearch";
import {useDispatch, useSelector} from "react-redux";
import api from "../../App/config/api";
import {sendFriendRequest} from "../../helpers/helpers";

const SearchHeaderMenu = props => {
    const history = useHistory()
    const [advancedSearch, setAdvancedSearch] = useState('')
    const location = useLocation()
    const dispatch = useDispatch()
    const [allUsers, setAllUsers] = useState([])
    const {data, isLoading} = useSelector(state => state.user.account)
    const {individualUserProfile} = data
    useEffect(() => {
        const getAllUsers = async () => {
            const res = await api.get('/api/suggested-users')
            setAllUsers(res.data)
        }
        getAllUsers()
    }, [])

    const handleChange = (e) => {
        setAdvancedSearch(e.target.value)
    }
    const allFilters = [
        {type: "post", fields: [], selected: false},
        {type: "individualUser", fields: [], selected: false},
        {type: "company", fields: [], selected: false},
        {type: "investmentFund", fields: [], selected: false},
        {type: "businessAssociation", fields: [], selected: false},
        {type: "freelanceProfile", fields: [], selected: false},
        {type: "serviceProvider", fields: [], selected: false},
        {type: "contract", fields: [], selected: false},
        {type: "opportunity", fields: [], selected: false},
        {type: "projects", fields: [], selected: false}
    ]
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            const newArr = allFilters.map((filter) => ({
                type: filter.type,
                fields: [{name: 'name', value: advancedSearch}]
            }))
            dispatch(getAdvancedSearchResults(newArr))
            history.push(`/advanced-search?modal=false`)
        }
    }

    const handleClick = () => {
        if (location.pathname !== '/advanced-search')
            history.push(`/advanced-search?search=${advancedSearch}`)
        else history.push(`/advanced-search?search=${advancedSearch}&modal=true`)
    }

    function handleRemove(id) {
        console.log("deleted" + id);
        const user = allUsers.filter(item => item.id !== id);
        setAllUsers(user)
    }

    return (
        <div>
            <div className={'d-flex justify-content-between px-3'}>
                <InputField label={"Search"} name={'search'} value={advancedSearch}
                            onKeyPress={handleKeyPress} onChange={handleChange}/>
                <Button className={'btn btn-outline-primary small-btn'}
                        onClick={handleClick}>Advanced</Button>
            </div>
            <div className={'tabs-border-bottom'}>
                <Tabs className={'tabs-header-menu'}
                      tabs={['All', 'Individual Users', 'Aliases', 'Opportunities', 'Media']}>
                    <div>
                        <div>
                            <h6>Recent</h6>
                            <div className={'d-flex flex-column mb-3'}>
                                <a href={''} className="edit-link">James Hobs</a>
                                <a href={''} className="edit-link">Amanda Jobs</a>
                                <a href={''} className="edit-link">Siemens</a>
                            </div>
                        </div>
                        <div>
                            <h6>Suggested users</h6>
                            {allUsers.map((item, i) => {
                                return i < 3 && <div className="d-flex align-items-center justify-content-between mb-3">
                                    <div key={i} className={'d-flex align-items-center'}>
                                        <Image w={65} h={65}
                                               src={`http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${item.id}`}
                                               alt=""/>

                                        <div className={'ml-3'}>
                                            <p className={'mb-2'}>{item.firstName + " " + item.lastName}</p>
                                            <div style={{fontSize: '0.8rem'}}>Computer Technology</div>
                                            <div style={{fontSize: '0.8rem'}}>Digital Marketing</div>
                                        </div>
                                    </div>
                                    <div>
                                        <Button onClick={() => {
                                            sendFriendRequest(individualUserProfile.id, item.id);
                                            handleRemove(item.id);
                                        }}
                                                className={'btn btn-outline-primary  small-btn'}>Add to Network</Button>
                                    </div>
                                </div>
                            })}


                        </div>
                        <div>
                            <h6>Suggested groups</h6>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className={'d-flex align-items-center'}>
                                    <Image w={65} h={65}
                                           src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/corporate-company-logo-design-template-2402e0689677112e3b2b6e0f399d7dc3_screen.jpg?ts=1561532453"
                                           alt=""/>
                                    <div className={'ml-3'}>
                                        <p className={'mb-2'}>Corporate</p>
                                        <div style={{fontSize: '0.8rem'}}>Group</div>
                                        <div style={{fontSize: '0.8rem'}}>1.234 members</div>
                                    </div>
                                </div>
                                <div>
                                    <Button className={'btn-white small-btn'}>+ Follow</Button>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className={'d-flex align-items-center'}>
                                    <Image w={65} h={65}
                                           src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/green-company-logo-design-template-e6cda0adebc58ba974fa4d2d02a14d1e_screen.jpg?ts=1567170398"
                                           alt=""/>

                                    <div className={'ml-3'}>
                                        <p className={'mb-2'}>Green Company</p>
                                        <div style={{fontSize: '0.8rem'}}>Group</div>
                                        <div style={{fontSize: '0.8rem'}}>52.122 members</div>
                                    </div>
                                </div>
                                <div>
                                    <Button className={'btn-white small-btn'}>+ Follow</Button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h6>Suggested opportunities</h6>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className={'d-flex align-items-center'}>
                                    <Image w={65} h={65}
                                           src="https://www.logoarena.com/contestimages/public_new/1844/129_1358314319_cloud1.jpg"
                                           alt=""/>

                                    <div className={'ml-3'}>
                                        <p className={'mb-2'}>Cloud Solutions</p>
                                        <div style={{fontSize: '0.8rem'}}>Simens</div>
                                        <div style={{fontSize: '0.8rem'}}>23 applicants</div>
                                    </div>
                                </div>
                                <div>
                                    <Button className={'btn-white small-btn'}>+ Follow</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </Tabs>
            </div>
        </div>
    );
};

SearchHeaderMenu.propTypes = {};

export default SearchHeaderMenu;