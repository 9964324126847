import React from 'react';

const CreateProjectBoardBox = ({title, children}) => {
    return (
        <div className={'simple-border bg-white corner-radius5 p-4'}>
            <div className={'border-bottom text-center'}><h4><b>{title}</b></h4></div>
            <div className={'mt-3'}>
                {children}
            </div>
        </div>
    );
};

CreateProjectBoardBox.propTypes = {};

export default CreateProjectBoardBox;