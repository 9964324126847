import React from 'react';
import VerificationEditing from "../../Common/VerificationEditing";
import {useParams} from "react-router";

const Verification = ({data}) => {
    const params = useParams()
    const id = params.id || Object.keys(data)[0]
    const verified = data[id].aliasIdentity.verified

    return (
        <div>
            <VerificationEditing verified={verified} id={id} type={"BUSINESS_ASSOCIATIONS_GOVERNMENTAL"}/>
        </div>

    );
};

Verification.propTypes = {};

export default Verification;