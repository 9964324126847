import React, { useState, useEffect } from "react";
import ProfileInfoBoxSection from "../../Common/ProfileInfoBoxSection";
import IOCss from './styles/InfoOpportunitiesView.module.scss';


import OVCss from './styles/OpportunitiesView.module.scss'


const InfoOpportunitiesView = ({ props }) => {

    const { name, description, startDate, projectWebsite, ownership, contribution, budget, currency, sector, subSector, untangableAsset } = props
    const [readMore, setReadMore] = useState(false);
    let readMoreLength = 218;


    //-------------------------------------------------------------------
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    //-------------------------------------------------------------------


    useEffect(() => {
        setReadMore(false)
    }, [props])

    return (



        // <ProfileInfoBoxSection custom={'Overview'} className={'mt-3 border-gray margin-b-1 '} title={name} >
        // <ViewProfileBoxSection className={'mt-3 margin-b-1 '}  >

        <section className={` mt-3 margin-b-1 d-flex flex-wrap  ${OVCss.border_gray} ${OVCss.form_box} `}>
            <div className="col-md-12 d-flex flex-column">

                <div>
                    <div className={OVCss.view_profile_form_title + ' mt-2 '}>{name}</div>

                </div>


                <div className=" ">
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Description</div>
                    {description.length <= readMoreLength ? (
                        <div>{description}</div>
                    ) : description.length > readMoreLength && readMore ? (
                        <div>
                            <div className={OVCss.view_profile_form_normal_text}>{description}</div>
                            <a
                                href={"javascript:void(0)"}
                                className={"link-small"}
                                onClick={() => setReadMore(!readMore)}>
                                Read Less
                            </a>
                        </div>
                    ) : (
                        <div>
                            <div className={OVCss.view_profile_form_normal_text}>{description.substr(0, readMoreLength) + ' ...'}</div>
                            <a
                                href={"javascript:void(0)"}
                                className={"link-small"}
                                onClick={() => setReadMore(!readMore)}>
                                Read More...
                            </a>
                        </div>
                    )}
                </div>


                <div>
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Start Date</div>

                    {/* moe za proba */}
                    <div className={OVCss.view_profile_form_normal_text}>{new Date(startDate).toLocaleDateString()}</div>
                    {/* moe za proba */}
                </div>
                <div>
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Project Website</div>
                    {/* moe za proba */}
                    <div className={OVCss.view_profile_form_normal_text}>{projectWebsite}</div>
                    {/* moe za proba */}
                </div>
                <div>
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Ownership</div>
                    {/* moe za proba */}
                    <div className={OVCss.view_profile_form_normal_text}>{ownership}</div>
                    {/* moe za proba */}
                </div>
                <div>
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Contribution</div>
                    {/* moe za proba */}
                    <div className={OVCss.view_profile_form_normal_text}>{contribution}</div>
                    {/* moe za proba */}
                </div>
                {
                    budget && currency && < div >
                        <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Project Budget</div>
                        {/* moe za proba */}
                        <div className={OVCss.view_profile_form_normal_text}>{budget + ' / ' + currency}</div>
                        {/* moe za proba */}
                    </div>
                }
                {
                    untangableAsset && <div>
                        <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Untangible Asset</div>
                        {/* moe za proba */}
                        <div className={OVCss.view_profile_form_string_with_commas_text}>{untangableAsset.split(",").join(', ')}</div>
                        {/* <div className={OVCss.view_profile_form_normal_text}>{untangableAsset.split(",").join(' ')}</div> */}

                        {/* moe za proba */}
                    </div>
                }
                <div>
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Sector</div>
                    {/* moe za proba */}
                    <div className={OVCss.view_profile_form_normal_text}>{sector}</div>
                    {/* moe za proba */}
                </div>
                <div>
                    <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Subector</div>
                    {/* moe za proba */}
                    <div className={OVCss.view_profile_form_normal_text}>{subSector}</div>
                    {/* moe za proba */}
                </div>
                {/* </ProfileInfoBoxSection > */}
                {/* </ViewProfileBoxSection > */}
            </div>
        </section>
    )
}

export default InfoOpportunitiesView