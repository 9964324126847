import React from 'react';
import Header from "../../Common/Header";
import MenuBar from "../../Common/MenuBar";
import Tabs from "../../../App/components/Tabs";
import Introduction from "../Creation/Introduction";
import Purpose from "../../IndividualUserProfile/Creation/Purpose";
import BranchesAndSubsidiary from "../Creation/BranchesAndSubsidiary";
import Clubs from "../Creation/Clubs";
import Media from "../Creation/Media";
import Activities from "../Creation/Activities";
import Verification from "../Creation/Verification";
import { useSelector } from "react-redux";
import OpportunityAlias from '../../CompanyProfile/Creation/OpportunityAlias';
import PurposeAlias from '../../CompanyProfile/Creation/PurposeAlias'

const NonGovernmentalProfileCreation = props => {
    const nonGovernmentalProfileCreated = useSelector(state => {
        if (state.aliases.data.nonGovernmentalOrganisations === undefined)
            return {}
        else
            return state.aliases.data.nonGovernmentalOrganisations
    })
    return (
        <div>
            <Header />
            <div className=""><MenuBar page={'Company'} /></div>
            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container'}>
                    <Tabs
                        tabClassName={!Object.keys(nonGovernmentalProfileCreated).length ? 'click-disabled' : ''}
                        className={'tabs-dashboard'}
                        // tabs={['Introduction', 'Purpose', 'Branches & Subsidiary', 'Clubs', 'Media', 'Activities', 'Verification']}>
                        tabs={['Introduction', 'Purpose', 'Branches', 'Opportunites', 'Verification']}>
                        <Introduction />
                        <PurposeAlias />
                        <BranchesAndSubsidiary />
                        <OpportunityAlias />
                        {/* <Clubs/> */}
                        {/* <Media/> */}
                        {/* <Activities/> */}
                        {/* <div>Opportunites Creation</div> */}
                        <Verification data={nonGovernmentalProfileCreated} />
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

NonGovernmentalProfileCreation.propTypes = {};

export default NonGovernmentalProfileCreation;