import React, { useEffect, useState } from 'react';
import FormBoxSection from "./FormBoxSection";
import FormColumns from "./FormColumns";
import InputAndActionContainer from "./InputAndActionContainer";
import InputField from "../../App/components/InputField";
import useForm from "../../helpers/customHooks/useForm";
import Dropdown from "../../App/components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import api from "../../App/config/api";
import { useParams } from "react-router";
import { createAlias, updateAlias } from "../../App/store/aliases";
import Loader from "./Loader";
import InputContainer from "./InputContainer";
import FormBoxSectionDualScreen from "./FormBoxSectionDualScreen";


const IdentityEditing = ({ aliasType, data, AssociationTypeEditing }) => {
    const { aliasIdentity } = data || {}
    const dispatch = useDispatch()
    const params = useParams()
    const activeId = useSelector(state => state.aliases.data.activeId)
    const initialState = {
        name: "",
        publicUrl: "",
        description: "",
        openSince: "",
        size: aliasType == 'LEGAL_ENTITIES_COMPANY' ? "SMALL" : "",
        aliasType: aliasType,
        phoneNumber: '',
        email: '',
        website: '',
        youtubeChanel: '',
        linkedin: '',
        ownership: '',
        influence: '',
        sector: '',
        subSector: '',
        country: '',
        city: ''

    }

    const { handleChange, handleSubmit, values, setValues, isEdited, setIsEdited } = useForm(initialState, submit, validate)
    const [message, setMessage] = useState(null)

    function submit() {
        if (params.id || activeId) {
            return dispatch(updateAlias({ ...values, id: params.id || activeId }))
        }
        setIsEdited(false)
        return dispatch(createAlias(values))
    }

    function validate(v) {
        return {}
    }

    useEffect(() => {

        if (aliasIdentity)
            return setValues(aliasIdentity)
    }, [aliasIdentity])

    const checkAliasName = async () => {
        if ((aliasIdentity && aliasIdentity.name === values.name) || !values.name)
            return setMessage(null)
        const res = await api.get(`/api/check-alias-name/${values.name}`)
        setMessage(res.data.nameStatus)
    }

    return (
        // <form className={'mt-5'} action="" onSubmit={handleSubmit}>
        // <form className={`${aliasType == 'LEGAL_ENTITIES_COMPANY' ? 'mt-3' : 'mt-5'}`} action="" onSubmit={handleSubmit}>
        <form className={`${aliasType == 'LEGAL_ENTITIES_COMPANY' ? 'mt-3' : 'mt-4r'}`} action="" onSubmit={handleSubmit}>
            {aliasIdentity && aliasIdentity.updating ? <Loader /> : <>
                {aliasType == 'LEGAL_ENTITIES_COMPANY' &&
                    <div className={'row'}>
                        <div className="col-md-6">

                            <FormBoxSectionDualScreen type={'no_title_md_8_center_start'} className={' margin-b-0 '} title={""}>
                                < >
                                    <div className={'d-flex flex-column py-2 '}>
                                        <InputContainer >
                                            <div className=' g-company-alias-create-introduction-logo-container '>
                                                <div className=' g-company-alias-create-introduction-logo-div '><img className={' g-company-alias-create-introduction-logo-img '} src={`/assets/images/alias/CompanyAlias.svg`} alt="" /></div>
                                                <div className=' g-company-alias-create-introduction-update-logo-div '><button className=' g-company-alias-create-introduction-update-logo-button ' >Company Logo</button></div>

                                            </div>
                                        </InputContainer>
                                    </div>
                                </>
                            </FormBoxSectionDualScreen>

                        </div>
                    </div>}
                <div className={'row'}>
                    <div className="col-md-6">

                        {AssociationTypeEditing}

                        <FormBoxSectionDualScreen type={'title_even_with_inputs_md_8_center_start'} className={`${aliasType == 'LEGAL_ENTITIES_COMPANY' && ' mt-1r ' + ' margin-b-0'}`} title={"Identity"}>
                            {/* <FormBoxSectionDualScreen type={'title_even_with_inputs_md_8_center_start'} className={'   margin-b-0 '} title={"Identity"}> */}
                            <>
                                <div className={'d-flex flex-column py-2'}>
                                    <InputContainer >
                                        <> <InputField
                                            className={message && message === 'Name already used' ? 'red' : message && 'green'}
                                            onBlur={checkAliasName} label={"Company Name"}
                                            name={'name'}
                                            onChange={handleChange} value={values.name}
                                        />{message && <span style={{ color: 'black' }}>{message}</span>}</>
                                    </InputContainer>

                                    <InputContainer >
                                        <InputField label={"Public URL"} name={'publicUrl'}
                                            onChange={handleChange} value={values.publicUrl} />
                                    </InputContainer>

                                    {(aliasType !== 'BUSINESS_ASSOCIATIONS_NON_GOVERNMENTAL' && aliasType !== 'BUSINESS_ASSOCIATIONS_GOVERNMENTAL') &&
                                        <InputContainer >
                                            <InputField multiline rows={4} label={"Description"} name={'description'}
                                                onChange={handleChange} value={values.description} />
                                        </InputContainer>}


                                    <InputContainer >
                                        <InputField label={"Open Since"} name={'openSince'} type={'date'}
                                            onChange={handleChange} value={values.openSince} />

                                    </InputContainer>

                                    {aliasType !== 'LEGAL_ENTITIES_COMPANY' &&
                                        <InputContainer >
                                            <Dropdown options={[{ name: 'Small', value: 'SMALL' }, {
                                                name: 'Medium',
                                                value: 'MEDIUM'
                                            }, {
                                                name: 'Large',
                                                value: 'LARGE'
                                            }]} label={'Size'} onChange={handleChange} name={'size'}
                                                value={values.size || ''}
                                            />
                                        </InputContainer>
                                    }
                                </div>
                            </>
                        </FormBoxSectionDualScreen>


                        <FormBoxSectionDualScreen type={'title_even_with_inputs_md_8_center_start'} className={'  mt-3r-1r-res  margin-b-0 '} title={"Contact"}>
                            <  >

                                <div className={'d-flex flex-column py-2 '}>

                                    <InputContainer >
                                        <InputField label={"Phone Number"} name={'phoneNumber'}
                                            onChange={handleChange} value={values.phoneNumber} />
                                    </InputContainer>


                                    <InputContainer >
                                        <InputField label={"Email"} name={'email'}
                                            onChange={handleChange} value={values.email} />
                                    </InputContainer>

                                    {aliasType == 'LEGAL_ENTITIES_COMPANY' &&
                                        <InputContainer >
                                            <InputField label={"Website"} name={'website'}
                                                onChange={handleChange} value={values.website} />
                                        </InputContainer>}

                                    {aliasType == 'LEGAL_ENTITIES_COMPANY' &&
                                        <InputContainer >
                                            <InputField label={"Youtube Chanel"} name={'youtubeChanel'}
                                                onChange={handleChange} value={values.youtubeChanel} />
                                        </InputContainer>}

                                    <InputContainer >
                                        <InputField label={"Linkedin"} name={'linkedin'}
                                            onChange={handleChange} value={values.linkedin} />
                                    </InputContainer>


                                </div>

                            </>
                        </FormBoxSectionDualScreen>
                    </div>
                    <div className="col-md-6 ">
                        <FormBoxSectionDualScreen type={'title_even_with_inputs_md_8_center_start'} className={`${aliasType == 'LEGAL_ENTITIES_COMPANY' && ' mt-1r ' + ' margin-b-0 '}`} title={"Company type"}>
                            <  >
                                <div className={'d-flex flex-column py-2 '}>

                                    <InputContainer >
                                        <InputField label={"Ownership"} name={'ownership'}
                                            onChange={handleChange} value={values.ownership} />
                                    </InputContainer>

                                    <InputContainer >
                                        <InputField label={"Influence"} name={'influence'}
                                            onChange={handleChange} value={values.influence} />
                                    </InputContainer>

                                    <InputContainer >
                                        <InputField label={"Sector"} name={'sector'}
                                            onChange={handleChange} value={values.sector} />
                                    </InputContainer>

                                    <InputContainer >
                                        <InputField label={"SubSector"} name={'subSector'}
                                            onChange={handleChange} value={values.subSector} />
                                    </InputContainer>

                                </div>

                            </>
                        </FormBoxSectionDualScreen>

                        <FormBoxSectionDualScreen type={'title_even_with_inputs_md_8_center_start'} className={'  mt-3r-1r-res  margin-b-0 '} title={"Location"}>
                            < >

                                <div className={'d-flex flex-column py-2 '}>

                                    <a href={'#doesnt_matter_is_just_for_show'} className={'link-small my-2'}>Remove</a>

                                    <InputContainer>
                                        <InputField label={"Country"} name={'country'}
                                            onChange={handleChange} value={values.country} />
                                    </InputContainer>

                                    <InputContainer>
                                        <InputField label={"City"} name={'city'}
                                            onChange={handleChange} value={values.city} />
                                    </InputContainer>
                                    {/* JUST FOR SHOW */}
                                    <InputContainer>
                                        <InputField label={"State"} name={'state'} />
                                    </InputContainer>

                                    <a href={'#doesnt_matter_is_just_for_show'} className={'link-small my-2'}>Add new location</a>
                                    {/* //----------------------------------- */}
                                    <div>
                                        {isEdited && <button className={'btn btn-primary mt-2'} type='submit'>Save</button>}
                                    </div>
                                </div>

                            </>
                        </FormBoxSectionDualScreen>
                    </div>
                </div>
            </>}

        </form>
    )
};

IdentityEditing.propTypes = {};

export default IdentityEditing;