import React from 'react';
import ContactEditing from "../../Common/ContactEditing";
import LocationEditing from "../../Common/LocationEditing";
import CompanyTypeEditing from "../../Common/CompanyTypeEditing";
import IdentityEditing from "../../Common/IdentityEditing";
import AssociationTypeEditing from "../../Common/AssociationTypeEditing";
import { checkAliasType } from "../../../helpers/helpers";
import useGetAliasIntroduction from "../../../helpers/customHooks/useGetAliasIntroduction";

const Introduction = props => {
    const aliasTypeStr = 'BUSINESS_ASSOCIATIONS_NON_GOVERNMENTAL'
    const { aliasType, introduction, paramsId, activeId, isLoading } = useGetAliasIntroduction(aliasTypeStr)

    if (isLoading && paramsId)
        return <div> loading</div>
    return (
        <div>
            {/* {!paramsId && !activeId && <AssociationTypeEditing aliasTypeValue={checkAliasType(aliasTypeStr).route} />} */}
            <IdentityEditing
                AssociationTypeEditing={!paramsId && !activeId && <AssociationTypeEditing aliasTypeValue={checkAliasType(aliasTypeStr).route} />}
                // AssociationTypeEditing={<AssociationTypeEditing aliasTypeValue={checkAliasType(aliasTypeStr).route} />}
                aliasType={aliasTypeStr} data={introduction} />



            {/* <ContactEditing aliasType={aliasType}/>
            <CompanyTypeEditing aliasType={aliasType}/>
            <LocationEditing aliasType={aliasType}/> */}
        </div>
    );
};

Introduction.propTypes = {};

export default Introduction;