import React from 'react';
import InputField from "../../../App/components/InputField";
import Checkbox from "../../../App/components/Checkbox";
import Button from "../../../App/components/Button";

const AddParties = ({setOpenModal, title}) => {
    return (
        <div>

            <div className=" corner-radius10 bg-white p-3">
                <div className={'d-flex justify-content-between'}>
                    <h4 className={'font-weight-bold mb-4'}>{title}</h4>
                </div>
                <div>
                    <InputField/>
                    <div className={'d-flex justify-content-end pt-2 my-2'}><a className={'link-small'}
                                                                               href=""><b>Select
                        All</b></a></div>
                    <div>
                        <div className={'d-flex pt-2'}>
                            <div style={{minWidth: 40, maxWidth: 40, height: 40}} className={'mr-3'}>
                                <img className={'img-responsive w-100 h-100 rounded-circle'}
                                     style={{objectFit: 'cover'}}
                                     src={'https://preview.keenthemes.com/metronic-v4/theme/assets/pages/img/avatars/team1.jpg'}
                                     alt=""/>
                            </div>
                            <div className={'border-bottom pb-2 d-flex w-100  justify-content-between'}>
                                <div className={' '}><h6>Steven Miller</h6>
                                    <div className="small">Management - Lawyer</div>
                                </div>
                                <div><Checkbox></Checkbox></div>
                            </div>
                        </div>
                        <div className={'d-flex pt-2'}>
                            <div style={{minWidth: 40, maxWidth: 40, height: 40}} className={'mr-3'}>
                                <img className={'img-responsive w-100 h-100 rounded-circle'}
                                     style={{objectFit: 'cover'}}
                                     src={'https://www.josari.io/wp-content/uploads/2019/07/smiling-man-in-suit-1920x1080-1024x576.jpg'}
                                     alt=""/>
                            </div>
                            <div className={'border-bottom pb-2 d-flex w-100 justify-content-between'}>
                                <div className={' '}><h6>Paul Evens</h6>
                                    <div className="small">Management - Lawyer</div>
                                </div>
                                <div><Checkbox></Checkbox></div>
                            </div>
                        </div>
                        <div className={'d-flex pt-2'}>
                            <div style={{minWidth: 40, maxWidth: 40, height: 40}} className={'mr-3'}>
                                <img className={'img-responsive w-100 h-100 rounded-circle'}
                                     style={{objectFit: 'cover'}}
                                     src={'https://pixomatic.us/blog/wp-content/uploads/2019/11/pixomatic_1572877223091.png'}
                                     alt=""/>
                            </div>
                            <div className={'border-bottom pb-2 d-flex w-100 justify-content-between'}>
                                <div className={' '}><h6>Augusto Memeli</h6>
                                    <div className="small">Management - Lawyer</div>
                                </div>
                                <div><Checkbox></Checkbox></div>
                            </div>
                        </div>
                        <div className={'d-flex pt-2'}>
                            <div style={{minWidth: 40, maxWidth: 40, height: 40}} className={'mr-3'}>
                                <img className={'img-responsive w-100 h-100 rounded-circle'}
                                     style={{objectFit: 'cover'}}
                                     src={'https://images.squarespace-cdn.com/content/v1/5d96fc780e85b877a919f4f2/1570777847921-A6W51WBD9SWVQYVPGJA4/ke17ZwdGBToddI8pDm48kMXRibDYMhUiookWqwUxEZ97gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0luUmcNM2NMBIHLdYyXL-Jww_XBra4mrrAHD6FMA3bNKOBm5vyMDUBjVQdcIrt03OQ/Angela-16.jpg'}
                                     alt=""/>
                            </div>
                            <div className={'border-bottom pb-2 d-flex w-100 justify-content-between'}>
                                <div className={' '}><h6>Hanna Collins</h6>
                                    <div className="small">Management - Lawyer</div>
                                </div>
                                <div><Checkbox></Checkbox></div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="d-flex justify-content-end mt-4">
                    <Button className={'btn-blue'}
                            onClick={(e) => {
                                e.preventDefault()
                                setOpenModal(false)
                            }}>Done</Button>
                </div>
            </div>

        </div>
    );
};

export default AddParties;