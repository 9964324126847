import React from 'react';
import Image from "../../../App/components/Image";
import {Link} from "react-router-dom";

const ProjectBoxView = ({name, location, img, btn}) => {
    return (
        <div style={{minHeight: 200, minWidth: 350}}
             className={'simple-border corner-radius5 p-4 d-flex align-items-center justify-content-between bg-white m-2'}>
            <div style={{minWidth: 150, maxWidth: 150, height: 150}} className={'mr-3'}>
                <img className={'img-responsive w-100 h-100 corner-radius5'} style={{objectFit: 'cover',}}
                     src={img}
                     alt=""/>
            </div>
            <div className={'w-100'}>
                <div className={'border-bottom p-2'}>
                    <div className={'d-flex justify-content-between'}><h5>{name}</h5>{btn &&
                    <Link className={'link-small'} to={'/create-project?name=AECF'}>View Project</Link>}</div>
                    <div>{location}</div>
                </div>
                <div className={'d-flex p-2 align-items-center'}>
                    <div className={'d-flex flex-wrap'}
                         style={{width: 100}}>{['https://preview.keenthemes.com/metronic-v4/theme/assets/pages/img/avatars/team1.jpg', 'https://i.pinimg.com/600x315/a6/0d/68/a60d685194a7fd984d08a595a0a99ae7.jpg', 'https://i.pinimg.com/236x/67/5f/40/675f409cfcbea9281f6649ca096caf25--corporate-photography-headshot-photography.jpg', 'https://osmey-milian-photography.com/_files/200002613-7c7f77d81f/OMD_7187-Website.jpg', 'https://content1.getnarrativeapp.com/68f6039f-a2f9-4e15-9b91-4f7cebe2a378/img_ref/4e80a9d6-aef4-4875-ab94-5952b6926e07/Hamish-5_professional_headshots_photographer_headshot_750.jpg', 'https://pixomatic.us/blog/wp-content/uploads/2019/11/pixomatic_1572877223091.png',].map((item, i) => {
                        return <Image key={i}
                                      style={{
                                          minWidth: 20,
                                          maxWidth: 20,
                                          height: 20,
                                          marginLeft: i !== 0 ? -10 : 0
                                      }}
                                      className={'mr-1 d-flex '} src={item}>
                        </Image>
                    })}</div>
                    <div>{Math.floor((Math.random() * 50) + 5)} people invited</div>
                </div>
            </div>
        </div>
    );
};

ProjectBoxView.propTypes = {};

export default ProjectBoxView;