import React from 'react';
import useForm from "../../../helpers/customHooks/useForm";
import FormBoxSection from "../../Common/FormBoxSection";
import FormColumns from "../../Common/FormColumns";
import InputAndActionContainer from "../../Common/InputAndActionContainer";
import InputField from "../../../App/components/InputField";
import Dropdown from "../../../App/components/Dropdown";

const AvailabilityEditing = props => {
    const {handleChange, handleSubmit, values, errors} = useForm({email: '', password: ""}, submit, validate)

    function submit() {
        console.log(values)
        console.log(errors)
    }

    function validate(v) {
        return {}
    }

    return (
        <form action="" className={'mt-5'} onSubmit={handleSubmit}>
            <FormBoxSection title={"Availability"}>
                <FormColumns>
                    <div className={'d-flex flex-column py-4'}>
                        <InputAndActionContainer>
                            <Dropdown label={"Select work days"} options={['']}
                                      onChange={handleChange}/>
                        </InputAndActionContainer>
                        <InputAndActionContainer>
                            <InputField label={"From"}/>
                        </InputAndActionContainer>
                        <InputAndActionContainer>
                            <InputField label={"To"}/>
                        </InputAndActionContainer>
                    </div>
                    <div>
                        <button className={'btn edit-link'} type='submit'>Done editing</button>
                    </div>
                </FormColumns>
            </FormBoxSection>
        </form>
    )
};

AvailabilityEditing.propTypes = {};

export default AvailabilityEditing;