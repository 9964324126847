import React from 'react';
import InputField from "../../../App/components/InputField";
import Checkbox from "../../../App/components/Checkbox";
import Dropdown from "../../../App/components/Dropdown";
import useAdvancedSearchFilters from "../../../helpers/customHooks/useAdvancedSearchFilters";

const ServiceProviderFields = ({setSearchFields, searchFields, setFilter}) => {
    const type = 'serviceProvider'
    const initialState = {
        country: '',
        city: '',
        language: '',
        languageLevel: '',
        sector: '',
        subSector: '',
        skills: '',
        skillsLevel: '',
        institution: '',
        subject: '',
        speciality: '',
        ownership: '',
        influence: ''
    }
    const {handleChange, handleOnBlur, values} = useAdvancedSearchFilters(initialState, type, setFilter, searchFields, setSearchFields)

    return (
        <div className={'px-4 mb-2'}>
            <h6>Location</h6>
            <div className={'row mb-4 px-1'}>
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.country} name={'country'}
                                label={"Country"}/></div>
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.city} name={'city'}
                                label={'City'}/>
                </div>
            </div>
            <h6>Sector</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.sector} name={'sector'}
                                label={'Sector'}/>
                </div>
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.subSector}
                                name={'subSector'} label={'Sub-sector'}/>
                </div>
            </div>
            <h6>Language</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.language} name={'language'}
                                label={'Language'}/>
                </div>
                <div className="col-md-6">
                    <Dropdown onBlur={handleOnBlur} onChange={handleChange} value={values.languageLevel} options={[]}
                              name={'languageLevel'} label={'Level'}/>
                </div>
            </div>

            <h6>Skills</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.skills} name={'skills'}
                                label={'Skill'}/>
                </div>
                <div className="col-md-6">
                    <Dropdown onBlur={handleOnBlur} onChange={handleChange} value={values.skillsLevel}
                              name={'skillsLevel'} options={[]} label={'Level'}/>
                </div>
            </div>
            <h6>Licenses and Certificates</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.institution}
                                name={'institution'} label={'Institution'}/>
                </div>
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.subject} name={'subject'}
                                label={'Subject'}/>
                </div>
            </div>
            <h6>Speciality</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <InputField onBlur={handleOnBlur} onChange={handleChange} value={values.speciality}
                                name={'speciality'} label={'Speciality'}/>
                </div>

            </div>
            <h6>Ownership</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <Dropdown onBlur={handleOnBlur} onChange={handleChange} value={values.ownership} name={'ownership'}
                              options={[]} label={'Ownership'}/>
                </div>
            </div>
            <h6>Influence</h6>
            <div className="row mb-4 px-1">
                <div className="col-md-6">
                    <Dropdown onBlur={handleOnBlur} onChange={handleChange} value={values.influence} options={[]}
                              name={'influence'} label={'Influence'}/>
                </div>
            </div>
            <Checkbox className={'label-large'}><b>Account Verified</b></Checkbox>
            <Checkbox className={'label-large'}><b>Available Employees</b></Checkbox>

        </div>
    );
};

ServiceProviderFields.propTypes = {};

export default ServiceProviderFields;