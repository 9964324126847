import React from 'react'
import { SearchOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { TextField, IconButton } from '@material-ui/core';

const SearchInputField = withStyles(theme => ({
    root: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: `90px`,
        },
      },
    
        '& .MuiOutlinedInput-input': {
         padding: '16px 18px',

         [theme.breakpoints.down('768')]: {
          padding: '14px 16px',
        }

        },
  
      '& .MuiInputBase-root': {
        color: 'black',
        fontSize: 14,
  
        [theme.breakpoints.down('768')]: {
          fontSize: 13,
        }
      },
    },
  }))(TextField);

export default SearchInputField


// const StyledTextField = withStyles(theme => ({
//     root: {
//       '& .MuiOutlinedInput-root': {
//         '& fieldset': {
//           borderRadius: `90px`,
//         },
//       },
//       '& .MuiInputBase-root': {
//         color: 'black',
//         fontSize: 14,
  
//         [theme.breakpoints.down('768')]: {
//           fontSize: 11,
//         }
//       },
//     },
//   }))(TextField);

//------------------------------

// const SearchInputField = withStyles(theme => ({
//   root: {
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderRadius: `90px`,
//       },
//     },
//     '& .MuiOutlinedInput-root': {
//       '& .MuiOutlinedInput-input': {
//        padding: 10,
//       },
//     },
//     '& .MuiInputBase-root': {
//       color: 'black',
//       fontSize: 14,

//       [theme.breakpoints.down('768')]: {
//         fontSize: 11,
//       }
//     },
//   },
// }))(TextField);

// export default SearchInputField