import React from 'react';
import '../styles/AutocompleteList.scss'

const AutocompleteList = ({list, inputName, idx, onClick, autocompleteOpen, setAutocompleteOpen}) => {

    return (
        list.length && autocompleteOpen ? <div className={'autocomplete'}>
            {list.map((item, index) => {
                return <div className={'autocomplete-item'}
                            onClick={() => {
                                setAutocompleteOpen(false)
                                onClick(item, inputName, idx)
                            }}
                            key={index}>{item.name}</div>
            })}</div> : null
    )
};

AutocompleteList.propTypes = {};

export default AutocompleteList;