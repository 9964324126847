import React from 'react'
import Header from "../../Common/Header";
import MenuBar from "../../Common/MenuBar";
import Tabs from "../../../App/components/Tabs";

import DueDiligenceSharedComponent from '../components/DueDiligenceSharedComponent';
import AMLCheck from '../components/AMLCheck';


const DueDiligence = () => {
  return (
    <div>
      <Header />
      <div className=""><MenuBar page={'Profile'} /></div>
      <div className="col-md-11 offset-md-1 ">
        <div className={'dashboard-inner-container'}>
          <Tabs
            className={'tabs-dashboard'}
            tabs={['AML Check', 'AML Check Statements', 'Packages']}>
            <AMLCheck />
            <AMLCheck />
            <AMLCheck />
           
          </Tabs>
        </div>
      </div>
    </div>
  )
}

DueDiligence.propTypes = {};
export default DueDiligence