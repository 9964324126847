import React from 'react';

const FormColumns = ({ children, dualScreen }) => {

    if (dualScreen) {
        return (
            <div className={'row'}>
                <div className={'col-md-12'}>{children[0]}</div>
                {/* <div className={'col-md-3 text-right'}>{children[1]}</div> */}
            </div>
        );
    }
    else {
        return (
            <div className={'row'}>
                <div className={'col-md-9'}>{children[0]}</div>
                <div className={'col-md-3 text-right'}>{children[1]}</div>
            </div>
        );
    }


};

FormColumns.propTypes = {};

export default FormColumns;