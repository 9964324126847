import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    value: ['one', "two", 'three']
}

export const locationsSlice = createSlice({

    name: 'locations',
    initialState,
    reducers: {

        addLocation: (state, action) => {
            state.value.push(action.payload)
        }
    }
})

export const { addLocation } = locationsSlice.actions

export default locationsSlice.reducer