import React from 'react';
import Button from "../../../App/components/Button";
import ReactGantt, {GanttRow} from 'react-gantt';

const Schedule = props => {

    let start = new Date(20, 11, 3, 5)
    let end = new Date(20, 11, 3, 23)
    return (
        <div className={'corner-radius5 simple-shadow p-4 bg-white mt-5'}>
            <h4><b>Schedule</b></h4>
            <div className={'row justify-content-between align-items-center'}>
                <div className="d-flex justify-content-between col-md-6">
                    <div>From <br/>
                        <a href=""><b>Mon, 29 Dec 2020</b></a>

                    </div>
                    <div>To <br/>
                        <a href=""><b>Mon, 29 Dec 2020</b></a>
                    </div>
                    <div><br/><a href=""><b>Clear Table</b></a></div>
                </div>
                <div className={'pr-4'}>
                    <Button className={'btn-blue'}>Make a Request</Button>
                </div>
            </div>
            <div className={'mt-5 px-3'}><ReactGantt
                templates={{
                    myTasks: {
                        title: 'My Tasks',
                        steps: [
                            {
                                name: 'Task Phase One',
                                color: '#0099FF'
                            },
                            // {
                            //     name: 'Task Phase Two',
                            //     color: '#FF9900'
                            // }
                        ],
                        // timeFormat: 'PM/AM'
                    }
                }}
                leftBound={start}
                rightBound={end}
            >
                <GanttRow
                    title="Mon"
                    templateName="myTasks"
                    steps={[
                        new Date(20, 11, 3, 5),
                        // new Date(20, 11, 3, 8),
                        new Date(20, 11, 3, 11),

                    ]}
                />
                <GanttRow
                    title="Tue"
                    templateName="myTasks"
                    steps={[
                        new Date(20, 11, 3, 12),
                        // new Date(20, 11, 3, 14),
                        new Date(20, 11, 3, 15),

                    ]}
                />
                <GanttRow
                    title="Wed"
                    templateName="myTasks"
                    steps={[
                        new Date(20, 11, 3, 11),
                        // new Date(20, 11, 3, 16),
                        new Date(20, 11, 3, 19),

                    ]}
                />
                <GanttRow
                    title="Thu"
                    templateName="myTasks"
                    steps={[
                        new Date(20, 11, 3, 20),
                        // new Date(20, 11, 3, 21),
                        new Date(20, 11, 3, 23),

                    ]}
                />
            </ReactGantt></div>

        </div>
    );
};

Schedule.propTypes = {};

export default Schedule;