import React from 'react';
import Overview from "./Overview";
import Skills from "./Skills";
import Contact from "./Contact";
import Alias from "./Alias";
import Network from "./Network";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import UserProfileInfo from "../../Common/UserProfileInfo";
import OverviewBriefView from "./OverviewBriefView";
import SkillsBriefView from "./SkillsBriefView";

const BriefInfo = () => {
    const params = useParams()
    const { userProfileData } = useSelector(state => state.users)
    const { account } = useSelector(state => state.user)
    const { introduction } = useSelector(state => state.user)

    const component = <div>
        {/* <Overview />
        <Skills /> */}
        <OverviewBriefView />
        <SkillsBriefView />
       
    </div>
    if (params.id) {
        if (userProfileData.isLoading)
            return <div>loading</div>
        return component
    } else if (!params.id) {
        if (account.isLoading || introduction.isLoading) return <div>loading</div>
        return component
       
    }
};


BriefInfo.propTypes = {};

export default BriefInfo;


    // const component = <div>
    //     <Overview />
    //     <Skills />
       
    // </div>