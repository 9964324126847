import React from 'react';
import {useParams} from "react-router";
import VerificationEditing from "../../Common/VerificationEditing";

const Verification = ({data}) => {
    const params = useParams()
    const id = params.id || Object.keys(data)[0]
    const verified = data[id].aliasIdentity.verified
    
    return (
        <div>
            <VerificationEditing verified={verified} id={id} type={"LEGAL_ENTITIES_COMPANY"}/>
        </div>
    );
};

Verification.propTypes = {};

export default Verification;