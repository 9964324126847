import React, {useEffect, useState} from 'react';
import useForm from "../../helpers/customHooks/useForm";
import FormBoxSection from "./FormBoxSection";
import FormColumns from "./FormColumns";
import InputAndActionContainer from "./InputAndActionContainer";
import DropZone from "../../App/components/Dropzone";
import api from "../../App/config/api";
import Loader from "./Loader";

const VerificationEditing = ({verified, id, type}) => {
    const {handleSubmit,} = useForm({}, submit, validate)
    const [zone1, setZone1] = useState()
    const [zone2, setZone2] = useState()
    const [zone3, setZone3] = useState()
    const [isVerified, setIsVerified] = useState(false)
    const [loader, setLoader] = useState(false)

    function submit() {
        setLoader(true)
        if (zone1 && zone2 && zone3) {
            const data = new FormData()
            data.append('personalId', zone1)
            data.append('contract', zone2)
            data.append('passport', zone3)

            api.post('/api/verification/documents?ownerId=' + id + '&owner-type=' + type, data)
                .then((res) => {
                    if (res.data === 'Success') {
                        setIsVerified(true)
                        setLoader(false)
                    }
                }).catch(e => console.log(e))
        }
    }

    useEffect(() => {
        setIsVerified(verified)
    }, [verified])


    function validate(v) {
        return {}
    }

    return (
        <form action="" className={'mt-4r'} onSubmit={handleSubmit}>
        {/* <form action="" className={'mt-5'} onSubmit={handleSubmit}> */}
            <FormBoxSection titleCss={'title-verification-green'} title={"Verification"}>
                {(isVerified && !loader) ?
                    <div style={{padding: '5rem 0'}}>Your documents are verified, please check your email!</div> :
                    (loader && !isVerified) ? <Loader/> :
                        <FormColumns>
                            <div className={'d-flex flex-column py-4'}>
                                <InputAndActionContainer>
                                    <><p className={' text-primary'}>Memorandum of Association</p>
                                        <DropZone setFiles={setZone1} title={'Upload Document'}
                                                  color={'#2983FD'}
                                                  maxFiles={1} accept={'image/*'}
                                                  border={'1px solid #2983FD'} background={'#ffffff'}/></>
                                </InputAndActionContainer>
                                <InputAndActionContainer>
                                    <><p className={'text-primary'}>Prospectus</p>
                                        <DropZone setFiles={setZone2} title={'Upload Document'}
                                                  color={'#2983FD'}
                                                  maxFiles={1} accept={'image/*'}
                                                  border={'1px solid #2983FD'} background={'#ffffff'}/></>
                                </InputAndActionContainer>
                                <InputAndActionContainer>
                                    <><p className={'text-primary'}>Articles of Association</p>
                                        <DropZone setFiles={setZone3} title={'Upload Document'}
                                                  color={'#2983FD'}
                                                  maxFiles={1} accept={'image/*'}
                                                  border={'1px solid #2983FD'}
                                                  background={'#ffffff'}/></>
                                </InputAndActionContainer>
                                <label style={{color: 'red'}}>All documents is required for verification!</label>
                                <div>
                                    <button className={'btn btn-verification-green custom-small-btn'} type={'submit'}>Save</button>
                                </div>
                            </div>
                            <div>
                            </div>
                        </FormColumns>}
            </FormBoxSection>
        </form>
    );
};

VerificationEditing.propTypes = {};

export default VerificationEditing;

