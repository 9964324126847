import React, { useState, useEffect } from "react";
// import React from 'react'
// import Purpose from "../Purpose";
import Purpose from "../View/Purpose";
import ProfileInfoBoxSection from "../../Common/ProfileInfoBoxSection";

const Pitch = ({ props }) => {

  const { fullProjectDescription, opportunityDesc, market, expenditures } = props
  const [readMore, setReadMore] = useState(false);
  let readMoreLength = 218;

  // useEffect(() => {
  //   setReadMore(false)
  // }, [props])

  return (
    <ProfileInfoBoxSection custom={'Overview'} className={'mt-3 border-gray margin-b-1'} title={'Pitch'}>
      {/* <div className="profile-info">
        <div className='overview-form-title-small mt-2'>Description</div>
        {description.length <= readMoreLength ? (
          <div>{description}</div>
        ) : description.length > readMoreLength && readMore ? (
          <div>
            <div className='overview-div-text'>{description}</div>
            <a
              href={"javascript:void(0)"}
              className={"link-small"}
              onClick={() => setReadMore(!readMore)}>
              Read Less
            </a>
          </div>
        ) : (
          <div>
            <div className='overview-div-text'>{description.substr(0, readMoreLength) + ' ...'}</div>
            <a
              href={"javascript:void(0)"}
              className={"link-small"}
              onClick={() => setReadMore(!readMore)}>
              Read More...
            </a>
          </div>
        )}
      </div> */}


      <div>
        <div className='overview-form-title-small mt-2'>full Project Description</div>
        {/* moe za proba */}
        <div className='overview-div-text'>{fullProjectDescription}</div>
        {/* moe za proba */}
      </div>
      <div>
        <div className='overview-form-title-small mt-2'>Opportunity Description</div>
        {/* moe za proba */}
        <div className='overview-div-text'>{opportunityDesc}</div>
        {/* moe za proba */}
      </div>
      <div>
        <div className='overview-form-title-small mt-2'>Market</div>
        {/* moe za proba */}
        <div className='overview-div-text'>{market}</div>
        {/* moe za proba */}
      </div>
      <div>
        <div className='overview-form-title-small mt-2'>Expenditures</div>
        {/* moe za proba */}
        <div className='overview-div-text'>{expenditures}</div>
        {/* moe za proba */}
      </div>
    </ProfileInfoBoxSection>
  )
}

export default Pitch

