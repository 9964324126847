import React, { useEffect, useState } from 'react';
import Header from "../../Common/Header";
import MenuBar from "../../Common/MenuBar";
import Tabs from "../../../App/components/Tabs";
import People from "../components/People";
import Following from "../components/Following";
import '../../styles/Network.scss'
import Requests from "../components/Requests";
import Invites from "../components/Invites";
import EmailInvoke from "../components/EmailInvoke";
import Groups from "../components/Groups";
import api from "../../../App/config/api";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccount } from "../../../App/store/user";
import Connections from "../components/Connections";
import Invitations from "../components/Invitations";
import SearchUsers from "../components/SearchUsers";
import AddToNetworkMessage from "../components/AddToNetworkMessage";

const Network = props => {
    // arrays for search
    const [xxx, setXxx] = useState([])
    const [showAddToNetworkMessage, setShowAddToNetworkMessage] = useState({ show: false, name: '' })

    const [searchFriendRequests, setSearchFriendRequests] = useState([])
    const [searchUserConnections, setSearchUserConnections] = useState([])
    const [searchAllUsers, setSearchAllUsers] = useState([])

    const [suggestedUsers, setSuggestedUsers] = useState([])



    const [allUsers, setAllUsers] = useState([])
    const [friends, setFriends] = useState([])
    const { data, isLoading } = useSelector(state => state.user.account)
    const { individualUserProfile } = data
    const dispatch = useDispatch()
    const [friendRequests, setFriendRequests] = useState([])

    useEffect(() => {
        const getAllUsers = async () => {
            // 5 random users
            const res = await api.get('/api/all-users')
            setAllUsers(res.data) // all
            setXxx(res.data)
            // setSuggestedUsers(res.data.sort(() => Math.random() - Math.random()).slice(0, 3))
            setSuggestedUsers(res.data.sort(() => .5 - Math.random()))



            // let howManyRadnomUsers = res.data.length > 5 ? 5 : 1;
            // setAllUsers(res.data.sort(() => Math.random() - Math.random()).slice(0, howManyRadnomUsers))
            // setAllUsers(res.data.sort(() => Math.random() - Math.random()).slice(0, 5))
        }
        // Original
        // useEffect(() => {
        //     const getAllUsers = async () => {
        //         const res = await api.get('/api/suggested-users')
        //         setAllUsers(res.data)
        //     }


        getAllUsers()
    }, [])

    useEffect(() => {
        dispatch(fetchAccount())


    }, [])
    useEffect(() => {
        if (individualUserProfile) {
            const getFrineds = async () => {
                const res = await api.get(`/api/friends?userId=${individualUserProfile.id}&userType=INDIVIDUAL_USER`)
                setFriends(res.data)
                setSearchUserConnections(res.data)
            }
            getFrineds()
            api.get(`/api/friendship-request?userId=${individualUserProfile.id}&userType=INDIVIDUAL_USER`)
                .then(res => setFriendRequests(res.data))
                .catch(e => console.log(e))
            setInterval(() => {
                api.get(`/api/friendship-request?userId=${individualUserProfile.id}&userType=INDIVIDUAL_USER`)
                    .then(res => setFriendRequests(res.data))
                    .catch(e => console.log(e))
            }, 50000)
        }

    }, [individualUserProfile])

    return (
        <div>
            <div style={{ display: 'relative' }}>
                {/* <div style={{ display: 'relative', backgroundColor: 'Azure', border: '4px solid red' }}> */}
                {showAddToNetworkMessage.show && <AddToNetworkMessage name={showAddToNetworkMessage.name} />}
                <Header />
                <div className=""><MenuBar page={'Network'} /></div>
                <div className="col-md-11 offset-md-1 ">
                    <div className={'dashboard-inner-container'}>
                        <Tabs
                            className={'tabs-dashboard'}
                            friendRequests={friendRequests}
                            // tabs={['People', 'Following', 'Groups', 'Requests', 'Invites', 'Email Invoke']}>
                            tabs={['Connections', 'Invitations', 'Search Users']}>

                            <Connections setShowAddToNetworkMessage={setShowAddToNetworkMessage} searchUserConnections={searchUserConnections} setSearchUserConnections={setSearchUserConnections} xxx={xxx} setXxx={setXxx} allUsers={allUsers} friends={friends} setAllUsers={setAllUsers}
                                suggestedUsers={suggestedUsers} setSuggestedUsers={setSuggestedUsers} />
                            <Invitations searchFriendRequests={searchFriendRequests} setSearchFriendRequests={setSearchFriendRequests} setFriends={setFriends} setShowAddToNetworkMessage={setShowAddToNetworkMessage} xxx={xxx} setXxx={setXxx} allUsers={allUsers} friends={friends} setAllUsers={setAllUsers} friendRequests={friendRequests}
                                setFriendRequests={setFriendRequests} suggestedUsers={suggestedUsers} setSuggestedUsers={setSuggestedUsers} />
                            <SearchUsers setShowAddToNetworkMessage={setShowAddToNetworkMessage} searchAllUsers={searchAllUsers} setSearchAllUsers={setSearchAllUsers} xxx={xxx} setXxx={setXxx} allUsers={allUsers} friends={friends} setAllUsers={setAllUsers}
                                suggestedUsers={suggestedUsers} setSuggestedUsers={setSuggestedUsers} />

                            {/* <People allUsers={allUsers} friends={friends} setAllUsers={setAllUsers} /> */}
                            {/* <Following allUsers={allUsers} setAllUsers={setAllUsers} /> */}
                            {/* <Groups allUsers={allUsers} setAllUsers={setAllUsers} /> */}
                            {/* <Requests allUsers={allUsers} setAllUsers={setAllUsers} friendRequests={friendRequests}
                                setFriendsRequests={setFriendRequests} /> */}
                            {/* <Invites allUsers={allUsers} setAllUsers={setAllUsers} /> */}
                            {/* <EmailInvoke /> */}
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
};

Network.propTypes = {};

export default Network;



// return (
//     <div>
//         <Header/>
//         <div className=""><MenuBar page={'Network'}/></div>
//         <div className="col-md-11 offset-md-1 ">
//             <div className={'dashboard-inner-container'}>
//                 <Tabs
//                     className={'tabs-dashboard'}
//                     friendRequests={friendRequests}
//                     // tabs={['People', 'Following', 'Groups', 'Requests', 'Invites', 'Email Invoke']}>
//                     tabs={['Connections', 'Invitations']}>
//                     <People allUsers={allUsers} friends={friends} setAllUsers={setAllUsers}/>
//                     <Following allUsers={allUsers} setAllUsers={setAllUsers}/>
//                     <Groups allUsers={allUsers} setAllUsers={setAllUsers}/>
//                     <Requests allUsers={allUsers} setAllUsers={setAllUsers} friendRequests={friendRequests}
//                               setFriendsRequests={setFriendRequests}/>
//                     <Invites allUsers={allUsers} setAllUsers={setAllUsers}/>
//                     <EmailInvoke/>
//                 </Tabs>
//             </div>
//         </div>
//     </div>