import React from 'react';
import Overview from "./Overview";
import Contact from "./Contact";
import CompanyType from "./CompanyType";
import Locations from "./Locations";
import Leadership from "./Leadership";
// import Subsidiary from "./Subsidiary";
import Branches from './Branches'

const BriefInfo = ({ introduction }) => {

    return (
        <div>
            <Overview data={introduction} />
            <div className={'row'}>
                <div className="col-md-6"><Contact data={introduction} /></div>
                <div className="col-md-6"><CompanyType data={introduction} /></div>
            </div>
            {/* <Leadership/> */}
            {/* <Locations data={introduction} /> */}
            {/* <Subsidiary/> */}
            {/* <Branches /> */}
        </div>
    );
};

BriefInfo.propTypes = {};

export default BriefInfo;