import React, { useState } from 'react';
import '../styles/Tabs.scss'
import TabsCSS from './components_styles/Tabs.module.scss'
import { useSelector } from 'react-redux';

const Tabs = ({ tabs, children, className, tabClassName, onClick, friendRequests, profilePicture, UserProfileInfo }) => {
    // const [activeTab, setActiveTab] = useState(0)
    
    // const tabNum = useSelector(state => state.tabs_navigation.value)
    const tabNum = useSelector(state => state.tabs_navigation.view_tabs_value)
    const [activeTab, setActiveTab] = useState(tabNum)

    return (
        <div className={className}
        >
            <ul className={'d-flex justify-content-between flex-wrap tabs-head '}>

                <div className={TabsCSS.back} onClick={() => { activeTab - 1 >= 0 && setActiveTab(activeTab - 1) }} ><img className={TabsCSS.img} src="\assets\images\left_arrow.svg" alt="" /></div>
                {tabs.map((item, index) => {
                    return <div onClick={onClick} key={index}
                        className={`${index !== activeTab ? TabsCSS.hide : ""} ` + ` flex-grow-1 ${tabClassName === 'click-disabled' && index !== 0 ? 'tooltip1' : ''}`}>
                        <li
                            className={`${item === 'Verification' ? TabsCSS.verification_green : ""} ` + ` tabs-btn ${index === activeTab ? "tab-active" : ""} ` + (tabClassName === 'click-disabled' && index === 0 ? '' : tabClassName)}
                            onClick={() => setActiveTab(index)}>
                            {friendRequests && friendRequests.length && index === 3 ? <div style={{
                                width: 8,
                                height: 8,
                                background: 'red',
                                position: 'absolute',
                                padding: 0,
                                right: 50,
                                top: 15,
                                borderRadius: '100%',
                            }}>
                            </div> : null}
                            {item}
                            {tabClassName === 'click-disabled' && index !== 0 &&
                                <span className="tooltiptext">You must create an alias first.</span>}
                        </li>

                    </div>

                })
                }

                <div className={TabsCSS.next} onClick={() => { activeTab + 1 <= (tabs.length - 1) && setActiveTab(activeTab + 1) }} ><img className={TabsCSS.img} src="\assets\images\right_arrow.svg" alt="" /></div>
            </ul>
            <div className='alias-profile-info-phone'>
                {UserProfileInfo}
                {profilePicture}
            </div>
            {children.map((child, i) => {
                return i === activeTab && <div key={i}>{child}</div>
            })}
        </div>
    );
};

Tabs.propTypes = {};

export default Tabs;
