import React from 'react';
import SharedLink from "../../Common/SharedLink";

const MediaInfo = props => {
    return (
        <div className={'mt-5'}>
            <SharedLink
                src={'https://www.thedubrovniktimes.com/media/k2/items/cache/02d2dd956176ea8899f4b086fbef968f_XL.jpg'}/>
        </div>
    );
};

MediaInfo.propTypes = {};

export default MediaInfo;