import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchAccount, logout } from "../../App/store/user";

import Button from "../../App/components/Button";
import { useClickAway } from "react-use";
import { useHistory } from "react-router";
import api from "../../App/config/api";
import { checkAliasType } from "../../helpers/helpers";
import { Link } from "react-router-dom";
import { setActiveAliasById } from "../../App/store/aliases";
import Image from "../../App/components/Image";

import './common_styles/ManageAccountsMenu.scss';

import { deleteUser } from '../../App/store/mixed'

const ManageAccountsMenu = ({ setOpenManageMenu, activeAlias, setActiveAlias }) => {
    const [aliases, setAliases] = useState([])
    const history = useHistory()
    const { data, isLoading } = useSelector(state => state.user.account)
    const { individualUserProfile, locations } = data
    const ref = useRef(null);
    const dispatch = useDispatch()

    useClickAway(ref, (event) => {
        setOpenManageMenu(false);

    });


    const getAliases = async () => {
        try {
            const res = await api.get('/api/aliases')
            setAliases(res.data)
        } catch (e) {
            return console.error(e.message);
        }
    }

    useEffect(() => {
        if (!Object.keys(data).length)
            dispatch(fetchAccount())
        getAliases()

    }, [])

    const handleSwitch = (e, id) => {
        e.preventDefault()
        if (!id) {
            dispatch(setActiveAliasById(null))
            return setActiveAlias(null)
        }
        return dispatch(setActiveAliasById(id))
    }

    const logOut = () => {
        dispatch(logout())
    }
    return (
        <div ref={ref}
            className={'menu-bar manage-menu-dropdown_2 d-flex flex-column align-items-center justify-content-around text-center'}>
            {!isLoading && <div className={'manage-menu corner-radius2 '}>
                <div
                    className="menu-profile-box d-flex flex-column justify-content-between corner-radius2">

                    <div className={'d-flex flex-column justify-content-center align-items-center '}>
                        <div
                            className={'account-name'}>{(activeAlias && activeAlias.name) || (individualUserProfile.firstName + " " + individualUserProfile.lastName)}</div>
                        <span>{(activeAlias && activeAlias.publicUrl) || individualUserProfile.email}</span>
                        <div className="d-flex justify-content-between">
                            <Link
                                to={`${(activeAlias && activeAlias.routeEdit + activeAlias.id) || '/individual-user'}`}
                                className={'edit-profile-btn mt-2 mr-3'}><b>Edit Profile</b></Link>

                            <Link to={`${(activeAlias && activeAlias.routeView + activeAlias.id) || '/profile'}`}
                                className={'view-profile-btn mt-2 ml-3'}><b>View Profile</b></Link></div>

                    </div>
                </div>
                <>
                    {activeAlias && <div
                        className="menu-profile-box menu-box-alias d-flex">
                        <div className="menu-profile-box-img  mr-3">
                            <Link to={'/profile'}>
                                <Image w={60} h={60} className={'simple-border'}
                                    src={`http://directventures.ekonet.mk:7777/api/auth/individual-user-profile-image/${individualUserProfile.id}`}
                                    alt="" />
                            </Link>
                        </div>
                        <div className={'d-flex flex-column text-left justify-content-center  '}>
                            <div
                                className={'account-name'}>{individualUserProfile.firstName + " " + individualUserProfile.lastName}</div>
                            <a href='' onClick={(e) => handleSwitch(e)}>Switch Account</a>
                        </div>
                    </div>}
                    {(aliases || []).map((item, index) => {
                        return index < 500 && (activeAlias && item.id !== activeAlias.id ?
                            // return index < 5 && (activeAlias && item.id !== activeAlias.id ?
                            <div key={index}>
                                <div
                                    className="menu-profile-box menu-box-alias d-flex ">
                                    <div className="menu-profile-box-img  mr-3">
                                        <Link to={checkAliasType(item.aliasType).routeProfile + item.id}>
                                            <Image w={60} h={60} className={'simple-border'}
                                                src={`http://directventures.ekonet.mk:7777/api/auth/alias-profile-image/${item.id}`}
                                                alt="" />
                                        </Link>
                                    </div>
                                    <div className={'d-flex flex-column text-left justify-content-center  '}>
                                        <div
                                            className={'account-name'}>{item.name}</div>
                                        <a href='' onClick={(e) => handleSwitch(e, item.id)}>Switch Account</a>
                                    </div>
                                </div>
                            </div> : (!activeAlias &&
                                <div key={index}
                                    className="menu-profile-box menu-box-alias d-flex ">
                                    <div className="menu-profile-box-img  mr-3">
                                        <Link to={checkAliasType(item.aliasType).routeProfile + item.id}>
                                            <Image w={60} h={60} className={'simple-border'}
                                                src={`http://directventures.ekonet.mk:7777/api/auth/alias-profile-image/${item.id}`}
                                                alt="" />
                                        </Link>
                                    </div>
                                    <div className={'d-flex flex-column text-left justify-content-center  '}>
                                        <div className={'account-name'}>{item.name}</div>
                                        <a href='' onClick={(e) => handleSwitch(e, item.id)}>Switch Account</a>
                                    </div>
                                </div>))
                    })}</>
                <div
                    className="menu-profile-box py-3 d-flex flex-column justify-content-between  ">
                    <Link to={'/create-alias'}
                    ><b>Add
                        new Alias</b></Link>
                </div>
                <div
                    className="menu-profile-box py-3 d-flex flex-column justify-content-between  ">
                    <Link to={'/all-alias'}
                    ><b>View All Alias Profiles</b></Link>
                </div>
                <div
                    className="menu-profile-box p-0  d-flex flex-column justify-content-between corner-radius2  ">
                    <Button className={'btn-red'} onClick={() => {
                        dispatch(deleteUser({ show: true, id: individualUserProfile.id }));
                        // dispatch(globalMessage({ name: `${x.firstName} ${x.lastName}`, type: 'add_to_network' }))
                    }} >Delete User</Button>
                </div>
                <div
                    className="menu-profile-box  d-flex flex-column justify-content-between corner-radius2  ">
                    <Button className={'btn-white'} onClick={logOut}>Log Out</Button>
                </div>
            </div>}
        </div>
    );
};

ManageAccountsMenu.propTypes = {};

export default ManageAccountsMenu;