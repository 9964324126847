import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    messageText: '',
    showMessage: false,
    message: {
        show: false,
        text: '',
        type: ''
    },
    deleteUserMessage: {
        show: false,
        id: null
    }
}

export const mixedSlice = createSlice({

    name: 'mixedReducers',
    initialState,
    reducers: {

        giveMessageText: (state, action) => {
            state.messageText = action.payload
        },
        // canShowMessage: (state, action) => {
        //     state.showMessage = action.payload
        //     setTimeout(() => state.showMessage = false, 2000);
        // },
        canShowMessage: (state, action) => {
            state.showMessage = action.payload
        },
        displayMessage: (state, action) => {
            state.message.show = action.payload.show
            state.message.text = action.payload.text
            state.message.type = action.payload.type
        },
        deleteUser: (state, action) => {
            state.deleteUserMessage.show = action.payload.show
            state.deleteUserMessage.id = action.payload.id
        },
    }
})

export const { giveMessageText, canShowMessage, displayMessage, deleteUser } = mixedSlice.actions
export default mixedSlice.reducer