import React from 'react';
import '../styles/MenuDots.scss'

const MenuDots = ({className, onClick, style}) => {
    return (
        <div className={className}>
            <a style={style} className={'dots-menu d-flex justify-content-around pointer'} onClick={onClick}>
                <div>.</div>
                <div>.</div>
                <div>.</div>
            </a>
        </div>
    );
};

MenuDots.propTypes = {};

export default MenuDots;