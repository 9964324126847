import React, { useEffect } from 'react';
import ProfileEditing from "./ProfileEditing";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccount } from "../../../App/store/user";
import ContactEditing from "./ContactEditing";
import Test_edit_location from "./Test_edit_location";

const Account = props => {

    const { data, isLoading } = useSelector(state => state.user.account)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!Object.keys(data).length)
            dispatch(fetchAccount())
    }, [])

    if (isLoading)
        return <div>loading</div>
    return (

        <div className={'row'}>
            <div className="col-md-6"><ProfileEditing /></div>
            <div className="col-md-6 mt-4r-0r-res"><ContactEditing /></div>
        </div>

        // <div>
        //     <ProfileEditing/>
        //     <ContactEditing/>
        // </div>
    );
};

Account.propTypes = {};

export default Account;

{/* <div className={'row'}>
    <div className="col-md-6"><ProfileEditing /></div>
    <div className="col-md-6 mt-3r-0r-res"><ContactEditing /></div>
</div> */}


// OVA SE SAMO ZA TEST ZA REDUX KAKO MI RABOTI - DA SI VIDAM STO KAKO
// return (

//     <div className={'row'}>
//         <div className="col-md-6"><ProfileEditing /></div>
//         <div className="col-md-6 mt-3r-0r-res"><ContactEditing /> <Test_edit_location /></div>
//     </div>
// );