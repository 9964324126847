import React from 'react';
import ProfileInfoBoxSection from "../../Common/ProfileInfoBoxSection";

const Network = props => {
    return (
        <ProfileInfoBoxSection className={''} title={<div className={'d-flex justify-content-between'}>
            <div>Network</div>
            <div><a href="" className={'link-small'}>View All</a></div>
        </div>}>
            <div className="profile-info row">
                <div className="col-md-3">
                    <div>
                        <img src="https://via.placeholder.com/150" className={'w-100 mb-3'} alt=""/>
                    </div>
                    <div>
                        <p>Katherine Adams</p>
                        <span>Senior Vice President and General Counsel</span>
                    </div>
                </div>
                <div className="col-md-3">
                    <div>
                        <img src="https://via.placeholder.com/150" className={'w-100 mb-3'} alt=""/>
                    </div>
                    <div>
                        <p>Eddy Cue</p>
                        <span>Senior Vice President Internet Software and Services</span>
                    </div>
                </div>
                <div className="col-md-3">
                    <div>
                        <img src="https://via.placeholder.com/150" className={'w-100 mb-3'} alt=""/>
                    </div>
                    <div>
                        <p>Craig Federighi</p>
                        <span>Senior Vice President Software Engineering</span>
                    </div>
                </div>

            </div>
        </ProfileInfoBoxSection>
    );
};

Network.propTypes = {};

export default Network;