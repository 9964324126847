import React, { useEffect } from "react";
import MenuBar from "../Common/MenuBar";
import Header from "../Common/Header";
import SharedLink from "../Common/SharedLink";
import GrayInfoBox from "../Common/GrayInfoBox";
import Button from "../../App/components/Button";
import CreatePost from "../Common/CreatePost";
import api from "../../App/config/api";
import ProfileInfoBoxSection from "../Common/ProfileInfoBoxSection";
import MenuDots from "../../App/components/MenuDots";
import Image from "../../App/components/Image";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccount } from "../../App/store/user";
import Loader from "../Common/Loader";
import HomePageOpportunities from "./HomePageOpportunities";


import k from "./../../i18n/keys";
import i18next from '../../i18n/init';
import { useTranslation } from 'react-i18next'

import './container_styles/HomePage.scss'
import './container_styles/cards.scss'


const HomePage = (props) => {
    const { data, isLoading } = useSelector((state) => state.user.account);
    const { t } = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchAccount());

        async function fetchData() {
            try {
                const res = await api.get("/api/check-token");
            } catch (e) {
                if (e.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/login";
                }
            }
        }

        fetchData();
    }, []);

    if (isLoading)
        return (
            <div className={"w-100 m-auto"}>
                <Loader />
            </div>
        );
    return (
        <>
            <Header />

            {/* Icons */}
            <div className="d-flex justify-content-center icon-container">
                <div className="icons-div mt-0">
                    <Link to='/network' className="icon blue-gradient">
                        <div className="asd">
                            <img className="img" src="\assets\images\Network-2-Home-Page-2.svg" alt="" />
                            <div className="icon-name-text ">{t(k.Network)}</div>
                        </div>
                    </Link>
                    <Link to='/due-diligence' className="icon light-purple-gradient">
                        <div className="asd">
                            <img className="img" src="\assets\images\Due_Diligence.svg" alt="" />
                            <div className="icon-name-text " >{t(k.Due_Diligence)}</div>
                        </div>
                    </Link>
                    <Link to='/all-alias' className="icon dark-blue-gradient">
                        <div className="asd">
                            <img className="img" src="\assets\images\Aliases.svg" alt="" />
                            <div className="icon-name-text ">{t(k.Aliases)}</div>
                        </div>
                    </Link>
                    <Link to='/opportunities' className="icon purple-gradient">
                        <div className="asd">
                            <img className="img" src="\assets\images\Opportunities-Home-Page-2.svg" alt="" />
                            <div className="icon-name-text ">{t(k.Opportunities)}</div>
                        </div>
                    </Link>
                </div>
            </div>


            <HomePageOpportunities />

           


        </>
    );
};

HomePage.propTypes = {};

export default HomePage;
