import axios from 'axios'

const api = axios.create({
    // baseURL: 'http://d589-46-217-7-132.ngrok.io',
    // baseURL: 'https://606c-89-205-99-22.ngrok.io/', // 
    baseURL: 'https://dvbackend.e2p.mk', // original
    headers: {
        'Content-Type': 'application/json'

    },
})
api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }
        return config
    },
    error => Promise.reject(error)
)
export default api