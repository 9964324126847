import React from 'react';

const Image = ({ src, w, h, className, onClick, style, corners, objectFit, imgStyle }) => {
    return (
        <div className={`flex-shrink-0 ${corners ? corners : 'rounded-circle'} ` + className}
            style={{ width: w, height: h, ...style }} onClick={onClick}>
            <img style={{ objectFit: objectFit || 'cover', ...imgStyle }}
                className={`w-100 h-100  img-responsive ${corners ? corners : 'rounded-circle '}`}
                src={src}
                alt="" />
        </div>
    );
};

Image.propTypes = {};

export default Image;