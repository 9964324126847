import React from 'react'
import DelUserScss from './styles/DeleteUserMessage.module.scss'
import { deleteUser } from '../../App/store/mixed'
import { useDispatch, useSelector } from "react-redux";

const DeleteUserMessage = (props) => {
    const dispatch = useDispatch()

    const { id } = props


    return (
        <div className={DelUserScss.message_div}>
            <div className={DelUserScss.message_text}>Are you sure you want to delete this Account?</div>
            <div className={DelUserScss.yes_no_div}>
                {/* <div>{id}</div> */}

                <div className={DelUserScss.yes}>Yes</div>
                <div
                    onClick={() => {
                        dispatch(deleteUser({ show: false, id: null }));
                    }}
                    className={DelUserScss.no}>No</div>
            </div>
        </div>
    )
}

export default DeleteUserMessage