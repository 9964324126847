import React from 'react';
import ContactEditing from "../../Common/ContactEditing";
import CompanyTypeEditing from "../../Common/CompanyTypeEditing";
import LocationEditing from "../../Common/LocationEditing";
import IdentityEditing from "../../Common/IdentityEditing";
import RateEditing from "../../FreelanceProfile/Creation/RateEditing";

const Introduction = props => {
    const aliasType = 'LEGAL_ENTITIES_SERVICE_COMPANY'
    // const aliasType = 'LEGAL_ENTITIES_INVESTMENT_FOUND'

    return (
        <div>
            <IdentityEditing aliasType={aliasType}/>
            {/* <ContactEditing/>
            <CompanyTypeEditing/>
            <LocationEditing/>
            <RateEditing/> */}
        </div>
    );
};

Introduction.propTypes = {};

export default Introduction;
