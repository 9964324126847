const k = require('./keys');

let Create_New_Post =  `arabic 你召奶百千入斤地冉兕午牟寸卵寺`;
let Direct_Ventures =`المشاريع المباشرة`;
let Network = `شبكة علاقاتك`;
let Opportunities = `فرص`;
let Aliases = `اسماء مستعارة`;
let Due_Diligence = `اجراءات لارضاء المتطلبات`;
export {Create_New_Post,
  Direct_Ventures,
  Network,
  Opportunities,
  Aliases,
  Due_Diligence
}