import React from 'react';
import ProfileInfoBoxSection from "../../Common/ProfileInfoBoxSection";

const Overview = ({data}) => {
    const {aliasIdentity} = data
    return (
        <ProfileInfoBoxSection className={'mt-5'} title={"Overview"}>
            <div className="profile-info">
                <div>
                    <p>{aliasIdentity.description}</p>
                </div>
            </div>
        </ProfileInfoBoxSection>
    );
};

Overview.propTypes = {};

export default Overview;