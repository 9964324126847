import React from 'react'
import Map from './Map';
import Purpose from "../Purpose";

const Location = ({props}) => {
  const { location, latitude, longitude } = props


  return (
    <Purpose>
      <div className={"d-flex justify-content-between"}>
          <div className={"d-flex align-items-center"}>
            <h4 className={"font-weight-bold"}>Location</h4>
           
            {/* <EditModeModal><ExperienceEditing /></EditModeModal> */}
          </div>
          
          {/* <MenuDots className={'dots-vertical'}/> */}
          {/* <button>View More</button> */}
        </div>
        <div>
        <div className="small mb-3">{location || 'no chosen location'}</div>
        </div>

          {/* <div className={ "row flex-wrap justify-content-center align-items-center py-4"}> */}
          <div className={ "row flex-wrap justify-content-center align-items-center pb-4"}>
               <Map latitude={latitude} longitude={longitude} />
          </div>
      </Purpose>
  )
}

export default Location