import React from 'react';
import GroupEditing from "./GroupEditing";

const Group = props => {
    return (
        <div>
            <GroupEditing/>
        </div>
    );
};

Group.propTypes = {};

export default Group;