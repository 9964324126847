import {checkAliasType} from "../helpers";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getAliasIntroductionById} from "../../App/store/aliases";


const useGetAliasIntroduction = (aliasTypeStr) => {
    const aliasType = checkAliasType(aliasTypeStr).variable
    const params = useParams()
    const introduction = useSelector(state => {
        if (state.aliases.data[aliasType] === undefined || (!params.id && !state.aliases.data.activeId))
            return {}
        else if (state.aliases.data.activeId) {
            return state.aliases.data[aliasType][state.aliases.data.activeId]
        } else
            return state.aliases.data[aliasType][params.id]
    })
    const activeId = useSelector(state => state.aliases.data.activeId)
    const {isLoading} = useSelector(state => state.aliases)
    const dispatch = useDispatch()

    useEffect(() => {

        // if (!Object.keys(introduction).length) {
        if (params.id) {
            dispatch(getAliasIntroductionById(params.id))
        } else if (activeId) {
            dispatch(getAliasIntroductionById(activeId))
        }
        // }
    }, [])


    return {
        aliasType,
        introduction,
        activeId,
        paramsId: params.id,
        isLoading
    }
}

export default useGetAliasIntroduction


