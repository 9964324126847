import React from 'react';
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'


const DropZone = ({ color = '#D0D1E4', title, border, maxFiles, accept, setMeta, background, setFiles, onSubmit, maxWidth }) => {

    const handleChangeStatus = ({ meta }, status, files, allFiles) => {
        if (setMeta) {
            status !== 'removed' ? setMeta(meta) : setMeta(null)
        }
        if (status === 'removed')
            setFiles(null)
        if (status === 'done') {
            setFiles(files[0].file)
        }
    }

    const handleSubmit = (files, allFiles) => {
        setFiles(allFiles[0].file)

        // allFiles.forEach(f => f.remove())
    }
    // console.log("onSubmit", onSubmit)
    return (
        <div>
            <Dropzone
                onChangeStatus={handleChangeStatus}
                {...onSubmit && { onSubmit: handleSubmit }}
                {...maxFiles && { maxFiles: maxFiles }}
                accept={accept || '*'}
                styles={{
                    dropzone: {
                        // minHeight: 40,
                        // maxHeight: 60,
                        minHeight: 36,
                        maxHeight: 56,
                        maxWidth: maxWidth || '100%', // moe dodadeno
                        // minWidth: 140, // moe dodadeno
                        overflow: 'hidden',
                        border: border || '2px dashed #D0D1E4',
                        background: background
                    },
                    inputLabel: { color: color, fontSize: '0.8rem', marginBottom: 0, fontFamily: 'Montserrat' },
                    submitButton: { backgroundColor: '#D0D1E4' }
                }}
                inputContent={title || "Upload Documents"}
            />
        </div>
    );
};

DropZone.propTypes = {};

export default DropZone;