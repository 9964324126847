import React from 'react';
import Header from "../../Common/Header";
import MenuBar from "../../Common/MenuBar";
import Tabs from "../../../App/components/Tabs";
import Introduction from "../Creation/Introduction";
import Purpose from "../../IndividualUserProfile/Creation/Purpose";
import Media from "../../IndividualUserProfile/Creation/Media";
import Verification from "../../InvestmentFundProfile/Creation/Verification";
import BranchesAndSubsidiary from "../Creation/BranchesAndSubsidiary";
import RolesAndEmployees from "../Creation/RolesAndEmployees";
import Portfolio from "../Creation/Portfolio";
import { useSelector } from "react-redux";
import OpportunityAlias from '../../CompanyProfile/Creation/OpportunityAlias';
import PurposeAlias from '../../CompanyProfile/Creation/PurposeAlias'


const InvestmentFundProfileCreation = props => {
    const investmentFundCreated = useSelector(state => {
        if (state.aliases.data.investmentFunds === undefined)
            return {}
        else
            return state.aliases.data.investmentFunds
    })
    return (
        <div>
            <Header />
            <div className=""><MenuBar page={'Company'} /></div>
            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container'}>
                    <Tabs
                        tabClassName={!Object.keys(investmentFundCreated).length ? 'click-disabled' : ''}
                        className={'tabs-dashboard'}
                        // tabs={['Introduction', 'Purpose', 'Branches & Subsidiary', 'Roles & Employees', 'Portfolio', 'Media', 'Verification']}>
                        tabs={['Introduction', 'Purpose', 'Branches', 'Opportunities', 'Verification']}>
                        <Introduction />
                        <PurposeAlias />
                        <BranchesAndSubsidiary />
                        <OpportunityAlias />
                        {/* <RolesAndEmployees/> */}
                        {/* <Portfolio/> */}
                        <div>Opportunities cration</div>
                        <Verification data={investmentFundCreated} />
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

InvestmentFundProfileCreation.propTypes = {};

export default InvestmentFundProfileCreation;