import React from 'react'
import Purpose from "../Purpose";
import ExperienceItem from "../../shared_components/ExperienceItem";
import ProfileInfoBoxSection from "../../../Common/ProfileInfoBoxSection";

const Experience = ({ experience }) => {

  let experienceArr = experience.split(',');
  return (<>
    {/* <div onClick={() => { console.log(experienceArr) }}>asdasdasd eexp</div> */}
    <ProfileInfoBoxSection custom={'Overview'} className={'mt-3 border-gray margin-b-1'} title="Experience">

      <div className="profile-info">

        {experienceArr.map(x => {
          return (
            <span className='opportunity_view_experience'>{` ${x},`}</span>
            // <div className='overview-form-title-small mt-3'>{x}</div>
          )
        })}

      </div>
    </ProfileInfoBoxSection>
  </>
  )
}

export default Experience

