import React, {useEffect, useState} from 'react';
import Header from "../../Common/Header";
import '../../styles/Contracting.scss'
import MenuContracting from '../components/MenuContracting'
import api from "../../../App/config/api";
import {Link} from "react-router-dom";

const Contracting = props => {
    const [contracts, setContracts] = useState([])

    const getAllContracts = async () => {
        try {
            const res = await api.get('/api/contract/contract')
            setContracts(res.data.body)
        } catch (e) {
            return console.error(e.message);
        }
    }

    useEffect(() => {
        getAllContracts()
    }, [])

    return (
        <div>
            <div>
                <Header/>
                <div className=""><MenuContracting/></div>
                <div className="col-md-11 offset-md-1 ">
                    <div className={'dashboard-inner-container'}>
                        <div className={'contracting'}>
                            <div className="recent-documents">
                                <h4 className={'mt-5'}>All Documents</h4>
                                <div className="row doc-list">
                                    {contracts.map((item, index) => {
                                        return <Link key={index} to={`/contract/${item.id}`} className={'document'}>
                                            <img src={"/assets/images/Screenshot_3.png"} alt=""/>
                                            <div className={'doc-title'}>
                                                <h6>{item.name}</h6>
                                                <span>Word Document</span>
                                            </div>
                                        </Link>
                                    })}
                                </div>
                                <h4 className={'mt-5'}>Templates</h4>
                                <div className="row doc-list ">
                                    <Link to={'/contract'} className={'document'}>
                                        <img src={"/assets/images/Screenshot_3.png"} alt=""/>
                                        <div className={'doc-title'}>
                                            <h6>Blank</h6>
                                            <span>Word Document</span>
                                        </div>
                                    </Link>
                                </div>
                                <h4 className={'mt-5'}>Design</h4>
                                <div className="row doc-list ">
                                    <a target={'_blank'}
                                       href={'https://xd.adobe.com/view/bc308a0a-1b2e-42de-a605-91b5a8cff83e-e963/screen/3c866835-a999-4a39-96bf-2e2ac668b2d3?fullscreen'}
                                       className={'document'}>
                                        <img src={"/assets/images/Screenshot_3.png"} alt=""/>
                                        <div className={'doc-title'}>
                                            <h6>Contracting - Whole Design Prototype</h6>
                                            {/*<span>Doc type</span>*/}
                                        </div>
                                    </a>
                                </div>
                                {/*<div className="recent-documents">*/}

                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
};

Contracting.propTypes = {};

export default Contracting;