import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {isAuth} from "./isAuth";

const PrivateRoute = ({component: Component, ...rest}) => {
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /login page
        <Route {...rest} render={props => (
            isAuth() ?
                <Component {...props} />
                : <Redirect to="/login"/>
        )}/>
    );
};


PrivateRoute.propTypes = {};

export default PrivateRoute;