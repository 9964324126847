import React from 'react';
import MenuBar from "../../Common/MenuBar";
import GrayInfoBox from "../../Common/GrayInfoBox";
import '../../styles/CreateAlias.scss'
import Header from "../../Common/Header";
import CACss from './styles/CreateAlias.module.scss'
import { Link } from "react-router-dom";

const CreateAlias = props => {
    return (
        <div>
            <Header />
            <div className=""><MenuBar page={'Company'} /></div>
            {/* <div className={CACss.ww}>asdasd</div>
            <img className={CACss.zz} src="assets/images/background/Create_alias_background.png" alt="" /> */}

            {/*  */}

            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container create-alias'}>
                    <div className={CACss.main_div}>
                        <img className={CACss.bg} src="assets/images/background/Create_alias_background.png" alt="" />

                        <div className={CACss.description} >
                            <div className={CACss.description_title} >What is</div>
                            <div className={CACss.description_title} >alias?</div>
                            <div className={CACss.description_text} >Alias is a company profile. You can create your own aliases on Direct Ventures to represent your business and to collaborate with other businesses or individuals. Aliases can be created as Company, investment Fund, Business Association, Service Company</div>

                            {/* <button className={CACss.button} >My Aliases</button> */}
                            <button className={CACss.button} > <Link className={CACss.link} to={'/all-alias'} >My Aliases</Link></button>
                            {/* <Link to={'/create-alias'} className={CACss.button} >Create New</Link> */}

                        </div>
                        {/* <div className={CACss.title} >
                            Create Alias
                        </div> */}

                    </div>
                </div>
            </div>

            {/*  */}
            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container create-alias'}>
                    {/* <div className={'alias-title mt-5'}><h4>Create Alias</h4></div> */}
                    <GrayInfoBox className={'alias-gray-box no-background '}>
                        <h4  style={{ fontWeight: 700 }} className={'ml-3'}>Create Alias</h4>
                        <div className="row">
                            {/* <div className="col-md-3 text-center"> */}
                            <div className="col-md-3">
                                <a className={'alias-link'} href="/create-company">
                                    <div className={'corner-radius10 p-4  alias-box'}>
                                        <img className={'img-fluid'} src="/assets/images/CompanyAlias.svg" alt="" />
                                        {/* <div style={{ minHeight: '25vh' }} className={'alias-text d-flex flex-column justify-content-center pl-2'}> */}
                                        <div style={{ minHeight: '15vh' }} className={'alias-text d-flex flex-column justify-content-center pl-2'}>
                                            <h5>Company</h5>
                                            <p>An entity that is interested in seeking or
                                                providing investment
                                                opportunities, as
                                                well as taking advantage of the integrated business and management
                                                features.</p>

                                           

                                        </div>
                                        <div className='d-flex justify-content-center d-flex flex-column pl-2'>

                                            <p className='check-mark-p' ><img className='check-mark-img' src="/assets/images/check-mark-green.svg" alt="" />Corporate</p>
                                            <p className='check-mark-p' ><img className='check-mark-img' src="/assets/images/check-mark-green.svg" alt="" />Finance</p>
                                            <p className='check-mark-p' ><img className='check-mark-img' src="/assets/images/check-mark-green.svg" alt="" />Service</p>

                                        </div>
                                        <div className='d-flex justify-content-center d-flex mt-5 '>
                                            {/* ako sakame samo create Profile kopceto da bide link - ama nas celata 'card' sea ni e link */}
                                            {/* <a href="/create-company" className={'link-small create-profile'}>Create Profile</a> */}
                                            <div className={'link-small create-profile'}>Create Profile</div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-md-3">
                                <a className={'alias-link'} href="/create-investment-fund">
                                    <div className={'corner-radius10 p-4  alias-box'}>
                                        <img className={'img-fluid'} src="/assets/images/Investment Fund.svg" alt="" />
                                        <div style={{ minHeight: '15vh' }} className={'alias-text d-flex flex-column justify-content-center  '}>
                                            <h5>Investment Fund</h5>
                                            <p>An investment fund that pools capital of other
                                                investors and uses Direct
                                                Ventures
                                                to discover and Invest in potential business opportunities.</p>


                                        </div>

                                        <div className='d-flex justify-content-center d-flex flex-column pl-2'>

                                            <p className='check-mark-p' ><img className='check-mark-img' src="/assets/images/check-mark-green.svg" alt="" />Real Estate Investment</p>
                                            <p className='check-mark-p' ><img className='check-mark-img' src="/assets/images/check-mark-green.svg" alt="" />Global Funds</p>
                                            <p className='check-mark-p' ><img className='check-mark-img' src="/assets/images/check-mark-green.svg" alt="" />Money market funds</p>

                                        </div>

                                        <div className='d-flex justify-content-center mt-5'>
                                            {/* <a href="/create-investment-fund" className={'link-small create-profile'}>Create Profile</a> */}
                                            <div className={'link-small create-profile'}>Create Profile</div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-md-3">
                                <a className={'alias-link'} href="/create-nongovernmental-profile">
                                    <div className={'corner-radius10 p-4  alias-box'}>
                                        <img className={'img-fluid'} src="/assets/images/Non-Governmental.svg" alt="" />
                                        <div style={{ minHeight: '15vh' }} className={'alias-text d-flex flex-column justify-content-center '}>
                                            <h5>Business Association</h5>
                                            <p>An investment fund that pools capital of other
                                                investors and uses Direct
                                                Ventures
                                                to discover and Invest in potential business opportunities.</p>


                                        </div>

                                        <div className='d-flex justify-content-center d-flex flex-column pl-2'>

                                            <p className='check-mark-p' ><img className='check-mark-img' src="/assets/images/check-mark-green.svg" alt="" />Corporate</p>
                                            <p className='check-mark-p' ><img className='check-mark-img' src="/assets/images/check-mark-green.svg" alt="" />Finance</p>
                                            <p className='check-mark-p' ><img className='check-mark-img' src="/assets/images/check-mark-green.svg" alt="" />Service</p>

                                        </div>

                                        <div className='d-flex justify-content-center mt-5'>
                                            {/* <a href="/create-nongovernmental-profile" className={'link-small create-profile'}>Create Profile</a> */}
                                            <div className={'link-small create-profile'}>Create Profile</div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-md-3 mx-auto">
                                <a className={'alias-link'} href="/create-service-company">
                                    <div className={'corner-radius10 p-4 alias-box'}>
                                        <img className={'img-fluid'} src="/assets/images/Service-Provider.svg" alt="" />
                                        <div style={{ minHeight: '15vh' }} className={'alias-text d-flex flex-column justify-content-center '}>
                                            <h5>Service Company</h5>
                                            <p>An established company in a particular field that can be hired to
                                                facilitate
                                                transactions pursued on the Direct Ventures platform.</p>



                                        </div>

                                        <div className='d-flex justify-content-center d-flex flex-column pl-2'>

                                            <p className='check-mark-p' ><img className='check-mark-img' src="/assets/images/check-mark-green.svg" alt="" />Corporate</p>
                                            <p className='check-mark-p' ><img className='check-mark-img' src="/assets/images/check-mark-green.svg" alt="" />Finance</p>
                                            <p className='check-mark-p' ><img className='check-mark-img' src="/assets/images/check-mark-green.svg" alt="" />Service</p>

                                        </div>

                                        <div className='d-flex justify-content-center mt-5'>
                                            {/* <a href="/create-service-company" className={'link-small create-profile'}>Create Profile</a> */}
                                            <div className={'link-small create-profile'}>Create Profile</div>
                                        </div>
                                    </div>
                                </a>
                            </div>


                        </div>
                    </GrayInfoBox>

                
                </div>
            </div>
        </div>
    )
        ;
};

CreateAlias.propTypes = {};

export default CreateAlias;
