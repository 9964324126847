import React from 'react';
import Header from "../../Common/Header";
import MenuBar from "../../Common/MenuBar";
import Tabs from "../../../App/components/Tabs";
import Introduction from "../Creation/Introduction";
import Purpose from "../Creation/Purpose";
import Media from "../Creation/Media";
import Verification from "../Creation/Verification";
import BranchesAndSubsidiary from "../Creation/BranchesAndSubsidiary";
import RolesAndEmployees from "../Creation/RolesAndEmployees";
import Qualification from "../Creation/Qualification";
import Availability from "../Creation/Availability";
import OpportunityAlias from '../../CompanyProfile/Creation/OpportunityAlias';
import PurposeAlias from '../../CompanyProfile/Creation/PurposeAlias'
const ServiceCompanyProfileCreation = props => {
    return (
        <div>
            <Header/>
            <div className=""><MenuBar page={'Profile'}/></div>
            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container'}>
                    <Tabs
                        className={'tabs-dashboard'}
                        // tabs={['Introduction', 'Purpose', 'Branches & Subsidiary', 'Roles & Employees', 'Qualification', 'Availability', 'Media', 'Verification']}>
                        tabs={['Introduction', 'Purpose', 'Branches', 'Opportunites', 'Verification']}>
                        <Introduction/>
                        <PurposeAlias/>
                        <BranchesAndSubsidiary/>
                        <OpportunityAlias/>
                        {/* <RolesAndEmployees/> */}
                        {/* <Qualification/> */}
                        {/* <Availability/> */}
                        {/* <Media/> */}
                        {/* <div>Opportunites creation</div> */}
                        <Verification/>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

ServiceCompanyProfileCreation.propTypes = {};

export default ServiceCompanyProfileCreation;
