import React from 'react';
import MenuBar from "../../Common/MenuBar";
import Tabs from "../../../App/components/Tabs";
import '../../styles/Dasboard.scss'
import Account from "../Creation/Account";
import Introduction from "../Creation/Introduction";
import Purpose from "../Creation/Purpose";
import Verification from "../Creation/Verification";
import Header from "../../Common/Header";
import Opportunity from '../Creation/Opportunity';
import TestingPhotos from "../Creation/TestingPhotos/TestingPhotos";
import TestingPhotos_2 from "../Creation/TestingPhotos/TestingPhotos_2";
import TestingPhotos_3 from "../Creation/TestingPhotos/TestingPhotos_3";

const IndividualUserProfileCreation = props => {
    return (
        <div>
            <Header />
            <div className=""><MenuBar page={'Profile'} /></div>
            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container'}>
                    <Tabs
                        className={'tabs-dashboard'}
                        // tabs={['Account', 'Introduction', 'Purpose', 'Opportunities', 'Verification', 'Testing Photos', 'TestingPhotos_2', 'TestingPhotos_3']}>
                        tabs={['Account', 'Introduction', 'Purpose', 'Opportunities', 'Verification']}>
                        <Account />
                        <Introduction />
                        <Purpose />
                        <Opportunity />
                        <Verification />
                        {/* <TestingPhotos /> */}
                        {/* <TestingPhotos_2 /> */}
                        {/* <TestingPhotos_3 /> */}
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

IndividualUserProfileCreation.propTypes = {};

export default IndividualUserProfileCreation;