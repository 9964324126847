import React, { useEffect } from 'react';
import MenuBar from "../../Common/MenuBar";
import Tabs from "../../../App/components/Tabs";
import Introduction from "../Creation/Introduction";
import Purpose from "../../IndividualUserProfile/Creation/Purpose";
import Verification from "../Creation/Verification";
import BranchesAndSubsidiary from "../Creation/BranchesAndSubsidiary";
import OpportunityAlias from "../Creation/OpportunityAlias";
import PurposeAlias from "../Creation/PurposeAlias";
// import RolesAndEmployees from "../Creation/RolesAndEmployees";
import Header from "../../Common/Header";
// import Media from "../Creation/Media";
import { useSelector } from "react-redux";


const CompanyProfileCreation = props => {

    const companyCreated = useSelector(state => {
        if (state.aliases.data.companies === undefined)
            return {}
        else
            return state.aliases.data.companies
    })

    useEffect(() => {
        const id = Object.keys(companyCreated)
        // dispatch(setCreatedCompanyId(id[0]))
    }, [companyCreated])

    return (
        <div>
            <Header />
            <div className=""><MenuBar page={'Company'} /></div>
            <div className="col-md-11 offset-md-1 ">
                <div className={'dashboard-inner-container'}>
                    <Tabs
                        className={'tabs-dashboard'}
                        tabClassName={!Object.keys(companyCreated).length ? 'click-disabled' : ''}
                        // tabs={['Introduction', 'Purpose', 'Branches & Subsidiary', 'Roles & Employees', 'Media', 'Verification']}>
                        tabs={['Introduction', 'Purpose', 'Branches', 'Opportunities', 'Verification']}>
                        <Introduction />
                        <PurposeAlias />
                        <BranchesAndSubsidiary />
                        <OpportunityAlias />
                        {/* <div>Opportunities Creation</div> */}
                        {/* <RolesAndEmployees/>
                        <Media/> */}
                        <Verification data={companyCreated} />
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

CompanyProfileCreation.propTypes = {};

export default CompanyProfileCreation;