import React from 'react';
import FormBoxSection from "./FormBoxSection";
import FormBoxSectionDualScreen from "./FormBoxSectionDualScreen";
import FormColumns from "./FormColumns";
import InputContainer from "./InputContainer";
import Dropdown from "../../App/components/Dropdown";
import { useHistory } from "react-router";

const AssociationTypeEditing = ({ aliasTypeValue }) => {
    const history = useHistory()

    const handleChange = (e) => {
        history.push(e.target.value)
    }

    return (
        <form className={' '} action="">
            {/* <FormBoxSection title={"Type"}> */}
            {/* <FormBoxSection noTitle={true} className={' margin-b-0 '} title={""}> */}
            <FormBoxSectionDualScreen type={'title_even_with_inputs_md_8_center_start'} className={' margin-b-0 '} title={"Type"}>
                < >
                    {/* <div className={'d-flex flex-column py-4'}> */}
                    <div className={'d-flex flex-column py-2'}>
                        
                       

                        <InputContainer >
                            <Dropdown options={[{
                                name: 'Non-Governmental Organization',
                                value: '/create-nongovernmental-profile/'
                            }, {
                                name: 'Governmental Organization',
                                value: '/create-governmental-profile/'
                            }]}
                                label={"Type of Business Association"} onChange={handleChange}
                                value={aliasTypeValue || ''}
                                name={'aliasTypeName'} />
                        </InputContainer>
                    </div>
                   
                </>
            </FormBoxSectionDualScreen>
        </form>
    );
};

AssociationTypeEditing.propTypes = {};

export default AssociationTypeEditing;

