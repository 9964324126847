import React, { useState } from 'react';

import InputContainer from "../../Common/InputContainer";
import InputField from "../../../App/components/InputField";
import FormBoxSectionDualScreen from "../../Common/FormBoxSectionDualScreen";
import InputWithAutocomplete from "../../../App/components/InputWithAutocomplete";
import useForm from "../../../helpers/customHooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { updateIndividualUserContact } from "../../../App/store/user";
import Loader from "../../Common/Loader";


const ContactEditing = () => {
    const { data } = useSelector(state => state.user.account)
    const { locations } = data
    const { handleChange, handleSubmit, values, setValues, errors, isEdited, setIsEdited } = useForm((locations.length && locations) || [{
        country: "",
        stateOrCity: "",
        stateOrRegionOrProvince: ""
    }], submit, validate)
    const [countryId, setCountryId] = useState(null)
    const [disabled, setDisabled] = useState(true)
    const dispatch = useDispatch()


    function submit() {
        dispatch(updateIndividualUserContact(values))
        setIsEdited(false)
    }

    function validate(v) {
        return {}
    }

    const setNameInInput = (item, inputName, index) => {
        console.log(item, inputName, index)
        const array = [...values];
        const updatedObj = { ...array[index], [inputName]: item.name };
        const updatedValues = [
            ...array.slice(0, index),
            updatedObj,
            ...array.slice(index + 1),
        ];
        setValues(updatedValues)
        setCountryId(item.id)
        setDisabled(false)
    }

    const removeItem = (index) => {
        const filteredArr = [...values]
        filteredArr.splice(index, 1);
        setValues(filteredArr)
        setIsEdited(true)
    }
    return (
        <form className=' mt-7r-0r-res ' action="" onSubmit={handleSubmit}>
            <FormBoxSectionDualScreen  title={"Location"}>
                {!locations ? <Loader /> : < >
                    <div className={'d-flex flex-column py-2'}>
                    {/* <div className={'d-flex flex-column py-4'}> */}
                        {values.map((item, index) => {
                            return <div key={index}>
                                {
                                    // <span className={'pointer'} onClick={() => removeItem(index)}>&times;</span>}
                                    <a href={'#experience'} className={'link-small'}
                                        onClick={() => removeItem(index)}>Remove</a>}
                                <InputContainer  >
                                    <InputWithAutocomplete
                                        label={'Country'}
                                        name={'country'}
                                        idx={index}
                                        value={item.country || ''}
                                        url={'/api/info/countries/'}
                                        onClick={setNameInInput}
                                        onChange={(e) => handleChange(e, index)} />

                                </InputContainer>
                                <InputContainer  >
                                    <InputWithAutocomplete
                                        label={'State/City'}
                                        name={'stateOrCity'}
                                        value={item.stateOrCity || ''}
                                        url={`/api/info/states/${countryId}/`}
                                        idx={index}
                                        onClick={setNameInInput}
                                        onChange={(e) => handleChange(e, index)}
                                        disabled={disabled} />

                                </InputContainer>
                                <InputContainer  >
                                    <InputField label={"State/Region/Province"} value={item.stateOrRegionOrProvince}
                                        name={'stateOrRegionOrProvince'}
                                        onChange={(e) => handleChange(e, index)} />
                                </InputContainer>

                            </div>
                        })}
                        <>

                            <p><a href={'#experience'} className={'link-small'}
                                onClick={() => setValues([...values, {
                                    country: '',
                                    stateOrCity: '',
                                    stateOrRegionOrProvince: '',
                                    // {calculateWordFromNum(values.length)}
                                }])}>Add new location </a></p>

                            <div className=' d-flex justify-content-end '>
                                {isEdited && <button className={'btn btn-primary px-5 py-2 '} type='submit'>Save</button>}
                            </div>
                        </>
                    </div>
                    <div>
                    </div>
                </>}
            </FormBoxSectionDualScreen>
        </form>
    );
}
    ;

ContactEditing.propTypes = {};

export default ContactEditing;