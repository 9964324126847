import React, { useState } from 'react'
import '../styles/InputField.scss'
import { TextField } from '@material-ui/core';

const InputField = ({ type = 'text', name, value, className, label, multiline, onChange, onBlur, autocomplete, disabled, onKeyPress, rows }) => {
    const [togglePassword, setTogglePassword] = useState(false)

    return (

        <div className={className}>{multiline ?
            <TextField name={name} className={'textarea'}
                value={value} multiline rows={rows || 2}
                label={label}
                variant="outlined"
                disabled={disabled}
                onChange={onChange} />
            : <TextField name={name} type={type === 'password' ? togglePassword ? 'text' : 'password' : type}
                className={'input ' + className}
                onKeyPress={onKeyPress}
                autoComplete={autocomplete ? 'off' : ''}
                value={value}
                inputProps={{
                    style: {
                        // paddingTop: 18,
                        // paddingBottom: 18,
                        // paddingTop: 16,
                        // paddingBottom: 16,
                    }
                }}
                label={label}
                variant="outlined"
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}
                InputLabelProps={type === 'date' ? { shrink: true } : {}}
                InputProps={type === 'date' ? {
                    inputProps: {
                        max: "9999-12-31",
                        // style: {
                        //     paddingTop: 14,
                        //     paddingbottom: 14,
                        // }
                    }
                } : {}}
            // InputProps={type === 'date' ? { inputProps: { min: "2020-05-01", max: "2020-05-04" } } : {}}
            // yy-mm-dd
            />

        }
            {type === 'password' &&
                <span className=" field-icon " onClick={() => setTogglePassword(!togglePassword)}>
                    {!togglePassword ? <i className='far fa-eye-slash'> </i> : <i className='fa fa-eye'> </i>}</span>}
        </div>
    )
}

export default InputField