import React from 'react'
import OVCss from './styles/OpportunitiesView.module.scss'


const ExperienceOpportunitiesView = ({ experience }) => {
    return (

        <section className={` mt-3 margin-b-1 d-flex flex-wrap  ${OVCss.border_gray} ${OVCss.form_box} `}>
            <div className="col-md-12 d-flex flex-column">

                <div>
                    <div className={OVCss.view_profile_form_title + ' mt-2 '}>Experience</div>
                </div>

                <div>
                    {/* <div className={OVCss.view_profile_form_small_title + ' mt-2'}>Untangible Asset</div> */}
                    {/* moe za proba */}
                    <div className={OVCss.view_profile_form_string_with_commas_text}>{experience.split(",").join(', ')}</div>
                    {/* <div className={OVCss.view_profile_form_normal_text}>{experience.split(",").join(' ')}</div> */}
                    {/* moe za proba */}
                </div>

            </div>
        </section>
    )
}

export default ExperienceOpportunitiesView