import {createSlice} from '@reduxjs/toolkit'
import api from "../config/api";
import chatapi from "../config/chatapi";

const initialAuth = localStorage.getItem('token')
    ? localStorage.getItem('token')
    : {}

const initialAccount = {
    data: {},
    isLoading: true,
    error: false,
}
const initialIntroduction = {
    data: {},
    isLoading: true,
    error: false,
}
const slice = createSlice({
    name: 'user',
    initialState: {
        auth: initialAuth,
        account: initialAccount,
        introduction: initialIntroduction,
        alert_num: 0,
        message_map:{}
    },
    reducers: {
        loginSuccess: (state, action) => {
            state.auth = action.payload;
            localStorage.setItem('token', action.payload.accessToken)
            localStorage.setItem('chat-token', action.payload.chatAuthToken)
            localStorage.setItem('chat-user-id', action.payload.chatUserId)
        },
        loginFail: (state, action) => {
            state.auth.error = action.payload;
        },
        logoutSuccess: (state, action) => {
            state.auth = {};
            localStorage.removeItem('token')
            window.location = '/login'
        },
        fetchAlertsSuccess: (state, action) =>{
        let alerts =  action.payload.filter(x => x.alert===true && x.rid !== 'GENERAL')
        state.alert_num  =alerts.length


        const user_map = {}
        for (const user_message of action.payload) {
            try{
                user_map[ user_message.rid] = user_message.unread;
            }
            catch(e){}
        }

        state.message_map = user_map;
        },
        fetchAccountSuccess: (state, action) => {
            state.account.data = action.payload
            state.account.isLoading = false
        },
        fetchIntroductionSuccess: (state, action) => {
            state.introduction.data = action.payload
            console.log(action.payload)
            state.introduction.isLoading = false
        },
        registerSuccess: (state, action) => {
            window.location = '/login'
        },
        profileSuccess: (state, action) => {
            state.account.data.individualUserProfile = action.payload
            state.account.isLoading = false
        },
        locationSuccess: (state, action) => {
            state.account.data.locations = action.payload
            state.account.isLoading = false
        },
        skillsSuccess: (state, action) => {
            state.introduction.data.skills = action.payload
        },
        industrySuccess: (state, action) => {
            state.introduction.data.industry = action.payload
        },
        educationSuccess: (state, action) => {
            state.introduction.data.educations = action.payload
        },
        experienceSuccess: (state, action) => {
            state.introduction.data.experiences = action.payload
        },
        requestBegin: (state, action) => {
            const {tab, part} = action.payload
            if (Array.isArray(state[tab].data[part])) {
                state[tab].data[part] = false
            } else {
                if (state[tab].data[part] === null)
                    state[tab].data[part] = {}
                state[tab].data[part].updating = true
            }
        },
        requestFinish: (state, action) => {
            const {tab, part} = action.payload
            if (typeof state[action.payload.tab].data[action.payload.part] === "boolean") {
                state[tab].data[part] = true
            } else {
                if (state[tab].data[part] === null)
                    state[tab].data[part] = {}
                state[tab].data[part].updating = false
            }
        },
    }
});
export default slice.reducer
// Actions
const {
    loginSuccess, loginFail, logoutSuccess, fetchAccountSuccess, fetchIntroductionSuccess, registerSuccess,
    skillsSuccess, industrySuccess, requestBegin, requestFinish, educationSuccess, experienceSuccess, profileSuccess, locationSuccess,fetchAlertsSuccess
} = slice.actions

export const login = (email, password) => async dispatch => {
    try {
        const res = await api.post('/api/auth/sign-in/', {email, password})
        dispatch(loginSuccess(res.data));
        console.log(res.data)

        document.querySelector('iframe').contentWindow.postMessage({
            externalCommand: 'login-with-token',
            token: res.data.chatAuthToken,
        }, '*');

    } catch (e) {
        console.log(e);
        dispatch(loginFail(e.response.data.message))
        return console.error(e.message);
    }


}

export const register = (data) => async dispatch => {
    try {
        const res = await api.post('/api/auth/sign-up/', data)
        dispatch(registerSuccess())
    } catch (e) {
        return console.error(e.message);
    }
}


export const logout = () => async dispatch => {
    try {
        return dispatch(logoutSuccess())
    } catch (e) {
        return console.error(e.message);
    }
}
export const fetchAccount = () => async dispatch => {
    try {
        const res = await api.get('/api/individual-user-account')
        console.log(res.data)
        return dispatch(fetchAccountSuccess(res.data))
    } catch (e) {
        return console.error(e.message);
    }
}

export const fetchAlerts = () => async dispatch => {

    try {
        const res = await chatapi.post('/api/v1/method.call/subscriptions%3Aget',
            {"message":"{\"msg\":\"method\",\"id\":\"11\",\"method\":\"subscriptions/get\",\"params\":[]}"}
        )
        const message = JSON.parse(res.data.message)


        return dispatch(fetchAlertsSuccess( message.result))
    } catch (e) {
        return console.error(e.message);
    }
}

export const fetchIntroduction = () => async dispatch => {
    try {
        const res = await api.get('/api/individual-user-introduction')
        return dispatch(fetchIntroductionSuccess(res.data))
    } catch (e) {
        return console.error(e.message);
    }
}

export const updateIndividualUserProfile = (data) => async dispatch => {
    dispatch(requestBegin({tab: 'account', part: 'individualUserProfile'}))
    try {
        const res = await api.put('/api/individual-user-profile-update', data)
        dispatch(requestFinish({tab: 'account', part: 'individualUserProfile'}))
        return dispatch(profileSuccess(res.data))
    } catch (e) {
        return console.error(e.message);
    }
}

export const updateIndividualUserContact = (data) => async dispatch => {
    dispatch(requestBegin({tab: 'account', part: 'locations'}))
    try {
        const res = await api.put('/api/individual-user-address', data)
        dispatch(requestFinish({tab: 'account', part: 'locations'}))
        return dispatch(locationSuccess(res.data))

    } catch (e) {
        return console.error(e.message);
    }
}
export const fetchSkills = () => async dispatch => {
    try {
        const res = await api.get('/api/individual-user-skills')
        return dispatch(skillsSuccess(res.data))
    } catch (e) {
        return console.error(e.message);
    }
}
export const fetchIndustry = () => async dispatch => {
    try {
        const res = await api.get('/api/individual-user-industry')
        return dispatch(industrySuccess(res.data))
    } catch (e) {
        return console.error(e.message);
    }
}

export const updateSkills = (data) => async dispatch => {
    dispatch(requestBegin({tab: 'introduction', part: 'skills'}))
    try {
        const res = await api.put('/api/individual-user-skills', data)
        dispatch(requestFinish({tab: 'introduction', part: 'skills'}))
        return dispatch(skillsSuccess(res.data))
    } catch (e) {
        return console.error(e.message);
    }
}
export const updateIndustry = (data) => async dispatch => {
    dispatch(requestBegin({tab: 'introduction', part: 'industry'}))
    try {
        const res = await api.put('/api/individual-user-industry', data)
        dispatch(requestFinish({tab: 'introduction', part: 'industry'}))
        return dispatch(industrySuccess(res.data))
    } catch (e) {
        return console.error(e.message);
    }
}

export const updateEducation = (data) => async dispatch => {
    dispatch(requestBegin({tab: 'introduction', part: 'educations'}))
    try {
        const res = await api.put('/api/individual-user-education', data)
        dispatch(requestFinish({tab: 'introduction', part: 'educations'}))
        return dispatch(educationSuccess(res.data))
    } catch (e) {
        return console.error(e.message);
    }
}

export const updateExperience = (data) => async dispatch => {
    dispatch(requestBegin({tab: 'introduction', part: 'experiences'}))
    try {
        const res = await api.put('/api/individual-user-experience', data)
        dispatch(requestFinish({tab: 'introduction', part: 'experiences'}))
        return dispatch(experienceSuccess(res.data))
    } catch (e) {
        return console.error(e.message);
    }
}

// mine added


export const postOpportunity = (data) => async dispatch => {
    try {
        const res = await api.post('/api/opportunity', data)
        console.log(data)
        // console.log(JSON.stringify(data))
    } catch (e) {
        return console.error(e.message);
    }
}

export const postNewOpportunity = (data) => async dispatch => {
    try {
        const res = await api.post('/api/opportunity-create', data)
        console.log(data)
        // console.log(JSON.stringify(data))
    } catch (e) {
        return console.error(e.message);
    }
}

export const postNewPurpose = (data) => async dispatch => {
    try {
        const res = await api.post('/api/purpose-create', data)
        console.log(data)
        // console.log(JSON.stringify(data))
    } catch (e) {
        return console.error(e.message);
    }
}