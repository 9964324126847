import {createSlice} from "@reduxjs/toolkit";
import api from "../config/api";

const initialProfile = {
    data: {},
    isLoading: true,
    error: false,
}

const slice = createSlice({
    name: 'users',
    initialState: {
        userProfileData: initialProfile
    },
    reducers: {
        fetchUserByIdSuccess: (state, action) => {
            state.userProfileData.data = action.payload
            state.userProfileData.isLoading = false
        },
    }
})

export default slice.reducer
// Actions
const {
    fetchUserByIdSuccess
} = slice.actions


export const fetchUserById = (id) => async dispatch => {
    try {
        const res = await api.get(`/api/individual-user/${id}`)
        console.log(res.data, 'resssssssssssssss')
        dispatch(fetchUserByIdSuccess(res.data))
    } catch (e) {
        return console.error(e.message);
    }
}
